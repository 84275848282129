import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../../../app/providers/common-data.service';
import { Helper_Class } from '../../../../app/helper_class';
import { ipaddress } from '../../../ipaddress';
// import { PharmacyService } from '../../../../pharmacy/pharmacy.service';
import { pharmacy_helper } from '../../../pharma/Pharmcy_Helper';
import { Message_data } from '../../../../assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
// import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-pharma-product-mst',
  templateUrl: './pharma-product-mst.component.html',
  styleUrls: ['./pharma-product-mst.component.css'],
  // animations: [
  //   trigger('toggleCover', [
  //     state('closed', style({
  //       height: '0',
  //       opacity: '0',
  //       overflow: 'hidden'
  //     })),
  //     state('open', style({
  //       height: '*',
  //       opacity: '1',
  //       overflow: 'hidden'
  //     })),
  //     transition('closed <=> open', [
  //       animate('300ms ease-in-out')
  //     ])
  //   ])
  // ]

})
export class PharmaProductMstComponent implements OnInit {
  public filteredList = [];
  public productFilterList:any = [];
  uomTypeArray: any = [];
  productID: string = "";
  uomListArray: any;
  public supplierListArray: any = [];
  public categoryTypeArray: any = [];
  public mdeicareTypeArray: any = [];
  public productTypeArray: any = [];
  public filteredprodList:any = [];
  public hsnNO: string = "";
  public productName: string = "";
  public shortName: string = "";
  public genericName: string = "";
  public productNameTxt: string;
  public productTypeID;
  public medicareMedID;
  public categoryCtgID;
  public uomCode;
  public manufacturerMfgID;
  public productType: any = [];
  public medicareType: any = [];
  public categoryType: any = [];
  public manufacturer: any = [];
  public quantity;
  public costPrice;
  public sellingPrice;
  public MRP:any;
  public prodDiscount;
  public product: any = [];
  public pharmacyID;
  public PharmauserID;
  public productMasterAddArray:any = [];
  public rackList:any=[];
  public binList:any=[];
  public productMasterIndex = 0;
  public productTypeList;
  public page_navigate: boolean;
  public prevPageCount: boolean;
  public nextPageCount: boolean;
  public newFlag: boolean;
  public existFlag: boolean;
  public prodType;
  public currentPage;
  public prodDataCount;
  public mainCount;
  public pageList;
  public pageSize;
  public noOfPages: any
  public userInfo;
  public prodCenteralGST;
  public prodStateGST;
  public suppID;
  public suppName;
  public suppIdData;
  public supplierList: any = [];
  public manufacList:any = [];
  public scheduleType;
  public scheduleList;
  public saveFlag: boolean = true;
  private loc_type;
  public mfg_name:string;
  public supp_name: string;
  public rackid:string;
  public rackidname:string;
  public binid:string;
  public binnameng:string;
  public min_qty:string;
  public editbutton:boolean=false;
  public newbutton:boolean=false;
  public deletebutton:boolean=false;
  public printbutton:boolean=false;
  public viewbutton:boolean=false;
  public danger_lvl;
  public ReOrder_lvl;
  public max_lvl;
  public expirydura;
  public genericlist;
  public dur;
  public supplierdata:boolean=true;
  public perunittext;
  public perunit=[];
  public tableviewflag:boolean;
  public allowdiscount;
  public discountaccepted:boolean;
  public nextflag:boolean;
  public backflag:boolean;
  public arraytoedit:any=[];
  public rowid;
  public data_type:string;
  public department_id;
  public store_id;
  public hospitalid;
  public look_alike:boolean = false;
  public sound_alike:boolean  = false;
  public hight_risk:boolean  = false;
  public ward_only:boolean  = false;
  public lookalike_value;
  public sound_alike_value;
  public hight_risk_value;
  public ward_only_value;
  coverOpen = false;
  public editflag:boolean;
  public clearflag:boolean;
  public hide_show_btn;
  isCoverVisible: boolean = true;



  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public pharmaservice: MenuViewService) {
    this.page_navigate = true;
    this.mainCount = 1;
    this.pageList = "50";
    this.currentPage = 0;
    this.pageSize = 50;
    this.prodType = "New";
    this.newFlag = false;
    this.existFlag = true;
    this.loc_type="trans";
    this.quantity="";
    this.MRP="";
    this.prodCenteralGST="";
    this.prodStateGST="";
  }

  ngOnInit(): void {
   this.nextflag=true;
  this.backflag=false;
    this.productType = "Select";
    this.medicareType = "Select";
    this.categoryType = "Select";
    this.uomTypeArray = "Select";
    this.manufacturer = "Select";
    this.dur="month";
    this.expirydura=3;
    this.tableviewflag=false;
    this.userInfo = Helper_Class.getInfo();
    
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      this.PharmauserID = this.userInfo.po_approver;
      this.data_type='hospital'
    }else if(Helper_Class.getInfo().user_type == 'pharmacy'){
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.PharmauserID = this.userInfo.user_id;
      this.data_type='pharmacy'
    }
    else{
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.hospitalid = Helper_Class.getInfo().hospitals[0].hosp_id	;
      this.PharmauserID = this.userInfo.user_id;
      this.data_type='hospital'
    }
    
    // this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    this.GetProductdata();
    this.getMedicare();
    this.getCategory();
    //this.getManufacturer();
    this.getUOM();
    this.getProductCount();
    this.retrieveProduct();
   
    this.getSchedule();
     this.getRacks();
     this.getBins();
     this.perunitfunction();
    this.department_id=this.userInfo.hospitals[0].department_id;
    this.store_id=this.userInfo.hospitals[0].store_id	;

    if (pharmacy_helper.getManufacturer() == true || pharmacy_helper.getSupplier() == true) {
      if (pharmacy_helper.getProductMst() != undefined) {
        var productMst = pharmacy_helper.getProductMst();
        this.hsnNO = productMst.hsn_no;
        this.productName = productMst.name;
        this.shortName = productMst.short_name;
        this.genericName = productMst.generic_name;
        this.productTypeID = productMst.product_type_id;
        this.medicareMedID = productMst.medicare_id;
        this.categoryCtgID = productMst.category_type_id;
        this.uomCode = productMst.uom_code;
        this.suppID = productMst.supp_id;
        this.manufacturerMfgID = productMst.mfg_id;
        this.quantity = productMst.per_unit;
        this.costPrice = productMst.cost_price;
        this.sellingPrice = productMst.sales_price;
        this.MRP = productMst.MRP;
        this.prodCenteralGST = productMst.prod_cgst;
        this.prodStateGST = productMst.prod_sgst;
        pharmacy_helper.setProductMst(undefined);
        if (pharmacy_helper.getManufacturer() == true) {
          pharmacy_helper.setManufacturer(undefined);
        } else {
          pharmacy_helper.setSupplier(undefined);
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    } else {
      
    if(Helper_Class.getmodulelist() != undefined){
    
      console.log("id"+JSON.stringify(Helper_Class.getmodulelist()))
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
      
        if (Helper_Class.getmodulelist()[i].module_id == "53"){
         
          if(Helper_Class.getmodulelist()[i].edit == "1"){
              this.editbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].create == "1"){
            
              this.newbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].delete == "1"){
              this.deletebutton=true;
            }
            if(Helper_Class.getmodulelist()[i].print == "1"){
              this.printbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].view == "1"){
              this.viewbutton=true;
            } 
        }else{
          this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
        }
      }
    }
    }
    this.getSuppliers();
    this.hide_show_btn="../../../assets/ui_icons/buttons/up.svg";
    this.editflag=false;
    this.clearflag=false;
  }

  productTypeSelect() {
    if (this.prodType == "New") {
      this.newFlag = false;
      this.existFlag = true;
      this.hsnNO = "";
      this.productName = "";
      this.shortName = "";
      this.genericName = "";
      this.productType = null;
      this.medicareType = null;
      this.categoryType = null;
      this.uomTypeArray = null;
      this.manufacturer = null;
      this.quantity = "";
      this.costPrice = "";
      this.sellingPrice = "";
      this.MRP = "";
      this.prodCenteralGST = "";
      this.prodStateGST = "";
      this.prodDiscount = "";
    } else {
      this.newFlag = true;
      this.existFlag = false;
    }
  }

  perunitfunction(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inv/gump',
      JSON.stringify({
        pharma_id: this.hospitalid,
        type:this.data_type
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("per unit = "+JSON.stringify(obj))
          for(let i=0; i < obj.measures.length;i++){
            this.perunit.push({
              perunit_id:obj.measures[i].perunit_id,
              perunit_desc:obj.measures[i].perunit_desc
            })
          }
          console.log("perunit = "+JSON.stringify(obj))
        })
  }

  getRacks(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/grbd',
      JSON.stringify({
        pharma_id: this.pharmacyID,
        dept_id:this.department_id,
        store_id:this.store_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("getRACKS = "+JSON.stringify(obj));
          if(obj.racks != undefined){
            this.rackList = obj.racks;
          }else{
            this.rackList = []; // Initialize as empty array if bins are not available
          }
        
        
        
        })
  }

  getBins(){
    var send;
  
    send={
      pharma_id: this.pharmacyID,
      rackid:this.rackidname,
      dept_id:this.department_id,
      store_id:this.store_id
    }
    console.log(" data = "+JSON.stringify(send))
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gbbr',
      JSON.stringify({
        pharma_id: this.pharmacyID,
        rackid:this.rackidname,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("getbins = "+JSON.stringify(obj));
          if(obj.bins != undefined){
            this.binList = obj.bins;
          }else{
            this.binList = []; // Initialize as empty array if bins are not available
          }
           
          // for(var j=0;j<this.binList.length;j++){
          //   this.binList.push({
          //     binid:this.binList[j].binid,
          //     binname:this.binList[j].binname
          //   })
          // }
          
          console.log("bin list = "+JSON.stringify(this.binList))
        })
  }

  getProductCount() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gcount',
      JSON.stringify({
        pharma_id: this.pharmacyID,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.page_navigate = obj.product_count > 50 ? false : true;
          this.prodDataCount = obj.product_count;
        },
        error => { });
  }

  pagelistChange() {
    this.pageSize = this.pageList;
    this.currentPage = 1;
    this.getProductCount();
    if (this.pageList == "50") {
      if (this.prodDataCount > 50) {
        this.page_navigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.page_navigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }

    } else if (this.pageList == "100") {
      if (this.prodDataCount > 100) {
        this.page_navigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.page_navigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }

    } else if (this.pageList == "200") {
      if (this.prodDataCount > 200) {
        this.page_navigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.page_navigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    }
    this.retrieveProduct();
  }

  pageLimitnext() {
    this.currentPage = this.currentPage + 1;
    this.pageSize = this.pageList;
    this.getProductCount();
    this.retrieveProduct();
    this.prevPageCount = false;
    if (this.currentPage == this.noOfPages) {
      this.nextPageCount = true;
    }
  }

  pageLimitPrevious() {
    this.pageSize = this.pageList;
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.getProductCount();
      this.retrieveProduct();
      this.nextPageCount = false;
      if (this.currentPage == "1") {
        this.prevPageCount = true;
      }
    } else {
      this.prevPageCount = true;
    }
  }

  numberOfPages() {
   
    this.noOfPages = Math.ceil(this.prodDataCount / this.pageList);
    return Math.ceil(this.prodDataCount / this.pageList);
  }

  productFilter() {
    if (this.productNameTxt !== "" && this.productNameTxt.length > 2) {
      this.page_navigate = true;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/prodByName/',
        JSON.stringify({
          prod_name: this.productNameTxt,
          pharmacy_id: this.pharmacyID,
          dept_id:this.department_id,
          store_id:this.store_id,

        }),
        { headers: headers })
        .subscribe(
          response => {
            console.log(" productfilter name = "+ JSON.stringify({
              prod_name: this.productNameTxt,
              pharmacy_id: this.pharmacyID,
              dept_id:this.department_id,
              store_id:this.store_id,
    
            }),)
            this.productMasterAddArray = [];
            var obj = JSON.parse(response["_body"]);
            console.log("product name = "+JSON.stringify(obj))
            if (obj.products != null) {
              this.productMasterAddArray = [];
              this.pageList = "50";
              this.page_navigate = false;
              this.productMasterIndex = 0;
              var ranking;
              for (var i = 0; i < obj.products.length; i++) {
                if(obj.products[i].rank != undefined){
                  ranking=obj.products[i].rank
                }else{
                  ranking=''
                }
                
                this.productMasterAddArray.push({
                  Index: this.productMasterIndex,
                  hsn_no: obj.products[i].hsn_no,
                  name: obj.products[i].name,
                  short_name: obj.products[i].short_name,
                  generic_name: obj.products[i].generic_name,
                  medicare_id: obj.products[i].medicare_id,
                  medicare_name: obj.products[i].medicare_name,
                  product_type_id: obj.products[i].product_type_id,
                  product_type_desc: obj.products[i].product_type_desc,
                  category_type_id: obj.products[i].category_type_id,
                  category_name: obj.products[i].category_name,
                  uom_code: obj.products[i].uom_code,
                  uom_desc: obj.products[i].uom_desc,
                  mfg_id: obj.products[i].mfg_id,
                  mfg_name: obj.products[i].mfg_name,
                  product_id: obj.products[i].product_id,
                  pharmacist_id: this.PharmauserID.toString(),
                  per_unit: obj.products[i].per_unit,
                  cost_price: obj.products[i].cost_price,
                  sales_price: obj.products[i].sales_price,
                  MRP: obj.products[i].MRP,
                  prod_cgst: obj.products[i].prod_cgst,
                  prod_sgst: obj.products[i].prod_sgst,
                  prod_disc: obj.products[i].prod_disc,
                  supp_id: obj.products[i].supp_id,
                  supp_name: obj.products[i].supp_name,
                  schedule_type_id: obj.products[i].schedule_type_id,
                  schedule_type_desc: obj.products[i].schedule_type_desc,
                  type:obj.products[i].type,
                  rackid:obj.products[i].rackid,
                  binid:obj.products[i].binid,
                  min_qty:obj.products[i].min_level,	
                  danger_level:obj.products[i].danger_level,	
                  max_level:obj.products[i].max_level,
                  reorder_level:obj.products[i].reorder_level,
                  expiry_dur:obj.products[i].expiry_dur,
                  expiry_measure:obj.products[i].expiry_measure,
                  look_alike:obj.products[i].look_alike,
                  sound_alike:obj.products[i].sound_alike,
                  high_risk:obj.products[i].high_risk,
                  ward_only:obj.products[i].ward_only,
                  ranking:	ranking
                });
                this.productMasterIndex++;
              }
              this.productMasterAddArray.sort((a, b) => {
                // Convert ranking to numbers if possible, otherwise treat as 0
                const rankA = isNaN(Number(a.ranking)) ? 0 : Number(a.ranking);
                const rankB = isNaN(Number(b.ranking)) ? 0 : Number(b.ranking);
              
                // Return sorting order: ascending (rankA - rankB)
                return rankA - rankB;
              });
            } else {
              this.toastr.error(Message_data.noPrd);
            }
          },
          error => {}
        )
    } else {
      this.filteredprodList = [];
      this.page_navigate = false;
      this.pageList = "50";
      this.retrieveProduct();
    }
  }

  GetProductdata() {
    this.http.get(ipaddress.getIp + "pharmacontrol/mtypes/").subscribe(
      data => {
        if (data.json().med_details != null) {
          this.productTypeArray = data.json().med_details;
        }
      },
      error => { });
  }

  getMedicare() {
    this.http.get(ipaddress.getIp + "usercontroller/getmedicare/").subscribe(
      data => {
        if (data.json().medicare != null) {
          this.mdeicareTypeArray = data.json().medicare;
          this.medicareMedID = this.mdeicareTypeArray[0].medicare_id;
        }
      },
      error => { });
  }

  getCategory() {
    this.http.get(ipaddress.getIp + "pharmacontrol/gpcat/").subscribe(
      data => {
        if (data.json().product_categories != null) {
          this.categoryTypeArray = data.json().product_categories;
          this.categoryTypeArray.forEach(element => {
          });
        }
      },
      error => { });
  }

  getManufacturer() {
    if(this.mfg_name != undefined && this.mfg_name.length >2){
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/gman/", JSON.stringify({ pharmacy_id: this.pharmacyID,name:this.mfg_name }), { headers: headers }).subscribe(
        data => {
          var obj=data.json().manuf_details;
          console.log(" obj = "+JSON.stringify(obj))
          if (data.json().manuf_details != null) {
            this.manufacList = data.json().manuf_details;
          }
        },
        error => { });
    }
  }

  manufacturer_change(manufacturer){
    this.manufacturerMfgID = manufacturer.mfg_id;
    this.mfg_name = manufacturer.name;
    this.manufacList=[];
  }

  getUOM() {
    var send_data;
    if(this.userInfo.user_type == "pharmacy"){
      send_data={
        pharmacy_id: this.pharmacyID, 
         type:this.data_type
      }
    }else[
      send_data={
        pharmacy_id: this.hospitalid, 
           type:this.data_type
      }
    ]
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/guom/", 
      // JSON.stringify({ 
      //   pharmacy_id: this.pharmacyID, 
      //   type:this.data_type
      // })
      send_data
      , { headers: headers }).subscribe(
      data => {
        if (data.json().product_uoms != null) {
          this.uomListArray = data.json().product_uoms;
        }
      },
      error => { });
  }

  saveData() {
    if(this.look_alike == true){
      this.lookalike_value="1";
    }else{
      this.lookalike_value="0";
    }
    if(this.sound_alike == true){
      this. sound_alike_value="1";
    }else{
      this. sound_alike_value="0"
    }
    if(this.hight_risk == true){
      this. hight_risk_value="1"
    }else{
      this. hight_risk_value="0"
    }
    if(this.ward_only == true){
      this. ward_only_value="1"
    }else{
      this. ward_only_value="0"
    }
    if (this.hsnNO == "" || this.hsnNO == undefined || this.productName == "" || this.productName == undefined ||
      this.productTypeID == undefined || this.medicareMedID == undefined || this.quantity == "" || this.quantity == undefined
      || this.MRP == undefined || this.MRP == "" || this.supp_name =="" || this.supp_name == undefined) {
        console.log("h"+this.hsnNO)
        console.log("p"+this.productName)
        console.log("pr id"+this.productTypeID)
        console.log("med"+this.medicareMedID)
        console.log("qu"+this.quantity)
        console.log("mr"+this.MRP)
        console.log("uo"+this.uomCode)
        console.log("su"+this.supp_name)
        // console.log()
        // console.log()
        // console.log()

        this.toastr.error(Message_data.mandatory);
    }else if( this.expirydura == ""){
      this.toastr.error("Enter the Expiery duration");
    }else if(this.binid == ""){
      this.toastr.error("Enter the bin number");
    } 
    else {
      var cattype;
      var catname;
      if (this.productTypeID != undefined) {
        for (var i = 0; i < this.productTypeArray.length; i++) {
          if (this.productTypeID == this.productTypeArray[i].med_code) {
            this.productType = this.productTypeArray[i];
          }
        }
      }

      if (this.medicareMedID != undefined) {
        for (var i = 0; i < this.mdeicareTypeArray.length; i++) {
          if (this.medicareMedID == this.mdeicareTypeArray[i].medicare_id) {
            this.medicareType = this.mdeicareTypeArray[i];
          }
        }
      }

      if (this.categoryCtgID != undefined) {
        for (var i = 0; i < this.categoryTypeArray.length; i++) {
          if (this.categoryCtgID == this.categoryTypeArray[i].category_id) {
            this.categoryType = this.categoryTypeArray[i];
          }
        }
        if (this.categoryType != undefined) {
          cattype = this.categoryType.category_id;
          catname = this.categoryType.category_name;
        }
      }

      if (this.uomCode != undefined) {
        for (var i = 0; i < this.uomListArray.length; i++) {
          if (this.uomCode == this.uomListArray[i].uom_code) {
            this.uomTypeArray = this.uomListArray[i];
          }
        }
      }

      if (this.manufacturerMfgID != undefined) {
        for (var i = 0; i < this.manufacList.length; i++) {
          if (this.manufacturerMfgID == this.manufacList[i].mfg_id) {
            this.manufacturer = this.manufacList[i];
          }
        }
      }

      this.allowdiscount=this.allowdiscount != true ? "0":"1";
      this.productID = this.productID != "" ? this.productID : "0";
      var send_data = JSON.stringify({
        hsn_no: this.hsnNO,
        name: this.productName,
        short_name: this.shortName,
        generic_name: this.genericName,
        medicare_id: this.medicareType.medicare_id,
        medicare_name: this.medicareType.medicare_name,
        product_type_id: this.productType.med_code,
        product_type_desc: this.productType.med_name,
        
        category_type_id: cattype,
        category_name: catname,
        uom_code: this.uomCode != undefined && '' ? this.uomTypeArray.uom_code : undefined,
        uom_desc: this.uomCode != undefined && '' ? this.uomTypeArray.uom_desc : undefined,
        mfg_id:  this.manufacturer != undefined && '' ? this.manufacturer.mfg_id : undefined,
        mfg_name: this.manufacturer != undefined && '' ? this.manufacturer.name : undefined, 
        product_id: this.productID,
        per_unit: this.quantity,
        cost_price: this.costPrice,
        sales_price: this.sellingPrice,
        MRP: this.MRP,
        prod_cgst: this.prodCenteralGST,
        prod_sgst: this.prodStateGST,
        prod_disc: this.prodDiscount,
        pharmacy_id: this.pharmacyID,
      
        supp_id: this.suppID, 
        schedule_type_id: this.scheduleType,
        type:this.loc_type,
        rackid:this.rackidname,
        binid:this.binnameng,
        min_level:this.min_qty,
        max_level:this.max_lvl,
        danger_level:this.danger_lvl,
        reorder_level:this.ReOrder_lvl,
        // perunit_desc:this.perunittext,
        expiry_dur:this.expirydura,
        expiry_measure:this.dur,
        is_disc_allow:this.allowdiscount,
        look_alike:this.lookalike_value,
        sound_alike:this.sound_alike_value,
        high_risk:this.hight_risk_value,
        ward_only:this.ward_only_value,
        dept_id:this.department_id,
        store_id:this.store_id
      });
      
      console.log(" SEND DATA = "+send_data)
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/sprodcode/", send_data, { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          if (obj != undefined && obj.key == "1") {
            this.saveFlag = false;
            this.toastr.success(Message_data.prdUpldSuccess);
            this.retrieveProduct();
            this.productNameTxt="";
            this.hsnNO = "";
            this.productName = "";
            this.shortName = "";
            this.genericName = "";
            this.costPrice = undefined;
            this.sellingPrice = undefined;
            this.MRP = "";
            this.quantity = "";
            this.prodCenteralGST = "";
            this.prodStateGST = "";
            this.prodDiscount = undefined;
            this.productTypeID = "";
            this.manufacturerMfgID = undefined;
            this.suppID = undefined;
            this.categoryCtgID = "";
            this.uomCode = "";
            this.medicareType = undefined;
            this.productType = undefined;
            this.categoryType = undefined;
            this.uomTypeArray = undefined;
            this.manufacturer = undefined;
            this.scheduleType = undefined;
            this.prodType = "New";
            this.loc_type="trans";
            this.min_qty="";
            this.supp_name="";
            this.mfg_name="";
            this.min_qty="",
            this.max_lvl="",
            this.danger_lvl="";
            this.ReOrder_lvl="";
            this.expirydura="";
            this.dur="";
            this.rackidname="";
            this.binnameng="";
            this.discountaccepted=false;
           this.look_alike = false;
           this.sound_alike  = false;
           this.hight_risk = false;
           this.ward_only = false;
           this.lookalike_value=0;
           this.sound_alike_value=0;
           this. hight_risk_value=0;
           this.ward_only_value=0;
            
          } else {
            this.saveFlag = true;
            this.toastr.error(Message_data.unableToSave);
          }
        },
        error => { this.saveFlag = true; });
    }
  }

  retrieveProduct() {
    var send_data = {
      pharmacist_id: this.PharmauserID,
      pharmacy_id: this.pharmacyID,
      imei: Helper_Class.getIPAddress(),
      limit: this.pageSize,
      start_no: this.currentPage,
      dept_id:this.department_id,
      store_id:this.store_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gpcbyphar",
      JSON.stringify(send_data),
      { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          console.log("retrive product = "+JSON.stringify(obj))
          if (obj != null && obj.products != null) {
            this.productMasterAddArray = [];
            this.productMasterIndex = 0;
            var ranking;
            for (var i = 0; i < obj.products.length; i++) {
              var pharid;
              if(this.PharmauserID != undefined) {
                pharid = this.PharmauserID.toString();
              }
              // if(obj.products[i].ranking != undefined){
              //   ranking=obj.products[i].ranking
              // }else{
              //   ranking=''
              // }
                if(obj.products[i].rank != undefined){
                  ranking=obj.products[i].rank
                }else{
                  ranking=''
                }
              this.productMasterAddArray.push({
                Index: this.productMasterIndex,
                hsn_no: obj.products[i].hsn_no,
                name: obj.products[i].name,
                short_name: obj.products[i].short_name,
                generic_name: obj.products[i].generic_name,
                medicare_id: obj.products[i].medicare_id,
                medicare_name: obj.products[i].medicare_name,
                product_type_id: obj.products[i].product_type_id,
                product_type_desc: obj.products[i].product_type_desc,
                category_type_id: obj.products[i].category_type_id,
                category_name: obj.products[i].category_name,
                uom_code: obj.products[i].uom_code,
                uom_desc: obj.products[i].uom_desc,
                mfg_id: obj.products[i].mfg_id,
                mfg_name: obj.products[i].mfg_name,
                product_id: obj.products[i].product_id,
                pharmacist_id: pharid,
                per_unit: obj.products[i].per_unit,
                cost_price: obj.products[i].cost_price,
                sales_price: obj.products[i].sales_price,
                MRP: obj.products[i].MRP||0,
                prod_cgst: obj.products[i].prod_cgst,
                prod_sgst: obj.products[i].prod_sgst,
                prod_disc: obj.products[i].prod_disc,
                supp_id: obj.products[i].supp_id,
                supp_name: obj.products[i].supp_name,
                schedule_type_id: obj.products[i].schedule_type_id,
                schedule_type_desc: obj.products[i].schedule_type_desc,
                type:obj.products[i].type,
                rackid:obj.products[i].rackid,
                binid:obj.products[i].binid,
                min_qty:obj.products[i].min_level,
                max_level:obj.products[i].max_level,
                danger_level:obj.products[i].danger_level,
                reorder_level:obj.products[i].reorder_level,
                expiry_dur:obj.products[i].expiry_dur,
                expiry_measure:obj.products[i].expiry_measure,	
                is_disc_allow:obj.products[i].is_disc_allow,
                look_alike:obj.products[i].look_alike,
                sound_alike:obj.products[i].sound_alike,
                high_risk:obj.products[i].high_risk,
                ward_only:obj.products[i].ward_only,
                ranking:ranking
              });
              this.productMasterIndex++;
            }
          }
        },
        error => { }
      )
  }

  productNameFilter(e) {
    this.filteredList = [];
    this.productName = e.target.value.toString();
    if (this.productName !== "" && this.productName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gpbyn',
        JSON.stringify({
          product_name: this.productName,
          // dept_id:this.department_id,
          // store_id:this.store_id,
        }),
        { headers: headers })
        .subscribe(
          response => {
            console.log( "product filter send_data = "+JSON.stringify({
              product_name: this.productName,
              dept_id:this.department_id,
              store_id:this.store_id,
            }))
            var hospital_txt:any = [];
            var obj = JSON.parse(response["_body"]);
            if (obj.products != null) {
              this.productFilterList = obj.products;
              for (var i = 0; i < this.productFilterList.length; i++) {
                
                hospital_txt.push(this.productFilterList[i].name);
                console.log("name "+JSON.stringify(hospital_txt))
              }
              this.filteredList = hospital_txt.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.product_name.toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => { }
        )
    } else {
      this.filteredList = [];
    }
  }

  genericNameFilter(e) {
    this.filteredList = [];
    this.genericName = e.target.value.toString();
    if (this.genericName !== "" && this.genericName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'mas/ggmbn',
        JSON.stringify({
          name: this.genericName,
          pharma_id:this.pharmacyID
        }),
        { headers: headers })
        .subscribe(
          response => {
            var hospital_txt:any = [];
            var obj = JSON.parse(response["_body"]);
            console.log("generic name = "+JSON.stringify(obj))
            if (obj.generic_list != null) {
              this.genericlist = obj.generic_list;
              for (var i = 0; i < this.genericlist.length; i++) {
                
                hospital_txt.push(this.genericlist[i].generic_name);
                console.log("name "+JSON.stringify(hospital_txt))
              }
              this.genericlist = hospital_txt.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.genericName.toLowerCase()) > -1;
              }.bind(this));
              console.log("ADDER "+JSON.stringify(this.genericlist))
            }
          },
          error => { }
        )
    } else {
      this.filteredList = [];
    }
  }

  selectGEN(GEN){
this.genericName=GEN
  }
  savegen(){
    var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'mas/sgm',
        JSON.stringify({
          generic_name: this.genericName,
          pharma_id:this.pharmacyID
        }),
        { headers: headers })
        .subscribe(
          response => {
            var hospital_txt:any = [];
            var obj = JSON.parse(response["_body"]);
            console.log("generic name = "+JSON.stringify(obj))
            // if (obj.products != null) {
            //   this.productFilterList = obj.products;
            //   for (var i = 0; i < this.productFilterList.length; i++) {
                
            //     hospital_txt.push(this.productFilterList[i].name);
            //     console.log("name "+JSON.stringify(hospital_txt))
            //   }
            //   this.filteredList = hospital_txt.filter(function (this: any, el) {
            //     return el.toLowerCase().indexOf(this.product_name.toLowerCase()) > -1;
            //   }.bind(this));
            // }
          },
          error => { }
        )
    
  }



  selectItem(data) {
    this.productName = data;
    this.filteredList = [];
    for (var i = 0; i < this.productFilterList.length; i++) {
      if (data == this.productFilterList[i].name) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gpd',
          JSON.stringify({
            product_id: this.productFilterList[i].product_id
          }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              if (obj.hsn_no != null) {
                this.hsnNO = obj.hsn_no;
                this.shortName = obj.short_name;
                this.genericName = obj.generic_name;
                this.quantity = obj.per_unit;;
                this.productID = obj.product_id;
                for (var j = 0; j < this.mdeicareTypeArray.length; j++) {
                  if (this.mdeicareTypeArray[j].medicare_id == obj.medicare_id) {
                    this.medicareType = this.mdeicareTypeArray[j];
                    break;
                  }
                }
                for (var j = 0; j < this.productTypeArray.length; j++) {
                  if (this.productTypeArray[j].med_code == obj.product_type_id) {
                    this.productType = this.productTypeArray[j];
                    break;
                  }
                }
                for (var j = 0; j < this.categoryTypeArray.length; j++) {
                  if (this.categoryTypeArray[j].category_id == obj.category_type_id) {
                    this.categoryType = this.categoryTypeArray[j];
                    break;
                  }
                }
                for (var j = 0; j < this.uomListArray.length; j++) {
                  if (this.uomListArray[j].uom_code == obj.uom_code) {
                    this.uomTypeArray = this.uomListArray[j];
                    break;
                  }
                }
                for (var j = 0; j < this.manufacList.length; j++) {
                  if (this.manufacList[j].mfg_id == obj.mfg_id) {
                    this.manufacturer = this.manufacList[j];
                    break;
                  }
                }
              }
            },
            error => {
              this.toastr.error(JSON.stringify(error.json()));
            }
          )
      }
    }
  }

  editProductMaster(data,productmaster) {

    console.log(" data = "+JSON.stringify(data))
    
    if(this.productName.length !=0 || this.shortName.length !=0 ){
      this.toastr.error("Allready product filled the filed save befor edit")
    }
    else if (this.productMasterAddArray.length != 0) {
      this.prodType = "Existing";
      for (var i = 0; i < this.productMasterAddArray.length; i++) {
        if (this.productMasterAddArray[i].Index == data) {
          console.log(" product = "+JSON.stringify(this.productMasterAddArray[i]))
          this.tableviewflag=false;
          this.productID = this.productMasterAddArray[i].product_id;
          this.hsnNO = this.productMasterAddArray[i].hsn_no;
          this.rowid = this.productMasterAddArray[i].Index;
          this.productName = this.productMasterAddArray[i].name;
          this.shortName = this.productMasterAddArray[i].short_name;
          this.genericName = this.productMasterAddArray[i].generic_name;
          this.suppID = this.productMasterAddArray[i].supp_id;
          this.supp_name = this.productMasterAddArray[i].supp_name;
          this.manufacturerMfgID = this.productMasterAddArray[i].mfg_id;
          this.mfg_name = this.productMasterAddArray[i].mfg_name;
          console.log("RACK = "+JSON.stringify(this.productMasterAddArray[i].rackid))
          this.rackidname = this.productMasterAddArray[i].rackid;
          if(this.rackidname != undefined){
            this.getBins();
         }
          this.binnameng = this.productMasterAddArray[i].binid;
          this.min_qty = this.productMasterAddArray[i].min_qty;
          this.danger_lvl= this.productMasterAddArray[i].danger_level;
          this. ReOrder_lvl= this.productMasterAddArray[i].reorder_level;
          this. max_lvl= this.productMasterAddArray[i].max_level;
          if(this.productMasterAddArray[i].supp_id != undefined){
            this.suppID = this.productMasterAddArray[i].supp_id.toString();
          }
          this.quantity = parseInt(this.productMasterAddArray[i].per_unit);
          this.prodCenteralGST = this.productMasterAddArray[i].prod_cgst,
          this.prodStateGST = this.productMasterAddArray[i].prod_sgst,
          this.loc_type=this.productMasterAddArray[i].type;
         if(this.productMasterAddArray[i].expiry_dur != undefined && this.productMasterAddArray[i].expiry_dur != ''){
          this.expirydura=this.productMasterAddArray[i].expiry_dur
         }else{
          this.expirydura=3;
         }
          if(this.productMasterAddArray[i].expiry_measure != undefined && this.productMasterAddArray[i].expiry_measure != ''){
            this.dur=this.productMasterAddArray[i].expiry_measure
          }else{
            this.dur="month";
          }
          if(this.productMasterAddArray[i].is_disc_allow != undefined){
            this.allowdiscount=this.productMasterAddArray[i].is_disc_allow 
          }else{
            this.allowdiscount=1
          }

          if(this.allowdiscount == 1){
            this.discountaccepted=true
          }
          else
          {
            this.discountaccepted=false
          }
        
          for (var j = 0; j < this.productTypeArray.length; j++) {
            if (this.productTypeArray[j].med_code == this.productMasterAddArray[i].product_type_id) {
              this.productType = this.productTypeArray[j];
              this.productTypeID = this.productMasterAddArray[i].product_type_id;
            }
          }
          for (var j = 0; j < this.mdeicareTypeArray.length; j++) {
            if (this.mdeicareTypeArray[j].medicare_id == this.productMasterAddArray[i].medicare_id) {
              this.medicareType = this.mdeicareTypeArray[j];
              this.medicareMedID = this.productMasterAddArray[i].medicare_id;
            }
          }
          for (var j = 0; j < this.categoryTypeArray.length; j++) {
            if (this.categoryTypeArray[j].category_id == this.productMasterAddArray[i].category_type_id) {
              this.categoryType = this.categoryTypeArray[j];
              this.categoryCtgID = this.productMasterAddArray[i].category_type_id;
            }
          }
          for (var j = 0; j < this.uomListArray.length; j++) {
            if (this.uomListArray[j].uom_code == this.productMasterAddArray[i].uom_code) {
              this.uomTypeArray = this.uomListArray[j];
              this.uomCode = this.productMasterAddArray[i].uom_code;
            }
          }
          if (this.productMasterAddArray[i].schedule_type_id != undefined){
            //this.s = this.productMasterAddArray[i].schedule_type_id;
            this.scheduleType = this.productMasterAddArray[i].schedule_type_id;
          }
        
          if(this.productMasterAddArray[i].cost_price!= undefined){
            this.costPrice = this.productMasterAddArray[i].cost_price;
          }
          if(this.productMasterAddArray[i].sales_price != undefined){
            this.sellingPrice = this.productMasterAddArray[i].sales_price;
          }
          
          if(this.productMasterAddArray[i].MRP != undefined){
            this.MRP = this.productMasterAddArray[i].MRP.toString();
          }
          else{
            this.MRP = ''
          }

          if(this.productMasterAddArray[i].prod_disc != undefined){
            this.prodDiscount = this.productMasterAddArray[i].prod_disc;
          }

          if(this.productMasterAddArray[i].look_alike == "1"){
            this.look_alike = true
          }
          else{
            this.look_alike = false
          }

          if(this.productMasterAddArray[i].sound_alike == "1"){
            this.sound_alike = true
          }
          else{
            this.sound_alike = false
          }
          if(this.productMasterAddArray[i].high_risk == "1"){
            this.hight_risk = true
          }
          else{
            this.hight_risk = false
          }

          if(this.productMasterAddArray[i].ward_only == "1"){
            this.ward_only = true
          }
          else{
            this.ward_only = false
          }
          // look_alike:obj.products[i].look_alike,
          // sound_alike:obj.products[i].sound_alike,
          // high_risk:obj.products[i].high_risk,
          // ward_only:obj.products[i].ward_only,




           this.productMasterAddArray.splice(i, 1);
           this.arraytoedit.push(productmaster)
        }
      }
      this.editflag=true;
      this.toggleCover()
    }
  }

  getSuppliers() {
    // if(this.supp_name != undefined && this.supp_name.length >2){
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/gsup/",
        JSON.stringify({
          pharmacy_id: this.pharmacyID,
          supp_name: this.supp_name,
          dept_id:this.department_id,
          store_id:this.store_id
        }), { headers: headers }).subscribe(
          data => {
           
             var obj=data.json().supplier_details;
             console.log("supplier name  = "+JSON.stringify(obj))
           for(var i=0;i<obj.length;i++){
             // this.supplierList = [];

             var exists = this.supplierList.some(function(supplier) {
              return supplier.supp_id === obj[i].supp_id;
          });
            // if (data.json().supplier_details != null && data.json().supplier_details.length != 0) {
            //   this.supplierList = data.json().supplier_details;
            // }
            if (!exists) {
            this.supplierList.push({
              name:obj[i].name,
              supp_id:obj[i].supp_id,
              supp_code:obj[i].supp_code,
              gst_no:obj[i].gst_no
            })
          }
          }
          },
          error => {
            this.toastr.error(Message_data.unabletofetsupl);
          }
        )
    // }
    
  }

  
  supplierdatafunction(data){
    this.suppID=data.supp_id
  }

 supplier_change(event: any,supplier){
  
    this.suppID = supplier.supp_id;
    this.supp_name = supplier.name;
  }

  supplier_changee() {
    // Find the selected supplier based on the input value
    const selectedSupplier = this.supplierList.find(supplier => supplier.name === this.supp_name);
    if (selectedSupplier) {
        this.suppID = selectedSupplier.supp_id;
    }
}

  getSchedule() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "pharmacontrol/gsch", { headers: headers }).subscribe(
      data => {
        this.scheduleList = [];
        var obj = data.json();
        if (obj != undefined && obj.schedules.length != 0) {
          this.scheduleList = obj.schedules;
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchSech);
      }
    )
  }

  createNew(e) {
    var cattype;
    var catname;
    if (this.productTypeID != undefined) {
      for (var i = 0; i < this.productTypeArray.length; i++) {
        if (this.productTypeID == this.productTypeArray[i].med_code) {
          this.productType = this.productTypeArray[i];
        }
      }
    }
    if (this.medicareMedID != undefined) {
      for (var i = 0; i < this.mdeicareTypeArray.length; i++) {
        if (this.medicareMedID == this.mdeicareTypeArray[i].medicare_id) {
          this.medicareType = this.mdeicareTypeArray[i];
        }
      }
    }
    if (this.categoryCtgID != undefined) {
      for (var i = 0; i < this.categoryTypeArray.length; i++) {
        if (this.categoryCtgID == this.categoryTypeArray[i].category_id) {
          this.categoryType = this.categoryTypeArray[i];
        }
      }
      if (this.categoryType != undefined) {
        cattype = this.categoryType.category_id;
        catname = this.categoryType.category_name;
      }
    }
    if (this.uomCode != undefined) {
      for (var i = 0; i < this.uomListArray.length; i++) {
        if (this.uomCode == this.uomListArray[i].uom_code) {
          this.uomTypeArray = this.uomListArray[i];
        }
      }
    }

    if (this.manufacturerMfgID != undefined) {
      for (var i = 0; i < this.manufacList.length; i++) {
        if (this.manufacturerMfgID == this.manufacList[i].mfg_id) {
          this.manufacturer = this.manufacList[i];
        }
      }
    }
    this.productID = this.productID != "" ? this.productID : "0";
    var send_data = {
      hsn_no: this.hsnNO,
      name: this.productName,
      short_name: this.shortName,
      generic_name: this.genericName,
      medicare_id: this.medicareType.medicare_id,
      medicare_name: this.medicareType.medicare_name,
      product_type_id: this.productType.med_code,
      product_type_desc: this.productType.med_name,
      category_type_id: cattype,
      category_name: catname,
      uom_code: this.uomTypeArray.uom_code,
      uom_desc: this.uomTypeArray.uom_desc,
      mfg_id: this.manufacturer.mfg_id,
      mfg_name: this.manufacturer.name,
      product_id: this.productID,
      per_unit: this.quantity,
      cost_price: this.costPrice,
      sales_price: this.sellingPrice,
      MRP: this.MRP,
      prod_cgst: this.prodCenteralGST,
      prod_sgst: this.prodStateGST,
      prod_disc: this.prodDiscount,
      pharmacy_id: this.pharmacyID,
      supp_id: this.suppID,
      min_qty:this.min_qty,
    };
    pharmacy_helper.setProductMst(send_data);
    if (e == 'Supplier') {
      pharmacy_helper.setSupplier(true);
      this.pharmaservice.sendMessage("pharmacysupplier");
    } else {
      pharmacy_helper.setManufacturer(true);
      this.pharmaservice.sendMessage("manufacturer");
    }
  }


  allowdiscountfun(){


  }

  nexttableview(){
    this.tableviewflag = !this.tableviewflag
    this.backflag=true;
    this.nextflag=false;
  }

  backtableview(){
    this.tableviewflag = !this.tableviewflag
    this.backflag=false;
    this.nextflag=true;
  }

  cleararray(rowid){
    if( rowid == this.arraytoedit[0].Index){
      this.productMasterAddArray.push(this.arraytoedit[0])
      this.productMasterAddArray.sort((a, b) => a.Index - b.Index);
    }
   
    this.hsnNO="";
    this.productName="";
    this. shortName="";
    this.genericName="";
    this.genericName="";
    this.medicareMedID="";
    this.productTypeID="";
    this.categoryCtgID="";
    this.uomCode="";
    this.mfg_name="";
    this.supp_name="";
    this.scheduleType="";
    this.rackidname="";
    this.binnameng="";
    this.quantity="";
    this.MRP="";
    this.prodCenteralGST="";
    this.prodStateGST="";
    this.min_qty="";
    this.max_lvl="";
    this.ReOrder_lvl="";
    this.danger_lvl="";
    this.expirydura="";
    this.dur="";
    this.allowdiscount="";
    this.rowid="";
    this.look_alike= false;
    this.sound_alike= false;
    this.hight_risk= false;
    this.ward_only= false;
    this.clearflag=true;
    this.toggleCover();
  }

  look_alikevalue(value){
   

  }

  sound_alikevalue(value){
    // if(value == true){
    //   this.sound_alike="1"
    // }else{
    //   this.sound_alike="0"
    // }
  }
      
  high_riskvalue(value){
    // if(value == true){
    //   this.hight_risk="1"
    // }else{
    //   this.hight_risk="0"
    // }
  }

  ward_onlyvalue(value){
    // if(value == true){
    //   this.ward_only="1"
    // }else{
    //   this.ward_only="0"
    // }
  }

  toggleCover() {
    if(this.editflag == true){
      this.isCoverVisible=true 
    }else if(this.clearflag == true){
      this.isCoverVisible=true
    }else{
      this.isCoverVisible = !this.isCoverVisible 
    }
    if(this.isCoverVisible == false){
      this.hide_show_btn="../../../assets/ui_icons/buttons/down.svg";
    }else{
      this.hide_show_btn="../../../assets/ui_icons/buttons/up.svg";
    }
    this.editflag =false;
    this.clearflag =false;
  }

}
