import { Component, OnInit, HostListener, Input } from '@angular/core';
// import { ipaddress.getIp, ipaddress.country_code } from '../../../assets/js/ipaddress.js';
import { Http, Headers, Jsonp } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress' ;
import { Subscription } from 'rxjs';
import { TreatmentService } from '../service';
import { Date_Formate, ConvertTimeformat } from '../../../assets/js/common';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { CardioPopupHistoryComponent } from '../cardio-popup-history/cardio-popup-history.component';
import { MatDialog } from '@angular/material/dialog';
import { IcdCodePopupComponent } from '../icd-code-popup/icd-code-popup.component';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { Master_Helper } from '../Master_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { MasterHelperService } from '../MasterHelper.service';
import { HabitualService } from '../service/habitualservice';
//import { dietDisease } from '../service/dite-disease.service';
@Component({
  selector: 'app-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.css']
})
export class DiagnosisComponent implements OnInit {
  public flag1: boolean;
  public life_exerview;
  public exercisetable:boolean;
  public exercisearrayflag:any;
  public recom_exec_id;
  public exercisearray=[];
  public casesheet_case_hist_id;
  public life_style_id;
  public person_hist;
  public personal_hist;
  public diagnosisFlag = "min";
  public disease_txtsend;
  public icd_data;
  public dietfollowadd = [];
  public dietavoidwadd = [];
  public diagmin: boolean;
  public Get_theyoga = [];
  public Physcial_exercise = [];
  public get_foodtype_arry = [];
  public prognosis_array = [];
  public remarksList = [];
  public cardiodis_array = [];
  public cardio_prognosis = [];
  public fear_label: string;
  public fear_hidden: boolean;
  public fear: string;
  public medproc_label: string;
  public medical_procedure: string;
  public medproc_hidden: boolean;
  public next_data;
  public prog_hidden: boolean = false;
  public prognosis_array_flag: boolean;
  public exercise_array_flag:boolean;
  public remarksList_flag: boolean;
  public hosp_id: string;

  public filteredhospList = [];
  public hospital_filterlist = [];
  public hospital_txt;
  public curr_date;
  public popproc_label: string;
  public popproc_hidden: boolean;
  public popcomp_txt: string;

  //food to avoid or follow
  public food_type_name: string;
  public diet_followtable: boolean;
  public diet_avoidtable: boolean;
  public food_type: string;
  public food_type_id: string;
  public Foot_Item_avoidtxt: string;
  public Food_type_avoid: string;
  public food_typeavoid_id: string;
  public food_type_avoiddes: string;
  public follow_food_search_items: any = [];
  public follow_avoid_search_items: any = [];

  public get_speclization_arry: any;
  public cardio_dis: string;
  public Icd_code: string;
  public Icd_Descript: string;
  public life_exer: string;
  public life_exerdure: string;
  public life_exe_freq: string;
  public prog_txt: string;
  public yoga_check = [];
  public yoga_check1=[];
  public yoga_div: boolean = true;

  public hosp_name: string;
  public speclization_txt: string;
  public refer_txt: string;
  public Refered_name = [];

  public yogadescription;
  items: any;
  public filtereditems: any;
  public Foot_Item_txt: string = '';
  public query = '';
  public food_txt: any = [];
  public yoga_send_data = [];

  public filteredList: any = [];
  public filteredList1: any = []; public date_pick: string[]

  public Refered_doctor: any = [];
  public header_footer_flag: boolean;
  public pres_pop_flag;
  public physcial_acthead: boolean;
  public Prognosis;
  public remarks;
  public CurrentDatetime: string;
  public Prognosis_data: boolean;
  public disease_typetxt;
  public diabetichidden: boolean;
  public dis_type: boolean;
  public diab_typelabel;
  public icd_label;
  public icd_hidden: boolean;
  public icddis_label;
  public icddis_hidden: boolean;
  public icdList = [];
  public phyexer_label;
  public phyex_hidden: boolean; public type_id: string;

  public phydure_hidden;
  public phyfreq_hidden: boolean;
  public popcomp_hidden: boolean;
  public next_hidden: boolean;
  public Hospital_hidden: boolean;
  public spl_hidden: boolean;
  public refer_hidden: boolean;
  public refer_head: boolean;
  public diet_head: boolean;
  public diagnosis_flag: boolean;
  public next_label;
  public yoga_label;
  public phydure_label;
  public phyfreq_label;
  public hospital_label;
  public spl_label;
  public refer_label;
  public medicalproc_array = [];
  public doctorname
  public remarks_hidden: boolean = true;
  public family;

  public follow_hidden: boolean;
  public avoid_hidden: boolean;
  public refer_doctor_hidden: boolean;
  public spltxt_name;

  public Is_Gynecology;
  public Gynecology_flag: boolean = false;
  public cardio_flag: boolean = false;
  public Diabetes_flag: boolean = false;
  public General_flag: boolean = false;
  public ENT_flag: boolean = false;

  public Others_flag: boolean = false;
  public diettofollow_label;
  public diet_avoid_label;

  public Pediatrics_flag: boolean = false;
  public splarray;
  //gyno
  public Get_thedise = [];
  public disease_txt;
  //others
  public diab_type = [];
  public diabetes_type = [];

  public Diabetic_type: string;

  public refer_flag: boolean = false;
  public oflag: boolean = false;
  subscription: Subscription;
  public disease_hidden: boolean;
  public disease_required: boolean;

  //general
  tomorrow = new Date();
  public remark_hidden: boolean;
  public currentDate;
  public url_txt;
  public current_date;
  public current_time;
  public current_year;
  public personalinfo;
  public userinfo;
  public client: boolean = false;

  public diet_table: boolean;
  public prognosis_table: boolean;
  public notes_table: boolean;

  public sugeryFlag: boolean;

  public surgeryarray: any = [];
  public surgery_id;
  public surgery_type;
  public sug_investigation;
  public sug_procedure;
  public sugeryDetails;
  public therapy_array = [];
  public therapy_check = [];
  public therapy_txt: string;
  public case_hist_id: string;
  public visit_freq: string;
  public therapyList = [];
  public therapy_table: boolean;
  public sittings: string;
  public therapyflag: string;
  public therapies;
  public therapydata;
  public therapy_label: string;
  public therapy_hidden: boolean;
  public Select = "Select";
  public visit_label;
  public visit_hidden: boolean;
  public sitting_label;
  public sitting_hidden: boolean;
  public booknowFlag: boolean = false;
  public therapyhist_array = [];
  public physio_name;
  public client_reg_id;
  public relation_id;
  public sub_id;
  public doc_reg_id;
  public notes_list = [];
  public notes_data = [];
  public noteslist = [];
  public physioStartDate;
  public physioAppDate = [];
  public Start_time;
  public end_time;
  public time_session;
  public Start_time1;
  public end_time1;
  public time_session_end;
  public starts_from_array: any = [];
  public ends_at_array: any = [];
  public chk_data;
  public session_txt;

  public Appoint_Date: string;
  public Current_time;
  public doc_sche_list: any = [];
  public doc_sche_list1 = [];
  public reason_txt;
  public From_date;
  public To_date;

  public showFullday: boolean = false;
  public session_hide;
  public session = [];
  public exersession
  public physio_remarks;
  public physioTabFlag: boolean = false;
// public dietDisease : dietDisease
  constructor(public dialog: MatDialog, public messageservice: CasesheetService, public http: Http,
    public toastr: ToastrService, public masterCSdata: MasterCSHelperService, public masterData: MasterHelperService, public habitualservice: HabitualService, ) {
  }

  async ngOnInit() {
    this.exercisearray=[];
    this.exercisetable=true;
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.diagnosisFlag = message;
        if (this.diagnosisFlag == 'max') {
         
        }
      }
     // Doc_Helper.setDietDiease(null);
    });
    var sendData = {
      diseaseName : "",
      changeFlag : "1",
    }
    Doc_Helper.setDietDiease(sendData);
    this.doctorname = Helper_Class.getProfilename();
    this.icd_label = "ICD code";
    this.icddis_label = "ICD description";
    this.diagmin = true;
    this.follow_hidden = true;
    this.avoid_hidden = true;
    this.diabetichidden = true;

    this.appGetdate();
    if (Doc_Helper.getAppFlow() == "client") {
      this.userinfo = Helper_Class.getclient_hospitalDetails();
      this.personalinfo = Helper_Class.getInfo();
      this.client = true;
    } else {
      this.userinfo = Helper_Class.getInfo();
      this.personalinfo = Doc_Helper.getClient_Info();
      this.client = false;
    }

    if (Helper_Class.getInpatientFlow() == "nurse") {
      this.doc_reg_id = this.personalinfo.user_id;
    } else {
      this.doc_reg_id = this.userinfo.user_id;
    }
    this.hosp_id=this.userinfo.hospitals[0].hptl_clinic_id
    this.client_reg_id = this.personalinfo.Client_id;
    this.relation_id = this.personalinfo.rel_id;
    this.sub_id = this.personalinfo.sub_id != "" ? this.personalinfo.sub_id : undefined;
    var referal = Doc_Helper.getReferal();
    if (referal != undefined) {
      if (referal.hosp_name != undefined && referal.hosp_name != "" && referal.hosp_name != null) {
        this.hosp_name = referal.hosp_name;
      }
      if (referal.refer_txt != undefined && referal.refer_txt != "" && referal.refer_txt != null) {
        this.refer_txt = referal.refer_txt;
      }
      if (referal.spl_txt != undefined && referal.spl_txt != "" && referal.spl_txt != null) {
        this.speclization_txt = referal.spl_txt;
      }
    }

    if (Helper_Class.getHopitalfieldsRet() != undefined) {
      for (var i = 0; i < Helper_Class.getHopitalfieldsRet().length; i++) {
        if (Helper_Class.getHopitalfieldsRet()[i].page_name == "CardioDiagnosis") {
          var fieldsvalue = Helper_Class.getHopitalfieldsRet()[i].fields;
          for (var j = 0; j < fieldsvalue.length; j++) {
            if (fieldsvalue[j].field_name == "Heart Disorder") {
              this.diab_typelabel = fieldsvalue[j].field_name;
            } else if (fieldsvalue[j].field_name == "ICD code") {
              this.icd_label = fieldsvalue[j].field_name;
              this.icd_hidden = false;
            } else if (fieldsvalue[j].field_name == "ICD description") {
              this.icddis_label = fieldsvalue[j].field_name;
              this.icddis_hidden = false;
            } else if (fieldsvalue[j].field_name == "Physical Excercise") {
              this.phyexer_label = "Excercise";
              this.phyex_hidden = false;
            } else if (fieldsvalue[j].field_name == "Physical Duration") {
              this.phydure_label = "Duration";
              this.phydure_hidden = false;
            } else if (fieldsvalue[j].field_name == "Physical Frequency") {
              this.phyfreq_label = "Frequency";
              this.phyfreq_hidden = false;
            } else if (fieldsvalue[j].field_name == "Prognosis") {
              this.Prognosis_data = false;
            } else if (fieldsvalue[j].field_name == "Remarks") {
              this.remark_hidden = false;
            } else if (fieldsvalue[j].field_name == "Medical procedure") {
              this.medproc_label = fieldsvalue[j].field_name;
              this.medproc_hidden = false;
            } else if (fieldsvalue[j].field_name == "Fear") {
              this.fear_label = "Fear / Anxiety";
              this.fear_hidden = false;
            } else if (fieldsvalue[j].field_name == "Post operation complications") {
              this.popproc_label = fieldsvalue[j].field_name;
              this.popproc_hidden = false;
            } else if (fieldsvalue[j].field_name == "Next visit") {
              this.next_label = fieldsvalue[j].field_name;
              this.next_hidden = false;
            } else if (fieldsvalue[j].field_name == "Hospital name") {
              this.hospital_label = fieldsvalue[j].field_name;
              this.Hospital_hidden = false;
            } else if (fieldsvalue[j].field_name == "Speciality") {
              this.spl_label = fieldsvalue[j].field_name;
              this.spl_hidden = false;
            } else if (fieldsvalue[j].field_name == "Reffered to") {
              this.refer_label = fieldsvalue[j].field_name;
              this.refer_hidden = false;
            }

            if (this.refer_hidden == false || this.spl_hidden == false || this.Hospital_hidden == false) {
              this.refer_head = false
            }

            if (this.phyfreq_hidden == false || this.phydure_hidden == false || this.phyex_hidden == false) {
              this.physcial_acthead = false;
            }
          }
        }
      }
    }

    this.url_txt = ipaddress.getIp + "prescription/mpbyclntid/";
    this.diet_followtable = true;
    this.splarray = this.personalinfo.spl;
    if (this.splarray == "Gynecology") {
      if (masterCSData_Helper.getMasterGynoDisease() == undefined) {
        await this.masterCSdata.getDiseases();
        this.Get_diseases();
      } else {
        this.Get_diseases();
      }
    }
    if (masterCSData_Helper.getMasterSpecilizationType() == undefined) {
      await this.masterCSdata.getSpecilizationType();
      this.getSpecilizationType()
    } else {
      this.getSpecilizationType();
    }
    if (masterCSData_Helper.getMasterExercise() == undefined) {
      await this.masterCSdata.getExercise();
      this.get_exercise();
    } else {
      this.get_exercise();
    }

    if (this.splarray == "Gynecology") {
      this.phyex_hidden = false;
      this.phydure_hidden = false;
      this.phyfreq_hidden = false;
      this.Gynecology_flag = true;
      this.refer_flag = true;
      this.oflag = true;
      this.Prognosis_data = true;
      this.dis_type = true;
      this.refer_doctor_hidden = true;
      this.diabetichidden = true;
      this.icd_hidden = false;
      this.icddis_hidden = false;
      this.spltxt_name = "Gynaecology";
      // if (masterCSData_Helper.getMasterGynoDisease() == undefined) {
      //   await this.masterCSdata.getDiseases();
      //   this.Get_diseases();
      // } else {
      //   this.Get_diseases();
      // }
    } else if (this.splarray == "Cardiology") {
      this.phyex_hidden = false;
      this.phydure_hidden = false;
      this.phyfreq_hidden = false;
      this.cardio_flag = true;
      this.refer_flag = true;
      this.oflag = true;
      this.physioTabFlag = true; // show physio tab
      if (masterCSData_Helper.getMasterCardiovasular() == undefined) {
        await this.masterCSdata.getCardiovascular();
        this.get_Cardiovascular();
      } else {
        this.get_Cardiovascular();
      }
    } else if (this.splarray == "Pediatrics") {
      this.Pediatrics_flag = true;
      this.phyex_hidden = true;
      this.phydure_hidden = true;
      this.phyfreq_hidden = true;
      this.refer_flag = true;
      this.oflag = true;
      this.icd_hidden = false;
      this.icddis_hidden = false;
      this.follow_hidden = false;
      this.avoid_hidden = false;
      this.physioTabFlag = true; // show physio tab
      // this.get_exercise();
    } else if (this.splarray == "Diabetes") {
      this.phyex_hidden = false;
      this.phydure_hidden = false;
      this.phyfreq_hidden = false;
      this.Diabetes_flag = true;
      this.refer_flag = true;
      this.oflag = true;
      this.follow_hidden = false;
      this.avoid_hidden = false;
      this.icd_hidden = false;
      this.icddis_hidden = false;
      this.physioTabFlag = true; // show physio tab
      this.field_name();
      if (masterCSData_Helper.getMasterDiabTreatmentType() == undefined) {
        await this.masterCSdata.getDiab_tratment_type();
        this.getDiab_tratment_type();
      } else {
        this.getDiab_tratment_type();
      }
    } else if (this.splarray == "General" || this.splarray == "General surgery") {
      this.General_flag = true;
      this.phyex_hidden = false;
      this.phydure_hidden = false;
      this.phyfreq_hidden = false;
      this.refer_flag = true;
      this.dis_type = false;
      this.oflag = true;
      this.icd_hidden = false;
      this.icddis_hidden = false;
      this.diabetichidden = true;
      this.physioTabFlag = true; // show physio tab
      this.field_name();
      setTimeout(() => {
        this.getDiab_tratment_type();
      }, 1000)
      if (this.splarray == "General surgery") {
        setTimeout(() => {
          this.getGeneralSurgeries();
        }, 1000)
      }
    } else if (this.splarray == "ENT") {
      this.phyex_hidden = false;
      this.phydure_hidden = false;
      this.phyfreq_hidden = false;
      this.diabetichidden = false;
      this.refer_flag = true;
      this.ENT_flag = true;
      this.oflag = true;
      this.icd_hidden = false;
      this.icddis_hidden = false;
      this.follow_hidden = false;
      this.avoid_hidden = false;
      setTimeout(() => {
        this.getDiab_tratment_type();
      }, 1000)
    } else if (this.oflag == false) {
      this.Others_flag = true;
      this.phyex_hidden = false;
      this.phydure_hidden = false;
      this.phyfreq_hidden = false;
      this.diabetichidden = false;
      this.refer_flag = true;
      this.Pediatrics_flag = false;
      this.Gynecology_flag = false;
      this.cardio_flag = false;
      this.icd_hidden = false;
      this.physioTabFlag = true; // show physio tab
      this.icddis_hidden = false;
      this.getTreatmentTypes();
    }
    this.Prognosis_data = false;
    this.medproc_hidden = false;
    this.fear_hidden = false;
    this.popproc_hidden = false;
    this.physcial_acthead = false;
    this.next_hidden = false;
    this.Hospital_hidden = false;
    this.spl_hidden = false;
    this.refer_hidden = false;
    this.refer_head = false
    this.diagnosis_flag = true;

    this.diab_typelabel = "Heart Disorder";
    this.medproc_label = "Medical procedure";
    this.fear_label = "Fear / Anxiety";
    this.popproc_label = "Post operation complications";
    this.next_label = "Next visit";
    this.phyexer_label = "Physical Excercise";
    this.yoga_label = "Asanas";
    this.phydure_label = "Duration";
    this.phyfreq_label = "Frequency";
    this.hospital_label = "Hospital name";
    this.spl_label = "Speciality";
    this.refer_label = "Referred to";
    var getHopitalfieldsRet = Helper_Class.getHopitalfieldsRet();
    for (var i = 0; i < getHopitalfieldsRet.length; i++) {
      for (var j = 0; j < getHopitalfieldsRet[i].fields.length; j++) {
        if (getHopitalfieldsRet[i].fields[j].field_name == "Diet to follow") {
          this.diettofollow_label = "Follow";
          this.follow_hidden = false;
        }
        if (getHopitalfieldsRet[i].fields[j].field_name == "Diet to avoid") {
          this.diet_avoid_label = "Avoid";
          this.avoid_hidden = false;
        }
        if (this.follow_hidden == false || this.avoid_hidden == false) {
          this.diet_head = false
        }
      }
    }
    if (masterCSData_Helper.getMasterFoodType() == undefined) {
      await this.masterCSdata.getFoodType();
      this.Get_food_type();
    } else {
      this.Get_food_type();
    }
    this.diet_followtable = true;
    this.diet_avoidtable = true;
    this.changetab('prognosis');
    this.getTherapyHistory();
    this.getThetraphist();
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.starts_from_array.push("0" + i);
      } else {
        this.starts_from_array.push(i);
      }
    }
    for (var i = 0; i <= 55; i += 5) {
      if (i < 10) {
        this.ends_at_array.push("0" + i);
      } else {
        this.ends_at_array.push(i);
      }
    }
  }
  cardio_dis_change(e) {
    for (var i = 0; i < this.cardiodis_array.length; i++) {
      if (e == this.cardiodis_array[i].description) {
        this.type_id = this.cardiodis_array[i].cardio_id;
      }
    }
    var sendData = {
      diseaseName : e,
      changeFlag : "1",
    }
    if(this.personalinfo  != undefined){
      if(this.personalinfo.disease != undefined){
        var sendData = {
          diseaseName : this.personalinfo.disease,
          changeFlag : "1",
        }
      }else{
        var sendData = {
          diseaseName : e,
          changeFlag : "1",
        }
      }
    }
    Doc_Helper.setDietDiease(sendData);
    // this.dietDisease.sendDisease(e);
  }
  //others disease type
  getTreatmentTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'other/gdis',
      JSON.stringify({
        spl_txt: this.splarray
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.diab_type = obj.disease_types;
          //  this.get_exercise();
        },
        error => {
          this.toastr.error("Something Went Wrong, Please Try Again Later");
        }
      )
  }

  field_name() {
    if (this.splarray == "General" || this.splarray == "General surgery") {
      if (Helper_Class.getHopitalfieldsRet() != undefined) {
        for (var i = 0; i < Helper_Class.getHopitalfieldsRet().length; i++) {
          if (Helper_Class.getHopitalfieldsRet()[i].page_name == "General Diagnosis") {
            var fieldsvalue = Helper_Class.getHopitalfieldsRet()[i].fields;
            for (var j = 0; j < fieldsvalue.length; j++) {
              // if (fieldsvalue[j].field_name == "Diabetes type") {
              //   this.diab_typelabel = fieldsvalue[j].field_name;
              //   this.diabetichidden = false;
              // }

              if (fieldsvalue[j].field_name == "ICD code") {
                this.icd_label = fieldsvalue[j].field_name;
                this.icd_hidden = false;
              }

              if (fieldsvalue[j].field_name == "ICD description") {
                this.icddis_label = fieldsvalue[j].field_name;
                this.icddis_hidden = false;
              }

              if (fieldsvalue[j].field_name == "Physical Excercise") {
                this.phyexer_label = "Excercise";
                this.phyex_hidden = false;

              }

              if (fieldsvalue[j].field_name == "Physical Duration") {
                this.phydure_label = "Duration";
                this.phydure_hidden = false;
              }

              if (fieldsvalue[j].field_name == "Physical Frequency") {
                this.phyfreq_label = "Frequency";
                this.phyfreq_hidden = false;
              }

              if (this.phyfreq_hidden == false || this.phydure_hidden == false || this.phyex_hidden == false) {
                this.physcial_acthead = false;
              }

              if (fieldsvalue[j].field_name == "Diet to follow") {
                this.diettofollow_label = "Follow";
                this.follow_hidden = false;
              }

              if (fieldsvalue[j].field_name == "Diet to avoid") {
                this.diet_avoid_label = "Avoid";
                this.avoid_hidden = false;
              }

              if (this.follow_hidden == false || this.avoid_hidden == false) {
                this.diet_head = false
              }

              if (fieldsvalue[j].field_name == "Next visit") {
                this.next_label = fieldsvalue[j].field_name;
                this.next_hidden = false;
              }

              if (fieldsvalue[j].field_name == "Hospital name") {
                this.hospital_label = fieldsvalue[j].field_name;
                this.Hospital_hidden = false;
              }

              if (fieldsvalue[j].field_name == "Speciality") {
                this.spl_label = fieldsvalue[j].field_name;
                this.spl_hidden = false;
              }

              if (fieldsvalue[j].field_name == "Reffered to") {
                this.refer_label = fieldsvalue[j].field_name;
                this.refer_hidden = false;
              }

              if (fieldsvalue[j].field_name == "Prognosis") {
                this.Prognosis_data = false;
              }

              if (fieldsvalue[j].field_name == "Remarks") {
                this.remark_hidden = false;
              }

              if (this.refer_hidden == false || this.spl_hidden == false || this.Hospital_hidden == false) {
                this.refer_head = false
              }
            }
          }
        }
      }
    } else if (this.splarray == "Diabetes") {
      if (Helper_Class.getHopitalfieldsRet() != undefined) {
        for (var i = 0; i < Helper_Class.getHopitalfieldsRet().length; i++) {
          if (Helper_Class.getHopitalfieldsRet()[i].page_name == "Treatment plan") {
            if (Helper_Class.getHopitalfieldsRet()[i].fields != undefined) {
              var fieldsvalue = Helper_Class.getHopitalfieldsRet()[i].fields;
              for (var j = 0; j < fieldsvalue.length; j++) {
                if (fieldsvalue[j].field_name == "Diabetes type") {
                  this.diab_typelabel = fieldsvalue[j].field_name;
                  this.diabetichidden = false;
                }

                if (fieldsvalue[j].field_name == "ICD code") {
                  this.icd_label = fieldsvalue[j].field_name;
                  this.icd_hidden = false;
                }

                if (fieldsvalue[j].field_name == "ICD description") {
                  this.icddis_label = fieldsvalue[j].field_name;
                  this.icddis_hidden = false;
                }

                if (fieldsvalue[j].field_name == "Physical Excercise") {
                  this.phyexer_label = "Excercise";
                  this.phyex_hidden = false;
                }

                if (fieldsvalue[j].field_name == "Physical Duration") {
                  this.phydure_label = fieldsvalue[j].field_name;
                  this.phydure_hidden = false;
                }

                if (fieldsvalue[j].field_name == "Physical Frequency") {
                  this.phyfreq_label = fieldsvalue[j].field_name;
                  this.phyfreq_hidden = false;
                }

                if (this.phyfreq_hidden == false || this.phydure_hidden == false || this.phyex_hidden == false) {
                  this.physcial_acthead = false
                }

                if (fieldsvalue[j].field_name == "Diet to follow") {
                  this.diettofollow_label = "Follow";
                  this.follow_hidden = false;
                }

                if (fieldsvalue[j].field_name == "Diet to avoid") {
                  this.diet_avoid_label = "Avoid";
                  this.avoid_hidden = false;
                }

                if (this.follow_hidden == false || this.avoid_hidden == false) {
                  this.diet_head = false
                }

                if (fieldsvalue[j].field_name == "Prognosis") {
                  //this.prognosis_label = fieldsvalue[j].field_name;
                  this.Prognosis_data = false;
                }

                if (fieldsvalue[j].field_name == "Remarks") {
                  this.remark_hidden = false;
                }

                if (fieldsvalue[j].field_name == "Next visit") {
                  this.next_label = fieldsvalue[j].field_name;
                  this.next_hidden = false;
                }

                if (fieldsvalue[j].field_name == "Hospital name") {
                  this.hospital_label = fieldsvalue[j].field_name;
                  this.Hospital_hidden = false;
                }

                if (fieldsvalue[j].field_name == "Speciality") {
                  this.spl_label = fieldsvalue[j].field_name;
                  this.spl_hidden = false;
                }

                if (fieldsvalue[j].field_name == "Reffered to") {
                  this.refer_label = fieldsvalue[j].field_name;
                  this.refer_hidden = false;
                }

                if (this.refer_hidden == false || this.spl_hidden == false || this.Hospital_hidden == false) {
                  this.refer_head = false
                }
              }
            }
          }
        }
      }
    }
  }

  getDiab_tratment_type() {
    var obj = masterCSData_Helper.getMasterDiabTreatmentType();
    if(obj != undefined)
      this.diabetes_type = obj.diabete_types;
    //  this.get_exercise();
  }

  get_Cardiovascular() {//Get Cardiovascular data
    var obj = masterCSData_Helper.getMasterCardiovasular();
    if(obj != undefined){
      if (obj.cardio_diseases != null) {
        for (var i = 0; i < obj.cardio_diseases.length; i++) {
          this.cardiodis_array.push({
            cardio_id: obj.cardio_diseases[i].cardio_id,
            description: obj.cardio_diseases[i].description
          });
        }
        this.cardio_dis = this.cardiodis_array[0].description;
      }
    }
    this.getMedicalProcedures();
  }

  addPrognosis() {// Add prognosis method
    var flag = true;
    if (this.prog_txt == undefined || this.prog_txt == "" || this.prog_txt == null || this.prog_txt.length == 0) {
      flag = false;
      this.toastr.error("add prognosis")
    }
    if (this.prognosis_array.length == 0 && (this.prog_txt == undefined || this.prog_txt == "")) {
      this.prognosis_array_flag = false;
    }
    else {
      this.prognosis_array_flag = true;
    }

    for (var i = 0; i < this.prognosis_array.length; i++) {
      if (this.prognosis_array[i].Prognosis == this.prog_txt) {
        flag = false;
        break;
      }
    }
    var docname;
    if (this.userinfo.middle_name != undefined) {
      docname = this.userinfo.first_name + " " + this.userinfo.middle_name + " " + this.userinfo.last_name;
    } else {
      docname = this.userinfo.first_name + " " + this.userinfo.last_name;
    }
    if (flag == true) {
      this.prognosis_array.push({
        date: Date_Formate(this.CurrentDatetime),
        prognosis: this.prog_txt,
        doc_name: docname,
        add_new: "1",
      });
      this.prog_hidden = true;
    }
    //Resets the form 
    this.prog_txt = "";

  }

  adexercise(){
    // console.log("this.yoga_send_data ge"+JSON.stringify(this.yoga_send_data))
    console.log("this.exercisearray ge"+JSON.stringify(this.exercisearray))    
    console.log("this.yoga_send_data ge"+JSON.stringify(this.yoga_send_data))    
    console.log("this.yoga_send_data ge"+JSON.stringify(this.Get_theyoga))
    this.yoga_check = [];
    var flag = true;var exercise_id
    if (this.life_exer == undefined || this.life_exer == "" || this.life_exer == null || this.life_exer.length == 0) {
      if(this.life_exer != "Yoga"){
        flag = false;
        this.toastr.error("add Exercise")
      }
     
    }
    if (this.life_exerdure == undefined || this.life_exerdure == "" || this.life_exerdure == null || this.life_exerdure.length == 0) {
      flag = false;
      this.toastr.error("add Exercise Duration")
    }
    if (this.life_exe_freq == undefined || this.life_exe_freq == "" || this.life_exe_freq == null || this.life_exe_freq.length == 0) {
      flag = false;
      this.toastr.error("add Exercise Frequency")
    }
    if (this.exersession == undefined || this.exersession == "" || this.exersession == null || this.exersession.length == 0) {
      flag = false;
      this.toastr.error("add Session")
    }
    if (this.exercisearray.length == 0 && (this.life_exer == undefined || this.life_exer == "")) {
      this.exercise_array_flag = false;
    }
    else {
      this.exercise_array_flag = true;
    }
    for (var i = 0; i < this.Physcial_exercise.length; i++) {
      if (this.life_exer == this.Physcial_exercise[i].description)  {
        exercise_id = this.Physcial_exercise[i].exercise_id;
      }
    }
    for (var i = 0; i < this.exercisearray.length; i++) {
      if (this.exercisearray[i].life_exer == this.life_exer && this.exersession == this.exercisearray[i].session) {
        this.toastr.error("Same session")
        flag = false;
        break;
      }
    }
    var selectedyoga = "";
    if(this.yoga_div == false){
      var array=[]
      for(var i = 0; i < this.yoga_send_data.length; i++){    
        if(i != 0){
          selectedyoga +=  ",  " + this.yoga_send_data[i] ;
        } else {
          selectedyoga +=  this.yoga_send_data[i];
        }
        array =this.yoga_send_data
        // sensitiveMedicines += this.sensitivityCardList[i].med_Name + ",  ";      
      }    
    }
    if(this.yoga_div == false){
      console.log("yoga_div is false"); 
      const matchFound = this.compareYogaAndSession();
      console.log("matchFound:", matchFound);
    //  alert("jhjkh" + matchFound);   
      if (matchFound) {
      //  alert('Matching yoga asanas and session found');
        this.toastr.error("Same session **");    
      flag = false;
      }else{
        flag = true; 
      }  
     
    }
    console.log("exercisearray ge"+JSON.stringify(this.exercisearray))
    console.log("selectedyoga ge"+JSON.stringify(selectedyoga))
    if(selectedyoga != ""){
      this.life_exerview =this.life_exer+" "+(selectedyoga)
    }else{
      this.life_exerview =this.life_exer
    }
    if (flag == true) {
      this.exercisearray.push({
        date: Date_Formate(this.CurrentDatetime),
        life_exer: this.life_exer, 
        // life_exerview: this.life_exerview ,    
        session:this.exersession,
        recom_exec_dur: this.life_exerdure,
        recom_exec_freq:this.life_exe_freq,
        // add_new: "1",
        recom_exec_id:exercise_id,
        yogasanas:selectedyoga,
        yogaflag:this.yoga_div,
        yogaarray:array
      });
    }
    console.log("exercisearray ge"+JSON.stringify(this.exercisearray))
    this.yoga_div = true;  
    
  }
  // compareYogaAndSession() {
  //   this.exercisearray.forEach(prognosis => {
  //     const yogaNames = prognosis.yogasanas.map(asana => asana);
  //     const allMatch = this.yoga_send_data.every(y => yogaNames.includes(y));
  //     if (allMatch && prognosis.session === this.exersession) {
  //       alert('Matching yoga asanas and session found');
  //     }
  //   });
  // }
  compareYogaAndSession(): boolean {
    console.log("exercisearray:", JSON.stringify(this.exercisearray));
    //alert("yoga_send_data:" + JSON.stringify(this.yoga_send_data));

    if (!Array.isArray(this.exercisearray) || this.exercisearray.length === 0) {
      console.error("exercisearray is not defined, not an array, or empty");
      return false;
    }

    if (!Array.isArray(this.yoga_send_data) || this.yoga_send_data.length === 0) {
      console.error("yoga_send_data is not defined, not an array, or empty");
      return false;
    }

    return this.exercisearray.some(prognosis => {
      if (!Array.isArray(prognosis.yogaarray) || prognosis.yogaarray.length === 0) {
        console.error("yogaarray is not defined, not an array, or empty for prognosis:", prognosis);
        return false;
      }
      return prognosis.yogaarray.some(yogaAsana =>
        this.yoga_send_data.includes(yogaAsana)
      ) && prognosis.session === this.exersession;
    });
  }

  
  
  editexercise(list){
    var values,values1,matchedItems;
    var array1
    console.log("list ge"+JSON.stringify(list))
    console.log("Get_theyoga ge"+JSON.stringify(this.yoga_check))
    console.log("Get_theyoga ge"+JSON.stringify(this.Get_theyoga))
   if(list.yogaflag == true){
    for (var i = 0; i < this.exercisearray.length; i++) {
      if(list.recom_exec_id == this.exercisearray[i].recom_exec_id){
        this.life_exer=this.exercisearray[i].life_exer;
        this.life_exerdure=this.exercisearray[i].life_exerdure;
        this.life_exe_freq=this.exercisearray[i].life_exe_freq;
        this.exersession=this.exercisearray[i].session;
        if(list.yogaflag == false){
          this.yoga_div=false;
          for (var j = 0; j < this.Get_theyoga.length; j++) {
            if(list.recom_exec_id == this.exercisearray[i].recom_exec_id){
            }
          }

        }
        this.exercisearray.splice(i);
        break;
      }
    }
   }else{
    for (let i = 0; i < this.exercisearray.length; i++) {
      if (list.recom_exec_id === this.exercisearray[i].recom_exec_id) {
        const values = this.exercisearray[i].yogasanas.split(",").map(value => value.trim());
        console.log("values -", JSON.stringify(values));
    
        for (let k = 0; k < values.length; k++) {
          for (let m = 0; m < this.Get_theyoga.length; m++) {
            if (values[k] === this.Get_theyoga[m].description) {
              console.log("Matched value:", values[k], "in Get_theyoga:", this.Get_theyoga[m].description);
              this.Get_theyoga[m].checked = false;
            }
          }
          this.exercisearray.splice(i)
        }    
        break; // Exit the loop after finding the matching recom_exec_id
      }
    }    
  }
  console.log("Get_theyoga **8"+JSON.stringify(this.Get_theyoga))
  }
  compareValuesWithJsonArray(values: string[], jsonArray: any[]) {
    jsonArray.forEach(item => {
      if (values.includes(item.description)) {
        item.checked = false;
      }
    });
  }
  deleteexercise(list){
    for (var i = 0; i < this.exercisearray.length; i++) {
      if (list.recom_exec_id === this.exercisearray[i].recom_exec_id) {
          this.exercisearray.splice(i, 1);
        break;
      }
    }

  }


  //Get medical procedure data
  getMedicalProcedures() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'cardio/medproc', { "spl_id": "3" },
      { headers: headers })
      .subscribe(
        response => {
          this.medicalproc_array = [];
          var obj = response.json();
          if (obj.med_procedures != null) {
            this.medicalproc_array = obj.med_procedures;
          }

        },
        error => {
          this.toastr.error("Something Went Wrong, Please Try Again Later");
        }
      )
  }

  // get exercise
  async get_exercise() {
    var obj = masterCSData_Helper.getMasterExercise();
    if (obj != undefined) {
      this.Physcial_exercise = obj.exercises;
      console.log("this.Physcial_exercise ---"+JSON.stringify(this.Physcial_exercise))
      if (masterCSData_Helper.getMasterYogaType() == undefined) {
        await this.masterCSdata.getYogaType();
        this.get_yoga_type();
      } else {
        this.get_yoga_type();
      }
    }
  }
  // get yoga data
  get_yoga_type() {
    var jsonArray
    var obj = masterCSData_Helper.getMasterYogaType();
    if(obj != undefined)
      jsonArray = obj.yogasanas;
    this.Get_theyoga = jsonArray.map(obj => {
      obj.checked = true; // Assign a default value or calculate based on other properties
      return obj;
    });
    if (this.splarray == "Gynecology") {
      this.diagnosis_retrivel();
    } else {
      setTimeout(() => {
        this.diagnosis_display();
      }, 300);
      
    }
  }

  getSpecilizationType() {//Get specilization
    var obj = masterCSData_Helper.getMasterSpecilizationType();
    this.get_speclization_arry = [];
    if(obj != undefined){
      for (var i = 0; i < obj.specialization.length; i++) {
        if (obj.specialization[i].spl_name != "-") {
          this.get_speclization_arry.push({
            spl_id: obj.specialization[i].spl_id,
            spl_name: obj.specialization[i].spl_name
          });
        }
      }
    }
  }

  diagnosis_display() {
    this.dietfollowadd = [];
    this.dietavoidwadd = [];
    this.prognosis_array = [];
    this.remarksList = [];
    if (this.splarray == "Gynecology") {
      this.family = Helper_Class.getgynaRet();

    } else if (this.splarray == "Cardiology") {
      this.family = Helper_Class.getRet();

    } else if (this.splarray == "Pediatrics") {
      this.family = Helper_Class.getpediaRet();

    } else if (this.splarray == "Diabetes") {
      this.family = Helper_Class.getRet();

    } else if (this.splarray == "ENT") {
      this.family = Helper_Class.getRet();

    } else if (this.splarray == "General" || this.splarray == "General surgery") {
      this.family = Helper_Class.getRet();

    } else if (this.oflag == false) {
      this.family = Helper_Class.getRet();
    }
    console.log("Helper_Class.getRet >>"+JSON.stringify(this.family))
    if (this.family != undefined) {
      for (var i = 0; i < this.cardiodis_array.length; i++) {
        if (this.family.disease_type == this.cardiodis_array[i].cardio_id) {
          this.cardio_dis = this.cardiodis_array[i].description;
          
        }
      }
      if (this.family.case_hist_id != null) {
        this.casesheet_case_hist_id = this.family.case_hist_id;
      }

      //Icd code and description
      this.Icd_code = this.family.icd10_code;
      this.Icd_Descript = this.family.icd10_desc;

      if (this.family.life_style_id != null) {
        this.life_style_id = this.family.life_style_id;
      }
      
      if (this.family.disease != undefined) {
        this.disease_typetxt = this.family.disease;
        if (this.Pediatrics_flag == true) {
          this.disease_txt = this.family.disease;
        }
      }
      this.dietfollowadd = [];
      this.exercisearray=[]
      if (this.family.diet_follow != null && this.family.diet_follow.length != 0) {
        this.diet_followtable = false;
        for (var i = 0; i < this.family.diet_follow.length; i++) {
          this.dietfollowadd.push({
            food_type_name: this.family.diet_follow[i].food_type_name,
            food_item: this.family.diet_follow[i].food_item,
            food_type_id: this.family.diet_follow[i].food_type_id
          });
        }
      }

      //Diet avoid
      this.dietavoidwadd = [];
      if (this.family.diet_avoid != null && this.family.diet_avoid.length != 0) {
        this.diet_avoidtable = false;
        for (var i = 0; i < this.family.diet_avoid.length; i++) {
          this.dietavoidwadd.push({
            food_type_name: this.family.diet_avoid[i].food_type_name,
            food_item: this.family.diet_avoid[i].food_item,
            food_type_id: this.family.diet_avoid[i].food_type_id
          });
        }
      }

      this.medical_procedure = this.family.medical_procedure;
      this.fear = this.family.fear_anxiety;
      this.popcomp_txt = this.family.postop_complex;

      //Next visit
      if (this.family.next_visit != undefined) {
        this.next_data = this.family.next_visit;
      }
      if (this.family.dis_desc != undefined) {
        this.Diabetic_type = this.family.dis_desc;
      }
      if (this.family.recom_exec_id != null && this.family.recom_exec_id != undefined) {
        for (var i = 0; i < this.Physcial_exercise.length; i++) {
          if (this.family.recom_exec_id == this.Physcial_exercise[i].exercise_id) {
            this.life_exer = this.Physcial_exercise[i].description;
          }
        }
      }
      if (this.family.recom_exec_dur != null && this.family.recom_exec_dur != undefined) {
        this.life_exerdure = this.family.recom_exec_dur;
      }
      if (this.family.recom_exec_freq != null && this.family.recom_exec_freq != undefined) {
        this.life_exe_freq = this.family.recom_exec_freq;
      }
     // excercises
     var yogaflag,datacheckvalue;var datacheckvalue1;
      if (this.family.excercises != null && this.family.excercises != undefined) {
        this.exercise_array_flag =true;
      //  this.exercisetable=true;
      console.log("EXER ARRAY___"+JSON.stringify(this.Physcial_exercise))
      console.log("EXER this.family.excercises"+JSON.stringify(this.family.excercises))

        if (this.family.excercises.length != 0) {
          for (var i = 0; i < this.Physcial_exercise.length; i++) {
            for (var j = 0; j < this.family.excercises.length; j++) {
              if (this.family.excercises[j].recom_exec_id == this.Physcial_exercise[i].exercise_id) {
              var data = this.family.excercises[j]
              if( this.family.excercises[j].rec_exec_desc == "Yoga"){
                yogaflag=false;
                datacheckvalue=this.family.excercises[j].rec_exec_desc;
                datacheckvalue1=this.family.excercises[j].rec_exec_desc+"("+data.recom_yoga_asana+")";
                var selectedyoga = "";
                if(this.yoga_div == false){
                  var array=[]
                  for(var i = 0; i < this.yoga_send_data.length; i++){    
                    if(i != 0){
                      selectedyoga +=  ",  " + this.yoga_send_data[i] ;
                    } else {
                      selectedyoga +=  this.yoga_send_data[i];
                    }
                    array =this.yoga_send_data
                    // sensitiveMedicines += this.sensitivityCardList[i].med_Name + ",  ";      
                  }    
                }
                var values= data.recom_yoga_asana.split(",")
              //  alert("valuesvalues"+JSON.stringify(values))
                for (let k = 0; k < values.length; k++) {
                  for (let m = 0; m < this.Get_theyoga.length; m++) {
                    if (values[k] === this.Get_theyoga[m].description) {
                      console.log("Matched value:", values[k], "in Get_theyoga:", this.Get_theyoga[m].description);
                      this.Get_theyoga[m].checked = false;
                      this.yoga_send_data.push(this.Get_theyoga[m].description)
                    }
                  }
                 // this.exercisearray.splice(i)
                }    
              //  break; 
              }else{
                yogaflag=true;
                datacheckvalue=this.family.excercises[j].rec_exec_desc;
                datacheckvalue1=this.family.excercises[j].rec_exec_desc;
              }
              this.exercisearray.push({                
                life_exer: datacheckvalue,
                // life_exerview:datacheckvalue1,
                recom_exec_id: data.recom_exec_id,
                recom_exec_dur: data.recom_exec_dur,
                recom_exec_freq: data.recom_exec_freq,
                recom_exec_session: data.recom_exec_session,
                session:data.recom_exec_session,
                yogasanas:data.recom_yoga_asana,
                recom_yoga_asana: data.recom_yoga_asana,
                yogaflag:yogaflag,
                yogaarray:this.yoga_send_data
              })
            }
           }
          }


        }
      }
      console.log("EXER ARRAY___"+JSON.stringify(this.exercisearray))

      //Yoga
      // this.yoga_check = [];
      // this.yoga_check1=[];
      // if (this.family.yogasanas != null && this.family.yogasanas != undefined) {
      //   for (var i = 0; i < this.Get_theyoga.length; i++) {
      //     for (var j = 0; j < this.family.yogasanas.length; j++) {
      //       if (this.family.yogasanas[j].yoga_asana_id == this.Get_theyoga[i].yoga_asana_id) {
      //         this.yoga_check.push(this.Get_theyoga[i].yoga_asana_id);
      //         this.yoga_check1.push(this.Get_theyoga[i].yoga_asana_id);
      //         this.yogadescription = this.Get_theyoga[i].description;
      //         this.yoga_send_data = this.yoga_check;
      //       }
      //     }
      //   }
      //   this.yoga_div = this.yoga_check.length != 0 ? false : true;
      // }

      //others disease
      if (this.family.disease_type != null && this.family.disease_type != undefined) {
        for (var i = 0; i < this.diab_type.length; i++) {
          for (var j = 0; j < this.family.disease_type.length; j++) {
            if (this.family.disease_type[j] == this.diab_type[i].type_id) {
              this.Diabetic_type = this.diab_type[i].description;
            }
          }
        }
      }

      if (this.family.disease != null && this.family.disease != undefined) {
        this.Diabetic_type = this.family.disease;
      }

      if (this.family.refered_to_hospital != undefined && this.family.refered_to_hospital != null) {
        this.hosp_name = this.family.refered_to_hospital;
      }
      if (this.family.refered_to_speciality != undefined && this.family.refered_to_speciality != null) {
        this.speclization_txt = this.family.refered_to_speciality;
      }
      if (this.family.refered_to != undefined && this.family.refered_to != null) {
        this.refer_txt = this.family.refered_to;
      }

      if (this.family.prognosis != undefined && this.family.prognosis != null) {
        for (var i = 0; i < this.family.prognosis.length; i++) {
          var get_date = this.family.prognosis[i].date.split('-');
          var docname;
          if (this.family.prognosis[i].middle_name != undefined) {
            docname = this.family.prognosis[i].first_name + " " + this.family.prognosis[i].middle_name + " " + this.family.prognosis[i].last_name;
          } else {
            docname = this.family.prognosis[i].first_name + " " + this.family.prognosis[i].last_name;
          }

          this.prognosis_array.push({
            date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
            doc_name: docname,
            prognosis: this.family.prognosis[i].prognosis,
            add_new: "0",
          });
          this.prognosis_array_flag = true;
        }
      }
      if (this.family.remarks != undefined && this.family.remarks != null) {
        for (var j = 0; j < this.family.remarks.length; j++) {
          var datevalue = this.family.remarks[j].date.split('-');
          var newdate = datevalue[2] + "-" + datevalue[1] + "-" + datevalue[0];
          this.remarksList.push({
            remarks_data: this.family.remarks[j].remarks,
            Doct_Name: docname,
            Date: newdate,
            add_new: "0"
          })
        }
        this.remarksList_flag = true;
      }
      if (this.family.therapies != undefined && this.family.therapies != null) {
        for (var j = 0; j < this.family.therapies.length; j++) {
          this.therapyList.push({
            therapy_id: this.family.therapies[j].therapy_id,
            therapy_name: this.family.therapies[j].therapy_name,
            sittings: this.family.therapies[j].sittings,
            frequency: this.family.therapies[j].frequency
          });
        }
        this.therapy_table = true;
      }
    }
    else { }
    this.send_data();
  }

  isReadonly() { return true; }//Read only(disabeld)

  Exercise_change(e) {
    if (e == "Yoga") {
      this.yoga_div = false;
    } else {
      this.yoga_check = [];
      this.yoga_check1 = [];
      this.yoga_div = true;
    }
  }

  yoga_click(valuecard,description, event) {
    this.yoga_send_data=[];
    console.log('kkljkdd '+JSON.stringify(this.yoga_check))
    if (event == true) {
      this.yoga_send_data.push(description);
      this.addYogaCheck(valuecard);

    } else {
      for (var i = 0; this.yoga_send_data.length; i++) {
        if (this.yoga_send_data[i] == valuecard) {
          this.yoga_send_data.splice(i, 1);
          break;
        }
      }
      this.removeYogaCheck(valuecard);
    }
  }
  addYogaCheck(yoga_asana_id: number) {
    if (this.yoga_check.indexOf(yoga_asana_id) === -1) {
      this.yoga_check.push(yoga_asana_id);
      this.updateYogaArray(yoga_asana_id, true);
    }
  }

  removeYogaCheck(yoga_asana_id: number) {
    const index = this.yoga_check.indexOf(yoga_asana_id);
    if (index > -1) {
      this.yoga_check.splice(index, 1);
      this.updateYogaArray(yoga_asana_id, false);
    }
  }
  updateYogaArray(yoga_asana_id: number, isChecked: boolean) {
    this.Get_theyoga = this.Get_theyoga.map(asana => {
      if (asana.yoga_asana_id === yoga_asana_id) {
        return { ...asana, checked: isChecked };
      }
      return asana;
    });
  }
  Get_referal(e) {
    this.Refered_doctor = [];
    this.Refered_name = [];
    this.refer_txt = e.target.value.toString();
    console.log("check ref"+JSON.stringify({
      hospital_id: this.hosp_id,
      spl_name: this.speclization_txt,
      doc_name: this.refer_txt
    }))

    if (this.refer_txt != "" && this.refer_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        JSON.stringify({
          hospital_id: this.hosp_id,
          spl_name: this.speclization_txt,
          doc_name: this.refer_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("check send data"+ JSON.stringify({
              hospital_id: this.hosp_id,
              spl_name: this.speclization_txt,
              doc_name: this.refer_txt
            }))
            console.log("check ref"+JSON.stringify(obj))
            this.Refered_name = obj.doctors;
            if (obj.doctors != null) {
              for (var i = 0; i < this.Refered_name.length; i++) {
                var docname;
                if (this.Refered_name[i].middle_name != undefined) {
                  docname = this.Refered_name[i].first_name + " " + this.Refered_name[i].middle_name + " " + this.Refered_name[i].last_name;
                } else {
                  docname = this.Refered_name[i].first_name + " " + this.Refered_name[i].last_name;
                }
                this.Refered_doctor.push(docname);
              }
            }
            this.Refered_doctor = this.Refered_doctor.filter(function (this: any, el) {
              return el.toLowerCase().indexOf(this.refer_txt.toLowerCase()) > -1;
            }.bind(this));
          
          },
          error => {
            this.toastr.error("Something Went Wrong, Please Try Again Later");
          }
        )
    }
    else {
      this.Refered_doctor = [];
    }
  }
  doctornamebased(){
    if (this.Refered_doctor.length == 0) {
      this.toastr.error("No doctor found");
    }
  }

  select_hospital(data) {
    this.hosp_name = data;
    for (var i = 0; i < this.hospital_filterlist.length; i++) {
      if (this.hospital_filterlist[i].hosp_name == data) {
        this.hosp_id = this.hospital_filterlist[i].hosp_id;
      }
    }
    this.filteredhospList = [];
    this.refer_txt = null;
  }

  Select_refer_doctor(item) {
    this.refer_txt = item;
    this.Refered_doctor = [];
  }

  split(date) {
    var get_date = date.split('-');
    var date_mod = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
    return date_mod;
  }

  addRemarks() {
    var today = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    var docname;
    if (this.remarks == undefined || this.remarks == "" || this.remarks == null) {
      this.toastr.error("add Notes")
    }
    this.remarksList_flag = this.remarksList.length == 0 && (this.remarks == undefined && this.remarks == "") ? false : true;
    if (this.userinfo.middle_name != undefined) {
      docname = this.userinfo.first_name + " " + this.userinfo.middle_name + " " + this.userinfo.last_name;
    } else {
      docname = this.userinfo.first_name + " " + this.userinfo.last_name;
    }

    if (this.remarks != undefined && this.remarks != "") {
      this.remarksList.push({
        remarks_data: this.remarks,
        Doct_Name: docname,
        Date: Date_Formate(this.CurrentDatetime),
        add_new: "1"
      });
    }
    this.remarks_hidden = true;
    this.remarks = "";
  }

  edit_prognosis(data) {
    if (data.add_new == "1") {
      this.prog_txt = data.prognosis;
      for (var i = 0; i < this.prognosis_array.length; i++) {
        if (this.prognosis_array[i].add_new == data.add_new) {
          this.prognosis_array.splice(i);
        }
      }
      this.prog_hidden = false;
    }
  }

  edit_remarks(data) {
    if (data.add_new == "1") {
      this.remarks = data.remarks_data;
      for (var i = 0; i < this.remarksList.length; i++) {
        if (this.remarksList[i].add_new == data.add_new) {
          this.remarksList.splice(i);
        }
      }
      this.remarks_hidden = false;
    }
  }

  send_data() {
    if (this.cardio_flag == true) { 
      var exercise_id = null, yoga_flag = null, diag_pres_id = undefined, med_pres_id = undefined,
        yoga_txt = undefined, nxt_vis = undefined;

      if (this.life_exer != undefined) {
        if (this.life_exer == "Yoga") {
          yoga_txt = this.yoga_send_data;
          yoga_flag = "yes";
        } else {
          yoga_flag = "no";
        }
      }

      nxt_vis = this.next_data;
      for (var i = 0; i < this.Physcial_exercise.length; i++) {
        if (this.life_exer == this.Physcial_exercise[i].description) {
          exercise_id = this.Physcial_exercise[i].exercise_id;
        }
      }

      if (this.prognosis_array != undefined) {
        for (var i = 0; i < this.prognosis_array.length; i++) {
          if (this.prognosis_array[i].add_new != "0") {
            var prog_arry = (
              this.prognosis_array[i].prognosis
            )
          }
        }
      } else {
        prog_arry = null;
      }

      var remarks_data = undefined;
      if (this.remarksList != undefined) {
        for (var i = 0; i < this.remarksList.length; i++) {
          if (this.remarksList[i].add_new == "1") {
            remarks_data = this.remarksList[i].remarks_data;
            break;
          }
        }
      }
      var medpresid = Helper_Class.get_med_pres_id();
      if (medpresid != undefined && medpresid != null) {
        med_pres_id = medpresid;
      }
      var diagpresid = Helper_Class.get_diag_pres_id();
      if (diagpresid != undefined && diagpresid != null) {
        diag_pres_id = diagpresid;
      }
      if (this.phydure_hidden == true) {
        this.life_exerdure = null;
      }

      if (this.phyfreq_hidden == true) {
        this.life_exe_freq = null;
      }

      for (var i = 0; i < this.cardiodis_array.length; i++) {
        if (this.cardio_dis == this.cardiodis_array[i].description) {
          type_id = this.cardiodis_array[i].cardio_id;
        }
      }
      var diab_treat_plan;
      if (this.therapyList.length != 0) {
        diab_treat_plan = {
          disease_type: type_id,//diabetes
          disease: this.disease_typetxt,
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          medical_procedure: this.medical_procedure,
          fear_anxiety: this.fear,
          post_opt_comp: this.popcomp_txt,
          country: "IN",
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
          next_visit: nxt_vis,
          yoga_asana_id: yoga_txt,
          yogasanas: yoga_flag,
          recom_exec_id: exercise_id,
          recom_exec_dur: this.life_exerdure,
          recom_exec_freq: this.life_exe_freq,
          pres_drug_id: med_pres_id,
          pres_diag_id: diag_pres_id,
          prognosis: prog_arry,
          remarks: remarks_data,
          physio_app_array: this.physioAppDate,
          hptl_clinic_id: JSON.parse(this.userinfo.hospitals[0].hptl_clinic_id),
          physio_id: this.physio_name,
          client_reg_id: this.client_reg_id,
          relation_id: this.relation_id,
          sub_rel_id: this.sub_id,
          created_by: this.doc_reg_id,
          disease_desc : this.cardio_dis,
          excercises:this.exercisearray
        }
      } else {
        diab_treat_plan = {
          disease_type: type_id,//diabetes
          disease: this.disease_typetxt,
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          medical_procedure: this.medical_procedure,
          fear_anxiety: this.fear,
          post_opt_comp: this.popcomp_txt,
          country: "IN",
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
          next_visit: nxt_vis,
          yoga_asana_id: yoga_txt,
          yogasanas: yoga_flag,
          recom_exec_id: exercise_id,
          recom_exec_dur: this.life_exerdure,
          recom_exec_freq: this.life_exe_freq,
          pres_drug_id: med_pres_id,
          pres_diag_id: diag_pres_id,
          prognosis: prog_arry,
          remarks: remarks_data,
          disease_desc : this.cardio_dis,
          excercises:this.exercisearray
        }
      }
      Helper_Class.setDiabTreat(diab_treat_plan)
    } else if (this.Gynecology_flag == true) {
      var exercise_id = null, yoga_flag = null, diag_pres_id = undefined, med_pres_id = undefined,
        yoga_txt = undefined, nxt_vis = undefined, dit_fol_flg = null, dit_avoid_flg = null;

      if (this.life_exer != undefined) {
        if (this.life_exer == "Yoga") {
          yoga_txt = this.yoga_send_data;
          yoga_flag = "yes";
        } else {
          yoga_flag = "no";
        }
      }

      if (this.next_data != undefined) {
        nxt_vis = this.next_data;
      }

      for (var i = 0; i < this.Physcial_exercise.length; i++) {
        if (this.life_exer == this.Physcial_exercise[i].description) {
          exercise_id = this.Physcial_exercise[i].exercise_id;
        }
      }
      var medpresid = Helper_Class.get_med_pres_id();
      if (medpresid != undefined && medpresid != null) {
        med_pres_id = medpresid;
      }
      var diagpresid = Helper_Class.get_diag_pres_id();
      if (diagpresid != undefined && diagpresid != null) {
        diag_pres_id = diagpresid;
      }

      var diag_outcome = null;
      if (this.disease_txt != undefined) {
        for (var i = 0; i < this.Get_thedise.length; i++) {
          if (this.disease_txt == this.Get_thedise[i].description) {
            this.disease_txtsend = this.Get_thedise[i].disease_id;
          }
        }
      } else {
        this.disease_txtsend = null;
      }

      if (this.prognosis_array != undefined) {
        for (var i = 0; i < this.prognosis_array.length; i++) {
          if (this.prognosis_array[i].add_new != "0") {
            var prog_arry = (
              this.prognosis_array[i].prognosis
            )
          }
        }
      } else {
        prog_arry = null;
      }

      dit_fol_flg = this.dietfollowadd.length != 0 ? "yes" : "no";
      dit_avoid_flg = this.dietavoidwadd.length != 0 ? "yes" : "no";

      var remarks_data = undefined;
      if (this.remarksList != undefined) {
        for (var i = 0; i < this.remarksList.length; i++) {
          if (this.remarksList[i].add_new == "1") {
            remarks_data = this.remarksList[i].remarks_data;
            break;
          }
        }
      }

      diag_outcome = {
        disease: this.disease_txtsend,
        icd10_code: this.Icd_code,
        icd10_desc: this.Icd_Descript,
        prognosis: prog_arry,
        remarks: remarks_data
      }

      Helper_Class.setdiagoutcome(diag_outcome);
      var gyno_diab_treat_plan;
      if (this.therapyList.length != 0) {
        gyno_diab_treat_plan = {
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          recom_exec_id: exercise_id,
          recom_exec_dur: this.life_exerdure,
          recom_exec_freq: this.life_exe_freq,
          food_follow: this.dietfollowadd,
          food_avoid: this.dietavoidwadd,
          food_follow_flag: dit_fol_flg,
          food_avoid_flag: dit_avoid_flg,
          country: ipaddress.country_code,
          yoga_asana_id: yoga_txt,
          yogasanas: yoga_flag,
          pres_drug_id: med_pres_id,
          pres_diag_id: diag_pres_id,
          physio_app_array: this.physioAppDate,
          hptl_clinic_id: JSON.parse(this.userinfo.hospitals[0].hptl_clinic_id),
          physio_id: this.physio_name,
          client_reg_id: this.client_reg_id,
          relation_id: this.relation_id,
          sub_rel_id: this.sub_id,
          created_by: this.doc_reg_id,
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
          next_visit: nxt_vis,
          excercises:this.exercisearray
        }
      } else {
        gyno_diab_treat_plan = {
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          recom_exec_id: exercise_id,
          recom_exec_dur: this.life_exerdure,
          recom_exec_freq: this.life_exe_freq,
          food_follow: this.dietfollowadd,
          food_avoid: this.dietavoidwadd,
          food_follow_flag: dit_fol_flg,
          food_avoid_flag: dit_avoid_flg,
          country: ipaddress.country_code,
          yoga_asana_id: yoga_txt,
          yogasanas: yoga_flag,
          pres_drug_id: med_pres_id,
          pres_diag_id: diag_pres_id,
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
          next_visit: nxt_vis,
          excercises:this.exercisearray,disease:this.disease_txtsend
        }
      }
      Helper_Class.setDiabTreat(gyno_diab_treat_plan);
    } else if (this.Pediatrics_flag == true) {
      var diag_pres_id = undefined, med_pres_id = undefined,
        yoga_txt = undefined, nxt_vis = undefined, dit_fol_flg = null, dit_avoid_flg = null;

      if (this.next_data != undefined) {
        nxt_vis = this.next_data;
      }

      for (var i = 0; i < this.Physcial_exercise.length; i++) {
        if (this.life_exer == this.Physcial_exercise[i].description) {
          exercise_id = this.Physcial_exercise[i].exercise_id;
        }
      }
      var medpresid = Helper_Class.get_med_pres_id();
      if (medpresid != undefined && medpresid != null) {
        med_pres_id = medpresid;
      }
      var diagpresid = Helper_Class.get_diag_pres_id();
      if (diagpresid != undefined && diagpresid != null) {
        diag_pres_id = diagpresid;
      }

      var diag_outcome = null;
      this.disease_txtsend = this.disease_txt != undefined ? this.disease_txt : null;

      if (this.prognosis_array != undefined) {
        for (var i = 0; i < this.prognosis_array.length; i++) {
          if (this.prognosis_array[i].add_new != "0") {
            var prog_arry = this.prognosis_array[i].prognosis;
          }
        }
      } else {
        prog_arry = null;
      }

      dit_fol_flg = this.dietfollowadd.length != 0 ? "yes" : "no";
      dit_avoid_flg = this.dietavoidwadd.length != 0 ? "yes" : "no";

      var remarks_data = undefined;
      if (this.remarksList != undefined) {
        for (var i = 0; i < this.remarksList.length; i++) {
          if (this.remarksList[i].add_new == "1") {
            remarks_data = this.remarksList[i].remarks_data;
            break;
          }
        }
      }
      var pedia_diab_treat_plan = null;
      if (this.therapyList.length != 0) {
        pedia_diab_treat_plan = {
          disease: this.disease_txtsend,
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          food_follow: this.dietfollowadd,
          food_avoid: this.dietavoidwadd,
          food_follow_flag: dit_fol_flg,
          food_avoid_flag: dit_avoid_flg,
          country: ipaddress.country_code,
          prognosis: prog_arry,
          remarks: remarks_data,
          physio_app_array: this.physioAppDate,
          hptl_clinic_id: JSON.parse(this.userinfo.hospitals[0].hptl_clinic_id),
          physio_id: this.physio_name,
          client_reg_id: this.client_reg_id,
          relation_id: this.relation_id,
          sub_rel_id: this.sub_id,
          created_by: this.doc_reg_id,
          // country: "IN",
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
          //excercises:this.exercisearray predi
        }
      } else {
        pedia_diab_treat_plan = {
          disease: this.disease_txtsend,
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          food_follow: this.dietfollowadd,
          food_avoid: this.dietavoidwadd,
          food_follow_flag: dit_fol_flg,
          food_avoid_flag: dit_avoid_flg,
          country: ipaddress.country_code,
          prognosis: prog_arry,
          remarks: remarks_data,
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
         // excercises:this.exercisearray
        }
      }
      Helper_Class.setDiabTreat(pedia_diab_treat_plan);
    } else if (this.Diabetes_flag == true) {
      var exercise_id = null, yoga_flag = null, diag_pres_id = undefined, med_pres_id = undefined, type_id = null,
        yoga_txt = undefined, nxt_vis = undefined;

      if (this.life_exer != undefined) {
        if (this.life_exer == "Yoga") {
          yoga_txt = this.yoga_send_data;
          yoga_flag = "yes";
        } else {
          yoga_flag = "no";
        }
      }
      nxt_vis = this.next_data
      dit_fol_flg = this.dietfollowadd.length != 0 ? "yes" : "no";
      dit_avoid_flg = this.dietavoidwadd.length != 0 ? "yes" : "no";

      for (var i = 0; i < this.Physcial_exercise.length; i++) {
        if (this.life_exer == this.Physcial_exercise[i].description) {
          exercise_id = this.Physcial_exercise[i].exercise_id;
        }
      }

      for (var i = 0; i < this.diabetes_type.length; i++) {
        if (this.Diabetic_type == this.diabetes_type[i].description) {
          type_id = this.diabetes_type[i].type_id;
        }
      }

      if (this.prognosis_array != undefined) {
        for (var i = 0; i < this.prognosis_array.length; i++) {
          if (this.prognosis_array[i].add_new != "0") {
            var prog_arry = this.prognosis_array[i].prognosis;
          }
        }
      } else {
        prog_arry = null;
      }

      var remarks_data = undefined;
      if (this.remarksList != undefined) {
        for (var i = 0; i < this.remarksList.length; i++) {
          if (this.remarksList[i].add_new == "1") {
            remarks_data = this.remarksList[i].remarks_data;
            break;
          }
        }
      }
      var medpresid = Helper_Class.get_med_pres_id();
      if (medpresid != undefined && medpresid != null) {
        med_pres_id = medpresid;
      }
      var diagpresid = Helper_Class.get_diag_pres_id();
      if (diagpresid != undefined && diagpresid != null) {
        diag_pres_id = diagpresid;
      }
      if (this.phydure_hidden == true) {
        this.life_exerdure = null;
      }

      if (this.phyfreq_hidden == true) {
        this.life_exe_freq = null;
      }
      var Diabetes_diab_treat_plan;

      if (this.therapyList.length != 0) {
        Diabetes_diab_treat_plan = {
          disease_type: type_id,//diabetes
          disease: this.disease_typetxt,
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          medical_procedure: this.medical_procedure,
          fear_anxiety: this.fear,
          post_opt_comp: this.popcomp_txt,
          country: "IN",
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
          next_visit: nxt_vis,
          yoga_asana_id: yoga_txt,
          yogasanas: yoga_flag,
          recom_exec_id: exercise_id,
          recom_exec_dur: this.life_exerdure,
          recom_exec_freq: this.life_exe_freq,
          food_follow: this.dietfollowadd,
          food_avoid: this.dietavoidwadd,
          food_follow_flag: dit_fol_flg,
          food_avoid_flag: dit_avoid_flg,
          pres_drug_id: med_pres_id,
          pres_diag_id: diag_pres_id,
          prognosis: prog_arry,
          remarks: remarks_data,
          // physio data save
          physio_app_array: this.physioAppDate,
          hptl_clinic_id: JSON.parse(this.userinfo.hospitals[0].hptl_clinic_id),
          physio_id: this.physio_name,
          client_reg_id: this.client_reg_id,
          relation_id: this.relation_id,
          sub_rel_id: this.sub_id,
          created_by: this.doc_reg_id,
          excercises:this.exercisearray
        }
      } else {
        Diabetes_diab_treat_plan = {
          disease_type: type_id,//diabetes
          disease: this.disease_typetxt,
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          medical_procedure: this.medical_procedure,
          fear_anxiety: this.fear,
          post_opt_comp: this.popcomp_txt,
          country: "IN",
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
          next_visit: nxt_vis,
          yoga_asana_id: yoga_txt,
          yogasanas: yoga_flag,
          recom_exec_id: exercise_id,
          recom_exec_dur: this.life_exerdure,
          recom_exec_freq: this.life_exe_freq,
          food_follow: this.dietfollowadd,
          food_avoid: this.dietavoidwadd,
          food_follow_flag: dit_fol_flg,
          food_avoid_flag: dit_avoid_flg,
          //disease: this.disease_typetxt,
          pres_drug_id: med_pres_id,
          pres_diag_id: diag_pres_id,
          prognosis: prog_arry,
          remarks: remarks_data,
          excercises:this.exercisearray
        }
      }
      Helper_Class.setDiabTreat(Diabetes_diab_treat_plan)
    } else if (this.General_flag == true) {
      var diag_pres_id = undefined, med_pres_id = undefined, exercise_id = null, yoga_flag = null, yoga_txt = undefined, nxt_vis = undefined, dit_fol_flg = null, dit_avoid_flg = null, type_id = null;
      if (this.life_exer != undefined) {
        if (this.life_exer == "Yoga") {
          yoga_txt = this.yoga_send_data;
          yoga_flag = "yes";
        } else {
          yoga_flag = "no";
        }
      }

      if (this.next_data != undefined) {
        nxt_vis = this.next_data;
      }
      dit_fol_flg = this.dietfollowadd.length != 0 ? "yes" : "no";
      dit_avoid_flg = this.dietavoidwadd.length != 0 ? "yes" : "no";

      for (var i = 0; i < this.Physcial_exercise.length; i++) {
        if (this.life_exer == this.Physcial_exercise[i].description) {
          exercise_id = this.Physcial_exercise[i].exercise_id;
        }
      }

      if (Helper_Class.get_med_pres_id() != undefined && Helper_Class.get_med_pres_id() != null) {
        med_pres_id = Helper_Class.get_med_pres_id();
      }

      if (Helper_Class.get_diag_pres_id() != undefined && Helper_Class.get_diag_pres_id() != null) {
        diag_pres_id = Helper_Class.get_diag_pres_id();
      }

      if (this.prognosis_array != undefined) {
        for (var i = 0; i < this.prognosis_array.length; i++) {
          if (this.prognosis_array[i].add_new != "0") {
            var prog_arry = this.prognosis_array[i].prognosis;
          }
        }
      } else {
        prog_arry = null;
      }

      if (this.phydure_hidden == true) {
        this.life_exerdure = null;
      }

      if (this.phyfreq_hidden == true) {
        this.life_exe_freq = null;
      }

      var remarks_data = undefined;
      if (this.remarksList != undefined) {
        for (var i = 0; i < this.remarksList.length; i++) {
          if (this.remarksList[i].add_new == "1") {
            remarks_data = this.remarksList[i].remarks_data;
            break;
          }
        }
      }

      var General_diab_treat_plan = null;
      if (this.therapyList.length != 0) {
        General_diab_treat_plan = {
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          disease: this.disease_typetxt,
          life_exer: this.life_exer,
          recom_exec_id: exercise_id,
          recom_exec_dur: this.life_exerdure,
          recom_exec_freq: this.life_exe_freq,
          food_follow: this.dietfollowadd,
          food_avoid: this.dietavoidwadd,
          food_follow_flag: dit_fol_flg,
          food_avoid_flag: dit_avoid_flg,
          country: ipaddress.country_code,
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
          next_visit: nxt_vis,
          yoga_asana_id: yoga_txt,
          yogasanas: yoga_flag,
          pres_drug_id: med_pres_id,
          pres_diag_id: diag_pres_id,
          prognosis: prog_arry,
          remarks: remarks_data,
          physio_app_array: this.physioAppDate,
          hptl_clinic_id: JSON.parse(this.userinfo.hospitals[0].hptl_clinic_id),
          physio_id: this.physio_name,
          client_reg_id: this.client_reg_id,
          relation_id: this.relation_id,
          sub_rel_id: this.sub_id,
          created_by: this.doc_reg_id,
          excercises:this.exercisearray
        }
      } else {
        General_diab_treat_plan = {
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          disease: this.disease_typetxt,
          life_exer: this.life_exer,
          recom_exec_id: exercise_id,
          recom_exec_dur: this.life_exerdure,
          recom_exec_freq: this.life_exe_freq,
          food_follow: this.dietfollowadd,
          food_avoid: this.dietavoidwadd,
          food_follow_flag: dit_fol_flg,
          food_avoid_flag: dit_avoid_flg,
          country: ipaddress.country_code,
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
          next_visit: nxt_vis,
          yoga_asana_id: yoga_txt,
          yogasanas: yoga_flag,
          pres_drug_id: med_pres_id,
          pres_diag_id: diag_pres_id,
          prognosis: prog_arry,
          remarks: remarks_data,
          excercises:this.exercisearray
        }
      }
      Helper_Class.setDiabTreat(General_diab_treat_plan);
    } else if (this.ENT_flag == true) {
      var exercise_id = null, yoga_flag = null, diag_pres_id = undefined, med_pres_id = undefined, type_id = null,
        yoga_txt = undefined, nxt_vis = undefined;
      if (this.life_exer != undefined) {
        if (this.life_exer == "Yoga") {
          yoga_txt = this.yoga_send_data;
          yoga_flag = "yes";
        } else {
          yoga_flag = "no";
        }
      }
      nxt_vis = this.next_data

      for (var i = 0; i < this.Physcial_exercise.length; i++) {
        if (this.life_exer == this.Physcial_exercise[i].description) {
          exercise_id = this.Physcial_exercise[i].exercise_id;
        }
      }

      if (this.prognosis_array != undefined) {
        for (var i = 0; i < this.prognosis_array.length; i++) {
          if (this.prognosis_array[i].add_new != "0") {
            var prog_arry = (
              this.prognosis_array[i].prognosis
            )
          }
        }
      } else {
        prog_arry = null;
      }

      var remarks_data = undefined;
      if (this.remarksList != undefined) {
        for (var i = 0; i < this.remarksList.length; i++) {
          if (this.remarksList[i].add_new == "1") {
            remarks_data = this.remarksList[i].remarks_data;
            break;
          }
        }
      }
      var medpresid = Helper_Class.get_med_pres_id();
      if (medpresid != undefined && medpresid != null) {
        med_pres_id = medpresid;
      }
      var diagpresid = Helper_Class.get_diag_pres_id();
      if (diagpresid != undefined && diagpresid != null) {
        diag_pres_id = diagpresid;
      }
      if (this.phydure_hidden == true) {
        this.life_exerdure = null;
      }

      if (this.phyfreq_hidden == true) {
        this.life_exe_freq = null;
      }
      var ENT_diab_treat_plan;
      if (this.therapyList.length != 0) {
        ENT_diab_treat_plan = {
          disease: this.Diabetic_type,
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          medical_procedure: this.medical_procedure,
          fear_anxiety: this.fear,
          post_opt_comp: this.popcomp_txt,
          country: "IN",
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
          next_visit: nxt_vis,
          yoga_asana_id: yoga_txt,
          yogasanas: yoga_flag,
          recom_exec_id: exercise_id,
          recom_exec_dur: this.life_exerdure,
          recom_exec_freq: this.life_exe_freq,
          food_follow: this.dietfollowadd,
          food_avoid: this.dietavoidwadd,
          food_follow_flag: dit_fol_flg,
          food_avoid_flag: dit_avoid_flg,
          pres_drug_id: med_pres_id,
          pres_diag_id: diag_pres_id,
          prognosis: prog_arry,
          remarks: remarks_data,
          physio_app_array: this.physioAppDate,
          hptl_clinic_id: JSON.parse(this.userinfo.hospitals[0].hptl_clinic_id),
          physio_id: this.physio_name,
          client_reg_id: this.client_reg_id,
          relation_id: this.relation_id,
          excercises:this.exercisearray
        }
      } else {
        ENT_diab_treat_plan = {
          disease: this.Diabetic_type,
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          medical_procedure: this.medical_procedure,
          fear_anxiety: this.fear,
          post_opt_comp: this.popcomp_txt,
          country: "IN",
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
          next_visit: nxt_vis,
          yoga_asana_id: yoga_txt,
          yogasanas: yoga_flag,
          recom_exec_id: exercise_id,
          recom_exec_dur: this.life_exerdure,
          recom_exec_freq: this.life_exe_freq,
          food_follow: this.dietfollowadd,
          food_avoid: this.dietavoidwadd,
          food_follow_flag: dit_fol_flg,
          food_avoid_flag: dit_avoid_flg,
          pres_drug_id: med_pres_id,
          pres_diag_id: diag_pres_id,
          prognosis: prog_arry,
          remarks: remarks_data,
          excercises:this.exercisearray
        }
      }
      Helper_Class.setDiabTreat(ENT_diab_treat_plan)
    } else if (this.oflag == false) {
      var diag_pres_id = undefined, med_pres_id = undefined, exercise_id = null, yoga_flag = null, yoga_txt = undefined, nxt_vis = undefined, dit_fol_flg = null, dit_avoid_flg = null, type_id = null;
      if (this.life_exer != undefined) {
        if (this.life_exer == "Yoga") {
          yoga_txt = this.yoga_send_data;
          yoga_flag = "yes";
        } else {
          yoga_flag = "no";
        }
      }

      nxt_vis = this.next_data
      dit_fol_flg = this.dietfollowadd.length != 0 ? "yes" : "no";
      dit_avoid_flg = this.dietavoidwadd.length != 0 ? "yes" : "no";

      for (var i = 0; i < this.Physcial_exercise.length; i++) {
        if (this.life_exer == this.Physcial_exercise[i].description) {
          exercise_id = this.Physcial_exercise[i].exercise_id;
        }
      }

      if (Helper_Class.get_med_pres_id() != undefined && Helper_Class.get_med_pres_id() != null) {
        med_pres_id = Helper_Class.get_med_pres_id();
      }

      if (Helper_Class.get_diag_pres_id() != undefined && Helper_Class.get_diag_pres_id() != null) {
        diag_pres_id = Helper_Class.get_diag_pres_id();
      }

      if (this.prognosis_array != undefined) {
        for (var i = 0; i < this.prognosis_array.length; i++) {
          if (this.prognosis_array[i].add_new.toString() != "0") {
            var prog_arry = this.prognosis_array[i].prognosis;
          }
        }
      }

      if (this.phydure_hidden == true) {
        this.life_exerdure = null;
      }

      if (this.phyfreq_hidden == true) {
        this.life_exe_freq = null;
      }

      var remarks_data = undefined;
      if (this.remarksList != undefined) {
        for (var i = 0; i < this.remarksList.length; i++) {
          if (this.remarksList[i].add_new == "1") {
            remarks_data = this.remarksList[i].remarks_data;
            break;
          }
        }
      }

      var Others_diab_treat_plan = null;
      if (this.therapyList.length != 0) {
        Others_diab_treat_plan = {
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          disease: this.Diabetic_type,
          life_exer: this.life_exer,
          recom_exec_id: exercise_id,
          recom_exec_dur: this.life_exerdure,
          recom_exec_freq: this.life_exe_freq,
          food_follow: this.dietfollowadd,
          food_avoid: this.dietavoidwadd,
          food_follow_flag: dit_fol_flg,
          food_avoid_flag: dit_avoid_flg,
          country: ipaddress.country_code,
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
          next_visit: nxt_vis,
          yoga_asana_id: yoga_txt,
          yogasanas: yoga_flag,
          pres_drug_id: med_pres_id,
          pres_diag_id: diag_pres_id,
          prognosis: prog_arry,
          remarks: remarks_data,
          physio_app_array: this.physioAppDate,
          hptl_clinic_id: JSON.parse(this.userinfo.hospitals[0].hptl_clinic_id),
          physio_id: this.physio_name,
          client_reg_id: this.client_reg_id,
          relation_id: this.relation_id,
          sub_rel_id: this.sub_id,
          created_by: this.doc_reg_id,
          excercises:this.exercisearray
        }
      } else {
        Others_diab_treat_plan = {
          icd10: this.Icd_code,
          icd10_code: this.Icd_code,
          icd10_desc: this.Icd_Descript,
          disease: this.Diabetic_type,
          life_exer: this.life_exer,
          recom_exec_id: exercise_id,
          recom_exec_dur: this.life_exerdure,
          recom_exec_freq: this.life_exe_freq,
          food_follow: this.dietfollowadd,
          food_avoid: this.dietavoidwadd,
          food_follow_flag: dit_fol_flg,
          food_avoid_flag: dit_avoid_flg,
          country: ipaddress.country_code,
          ref_hospital: this.hosp_name,
          ref_speciality: this.speclization_txt,
          refered_to: this.refer_txt,
          next_visit: nxt_vis,
          yoga_asana_id: yoga_txt,
          yogasanas: yoga_flag,
          pres_drug_id: med_pres_id,
          pres_diag_id: diag_pres_id,
          prognosis: prog_arry,
          remarks: remarks_data,
          excercises:this.exercisearray
        }
      }
      Helper_Class.setDiabTreat(Others_diab_treat_plan);
    }
  }

  hospital_filter(e) {
    var tmp_arr = [];
    this.hosp_name = e.target.value.toString();
    if (this.hosp_name !== "" && this.hosp_name.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/hospital/',
        JSON.stringify({
          hosp_name: this.hosp_name
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.hospital_txt = [];
            this.hospital_filterlist = [];
            var obj = response.json()

            if (obj.hospitals != null) {
              this.hospital_filterlist = obj.hospitals;
              for (var i = 0; i < this.hospital_filterlist.length; i++) {
                this.hospital_txt.push(this.hospital_filterlist[i].hosp_name);
              }
              var hospname = this.hosp_name
              this.filteredhospList = this.hospital_txt.filter(function (el) {
                return el.toLowerCase().indexOf(hospname.toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => { }
        );
    } else {
      this.filteredhospList = [];
    }
  }

  Get_diseases() {//gyno
    var obj = masterCSData_Helper.getMasterGynoDisease();
    console.log("gyna  obj--"+JSON.stringify(obj))
    if(obj != undefined)
      this.Get_thedise = obj.gynaec_diseases;
    // this.get_exercise();
  }

  diagnosis_retrivel() {
    var getgynaRet = Helper_Class.getgynaRet();
    console.log("check getgynaRet"+JSON.stringify(Helper_Class.getgynaRet()))
    if (getgynaRet != undefined) {
      if (getgynaRet.icd10_code != null && getgynaRet.icd10_code != undefined) {
        this.Icd_code = getgynaRet.icd10_code;
      }

      if (getgynaRet.icd10_desc != null && getgynaRet.icd10_desc != undefined) {
        this.Icd_Descript = getgynaRet.icd10_desc;
      }

      if (getgynaRet.disease != null && getgynaRet.disease != undefined) {
        console.log("this.Get_thedise getgynaRet"+JSON.stringify(this.Get_thedise))
        console.log("this.Get_thedise getgynaRet"+JSON.stringify(getgynaRet.disease))

        for (var i = 0; i < this.Get_thedise.length; i++) {
          for (var j = 0; j < getgynaRet.disease.length; j++) {
            if (getgynaRet.disease[j] == this.Get_thedise[i].disease_id) {
              this.disease_txt = this.Get_thedise[i].description;
              
            }
          }
        }
        this.disease_txt=getgynaRet.dis_desc
      }
     
      this.dietfollowadd = [];
      if (getgynaRet.diet_follow != null && getgynaRet.diet_follow.length != 0) {
        this.diet_followtable = false;
        for (var i = 0; i < getgynaRet.diet_follow.length; i++) {
          this.dietfollowadd.push({
            food_type_name: getgynaRet.diet_follow[i].food_type_name,
            food_item: getgynaRet.diet_follow[i].food_item,
            food_type_id: getgynaRet.diet_follow[i].food_type_id
          });
        }
      }

      //Diet avoid
      this.dietavoidwadd = [];
      if (getgynaRet.diet_avoid != null && getgynaRet.diet_avoid.length != 0) {
        this.diet_avoidtable = false;
        for (var i = 0; i < getgynaRet.diet_avoid.length; i++) {
          this.dietavoidwadd.push({
            food_type_name: getgynaRet.diet_avoid[i].food_type_name,
            food_item: getgynaRet.diet_avoid[i].food_item,
            food_type_id: getgynaRet.diet_avoid[i].food_type_id
          });
        }
      }

      if (getgynaRet.next_visit != undefined) {
        this.next_data = getgynaRet.next_visit;

      }
      if (getgynaRet.dis_desc != undefined) {
        this.Diabetic_type = getgynaRet.dis_desc;
      }
      if (getgynaRet.recom_exec_id != null && getgynaRet.recom_exec_id != undefined) {
        for (var i = 0; i < this.Physcial_exercise.length; i++) {
          for (var j = 0; j < getgynaRet.recom_exec_id.length; j++) {
            if (getgynaRet.recom_exec_id[j] == this.Physcial_exercise[i].exercise_id) {
              this.life_exer = this.Physcial_exercise[i].description;

            }
          }
        }
      }
      if (getgynaRet.recom_exec_dur != null && getgynaRet.recom_exec_dur != undefined) {
        this.life_exerdure = getgynaRet.recom_exec_dur;
      }
      if (getgynaRet.recom_exec_freq != null && getgynaRet.recom_exec_freq != undefined) {
        this.life_exe_freq = getgynaRet.recom_exec_freq;
      }
      if (getgynaRet.refered_to_hospital != undefined && getgynaRet.refered_to_hospital != null) {
        this.hosp_name = getgynaRet.refered_to_hospital;
      }
      if (getgynaRet.refered_to_speciality != undefined && getgynaRet.refered_to_speciality != null) {
        this.speclization_txt = getgynaRet.refered_to_speciality;
      }
      if (getgynaRet.refered_to != undefined && getgynaRet.refered_to != null) {
        this.refer_txt = getgynaRet.refered_to;
      }
      var yogaflag,datacheckvalue;var datacheckvalue1;
      this.exercisearray=[];
      if (getgynaRet.excercises != null && getgynaRet.excercises != undefined) {
        this.exercise_array_flag =true;
      //  this.exercisetable=true;
      console.log("EXER ARRAY___"+JSON.stringify(this.Physcial_exercise))
      console.log("EXER getgynaRet.excercises"+JSON.stringify(getgynaRet.excercises))

        if (getgynaRet.excercises.length != 0) {
          for (var i = 0; i < this.Physcial_exercise.length; i++) {
            for (var j = 0; j < getgynaRet.excercises.length; j++) {
              if (getgynaRet.excercises[j].recom_exec_id == this.Physcial_exercise[i].exercise_id) {
              var data = getgynaRet.excercises[j]
              if( getgynaRet.excercises[j].rec_exec_desc == "Yoga"){
                yogaflag=false;
                datacheckvalue=getgynaRet.excercises[j].rec_exec_desc;
                datacheckvalue1=getgynaRet.excercises[j].rec_exec_desc+"("+data.recom_yoga_asana+")";
                var selectedyoga = "";
                if(this.yoga_div == false){
                  var array=[]
                  for(var i = 0; i < this.yoga_send_data.length; i++){    
                    if(i != 0){
                      selectedyoga +=  ",  " + this.yoga_send_data[i] ;
                    } else {
                      selectedyoga +=  this.yoga_send_data[i];
                    }
                    array =this.yoga_send_data
                    // sensitiveMedicines += this.sensitivityCardList[i].med_Name + ",  ";      
                  }    
                }
                var values= data.recom_yoga_asana.split(",")
              //  alert("valuesvalues"+JSON.stringify(values))
                for (let k = 0; k < values.length; k++) {
                  for (let m = 0; m < this.Get_theyoga.length; m++) {
                    if (values[k] === this.Get_theyoga[m].description) {
                      console.log("Matched value:", values[k], "in Get_theyoga:", this.Get_theyoga[m].description);
                      this.Get_theyoga[m].checked = false;
                      this.yoga_send_data.push(this.Get_theyoga[m].description)
                    }
                  }
                 // this.exercisearray.splice(i)
                }    
              //  break; 
              }else{
                yogaflag=true;
                datacheckvalue=getgynaRet.excercises[j].rec_exec_desc;
                datacheckvalue1=getgynaRet.excercises[j].rec_exec_desc;
              }
              this.exercisearray.push({                
                life_exer: datacheckvalue,
                // life_exerview:datacheckvalue1,
                recom_exec_id: data.recom_exec_id,
                recom_exec_dur: data.recom_exec_dur,
                recom_exec_freq: data.recom_exec_freq,
                recom_exec_session: data.recom_exec_session,
                session:data.recom_exec_session,
                yogasanas:data.recom_yoga_asana,
                recom_yoga_asana: data.recom_yoga_asana,
                yogaflag:yogaflag,
                yogaarray:this.yoga_send_data
              })
            }
           }
          }


        }
      }
      console.log("EXER ARRAY___"+JSON.stringify(this.exercisearray))
      //Yoga
      this.yoga_check = [];
      this.yoga_check1 = [];
      if (getgynaRet.yogasanas != null && getgynaRet.yogasanas != undefined) {
        for (var i = 0; i < this.Get_theyoga.length; i++) {
          for (var j = 0; j < getgynaRet.yogasanas.length; j++) {
            if (getgynaRet.yogasanas[j].yoga_asana_id == this.Get_theyoga[i].yoga_asana_id) {
              this.yoga_check.push(this.Get_theyoga[i].yoga_asana_id);
              this.yoga_check1.push(this.Get_theyoga[i].description);
              this.yogadescription = this.Get_theyoga[i].description;
              this.yoga_send_data = this.yoga_check;
            }
          }
        }
        this.yoga_div = this.yoga_check.length != 0 ? false : true;
      }

      //prognosis
      if (getgynaRet.prognosis != null && getgynaRet.prognosis != undefined) {
        for (var i = 0; i < getgynaRet.prognosis.length; i++) {
          var get_date = getgynaRet.prognosis[i].date.split('-');
          var progdocname;
          if (getgynaRet.prognosis[i].middle_name != undefined && getgynaRet.prognosis[i].middle_name != null) {
            progdocname = getgynaRet.prognosis[i].first_name + " " + getgynaRet.prognosis[i].middle_name + " " + getgynaRet.prognosis[i].last_name;
          } else {
            progdocname = getgynaRet.prognosis[i].first_name + " " + getgynaRet.prognosis[i].last_name;
          }

          this.prognosis_array.push({
            date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
            doc_name: progdocname,
            prognosis: getgynaRet.prognosis[i].prognosis,
            add_new: "0",
          });
        }
        this.prognosis_array_flag = true;
      }

      var docname;
      if (this.userinfo.middle_name != undefined) {
        docname = this.userinfo.first_name + " " + this.userinfo.middle_name + " " + this.userinfo.last_name;
      } else {
        docname = this.userinfo.first_name + " " + this.userinfo.last_name;
      }

      if (getgynaRet.remarks != undefined && getgynaRet.remarks != null) {
        for (var j = 0; j < getgynaRet.remarks.length; j++) {
          var datevalue = getgynaRet.remarks[j].date.split('-');
          var newdate = datevalue[2] + "-" + datevalue[1] + "-" + datevalue[0];
          this.remarksList.push({
            remarks_data: getgynaRet.remarks[j].remarks,
            Doct_Name: docname,
            Date: newdate,
            add_new: "0"
          });
        }
        this.remarksList_flag = true;
      }
    }
  }

  avoidfilter(e) {
    this.filteredList1 = [];
    this.food_txt = [];
    this.Foot_Item_avoidtxt = e.target.value.toString();
    if (this.Foot_Item_avoidtxt !== "" && this.Foot_Item_avoidtxt.length > 2) {
      var type_id = null;
      for (var i = 0; i < this.get_foodtype_arry.length; i++) {
        if (this.Food_type_avoid == this.get_foodtype_arry[i].description) {
          type_id = this.get_foodtype_arry[i].type_id;
        }
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'lifestyle/fdiet/',
        JSON.stringify({
          food_category: type_id,
          name: this.Foot_Item_avoidtxt
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.follow_avoid_search_items = [];
            this.food_txt = [];
            var obj = response.json();
            this.follow_avoid_search_items = obj.diet_values;
            for (var i = 0; i < this.follow_avoid_search_items.length; i++) {
              this.food_txt.push(this.follow_avoid_search_items[i].name);
            }
            this.filteredList1 = this.food_txt.filter(function (this: any, el) {
              return el.toLowerCase().indexOf(this.Foot_Item_avoidtxt.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
            this.toastr.error("Something Went Wrong, Please Try Again Later");
          }
        )
    } else {
      this.filteredList1 = [];
    }
  }

  // follow filter auto selecttion
  followfilter(e) {
    this.filteredList = [];
    this.food_txt = [];
    this.Foot_Item_txt = e.target.value.toString();
    if (this.Foot_Item_txt != "" && this.Foot_Item_txt.length > 2) {
      var type_id = null;
      for (var i = 0; i < this.get_foodtype_arry.length; i++) {
        if (this.food_type_name == this.get_foodtype_arry[i].description) {
          type_id = this.get_foodtype_arry[i].type_id;
        }
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'lifestyle/fdiet/',
        JSON.stringify({
          food_category: type_id,
          name: this.Foot_Item_txt
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.follow_food_search_items = [];
            this.food_txt = [];
            var obj = response.json();
            this.follow_food_search_items = obj.diet_values;
            for (var i = 0; i < this.follow_food_search_items.length; i++) {
              this.food_txt.push(this.follow_food_search_items[i].name);
            }
            this.filteredList = this.food_txt.filter(function (this: any, el) {
              return el.toLowerCase().indexOf(this.Foot_Item_txt.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
            this.toastr.error("Something Went Wrong, Please Try Again Later");
          }
        )
    } else {
      this.filteredList = [];
    }
  }

  Get_food_type() {
    var obj = masterCSData_Helper.getMasterFoodType();
    if(obj != undefined)
      this.get_foodtype_arry = obj.food_categories;
  }

  DietToFollowAdd() {
    var flag = true;
    if (this.food_type_name == undefined) {
      this.toastr.error("Select food type");
      flag = false;
    }

    if (flag == true && this.Foot_Item_txt == undefined && this.Foot_Item_txt == undefined) {
      this.toastr.error("Select food item");
      flag = false;
    }

    for (var i = 0; i < this.dietfollowadd.length; i++) {
      if (this.dietfollowadd[i].food_item == this.Foot_Item_txt) {
        flag = false;
        break;
      }
    }

    for (var i = 0; i < this.get_foodtype_arry.length; i++) {
      if (this.food_type_name == this.get_foodtype_arry[i].description) {
        this.food_type = this.get_foodtype_arry[i].description;
        this.food_type_id = this.get_foodtype_arry[i].type_id;
      }
    }

    if (flag == true) {
      this.diet_followtable = false;
      this.dietfollowadd.push({
        food_type_name: this.food_type,
        food_item: this.Foot_Item_txt,
        food_type_id: this.food_type_id
      });
    }

    //Resets the form 
    this.food_type_name = undefined;
    this.Foot_Item_txt = '';
  }

  //Add diet to avoid
  DietToAvoidAdd() {
    var flag = true;

    if (this.Food_type_avoid == undefined) {
      this.toastr.error("Select food type");
      flag = false;
    }

    if (flag == true && this.Foot_Item_avoidtxt == undefined && this.Foot_Item_avoidtxt == "") {
      this.toastr.error("Select food Item");
      flag = false;
    }

    for (var i = 0; i < this.dietavoidwadd.length; i++) {
      if (this.dietavoidwadd[i].food_item_avoid == this.Foot_Item_avoidtxt) {
        flag = false;
        break;
      }
    }

    for (var i = 0; i < this.get_foodtype_arry.length; i++) {
      if (this.Food_type_avoid == this.get_foodtype_arry[i].description) {
        this.food_type_avoiddes = this.get_foodtype_arry[i].description;
        this.food_typeavoid_id = this.get_foodtype_arry[i].type_id;
      }
    }

    if (flag == true) {
      this.diet_avoidtable = false;
      this.dietavoidwadd.push({
        food_type_name: this.food_type_avoiddes,
        food_item: this.Foot_Item_avoidtxt,
        food_type_id: this.food_typeavoid_id
      });
    }

    //Resets the form 
    this.Food_type_avoid = undefined;
    this.Foot_Item_avoidtxt = '';
  }
  //Diet to avoid delete
  DeleteDietAvoid(foodavoid, itemavoid) {
    for (var i = 0; i < this.dietavoidwadd.length; i++) {
      if (this.dietavoidwadd[i].diet_food_type_name == foodavoid && this.dietavoidwadd[i].food_item == itemavoid) {
        this.dietavoidwadd.splice(i, 1);
        break;
      }
    }

    this.diet_avoidtable = this.dietavoidwadd.length != 0 ? false : true;
  }

  select_avoid_item(item) {
    this.Foot_Item_avoidtxt = item;
    this.filteredList1 = [];
  }

  select_follow_item(item) {
    this.Foot_Item_txt = item;
    this.filteredList = [];
  }

  DeleteDietFollow(food, item) {
    for (var i = 0; i < this.dietfollowadd.length; i++) {
      if (this.dietfollowadd[i].food_type_name == food && this.dietfollowadd[i].food_item == item) {
        this.dietfollowadd.splice(i, 1);
        break;
      }
    }
    this.diet_followtable = this.dietfollowadd.length != 0 ? false : true;
  }

  

  appGetdate() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      this.curr_date = obj.current_date;
      var get_date = obj.current_date.split('-');
      this.current_date = Date_Formate(obj.current_date);
      var current_date1 = obj.current_date;
      this.current_time = obj.current_time;
      this.current_year = obj.current_date.split('-')[0];
      this.next_data = (current_date1);
      this.CurrentDatetime = obj.current_date;
      this.currentDate = (obj.current_date);
      this.physioStartDate = current_date1;
    }
  }

  getTherapyHistory() {//Get therapy history
    this.therapy_array = [];
    console.log("check ---"+JSON.stringify({
      hptl_clinic_id: (this.userinfo.hospitals[0].hptl_clinic_id),
      status: "1",
    }))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/gtherapy',
      JSON.stringify({
        hptl_clinic_id: (this.userinfo.hospitals[0].hptl_clinic_id),
        status: "1",
      }),
      { headers: headers })
      .subscribe(
        response => {
          console.log("check ---"+JSON.stringify(response))
          var obj = JSON.parse(response["_body"]);
          if (obj.therapies != null) {
            for (var i = 0; i < obj.therapies.length; i++) {
              this.therapy_array.push({
                type: 'checkbox',
                value: obj.therapies[i].therapy_id,
                label: obj.therapies[i].therapy,
                checked: false
              });
            }
          }
        },
        error => { });
  }

  getThetraphist() {
    this.therapyhist_array = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'search/npbyhs',
      JSON.stringify({
        hptl_clinic_id: JSON.parse(this.userinfo.hospitals[0].hptl_clinic_id),
        type: "physio"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          var name;

          if (obj.physio_nurses != null) {
            for (var i = 0; i < obj.physio_nurses.length; i++) {
              if (obj.physio_nurses[i].middle_name != undefined) {
                name = obj.physio_nurses[i].first_name + " " + obj.physio_nurses[i].middle_name + " " + obj.physio_nurses[i].last_name;
              } else {
                if (obj.physio_nurses[i].last_name != undefined) {
                  name = obj.physio_nurses[i].first_name + " " + obj.physio_nurses[i].last_name;
                } else {
                  name = obj.physio_nurses[i].first_name;
                }
              }
              this.therapyhist_array.push({
                name: name,
                prov_id: obj.physio_nurses[i].prov_id,

              });
            }
          }
        },
        error => { });
  }

  addTherapy() {
    if (this.therapy_txt == undefined || this.sittings == undefined || this.visit_freq == undefined) {
      this.toastr.error("please enter mandatory fields");
    } else {
      var therapyname;
      for (var i = 0; i < this.therapy_array.length; i++) {
        if (this.therapy_array[i].value == this.therapy_txt) {
          therapyname = this.therapy_array[i].label;
        }
      }
      this.therapyList.push({
        therapy_id: this.therapy_txt,
        therapy_name: therapyname,
        sittings: this.sittings,
        frequency: this.visit_freq,
        remarks: this.physio_remarks,
        app_time: this.Start_time + ":" + this.end_time + " " + this.time_session,
        start_from: Date_Formate(this.physioStartDate)
      });
      this.therapy_table = true;
      // for setting the app dates
      var thrrapyId = [];
      var startDate = new Date(this.physioStartDate);
      var getMonth = this.physioStartDate.split("-");
      var year = getMonth[0];
      var month = getMonth[1];
      var day = getMonth[2] - 1;
      var nextdate: any;
      var jan312009 = new Date(year, month, day);

      var sessionId = this.session_txt == "Morning" ? 1 : this.session_txt == "Afternoon" ? 1 : 2;
      var app_time = this.Start_time + ":" + this.end_time;
      for (var i = 0; i < parseInt(this.sittings); i++) {
        thrrapyId = [];
        if (i == 0) {
          nextdate = new Date(startDate.getTime() + 0 * 24 * 60 * 60 * 1000);
        } else {
          nextdate = this.visit_freq == "Every day" ? new Date(startDate.getTime() + 1 * 24 * 60 * 60 * 1000) :
            this.visit_freq == "Alternative days" ? new Date(startDate.getTime() + 2 * 24 * 60 * 60 * 1000) :
              this.visit_freq == "3 days once" ? new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000) :
                this.visit_freq == "Once in a week" ? new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000) :
                  this.visit_freq == "Once in fortnight" ? new Date(startDate.getTime() + 14 * 24 * 60 * 60 * 1000) :
                    startDate.setMonth(startDate.getMonth() + 1);
        }

        var validateDat = this.getDateMatePicker(nextdate);
        if (this.physioAppDate.length != 0) {
          var checkArray = this.physioAppDate.find(o => o.app_date == validateDat);
          if (checkArray != undefined) {
            if (checkArray.session == this.session_txt && checkArray.time == app_time) {
              for (var j = 0; j < this.physioAppDate.length; j++) {
                if (checkArray.app_date == this.physioAppDate[j].app_date && checkArray.session == this.physioAppDate[j].session && checkArray.time == this.physioAppDate[j].time) {
                  this.physioAppDate[j].therapy_id.push(this.therapy_txt);
                }
              }
            } else {
              thrrapyId.push(this.therapy_txt)
              this.physioAppDate.push({
                app_date: validateDat,
                session: sessionId,
                app_time: app_time,
                therapyarray: thrrapyId,
                remarks: this.physio_remarks,
              });
            }
          } else {
            thrrapyId.push(this.therapy_txt)
            this.physioAppDate.push({
              app_date: validateDat,
              session: sessionId,
              app_time: app_time,
              therapyarray: thrrapyId,
              remarks: this.physio_remarks,
            });
          }
        } else {
          thrrapyId.push(this.therapy_txt)
          this.physioAppDate.push({
            app_date: validateDat,
            session: sessionId,
            app_time: app_time,
            therapyarray: thrrapyId,
            remarks: this.physio_remarks,
          });
        }
        startDate = new Date(validateDat);
      }
    }
    this.therapyflag = "yes";
  }

  selectSession(event) {
    if (event == "Morning") {
      this.time_session = "AM";
      this.time_session_end = "AM";
      this.Start_time = "06";
      this.end_time = "00";
      this.Start_time1 = "08";
      this.end_time1 = "00";
      this.chk_data = "0";

    } else if (event == "Evening") {
      this.time_session_end = "PM";
      this.time_session = "PM";
      this.Start_time = "06";
      this.end_time = "00";
      this.Start_time1 = "08";
      this.end_time1 = "00";
      this.chk_data = "0";
      
    } else {
      this.time_session_end = "PM";
      this.time_session = "PM";
      this.Start_time = "12";
      this.end_time = "00";
      this.Start_time1 = "02";
      this.end_time1 = "00";
      this.chk_data = "0";
    }
  }

  checkAvilablity() {
    this.session = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/gpavail',
      JSON.stringify({
        physio_id: this.physio_name,
        hptl_clinic_id: JSON.parse(this.userinfo.hospitals[0].hptl_clinic_id)
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj.physio_avail != null) {
            for (var i = 0; i < obj.physio_avail.length; i++) {
              if (obj.physio_avail[i].day_session == "1") {
                this.session.push("Morning");
              } else if (obj.physio_avail[i].day_session == "2") {
                this.session.push("Afternoon");
              } else if (obj.physio_avail[i].day_session == "3") {
                this.session.push("Evening");
              }
            }
            this.selectSession(this.session[0])
            this.session_txt = this.session[0];
          }
        },
        error => {
          this.toastr.error("Something Went Wrong, Please Try Again Later");
        }
      )
  }

  getDateMatePicker(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  delete_therapy(data) {
    for (var i = 0; i < this.therapyList.length; i++) {
      if (this.therapyList[i].therapy_id == data) {
        this.therapyList.splice(i, 1);
      }
    }
    for (var i = 0; i < this.physioAppDate.length; i++) {
      for (var j = 0; j < this.physioAppDate[i].therapy_id.length; j++) {
        var index = this.physioAppDate[i].therapy_id.indexOf(data);
        if (index !== -1) {
          if (this.physioAppDate[i].therapy_id.length == 1) {
            this.physioAppDate.splice(i, 1);
          } else {
            this.physioAppDate[i].therapy_id.splice(index, 1);
          }
        }
      }
    }

    if (this.therapyList.length == 0) {
      this.therapyflag = "no"
    }
  }

  updateTherapy() {
    var therapylistval = [];

    for (var j = 0; j < this.therapyList.length; j++) {
      this.therapyList[j].checked = true;
      therapylistval.push({
        type: 'checkbox',
        value: this.therapyList[j].therapy_id,
        label: this.therapyList[j].therapy_name,
        checked: true
      })
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'physio/scphist',
      JSON.stringify({
        case_hist_id: this.case_hist_id,
        therapies: therapylistval,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.toastr.error(obj.result);
        },
        error => { });
  }

  OnDateMatePicker(e) {
    this.next_data = e;
  }

  physioOnDateMatePicker(e) {
    this.physioStartDate = e;
  }

  history() {
    this.person_hist = "diagnosis_hist"
    const dialogRef = this.dialog.open(CardioPopupHistoryComponent, {
      width: '700px',
      height: '300px',
      data: {
        Diab_case_hist_id: this.casesheet_case_hist_id,
        Life_style_id: this.life_style_id,
        History: this.person_hist,
      }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  changetab(e) {
    this.diet_table = false;
    this.prognosis_table = false;
    this.notes_table = false;
    this.therapy_table = false;
    this.exercisetable = false;
    if (e == "diet") {
      this.diet_table = true;
    } else if (e == "prognosis") {
      this.prognosis_table = true;
    } else if (e == "notes") {
      this.notes_table = true;
    } else if (e == "therapy") {
      this.therapy_table = true;
    }
    else if (e == "exercise") {
      this.exercisetable = true;
    }
  }

  lookupICDCodes() {
    this.Icd_code = "";
    if (this.Icd_Descript.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'icd/afillcode/',
        JSON.stringify({
          text: this.Icd_Descript
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.icdList = [];
            var obj = JSON.parse(response["_body"]);

            for (var i = 0; i < obj.icdcodes.length; i++) {
              this.icdList.push({
                code: obj.icdcodes[i].code,
                desc: obj.icdcodes[i].description
              });
            }
          },
          error => {
            this.toastr.error("Internet is too slow or no connection, try again later");
          }
        )
    }
  }

  select_icdcode(item) {
    this.Icd_code = "";

    for (var i = 0; i < this.icdList.length; i++) {
      if (this.icdList[i].desc == item) {
        this.Icd_code = this.icdList[i].code;
      }
    }
    this.Icd_Descript = item;
    this.icdList = [];
    this.send_data();
  }

  Icd_click() {
    const dialogRef = this.dialog.open(IcdCodePopupComponent, {
      width: '500px',
      height: '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        var Icd_data = result.split("-")
        this.Icd_code = Icd_data[0];
        this.Icd_Descript = Icd_data[1];
        this.send_data();
      }    
    });
  }

  async getGeneralSurgeries() {
    this.surgeryarray = [];
    if (Master_Helper.getMasterGeneralSurgeries() == undefined) {
      await this.masterData.getGeneralSurgeries()
    }
    var obj = Master_Helper.getMasterGeneralSurgeries();
    if (obj.med_procedures != undefined) {
      this.surgeryarray = obj.med_procedures;
    }
  }

  bookNow(e) {
    this.booknowFlag = e;
  }

  getNotes() {
    if (this.remarks.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gdn/',
        JSON.stringify({
          hptl_clinic_id: JSON.parse(this.userinfo.hospitals[0].hptl_clinic_id),
          doc_note: this.remarks
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            this.notes_list = [];
            this.notes_data = [];
            this.noteslist = [];
            this.notes_list = obj.doc_notes;
            for (var i = 0; i < this.notes_list.length; i++) {
              this.notes_data.push(this.notes_list[i].doc_note);
            }

            this.noteslist = this.notes_data.filter(function (this: any, el) {
              return el.toLowerCase().indexOf(this.remarks.toLowerCase()) > -1;
            }.bind(this));
          },
          error => { });
    }
  }

  select_symptom(notes) {
    this.remarks = notes;
    this.noteslist = [];
  }
}









