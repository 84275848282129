import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate, Date_Formate } from '../../../assets/js/common.js';
import { DomSanitizer } from '@angular/platform-browser';
import { ipaddress } from '../../ipaddress';
import { Helper_Class } from '../../helper_class';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import html2canvas from 'html2canvas';

import { NgxBarcodeModule } from 'ngx-barcode';
// import jsPDF from 'jspdf';
import htmlToPdfmake from 'html-to-pdfmake';


import autoTable from 'jspdf-autotable';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Console } from 'console';
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
   selector: 'app-diagnosis-report-detailview',
   templateUrl: './diagnosis-report-detailview.component.html',
   styleUrls: ['./diagnosis-report-detailview.component.scss']
})
export class DiagnosisReportDetailviewComponent implements OnInit {
   @ViewChild('printlogowithname') public printlogowithname: ElementRef = {} as ElementRef;
   @ViewChild('printbanner') public printbanner: ElementRef = {} as ElementRef;
   @ViewChild('printnoheader') public printnoheader: ElementRef = {} as ElementRef;
   @ViewChild('printnoheadsidebar') public printnoheadsidebar: ElementRef = {} as ElementRef;
   // @ViewChild('barcode', { static: false }) barcode!: ElementRef;
   // @ViewChild('barcodeElement') public barcodeElement: ElementRef = {} as ElementRef;
   @ViewChild('barcodeElement') barcodeElement!: ElementRef;
   @ViewChild('table') public table: ElementRef = {} as ElementRef;
   public address1;
   public outsource_id;
   public uploadidproof;
   public imageflag_outsource_id: boolean = true;
   public doctorname;
   public address2; public clntlocation; public clntstate; public clntdistrict; public clntcountry; public clntzipcode;
   public entry_date;
   public entry_time;
   public sampleidvalue;
   public sampletype;
   public sampledate;
   public sampletime;
   // public doctorname;

   pdfTable!: ElementRef;
   public tableReportTypeFlag: boolean;
   public paraReportTypeFlag: boolean;

   public reportType;
   public diagAppID;
   public presTestID;
   public presDiagID;

   public clientAnotherName;
   public clientGender;
   public clientAge;
   public reviewSign;
   public signFlag: boolean;
   public sigSectionFlag: boolean = false;
   public reviewFlag: boolean;
   public reviewBy;
   public sampleCollectedTime;
   public reportIMG;
   public dicomDotor: boolean = true;
   public sequences;
   public impression;
   public viewTestArray = [];
   public diagTestReadingArray = [];
   public centerName;
   public diagAddress;
   public diagLocation;
   public diagCityZip;
   public diagStateCon;
   public diagTelephone;
   public clientID;
   public diagReferedBy;
   public date;
   public time;
   public testArray = [];
   public sequence;
   public Impression;
   public ReportFlag;
   public approvelFlag: boolean;
   public uploadFlag: boolean;
   public loader: boolean;
   public reportDetails;
   public testName: string;
   public referFlag: boolean = false;
   public innerFrame;
   public iFrame;
   public pdfObj;
   public content;
   public suntestNameFlag: boolean = false;
   public testMethod;
   public printFlag: boolean;
   public printTemplate;
   public hospitalFooter;
   public printfFlag;
   public bgImage;
   public hospImage;
   public hospitalLogo;
   public imageString;
   public patientID: any;
   public subtestMethodFlag: boolean;
   public createdBy;
   public printArray = [];
   public culture;
   public cultureFlag: boolean = false;
   public testrangeFlag: boolean = false;
   public userInfo;
   public hospitalDetails;
   public hospitalName;
   public hptlLogo;
   public location;
   public city;
   public state;
   public country;
   public reporttype;
   public template: any;
   public colonyCountArray: any = [];
   public template_flag: boolean;
   imageUrl: any;

   // bar code

   // elementType: 'svg' | 'img' | 'canvas';
   format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';

   elementType: 'svg';
   lineColor = '#000000';
   width = 0.8;
   barcodeheight = 20;
   displayValue = true;
   fontOptions = '';
   font = 'monospace';
   textAlign = 'left';
   textPosition = 'bottom';

   textMargin = 0;
   fontSize = 18;
   background = '#ffffff';
   margin = 10;

   marginTop = 2;
   marginBottom = 2;
   marginLeft = 0;
   marginRight = 10;
   ot_Sterile_flag: boolean;
   micro_temp: boolean;

   diag_report_type: any;
   public pathology_flag: boolean = true;
   public radiology_flag: boolean = true;
   public microbiology_flag: boolean = true;

   value_flag: boolean;

   public oraganismIso1: any;
   public oraganismIso2: any;
   public oraganismIso3: any;
   culture_report_type_desc: any;
   gram_stain_desc: any;
   culture_report_desc: any;
   ot_sterile: any = [];
   ot_listFlag: boolean;
   technical_note: any;
   comment: any;
   sampleColl_dateTime: any;
   approve_dateTime: any;
   sample_id: any;
   sample_type: any;
   approver_name: string;
   qualityHead_name: string;
   visit_id: any;
   salutation: any;
   patient_type: any;
   mobile: any;

   public org_iso_1_code: any;
   public org_iso_2_code: any;
   public org_iso_3_code: any;

   public org_iso_1_desc: any;
   public org_iso_2_desc: any;
   public org_iso_3_desc: any;

   public org_iso_1_cc_desc: any;
   public org_iso_2_cc_desc: any;
   public org_iso_3_cc_desc: any;

   public org_iso1_cr_desc: any;
   public org_iso2_cr_desc: any;
   public org_iso3_cr_desc: any;

   public org_iso1_growth_type_desc: any;
   public org_iso2_growth_type_desc: any;
   public org_iso3_growth_type_desc: any;

   public org_iso1_antibiotics: any = [];
   public org_iso2_antibiotics: any = [];
   public org_iso3_antibiotics: any = [];
   public org_iso1_flag: boolean = false;
   public org_iso2_flag: boolean = false;
   public org_iso3_flag: boolean = false;
   pres_tests: any = [];
   test_flag: any;
   public mic_iso1_flag: any;
   public mic_iso2_flag: any;
   public mic_iso3_flag: any;

   public org_iso1_desc_flag: boolean = false;
   public org_iso1_cc_flag: boolean = false;
   public org_iso1_cr_flag: boolean = false;
   public org_iso1_growth_type_flag: boolean = false;

   public org_iso2_desc_flag: boolean = false;
   public org_iso2_cc_flag: boolean = false;
   public org_iso2_cr_flag: boolean = false;
   public org_iso2_growth_type_flag: boolean = false;

   public org_iso3_desc_flag: boolean = false;
   public org_iso3_cc_flag: boolean = false;
   public org_iso3_cr_flag: boolean = false;
   public org_iso3_growth_type_flag: boolean = false;

   public technical_noteFlag: boolean = false;
   public commentsFlag: boolean = false;

   age: any;
   serum: number;
   weight: string;
   serumValue: number;
   gender: string;
   total: number;
   eGFR: number;
   public consultant: string;

   public egfr_flag: boolean;
   public org_iso_heading: string;
   public visited_date: any;
   public visited_time: any;
   public barcode_url: any;
   public barcode_flag: boolean = false;
   public visited_date_time: any;
   public approver_sign: string;
   public lab_tech_sign: string;
   public quality_head_sign: string;
   public showTechnician: boolean = false;
   public showConsultant: boolean = false;
   public showQualhead: boolean = false;
   public grouping_flag: boolean;


   constructor(public santizer: DomSanitizer, public messageservice: MenuViewService, public gservice: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router) {
   }

   ngOnInit(): void {
      this.userInfo = Helper_Class.getInfo();

      this.hospitalDetails = this.userInfo.hospitals;
      this.hospitalName = this.hospitalDetails[0].hptl_name;
      this.hptlLogo = ipaddress.Ip_with_img_address + this.hospitalDetails[0].logo;
      this.location = this.hospitalDetails[0].location;
      this.city = this.hospitalDetails[0].city;
      this.state = this.hospitalDetails[0].state;
      this.country = this.hospitalDetails[0].country;
      if (this.hospitalDetails[0].test_range == "1") {
         this.testrangeFlag = true;
      } else {
         this.testrangeFlag = false;
      }

      if (Diagnosis_Helper.getReportView().type != undefined) {
         this.reportType = (Diagnosis_Helper.getReportView().type).charAt(0).toUpperCase() + (Diagnosis_Helper.getReportView().type).slice(1);
      }
      //navParams.get("type");  
      this.diagAppID = Diagnosis_Helper.getReportView().diag_app_id;  //navParams.get("diag_app_id");
      this.presTestID = Diagnosis_Helper.getReportView().pres_test_id; //navParams.get("pres_test_id");
      this.presDiagID = Diagnosis_Helper.getReportView().pres_diag_id; // navParams.get("pres_diag_id");
      this.ReportFlag = Diagnosis_Helper.getReportView().Diag_report_flag; //navParams.get("Diag_report_flag");
      this.testName = Diagnosis_Helper.getReportView().test_name; //navParams.get("test_name");
      this.pres_tests = Diagnosis_Helper.getReportView().pres_tests;
      this.test_flag = Diagnosis_Helper.getReportView().test_flag;

      this.sampleColl_dateTime = Diagnosis_Helper.getReportView().sample_datetime;
      this.approve_dateTime = Diagnosis_Helper.getReportView().approve_datetime;
      this.visited_date_time = Diagnosis_Helper.getReportView().visited_date_time;

      this.diag_report_type = Diagnosis_Helper.getReportType();
      if (this.diag_report_type == "pathology") {
         this.pathology_flag = false;
         this.consultant = "Consultant Pathologist";
         // this.radiology_flag = true;
      } else if (this.diag_report_type == "radiology") {
         this.radiology_flag = false;
         this.consultant = "Consultant Radiologist";
         // this.pathology_flag = true;
      } else {
         this.microbiology_flag = false;
         this.consultant = "Consultant Microbiologist";
      }

      if (this.ReportFlag == "Approval") {
         this.approvelFlag = false;
         this.uploadFlag = true;
      } else {
         this.approvelFlag = true;
         this.uploadFlag = false;
      }

      if (this.diag_report_type == "pathology") {
         this.tableReportTypeFlag = false;
         this.paraReportTypeFlag = true;
      } else {// Scan == X-ray == Ultra sound
         this.tableReportTypeFlag = true;
         this.paraReportTypeFlag = false;
         this.cultureFlag = this.reportType == "Culture" ? true : false;
      }
      this.printFlag = Helper_Class.getHospitalInfo().report_print == "1" ? true : false;
      this.clientGender = "0";
      this.clientAge = "0";

      if (Doc_Helper.getHospital_logo() != undefined) {
         this.hospImage = Doc_Helper.getHospital_logo();
      }
      this.hospImage = Doc_Helper.getHospital_logo();
      console.log(this.hospImage)

      if (Doc_Helper.getHospFooter() != undefined) {
         this.hospitalFooter = Doc_Helper.getHospFooter();
         this.printfFlag = false;
      } else {
         this.printfFlag = true;
      }

      this.getBase64Image(this.hospImage);
      this.printTemplate = Doc_Helper.getHospital_print_template() != undefined && Doc_Helper.getHospital_print_template() != "undefined" ? Doc_Helper.getHospital_print_template() : "noheader";
      if (Doc_Helper.getHospital_pres_logo() != undefined && Doc_Helper.getHospital_pres_logo() != "undefined")
         this.hospitalLogo = Doc_Helper.getHospital_pres_logo();
      console.log(this.hospitalLogo)

      if (Doc_Helper.getHospital_bg_image() != undefined)
         this.bgImage = Doc_Helper.getHospital_bg_image();

      if (this.test_flag == "group") {
         this.getRetrieval2();
      } else {
         this.getRetrieval();
      }

      this.printMod("header");
   }

   convertToImage() {
      let element = document.getElementById('barcode');
      html2canvas(element, { allowTaint: true, useCORS: false }).then((canvas) => {

         document.body.appendChild(canvas);
         const imgData = canvas.toDataURL('image/png');
         console.log("canvas =  " + canvas)
         const imgWidth = 210; // A4 width in m
         const imgHeight = (canvas.height * imgWidth) / canvas.width;
         const imageUrl = this.santizer.bypassSecurityTrustUrl(imgData);
         console.log("img data = " + imgData)
         console.log("img url = " + imageUrl)
      });
   }

   getRetrieval() {//Get report details
      this.grouping_flag = false;
      this.loader = false;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'record/drview',
         JSON.stringify({
            flag: "diagnosis",
            country: ipaddress.country_code,
            diag_app_id: this.diagAppID,
            type: this.diag_report_type,
            pres_test_id: this.presTestID,
            test_type: this.reportType,
         }),
         { headers: headers })
         .subscribe(
            response => {
               var obj = JSON.parse(response["_body"]);
               console.log("getRetreival send data", JSON.stringify({
                  flag: "diagnosis",
                  country: ipaddress.country_code,
                  diag_app_id: this.diagAppID,
                  type: this.diag_report_type,
                  pres_test_id: this.presTestID,
                  test_type: this.reportType,
               }))
               console.log("this.reportType", this.reportType)
               console.log("getRetrieval----------obj" + JSON.stringify(obj))
               if (obj != null) {
                  this.centerName = obj.diag_centre_name;
                  this.diagAddress = obj.address2 != undefined && obj.address2 != "" ? obj.address1 + " " + obj.address2 : obj.address1;

                  if (obj.barcode != undefined) {
                     this.barcode_url = ipaddress.Ip_with_img_address + obj.barcode;
                     this.barcode_flag = true;
                  }

                  if (obj.main_tests != undefined) {
                     if (obj.weight != undefined) {
                        this.weight = obj.weight;
                     }
                     if (obj.main_tests[0].sample_date != undefined) {
                        this.sampledate = Date_Formate(obj.main_tests[0].sample_date)
                     }
                     this.sampletime = obj.main_tests[0].sample_time
                     this.oraganismIso1 = obj.main_tests[0].org_iso_1_desc;
                     this.oraganismIso2 = obj.main_tests[0].org_iso_2_desc;
                     this.oraganismIso3 = obj.main_tests[0].org_iso_3_desc;
                     if (obj.main_tests[0].culture_report_type_desc != undefined) {
                        this.culture_report_type_desc = obj.main_tests[0].culture_report_type_desc;
                     }
                     if (obj.main_tests[0].outsource_id != undefined) {
                        this.outsource_id = obj.main_tests[0].outsource_id;
                        this.imageflag_outsource_id = false;
                        this.uploadidproof = this.santizer.bypassSecurityTrustResourceUrl(ipaddress.Ip_with_img_address + obj.main_tests[0].image_path);
                     }
                     if (obj.main_tests[0].gram_stain_desc != undefined) {
                        this.gram_stain_desc = obj.main_tests[0].gram_stain_desc;
                     }
                     if (obj.main_tests[0].culture_report_desc != undefined) {
                        this.culture_report_desc = obj.main_tests[0].culture_report_desc;
                     }
                     if (obj.main_tests[0].technical_note != undefined) {
                        this.technical_note = obj.main_tests[0].technical_note;
                        this.technical_noteFlag = true;
                     }
                     if (obj.main_tests[0].comment != undefined) {
                        this.comment = obj.main_tests[0].comment;
                        this.commentsFlag = true;
                     }
                     if (obj.ot_sterile != undefined) {
                        this.ot_sterile = obj.ot_sterile;
                        console.log(JSON.stringify(this.ot_sterile))
                        this.ot_listFlag = true;
                     } else {
                        this.ot_listFlag = false;
                     }

                     if (obj.main_tests[0].ot_sterile_key == '1') {
                        this.ot_Sterile_flag = false;
                     } else {
                        this.ot_Sterile_flag = true;
                     }

                     if (obj.main_tests[0].ot_sterile_key != undefined) {
                        this.micro_temp = false;
                     } else {
                        this.micro_temp = true;
                     }

                     if (obj.main_tests[0].template != undefined) {
                        this.template_flag = true;
                        this.template = obj.main_tests[0].template;
                        console.log("this.template_flag", this.template_flag, this.template)
                     } else {
                        this.template_flag = false;
                        this.template = "";
                        if (this.microbiology_flag == true) {
                           this.value_flag = true;
                        } else {
                           this.value_flag = false;
                        }
                     }
                  }

                  // New template variables 
                  if (obj.main_tests != undefined) {
                     if (obj.main_tests[0].org_iso1_cr != undefined && obj.main_tests[0].org_iso1_cr != "") {
                        this.org_iso1_cr_desc = obj.main_tests[0].org_iso1_cr;
                        this.org_iso1_cr_flag = true;
                     } else {
                        this.org_iso1_cr_flag = false;
                     }

                     if (obj.main_tests[0].org_iso2_cr != undefined) {
                        this.org_iso2_cr_desc = obj.main_tests[0].org_iso2_cr;
                        this.org_iso2_cr_flag = true;
                     }

                     if (obj.main_tests[0].org_iso3_cr != undefined) {
                        this.org_iso3_cr_desc = obj.main_tests[0].org_iso3_cr;
                        this.org_iso3_cr_flag = true ;
                     }


                     if (obj.main_tests[0].organism_isolation_2_code != undefined && obj.main_tests[0].organism_isolation_3_code != undefined) {
                        this.org_iso_heading = "Organism Isolated 1";
                     } else {
                        this.org_iso_heading = "Organism Isolated";
                     }

                     if (obj.main_tests[0].organism_isolation_1_code != undefined && obj.main_tests[0].organism_isolation_1_desc != undefined) {
                        this.org_iso1_flag = true;
                        this.mic_iso1_flag = false;
                        this.org_iso_1_code = obj.main_tests[0].organism_isolation_1_code;

                        if (obj.main_tests[0].organism_isolation_1_desc != undefined) {
                           this.org_iso_1_desc = obj.main_tests[0].organism_isolation_1_desc;
                           this.org_iso1_desc_flag = true;
                        }

                        if (obj.main_tests[0].org_iso1_colonycount_desc != undefined) {
                           this.org_iso_1_cc_desc = obj.main_tests[0].org_iso1_colonycount_desc;
                           this.org_iso1_cc_flag = true;
                        }

                        if (obj.main_tests[0].org_iso1_growth_type_desc != undefined) {
                           this.org_iso1_growth_type_desc = obj.main_tests[0].org_iso1_growth_type_desc;
                           this.org_iso1_growth_type_flag = true;
                        }

                        for (var l = 0; l < obj.org_iso1_antibiotics.length; l++) {
                           if (obj.org_iso1_antibiotics[l].sensitivity_desc != "None") {
                              this.org_iso1_antibiotics.push({
                                 antibiotic: obj.org_iso1_antibiotics[l].antibiotic,
                                 drug_name: obj.org_iso1_antibiotics[l].drug_name,
                                 sensitivity: obj.org_iso1_antibiotics[l].sensitivity,
                                 sensitivity_desc: obj.org_iso1_antibiotics[l].sensitivity_desc,
                                 mic: obj.org_iso1_antibiotics[l].mic
                              })
                           }
                        }

                        for (var i = 0; i < this.org_iso1_antibiotics.length; i++) {
                           if (this.org_iso1_antibiotics[i].mic != undefined) {
                              this.mic_iso1_flag = true;
                              break;
                           }
                        }
                     }

                     if (obj.main_tests[0].organism_isolation_2_code != undefined && obj.main_tests[0].organism_isolation_2_desc != undefined) {
                        this.org_iso2_flag = true;
                        this.org_iso_2_code = obj.main_tests[0].organism_isolation_2_code;

                        if (obj.main_tests[0].organism_isolation_2_desc != undefined) {
                           this.org_iso_2_desc = obj.main_tests[0].organism_isolation_2_desc;
                           this.org_iso2_desc_flag = true;
                        }

                        if (obj.main_tests[0].org_iso2_colonycount_desc != undefined) {
                           this.org_iso_2_cc_desc = obj.main_tests[0].org_iso2_colonycount_desc;
                           this.org_iso2_cc_flag = true;
                        }

                        if (obj.main_tests[0].org_iso2_growth_type_desc != undefined) {
                           this.org_iso2_growth_type_desc = obj.main_tests[0].org_iso2_growth_type_desc;
                           this.org_iso2_growth_type_flag = true;
                        }

                        for (var l = 0; l < obj.org_iso2_antibiotics.length; l++) {
                           if (obj.org_iso2_antibiotics[l].sensitivity_desc != "None") {
                              this.org_iso2_antibiotics.push({
                                 antibiotic: obj.org_iso2_antibiotics[l].antibiotic,
                                 drug_name: obj.org_iso2_antibiotics[l].drug_name,
                                 sensitivity: obj.org_iso2_antibiotics[l].sensitivity,
                                 sensitivity_desc: obj.org_iso2_antibiotics[l].sensitivity_desc,
                                 mic: obj.org_iso2_antibiotics[l].mic
                              })
                           }
                        }

                        for (var i = 0; i < this.org_iso2_antibiotics.length; i++) {
                           if (this.org_iso2_antibiotics[i].mic != undefined) {
                              this.mic_iso2_flag = true;
                              break;
                           }
                        }
                     }

                     if (obj.main_tests[0].organism_isolation_3_code != undefined && obj.main_tests[0].organism_isolation_3_desc != undefined) {
                        this.org_iso3_flag = true;

                        this.org_iso_3_code = obj.main_tests[0].organism_isolation_3_code;

                        if (obj.main_tests[0].organism_isolation_3_desc != undefined) {
                           this.org_iso_3_desc = obj.main_tests[0].organism_isolation_3_desc;
                           this.org_iso3_desc_flag = true;
                        }

                        if (obj.main_tests[0].org_iso3_colonycount_desc != undefined) {
                           this.org_iso_3_cc_desc = obj.main_tests[0].org_iso3_colonycount_desc;
                           this.org_iso3_cc_flag = true;
                        }

                        if (obj.main_tests[0].org_iso3_growth_type_desc != undefined) {
                           this.org_iso3_growth_type_desc = obj.main_tests[0].org_iso3_growth_type_desc;
                           this.org_iso3_growth_type_flag = true;
                        }

                        for (var l = 0; l < obj.org_iso3_antibiotics.length; l++) {
                           if (obj.org_iso3_antibiotics[l].sensitivity_desc != "None") {
                              this.org_iso3_antibiotics.push({
                                 antibiotic: obj.org_iso3_antibiotics[l].antibiotic,
                                 drug_name: obj.org_iso3_antibiotics[l].drug_name,
                                 sensitivity: obj.org_iso3_antibiotics[l].sensitivity,
                                 sensitivity_desc: obj.org_iso3_antibiotics[l].sensitivity_desc,
                                 mic: obj.org_iso3_antibiotics[l].mic
                              })
                           }
                        }

                        for (var i = 0; i < this.org_iso3_antibiotics.length; i++) {
                           if (this.org_iso3_antibiotics[i].mic != undefined) {
                              this.mic_iso3_flag = true;
                              break;
                           }
                        }
                     }
                  }


                  if (obj.date_entered != undefined)
                     this.date = Date_Formate(obj.date_entered);


                  if (obj.doctorname != undefined)
                     this.doctorname = obj.doctorname;
                  if (obj.entry_date != undefined)
                     this.entry_date = Date_Formate(obj.entry_date);
                  if (obj.entry_time != undefined)
                     this.entry_time = (obj.entry_time);

                  if (obj.time_entered != undefined)
                     this.time = Time_Formate(obj.time_entered);

                  if (obj.colony_count != undefined) {
                     this.colonyCountArray = obj.colony_count
                  }

                  this.diagLocation = obj.location;
                  this.diagCityZip = obj.city + "-" + obj.zipcode;
                  this.diagStateCon = obj.state + "-" + obj.country;
                  this.diagTelephone = obj.telephone;
                  this.clientID = obj.client_reg_id;
                  this.patientID = obj.patient_id;

                  if (obj.main_tests[0].visited_date != undefined) {
                     this.visited_date = Date_Formate(obj.main_tests[0].visited_date);
                  }

                  if (obj.main_tests[0].visited_time != undefined) {
                     this.visited_time = Time_Formate(obj.main_tests[0].visited_time);
                  }

                  if (obj.pat_type != undefined) {
                     this.patient_type = this.capitalizeFirstLetter(obj.pat_type);
                  }

                  if (obj.sample_id != undefined) {
                     this.sample_id = obj.sample_id;
                  }

                  if (obj.sample_type != undefined) {
                     this.sample_type = obj.sample_type;
                  }

                  if (obj.entry_date != undefined) {
                     this.entry_date = Date_Formate(obj.entry_date);
                  }
                  if (obj.entry_time != undefined) {
                     this.entry_time = Time_Formate(obj.entry_time);
                  }

                  if (obj.app_first_name != undefined && obj.app_first_name != "-" && obj.app_last_name != undefined && obj.app_last_name != "-") {
                     this.approver_name = obj.app_first_name + ' ' + obj.app_last_name;
                     this.showConsultant = true;
                  } else if (obj.app_first_name != undefined && obj.app_first_name != "-") {
                     this.approver_name = obj.app_first_name;
                     this.showConsultant = true;
                  }

                  if (obj.qual_first_name != undefined && obj.qual_last_name != undefined) {
                     this.qualityHead_name = obj.qual_first_name + ' ' + obj.qual_last_name;
                     this.showQualhead = true;
                  } else if (obj.qual_first_name != undefined) {
                     this.qualityHead_name = obj.qual_first_name;
                     this.showQualhead = true;
                  }

                  if (obj.diag_appointment_id != undefined) {
                     this.visit_id = obj.diag_appointment_id;
                  } else {
                     this.visit_id = obj.diag_appointment_id;
                  }

                  if (obj.salutation_desc != undefined) {
                     this.salutation = obj.salutation_desc
                  }

                  if (obj.approv_sign != undefined) {
                     this.approver_sign = "data:image/png;base64," + obj.approv_sign;
                  }

                  if (obj.tech_sign != undefined) {
                     this.lab_tech_sign = "data:image/png;base64," + obj.tech_sign;
                  }

                  if (obj.quality_sign != undefined) {
                     this.quality_head_sign = "data:image/png;base64," + obj.quality_sign;
                  }

                  if (obj.first_name != null) {
                     if (obj.middle_name != null && encrypt_decript.Decript(obj.middle_name).toString() != "")
                        this.clientAnotherName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
                     else
                        this.clientAnotherName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
                  }

                  if (obj.referred_by != undefined && obj.referred_by != "" && obj.referred_by != null && obj.referred_by != "null") {
                     this.referFlag = true;
                     this.diagReferedBy = obj.referred_by;
                  }
                  if (obj.dr_first_name != null) {
                     if (obj.dr_middle_name != null && obj.dr_middle_name != "")
                        this.doctorname = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
                     else
                        this.doctorname = "Dr." + obj.dr_first_name + " " + obj.dr_last_name;
                  }

                  if (obj.client_address2 != undefined) {
                     // alert(encrypt_decript.Decript(obj.client_address1))
                     this.address1 = encrypt_decript.Decript(obj.client_address1) + ' ' + encrypt_decript.Decript(obj.client_address2);
                  } else {
                     this.address1 = encrypt_decript.Decript(obj.client_address1)
                  }

                  if (obj.mobile != undefined) {
                     this.mobile = encrypt_decript.Decript(obj.mobile);
                  }

                  if (obj.client_location != undefined) {
                     this.clntlocation = (obj.client_location);
                  }
                  if (obj.client_state
                     != undefined) {
                     this.clntstate = (obj.client_state
                     );
                  }
                  if (obj.client_country != undefined) {
                     this.clntcountry = (obj.client_country
                     );
                  }
                  if (obj.client_district != undefined) {
                     this.clntdistrict = (obj.client_district);
                  }
                  if (obj.client_zipcode != null)
                     this.clntzipcode = encrypt_decript.Decript(obj.client_zipcode);
                  if (obj.gender != null)
                     this.clientGender = encrypt_decript.Decript(obj.gender);

                  if (obj.age != null)
                     this.clientAge = obj.age == 0 ? "<1" : obj.age;
                  else
                     this.clientAge = "";

                  if (obj.sign != undefined && obj.sign != "") {
                     this.reviewSign = "data:image/png;base64," + obj.sign;
                     this.signFlag = false;
                  } else {
                     this.signFlag = true;
                  }

                  if (obj.sign == undefined && obj.sign == "" || obj.app_middle_name == null)
                     this.sigSectionFlag = true;

                  this.reviewFlag = obj.app_first_name != null ? false : true;
                  this.reviewBy = obj.app_middle_name != null ? obj.app_first_name + " " + obj.app_middle_name + " " + obj.app_last_name : obj.app_first_name + " " + obj.app_last_name;

                  if (obj.time_collected != undefined)
                     this.sampleCollectedTime = Time_Formate(obj.time_collected);

                  if (obj.main_tests != null && obj.main_tests.length != 0) {
                     this.printArray = [];

                     if (obj.main_tests[0].image_path != null) {
                        this.reportDetails = ipaddress.Ip_with_img_address + obj.main_tests[0].image_path;
                        this.reportIMG = ipaddress.Ip_with_img_address + obj.main_tests[0].image_path;
                        this.dicomDotor = false;
                     }
                     this.viewTestArray = [];

                     for (var i = 0; i < obj.main_tests.length; i++) {
                        this.presTestID = obj.main_tests[i].pres_test_id;
                        this.sequences = obj.main_tests[i].sequences;
                        this.impression = obj.main_tests[i].impression;

                        if (obj.main_tests[i].created_by != undefined) {
                           this.createdBy = obj.main_tests[i].created_by;
                           this.showTechnician = true;
                        }

                        if (obj.main_tests[i].test_method != undefined && obj.main_tests[i].test_method != null
                           && obj.main_tests[i].test_method != "" && obj.main_tests[i].test_method != "null")
                           this.testMethod = obj.main_tests[i].test_method;

                        var test_name;
                        test_name = obj.main_tests[i].test_name;
                        if (obj.main_tests[i].subtests != null) {
                           this.viewTestArray = [];
                           for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                              var temp_value = "";
                              var test_method = "";
                              this.subtestMethodFlag = false;
                              this.suntestNameFlag = obj.main_tests[i].subtests[j].sub_test_name == test_name ? false : true;

                              if (this.testrangeFlag == true) {
                                 if (this.clientGender == "Male" && this.clientAge < 1) {
                                    temp_value = obj.main_tests[i].subtests[j].newborn;
                                 } else if (this.clientGender == "Male" && (this.clientAge > 1 && this.clientAge < 13)) {
                                    temp_value = obj.main_tests[i].subtests[j].child;
                                 } else if (this.clientGender == "Male" && this.clientAge > 13) {
                                    temp_value = obj.main_tests[i].subtests[j].male;
                                 } else if (this.clientGender == "Female" && this.clientAge > 13) {
                                    temp_value = obj.main_tests[i].subtests[j].female;
                                 }

                              } else {
                                 if (obj.main_tests[i].subtests[j].range != undefined)
                                    temp_value = obj.main_tests[i].subtests[j].range;
                              }

                              if (obj.main_tests[i].subtests[j].test_method != undefined) {
                                 test_method = obj.main_tests[i].subtests[j].test_method;
                                 this.subtestMethodFlag = true;
                              } else {
                                 this.subtestMethodFlag = false;
                              }

                              var isReadingNormal = false;
                              var isReadingAbnormal = false;
                              var isReadingCritical = false;
                              if (obj.main_tests[i].subtests[j].reading != undefined && obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].reference[0]).length != 0) {
                                 var min_nor_value = obj.main_tests[i].subtests[j].reference[0].min_nor_value;
                                 var max_nor_value = obj.main_tests[i].subtests[j].reference[0].max_nor_value;
                                 var min_abnor_value = obj.main_tests[i].subtests[j].reference[0].min_abnor_value;
                                 var max_abnor_value = obj.main_tests[i].subtests[j].reference[0].max_abnor_value;
                                 var min_crit_value = obj.main_tests[i].subtests[j].reference[0].min_crit_value;
                                 var max_crit_value = obj.main_tests[i].subtests[j].reference[0].max_crit_value;

                                 if (((min_nor_value != undefined || min_nor_value != "") && (max_nor_value != undefined || max_nor_value != "")) && ((min_abnor_value == undefined || min_abnor_value == "") && (max_abnor_value == undefined || max_abnor_value == "")) && ((min_crit_value == undefined || min_crit_value == "") && (max_crit_value == undefined || max_crit_value == ""))) {
                                    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                                       isReadingNormal = true;
                                    } else {
                                       isReadingAbnormal = true;
                                    }
                                 } else {

                                    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                                       isReadingNormal = true;
                                    } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                                       isReadingAbnormal = true;
                                    } else {
                                       isReadingCritical = true;
                                    }
                                 }
                              }

                              console.log(isReadingNormal, isReadingAbnormal, isReadingCritical)

                              var printReading;
                              if (obj.main_tests[i].subtests[j].reading != undefined) {
                                 if (obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].reference[0]).length != 0) {
                                    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                                       printReading = obj.main_tests[i].subtests[j].reading;
                                    } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                                       printReading = obj.main_tests[i].subtests[j].reading ;
                                    } else {
                                       printReading = obj.main_tests[i].subtests[j].reading ;
                                    }
                                 } else {
                                    printReading = obj.main_tests[i].subtests[j].reading;
                                 }
                              } else {
                                 printReading = "-"
                              }

                              var test_range;
                              if (obj.main_tests[i].subtests[j].reference != undefined) {
                                 if (obj.main_tests[i].subtests[j].reference.length != 0) {
                                    if (obj.main_tests[i].subtests[j].reference[0].range != undefined) {
                                       test_range = obj.main_tests[i].subtests[j].reference[0].range
                                    } else {
                                       test_range = ''
                                    }
                                 }
                              } else {
                                 test_range = ''
                              }

                              var testReading, egfr_value;
                              if (obj.main_tests[i].subtests[j].reading != undefined) {
                                 testReading = obj.main_tests[i].subtests[j].reading;
                                 if (obj.main_tests[i].subtests[j].sub_test_name.toLowerCase() == "creatinine") {
                                    this.egfr_flag = true;
                                    egfr_value = this.serumCreatChange(testReading);
                                    // alert(egfr_value + "   " + Number.isNaN(egfr_value))
                                    if (Number.isNaN(egfr_value) == true) {
                                       egfr_value = "-"
                                    }
                                 } else {
                                    this.egfr_flag = false;
                                 }
                              } else {
                                 testReading = '-'
                              }

                              var subtests = undefined;
                              if (obj.main_tests[i].subtests[j].tests != undefined) {
                                 subtests = [];
                                 for (var l = 0; l < obj.main_tests[i].subtests[j].tests.length; l++) {
                                    var isReadingNormal_sub = false;
                                    var isReadingAbnormal_sub = false;
                                    var isReadingCritical_sub = false;
                                    var reference = [];
                                    if (obj.main_tests[i].subtests[j].tests[l].reading != undefined && obj.main_tests[i].subtests[j].tests[l].reference != undefined && obj.main_tests[i].subtests[j].tests[l].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].tests[l].reference[0]).length != 0) {
                                       var min_nor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value;
                                       var max_nor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value;
                                       var min_abnor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_abnor_value;
                                       var max_abnor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_abnor_value;
                                       var min_crit_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_crit_value;
                                       var max_crit_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_crit_value;
                                       reference = obj.main_tests[i].subtests[j].tests[l].reference;


                                       if (((min_nor_value_sub != undefined || min_nor_value_sub != "") && (max_nor_value_sub != undefined || max_nor_value_sub != "")) && ((min_abnor_value_sub == undefined || min_abnor_value_sub == "") && (max_abnor_value_sub == undefined || max_abnor_value_sub == "")) && ((min_crit_value_sub == undefined || min_crit_value_sub == "") && (max_crit_value_sub == undefined || max_crit_value_sub == ""))) {
                                          // alert(obj.main_tests[i].subtests[j].reference.length + "  " +  obj.main_tests[i].subtests[j].sub_test_name)
                                          if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value)) {
                                             isReadingNormal_sub = true;
                                          } else {
                                             isReadingAbnormal_sub = true;
                                          }
                                       } else {
                                          if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value)) {
                                             isReadingNormal_sub = true;
                                          } else if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_abnor_value)) {
                                             isReadingAbnormal_sub = true;
                                          } else {
                                             isReadingCritical_sub = true;
                                          }
                                       }
                                    }

                                    var ref_range = '';
                                    if (obj.main_tests[i].subtests[j].tests[l].range != undefined) {
                                       ref_range = obj.main_tests[i].subtests[j].tests[l].range;
                                    }

                                    var test_order = '';
                                    if (obj.main_tests[i].subtests[j].tests[l].test_order != undefined) {
                                       test_order = obj.main_tests[i].subtests[j].tests[l].test_order;
                                    }

                                    var test_method = '';
                                    if (obj.main_tests[i].subtests[j].tests[l].test_method != undefined) {
                                       test_method = obj.main_tests[i].subtests[j].tests[l].test_method;
                                    }

                                    subtests.push({
                                       sub_test_list_id: obj.main_tests[i].subtests[j].tests[l].sub_test_list_id,
                                       sub_test_name: obj.main_tests[i].subtests[j].tests[l].sub_test_name,
                                       reading: obj.main_tests[i].subtests[j].tests[l].reading,
                                       unit: obj.main_tests[i].subtests[j].tests[l].unit,
                                       sub_uom: obj.main_tests[i].subtests[j].tests[l].sub_uom,
                                       test_method: test_method,
                                       diag_tests_list_id: obj.main_tests[i].subtests[j].tests[l].diag_tests_list_id,
                                       test_order: test_order,
                                       range: ref_range,
                                       reference: reference,
                                       is_Reading_normal: isReadingNormal_sub,
                                       is_Reading_Abnormal: isReadingAbnormal_sub,
                                       is_Reading_Critical: isReadingCritical_sub,
                                    })
                                 }
                              }

                              this.viewTestArray.push({
                                 sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,
                                 reading: obj.main_tests[i].subtests[j].reading,
                                 unit: obj.main_tests[i].subtests[j].sub_uom,
                                 test_range: test_range,
                                 test_method: obj.main_tests[i].subtests[j].test_method,
                                 is_Reading_normal: isReadingNormal,
                                 is_Reading_Abnormal: isReadingAbnormal,
                                 is_Reading_Critical: isReadingCritical,
                                 tests: subtests,
                                 egfr_value: egfr_value,

                                 egfr_flag: this.egfr_flag,
                                 print_reading: printReading,
                              });
                              console.log("this.viewTestArray", this.viewTestArray)
                           }
                        }

                        this.diagTestReadingArray.push({
                           test_name: obj.main_tests[i].test_name,
                           category: obj.main_tests[i].test_category,
                           sub_test_list: this.viewTestArray,
                           template: obj.main_tests.template
                        });

                        //printoption
                        this.printArray.push({
                           test_name: obj.main_tests[i].test_name,
                           category: obj.main_tests[i].test_category,
                           sub_test_list: this.viewTestArray
                        });

                        console.log("this.printArray", this.printArray)                        

                        if (obj.main_tests[i].image != null) {
                           this.dicomDotor = false;
                           this.reportDetails = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                           this.reportIMG = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                        }

                        if (obj.main_tests[i].observation != undefined) {
                           this.sequence = obj.main_tests[i].observation;
                        }

                        if (obj.main_tests[i].impression != undefined) {
                           this.Impression = obj.main_tests[i].impression;
                        }

                        if (obj.main_tests[i].culture != undefined) {
                           this.culture = obj.main_tests[i].culture;
                        }
                     }
                  }
               }
            },
            error => {
               this.toastr.error(Message_data.getNetworkMessage());
            });
   }

   serumCreatChange(SerumCreat) {//Serium criteria calculation for egrf
      // alert("SerumCreat "+SerumCreat)
      if (SerumCreat != "") {
         if (this.clientAge != undefined && this.clientAge != null) {
            if (this.clientGender == "Male") {
               this.eGFR = 175 * (parseFloat(SerumCreat) ** -1.154) * (parseInt(this.clientAge) ** -0.203);
            } else if (this.clientGender == "Female") {
               this.eGFR = 175 * (parseFloat(SerumCreat) ** -1.154) * (parseInt(this.clientAge) ** -0.203) * 0.742;
            }
         }
         // alert(this.eGFR.toFixed(2))
         return this.eGFR.toFixed(2);
      }
      else {
         this.eGFR = undefined;
      }
   }

   getRetrieval2() {//Get report details
      this.grouping_flag = true;
      this.loader = false;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'record/drvg',
         JSON.stringify({
            flag: "diagnosis",
            country: ipaddress.country_code,
            diag_app_id: this.diagAppID,
            type: this.diag_report_type,
            pres_tests: this.pres_tests,
         }),
         { headers: headers })
         .subscribe(
            response => {
               var obj = JSON.parse(response["_body"]);
               console.log("this.reportType", this.reportType)
               console.log("getRetrieval----------obj" + JSON.stringify(obj))
               console.log("this.pres_tests" + JSON.stringify(this.pres_tests))
               if (obj != null) {
                  this.centerName = obj.diag_centre_name;
                  this.diagAddress = obj.address2 != undefined && obj.address2 != "" ? obj.address1 + " " + obj.address2 : obj.address1;
                  if (this.pres_tests != undefined) {
                     if (this.pres_tests[0].sample_datetime != undefined) {
                        this.sampleColl_dateTime = this.pres_tests[0].sample_datetime;
                     }
                     if (this.pres_tests[0].approve_datetime != undefined) {
                        this.approve_dateTime = this.pres_tests[0].approve_datetime;
                     }
                  }

                  if (obj.barcode != undefined) {
                     this.barcode_url = ipaddress.Ip_with_img_address + obj.barcode;
                     this.barcode_flag = true;
                  }

                  if (obj.main_tests != undefined) {
                     if (obj.main_tests[0].sample_date != undefined) {
                        this.sampledate = Date_Formate(obj.main_tests[0].sample_date)
                     }
                     this.sampletime = obj.main_tests[0].sample_time
                     this.oraganismIso1 = obj.main_tests[0].org_iso_1_desc;
                     this.oraganismIso2 = obj.main_tests[0].org_iso_2_desc;
                     this.oraganismIso3 = obj.main_tests[0].org_iso_3_desc;
                     if (obj.main_tests[0].culture_report_type_desc != undefined) {
                        this.culture_report_type_desc = obj.main_tests[0].culture_report_type_desc;
                     }
                     if (obj.main_tests[0].outsource_id != undefined) {
                        this.outsource_id = obj.main_tests[0].outsource_id;
                        this.imageflag_outsource_id = false;
                        this.uploadidproof = this.santizer.bypassSecurityTrustResourceUrl(ipaddress.Ip_with_img_address + obj.main_tests[0].image_path);

                        //this.uploadidproof = ipaddress.Ip_with_img_address + obj.main_tests[0].image_path;
                        console.log("idkjbjklhh" + JSON.stringify(this.uploadidproof))
                     }
                     if (obj.main_tests[0].gram_stain_desc != undefined) {
                        this.gram_stain_desc = obj.main_tests[0].gram_stain_desc;
                     }
                     if (obj.main_tests[0].culture_report_desc != undefined) {
                        this.culture_report_desc = obj.main_tests[0].culture_report_desc;
                     }
                     if (obj.main_tests[0].technical_note != undefined) {
                        this.technical_note = obj.main_tests[0].technical_note;
                     }
                     if (obj.main_tests[0].comment != undefined) {
                        this.comment = obj.main_tests[0].comment;
                     }
                     if (obj.ot_sterile != undefined) {
                        this.ot_sterile = obj.ot_sterile;
                        console.log(JSON.stringify(this.ot_sterile))
                        this.ot_listFlag = true;
                     } else {
                        this.ot_listFlag = false;
                     }

                     if (obj.main_tests[0].ot_sterile_key == '1') {
                        this.ot_Sterile_flag = false;
                     } else {
                        this.ot_Sterile_flag = true;
                     }

                     if (obj.main_tests[0].ot_sterile_key != undefined) {
                        this.micro_temp = false;
                     } else {
                        this.micro_temp = true;
                     }

                     if (obj.main_tests[0].template != undefined) {
                        this.template_flag = true;
                        this.template = obj.main_tests[0].template;
                        console.log("this.template_flag", this.template_flag, this.template)
                     } else {
                        this.template_flag = false;
                        this.template = "";
                        if (this.microbiology_flag == true) {
                           this.value_flag = true;
                        } else {
                           this.value_flag = false;
                        }
                     }
                  }

                  // New template variables 
                  if (obj.main_tests != undefined) {

                     if (obj.main_tests[0].organism_isolation_1_code != undefined && obj.main_tests[0].organism_isolation_1_desc != undefined) {
                        this.org_iso1_flag = true;
                        this.org_iso_1_code = obj.main_tests[0].organism_isolation_1_code;
                        this.org_iso_1_desc = obj.main_tests[0].organism_isolation_1_desc;
                        this.org_iso_1_cc_desc = obj.main_tests[0].org_iso1_colonycount_desc;
                        this.org_iso1_cr_desc = obj.main_tests[0].org_iso1_cr;
                        this.org_iso1_growth_type_desc = obj.main_tests[0].org_iso1_growth_type_desc;
                        this.org_iso1_antibiotics = obj.org_iso1_antibiotics;
                     }

                     if (obj.main_tests[0].organism_isolation_2_code != undefined && obj.main_tests[0].organism_isolation_2_desc != undefined) {
                        this.org_iso2_flag = true;
                        this.org_iso_2_code = obj.main_tests[0].organism_isolation_2_code;
                        this.org_iso_2_desc = obj.main_tests[0].organism_isolation_2_desc;
                        this.org_iso_2_cc_desc = obj.main_tests[0].org_iso2_colonycount_desc;
                        this.org_iso2_cr_desc = obj.main_tests[0].org_iso2_cr;
                        this.org_iso2_growth_type_desc = obj.main_tests[0].org_iso2_growth_type_desc;
                        this.org_iso2_antibiotics = obj.org_iso2_antibiotics;
                     }

                     if (obj.main_tests[0].organism_isolation_3_code != undefined && obj.main_tests[0].organism_isolation_3_desc != undefined) {
                        this.org_iso3_flag = true;

                        this.org_iso_3_code = obj.main_tests[0].organism_isolation_3_code;
                        this.org_iso_3_desc = obj.main_tests[0].organism_isolation_3_desc;
                        this.org_iso_3_cc_desc = obj.main_tests[0].org_iso3_colonycount_desc;
                        this.org_iso3_cr_desc = obj.main_tests[0].org_iso3_cr;
                        this.org_iso3_growth_type_desc = obj.main_tests[0].org_iso3_growth_type_desc;
                        this.org_iso3_antibiotics = obj.org_iso3_antibiotics;
                     }
                  }


                  if (obj.date_entered != undefined)
                     this.date = Date_Formate(obj.date_entered);
                  if (obj.sample_id != undefined)
                     this.sampleidvalue = obj.sample_id;
                  if (obj.sample_type != undefined)
                     this.sampletype = obj.sample_type;
                  if (obj.doctorname != undefined)
                     this.doctorname = obj.doctorname;
                  if (obj.entry_date != undefined)
                     this.entry_date = Date_Formate(obj.entry_date);
                  if (obj.entry_time != undefined)
                     this.entry_time = (obj.entry_time);

                  if (obj.time_entered != undefined)
                     this.time = Time_Formate(obj.time_entered);

                  if (obj.colony_count != undefined) {
                     this.colonyCountArray = obj.colony_count
                  }

                  this.diagLocation = obj.location;
                  this.diagCityZip = obj.city + "-" + obj.zipcode;
                  this.diagStateCon = obj.state + "-" + obj.country;
                  this.diagTelephone = obj.telephone;
                  this.clientID = obj.client_reg_id;
                  this.patientID = obj.patient_id;

                  if (obj.pat_type != undefined) {
                     this.patient_type = this.capitalizeFirstLetter(obj.pat_type);
                  }

                  if (obj.sample_id != undefined) {
                     this.sample_id = obj.sample_id;
                  }

                  if (obj.sample_type != undefined) {
                     this.sample_type = obj.sample_type;
                  }

                  if (obj.entry_date != undefined) {
                     this.entry_date = Date_Formate(obj.entry_date);
                  }
                  if (obj.entry_time != undefined) {
                     this.entry_time = Time_Formate(obj.entry_time);
                  }

                  if (obj.app_first_name != undefined && obj.app_first_name != "-" && obj.app_last_name != undefined && obj.app_last_name != "-") {
                     this.approver_name = obj.app_first_name + ' ' + obj.app_last_name;
                     this.showConsultant = true;
                  } else if (obj.app_first_name != undefined && obj.app_first_name != "-") {
                     this.approver_name = obj.app_first_name;
                     this.showConsultant = true;
                  }

                  if (obj.qual_first_name != undefined && obj.qual_last_name != undefined) {
                     this.qualityHead_name = obj.qual_first_name + ' ' + obj.qual_last_name;
                     this.showQualhead = true;
                  } else if (obj.qual_first_name != undefined) {
                     this.qualityHead_name = obj.qual_first_name;
                     this.showQualhead = true;
                  }

                  if (obj.diag_appointment_id != undefined) {
                     this.visit_id = obj.diag_appointment_id;
                  } else {
                     this.visit_id = obj.diag_appointment_id;
                  }

                  if (obj.salutation_desc != undefined) {
                     this.salutation = obj.salutation_desc
                  }

                  // if(obj.dr_first_mame != undefined && obj.dr_middle_mame != undefined && obj.dr_last_mame)

                  if (obj.first_name != null) {
                     if (obj.middle_name != null && encrypt_decript.Decript(obj.middle_name).toString() != "")
                        this.clientAnotherName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
                     else
                        this.clientAnotherName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
                  }

                  if (obj.referred_by != undefined && obj.referred_by != "" && obj.referred_by != null && obj.referred_by != "null") {
                     this.referFlag = true;
                     this.diagReferedBy = obj.referred_by;
                  }
                  if (obj.dr_first_name != null) {
                     if (obj.dr_middle_name != null && obj.dr_middle_name != "")
                        this.doctorname = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
                     else
                        this.doctorname = "Dr." + obj.dr_first_name + " " + obj.dr_last_name;
                  }


                  if (obj.client_address2 != undefined && obj.client_address1 != undefined) {
                     // alert(encrypt_decript.Decript(obj.client_address1))
                     this.address1 = encrypt_decript.Decript(obj.client_address1) + ' ' + encrypt_decript.Decript(obj.client_address2);

                  } else if (obj.client_address1 != undefined) {
                     this.address1 = encrypt_decript.Decript(obj.client_address1)
                  }

                  if (obj.mobile != undefined) {
                     this.mobile = encrypt_decript.Decript(obj.mobile);
                  }

                  if (obj.client_location != undefined) {
                     this.clntlocation = (obj.client_location);
                  }
                  if (obj.client_state
                     != undefined) {
                     this.clntstate = (obj.client_state
                     );
                  }
                  if (obj.client_country != undefined) {
                     this.clntcountry = (obj.client_country
                     );
                  }
                  if (obj.client_district != undefined) {
                     this.clntdistrict = (obj.client_district);
                  }
                  if (obj.client_zipcode != null)
                     this.clntzipcode = encrypt_decript.Decript(obj.client_zipcode);
                  if (obj.gender != null)
                     this.clientGender = encrypt_decript.Decript(obj.gender);

                  if (obj.age != null)
                     this.clientAge = obj.age == 0 ? "<1" : obj.age;
                  else
                     this.clientAge = "";

                  if (obj.sign != undefined && obj.sign != "") {
                     this.reviewSign = "data:image/png;base64," + obj.sign;
                     this.signFlag = false;
                  } else {
                     this.signFlag = true;
                  }

                  if (obj.approv_sign != undefined) {
                     this.approver_sign = "data:image/png;base64," + obj.approv_sign;
                  }

                  if (obj.tech_sign != undefined) {
                     this.lab_tech_sign = "data:image/png;base64," + obj.tech_sign;
                  }

                  if (obj.quality_sign != undefined) {
                     this.quality_head_sign = "data:image/png;base64," + obj.quality_sign;
                  }

                  if (obj.sign == undefined && obj.sign == "" || obj.app_middle_name == null)
                     this.sigSectionFlag = true;

                  this.reviewFlag = obj.app_first_name != null ? false : true;
                  this.reviewBy = obj.app_middle_name != null ? obj.app_first_name + " " + obj.app_middle_name + " " + obj.app_last_name : obj.app_first_name + " " + obj.app_last_name;

                  if (obj.time_collected != undefined)
                     this.sampleCollectedTime = Time_Formate(obj.time_collected);

                  if (obj.main_tests != null && obj.main_tests.length != 0) {
                     this.printArray = [];
                     if (obj.main_tests[0].image != null) {
                        this.reportDetails = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                        this.reportIMG = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                        this.dicomDotor = false;
                     }
                     this.viewTestArray = [];

                     for (var i = 0; i < obj.main_tests.length; i++) {
                        this.presTestID = obj.main_tests[i].pres_test_id;
                        this.sequences = obj.main_tests[i].sequences;
                        this.impression = obj.main_tests[i].impression;

                        if (obj.main_tests[i].created_by != undefined) {
                           this.createdBy = obj.main_tests[i].created_by;
                           this.showTechnician = true;
                        }

                        if (obj.main_tests[i].test_method != undefined && obj.main_tests[i].test_method != null
                           && obj.main_tests[i].test_method != "" && obj.main_tests[i].test_method != "null")
                           this.testMethod = obj.main_tests[i].test_method;

                        var test_name;
                        test_name = obj.main_tests[i].test_name;
                        if (obj.main_tests[i].subtests != null) {
                           this.viewTestArray = [];
                           for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                              var temp_value = "";
                              var test_method = "";
                              this.subtestMethodFlag = false;
                              this.suntestNameFlag = obj.main_tests[i].subtests[j].sub_test_name == test_name ? false : true;

                              if (this.testrangeFlag == true) {
                                 if (this.clientGender == "Male" && this.clientAge < 1) {
                                    temp_value = obj.main_tests[i].subtests[j].newborn;
                                 } else if (this.clientGender == "Male" && (this.clientAge > 1 && this.clientAge < 13)) {
                                    temp_value = obj.main_tests[i].subtests[j].child;
                                 } else if (this.clientGender == "Male" && this.clientAge > 13) {
                                    temp_value = obj.main_tests[i].subtests[j].male;
                                 } else if (this.clientGender == "Female" && this.clientAge > 13) {
                                    temp_value = obj.main_tests[i].subtests[j].female;
                                 }

                              } else {
                                 if (obj.main_tests[i].subtests[j].range != undefined)
                                    temp_value = obj.main_tests[i].subtests[j].range;
                              }

                              if (obj.main_tests[i].subtests[j].test_method != undefined) {
                                 test_method = obj.main_tests[i].subtests[j].test_method;
                                 this.subtestMethodFlag = true;
                              } else {
                                 this.subtestMethodFlag = false;
                              }

                              var isReadingNormal = false;
                              var isReadingAbnormal = false;
                              var isReadingCritical = false;
                              if (obj.main_tests[i].subtests[j].reading != undefined && obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].reference[0]).length != 0) {
                                 var min_nor_value = obj.main_tests[i].subtests[j].reference[0].min_nor_value;
                                 var max_nor_value = obj.main_tests[i].subtests[j].reference[0].max_nor_value;
                                 var min_abnor_value = obj.main_tests[i].subtests[j].reference[0].min_abnor_value;
                                 var max_abnor_value = obj.main_tests[i].subtests[j].reference[0].max_abnor_value;
                                 var min_crit_value = obj.main_tests[i].subtests[j].reference[0].min_crit_value;
                                 var max_crit_value = obj.main_tests[i].subtests[j].reference[0].max_crit_value;

                                 if (((min_nor_value != undefined || min_nor_value != "") && (max_nor_value != undefined || max_nor_value != "")) && ((min_abnor_value == undefined || min_abnor_value == "") && (max_abnor_value == undefined || max_abnor_value == "")) && ((min_crit_value == undefined || min_crit_value == "") && (max_crit_value == undefined || max_crit_value == ""))) {
                                    // alert(obj.main_tests[i].subtests[j].reference.length + "  " +  obj.main_tests[i].subtests[j].sub_test_name)
                                    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                                       isReadingNormal = true;
                                    } else {
                                       isReadingAbnormal = true;
                                    }
                                 } else {

                                    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                                       isReadingNormal = true;
                                    } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                                       isReadingAbnormal = true;
                                    } else {
                                       isReadingCritical = true;
                                    }
                                 }
                              }

                              console.log(isReadingNormal, isReadingAbnormal, isReadingCritical)

                              var printReading = "-", font_Color = '#000000';
                              if (obj.main_tests[i].subtests[j].reading != undefined) {
                                 if (obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].reference[0]).length != 0) {
                                    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                                       printReading = obj.main_tests[i].subtests[j].reading;
                                       font_Color = '#00FF00';
                                    } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                                       printReading = obj.main_tests[i].subtests[j].reading;
                                       font_Color = '#FFA500';
                                    } else {
                                       printReading = obj.main_tests[i].subtests[j].reading ;
                                       font_Color = '#FF0000';
                                    }
                                 } else {
                                    printReading = obj.main_tests[i].subtests[j].reading;
                                 }
                              } else {
                                 printReading = "-"
                              }

                              var test_range;
                              if (obj.main_tests[i].subtests[j].reference != undefined) {
                                 if (obj.main_tests[i].subtests[j].reference.length != 0) {

                                    if (obj.main_tests[i].subtests[j].reference[0].range != undefined && Object.keys(obj.main_tests[i].subtests[j].reference[0]).length != 0) {
                                       test_range = obj.main_tests[i].subtests[j].reference[0].range
                                    } else {
                                       test_range = ''
                                    }
                                 }
                              } else {
                                 test_range = ''
                              }

                              var testReading, egfr_value;
                              if (obj.main_tests[i].subtests[j].reading != undefined) {
                                 testReading = obj.main_tests[i].subtests[j].reading;

                                 if (obj.main_tests[i].subtests[j].sub_test_name.toLowerCase() == "creatinine") {
                                    this.egfr_flag = true;
                                    egfr_value = this.serumCreatChange(testReading);
                                    // alert(egfr_value + "   " + Number.isNaN(egfr_value))
                                    if (Number.isNaN(egfr_value) == true) {
                                       egfr_value = "-"
                                    }
                                 } else {
                                    this.egfr_flag = false;
                                 }
                              } else {
                                 testReading = '-'
                                 this.egfr_flag = false;
                              }

                              var subtests = undefined;
                              if (obj.main_tests[i].subtests[j].tests != undefined) {
                                 subtests = [];
                                 for (var l = 0; l < obj.main_tests[i].subtests[j].tests.length; l++) {
                                    var sub_reading = '-'
                                    if (obj.main_tests[i].subtests[j].tests[l].reading != undefined) {
                                       sub_reading = obj.main_tests[i].subtests[j].tests[l].reading
                                    }
                                    var isReadingNormal_sub = false;
                                    var isReadingAbnormal_sub = false;
                                    var isReadingCritical_sub = false;
                                    var reference = [];
                                    if (obj.main_tests[i].subtests[j].tests[l].reading != undefined && obj.main_tests[i].subtests[j].tests[l].reference != undefined && obj.main_tests[i].subtests[j].tests[l].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].tests[l].reference[0]).length != 0) {
                                       var min_nor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value;
                                       var max_nor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value;
                                       var min_abnor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_abnor_value;
                                       var max_abnor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_abnor_value;
                                       var min_crit_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_crit_value;
                                       var max_crit_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_crit_value;
                                       reference = obj.main_tests[i].subtests[j].tests[l].reference;


                                       if (((min_nor_value_sub != undefined || min_nor_value_sub != "") && (max_nor_value_sub != undefined || max_nor_value_sub != "")) && ((min_abnor_value_sub == undefined || min_abnor_value_sub == "") && (max_abnor_value_sub == undefined || max_abnor_value_sub == "")) && ((min_crit_value_sub == undefined || min_crit_value_sub == "") && (max_crit_value_sub == undefined || max_crit_value_sub == ""))) {
                                          // alert(obj.main_tests[i].subtests[j].reference.length + "  " +  obj.main_tests[i].subtests[j].sub_test_name)
                                          if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value)) {
                                             isReadingNormal_sub = true;
                                          } else {
                                             isReadingAbnormal_sub = true;
                                          }
                                       } else {
                                          if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value)) {
                                             isReadingNormal_sub = true;
                                          } else if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_abnor_value)) {
                                             isReadingAbnormal_sub = true;
                                          } else {
                                             isReadingCritical_sub = true;
                                          }
                                       }
                                    }

                                    var ref_range = '';
                                    if (obj.main_tests[i].subtests[j].tests[l].range != undefined) {
                                       ref_range = obj.main_tests[i].subtests[j].tests[l].range;
                                    }

                                    var test_order = '';
                                    if (obj.main_tests[i].subtests[j].tests[l].test_order != undefined) {
                                       test_order = obj.main_tests[i].subtests[j].tests[l].test_order;
                                    }

                                    var test_method = '';
                                    if (obj.main_tests[i].subtests[j].tests[l].test_method != undefined) {
                                       test_method = obj.main_tests[i].subtests[j].tests[l].test_method;
                                    }

                                    subtests.push({
                                       sub_test_list_id: obj.main_tests[i].subtests[j].tests[l].sub_test_list_id,
                                       sub_test_name: obj.main_tests[i].subtests[j].tests[l].sub_test_name,
                                       reading: sub_reading,
                                       unit: obj.main_tests[i].subtests[j].tests[l].unit,
                                       sub_uom: obj.main_tests[i].subtests[j].tests[l].sub_uom,
                                       test_method: test_method,
                                       diag_tests_list_id: obj.main_tests[i].subtests[j].tests[l].diag_tests_list_id,
                                       test_order: test_order,
                                       range: ref_range,
                                       reference: reference,
                                       is_Reading_normal: isReadingNormal_sub,
                                       is_Reading_Abnormal: isReadingAbnormal_sub,
                                       is_Reading_Critical: isReadingCritical_sub,
                                    })
                                 }
                              }

                              this.viewTestArray.push({
                                 test_name: obj.main_tests[i].test_name,
                                 sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,

                                 reading: obj.main_tests[i].subtests[j].reading,
                                 unit: obj.main_tests[i].subtests[j].sub_uom,
                                 test_order: obj.main_tests[i].subtests[j].test_order,
                                 test_range: test_range,
                                 test_method: obj.main_tests[i].subtests[j].test_method,
                                 is_Reading_normal: isReadingNormal,
                                 is_Reading_Abnormal: isReadingAbnormal,
                                 is_Reading_Critical: isReadingCritical,
                                 tests: subtests,
                                 egfr_value: egfr_value,

                                 egfr_flag: this.egfr_flag,
                                 print_reading: printReading,
                                 fontColor: font_Color,
                                 fontWeight: '500',
                              });
                              console.log("this.viewTestArray", this.viewTestArray)
                           }
                        }

                        this.diagTestReadingArray.push({
                           test_name: obj.main_tests[i].test_name,
                           category: obj.main_tests[i].test_category,
                           test_order: obj.main_tests[i].test_order,
                           sub_test_list: this.viewTestArray,
                           template: obj.main_tests.template
                        });

                        //printoption
                        this.printArray.push({
                           test_name: obj.main_tests[i].test_name,
                           category: obj.main_tests[i].test_category,
                           sub_test_list: this.viewTestArray
                        });



                        if (obj.main_tests[i].image != null) {
                           this.dicomDotor = false;
                           this.reportDetails = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                           this.reportIMG = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                        }

                        if (obj.main_tests[i].observation != undefined) {
                           this.sequence = obj.main_tests[i].observation;
                        }

                        if (obj.main_tests[i].impression != undefined) {
                           this.Impression = obj.main_tests[i].impression;
                        }

                        if (obj.main_tests[i].culture != undefined) {
                           this.culture = obj.main_tests[i].culture;
                        }
                     }

                     console.log("this.printArray", JSON.stringify(this.printArray));

                     const groupedByCategory = this.groupBy(this.diagTestReadingArray, 'category');
                     console.log(groupedByCategory);
                     var viewtestarray2 = [];
                     var diagTestReadingArray2 = [];

                     var new_viewtestarray = [];

                     for (var key in groupedByCategory) {
                        // console.log(key + ': ' + JSON.stringify(groupedByCategory[key]));
                        new_viewtestarray = [];
                        for (var k = 0; k < groupedByCategory[key].length; k++) {
                           var subTest_list = groupedByCategory[key][k].sub_test_list;
                           var test_order_list = groupedByCategory[key][k].test_order;
                           var maintest_Name = "";
                           if (subTest_list.length > 1) {
                              maintest_Name = groupedByCategory[key][k].test_name;
                           }
                           console.log("subTest_list", subTest_list)
                           viewtestarray2 = [];
                           for (var l = 0; l < subTest_list.length; l++) {
                              viewtestarray2.push({
                                 test_name: subTest_list[l].test_name,
                                 sub_test_name: subTest_list[l].sub_test_name,
                                 reading: subTest_list[l].reading,
                                 unit: subTest_list[l].unit,
                                 test_range: subTest_list[l].test_range,
                                 test_method: subTest_list[l].test_method,
                                 is_Reading_normal: subTest_list[l].is_Reading_normal,
                                 is_Reading_Abnormal: subTest_list[l].is_Reading_Abnormal,
                                 is_Reading_Critical: subTest_list[l].is_Reading_Critical,
                                 tests: subTest_list[l].tests,
                                 egfr_value: subTest_list[l].egfr_value,
                                 egfr_flag: subTest_list[l].egfr_flag,
                                 print_reading: subTest_list[l].print_reading,
                                 fontColor: subTest_list[l].fontColor,
                                 fontWeight: subTest_list[l].fontWeight,
                              })
                           }
                           new_viewtestarray.push({
                              test_name: maintest_Name,
                              test_order: test_order_list,
                              sub_test_list: viewtestarray2,
                           })
                        }
                        diagTestReadingArray2.push({
                           category: key,
                           sub_test_list: viewtestarray2,
                           test_name: maintest_Name,
                           tests: new_viewtestarray.sort((a, b) => a.test_order - b.test_order),
                        })
                     }

                     this.diagTestReadingArray = [];
                     this.printArray = [];
                     this.diagTestReadingArray = diagTestReadingArray2;
                     this.printArray = diagTestReadingArray2;

                     // console.log("viewtestarray2 ", viewtestarray2);
                     console.log("diagTestReadingArray2", diagTestReadingArray2);
                  }
               }
            },
            error => {
               this.toastr.error(Message_data.getNetworkMessage());
            });
   }

   groupBy(array, key) {
      return array.reduce((result, obj) => {
         const keyValue = obj[key];
         // If the key doesn't exist in the result, create an empty array for it
         if (!result[keyValue]) {
            result[keyValue] = [];
         }
         // Push the object to the corresponding key's array
         result[keyValue].push(obj);
         return result;
      }, {});
   }

   generatePdfFromHtml(pdf, html, callback) {
      pdf.html(html, {
         'callback': function (pdf) {
            if (callback && typeof callback === 'function') {
               callback(pdf);
            }
         }
      });
   }

   uploadReport() {//report upload
      var send_data;
      if (this.reportType == "scans") {
         send_data = {
            pres_diag_id: this.presDiagID,
            diag_app_id: this.diagAppID,
            test_id: this.presTestID,
            user_id: Diagnosis_Helper.getUserID_Type().user_id,
            country: ipaddress.country_code,
            test_type: this.reportType,
            sequences: this.sequence,
            impression: this.Impression,
         }
      } else {
         send_data = {
            pres_diag_id: this.presDiagID,
            diag_app_id: this.diagAppID,
            test_id: this.presTestID,
            user_id: Diagnosis_Helper.getUserID_Type().user_id,
            country: ipaddress.country_code,
            test_type: this.reportType,
            observation: this.sequence,
            impression: this.Impression,
         };
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/udreport/", JSON.stringify(send_data), { headers: headers }).subscribe(
         data => {
            if (data.json().key == "1") {
               this.toastr.success(Message_data.rptApprovedSuccess);
               this.messageservice.sendMessage("completed")
            } else {
               if (data.json().result != null)
                  this.toastr.error(data.json().result);
               else
                  this.toastr.success(Message_data.rptApprovedNotSuccess);
            }
         });
   }

   //Get zoom image
   clickDocument() {
      // this.navCtrl.push('ReportZoomPage', {

      //     report_image: this.report_detail
      // })
   }

   rejectReport() {
      var send_data = {
         pres_diag_id: this.presDiagID,
         diag_app_id: this.diagAppID,
         test_id: this.presTestID,
         user_id: Diagnosis_Helper.getUserID_Type().user_id,
         country: ipaddress.country_code,
         test_type: this.reportType,
         reject_reason: "modify report as discussed",
         type: "reject"
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/stapp/", JSON.stringify(send_data), { headers: headers }).subscribe(
         data => {
            if (data.json().key == "1") {
               this.toastr.error(Message_data.rptRejectedSuccess);
            } else {
               if (data.json().result != null)
                  this.toastr.error(data.json().result);
               else
                  this.toastr.error(Message_data.unableToRejectRpt);
            }
         });
   }

   approveReport() {//Reportapprove
      var send_data;

      if (this.reportType == "blood" || this.reportType == "urine" || this.reportType == "faeces") {
         send_data = {
            pres_diag_id: this.presDiagID,
            diag_app_id: this.diagAppID,
            test_id: this.presTestID,
            user_id: Diagnosis_Helper.getUserID_Type().user_id,
            country: ipaddress.country_code,
            test_type: this.reportType,
            type: "approve"
         }
      } else {
         if (this.reportType == "scans") {
            send_data = {
               pres_diag_id: this.presDiagID,
               diag_app_id: this.diagAppID,
               test_id: this.presTestID,
               user_id: Diagnosis_Helper.getUserID_Type().user_id,
               country: ipaddress.country_code,
               test_type: this.reportType,
               sequences: this.sequence,
               impression: this.Impression,
               type: "approve"
            };
         } else {
            send_data = {
               pres_diag_id: this.presDiagID,
               diag_app_id: this.diagAppID,
               test_id: this.presTestID,
               user_id: Diagnosis_Helper.getUserID_Type().user_id,
               country: ipaddress.country_code,
               test_type: this.reportType,
               observation: this.sequence,
               impression: this.Impression,
               type: "approve"
            }
         }
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/stapp/", JSON.stringify(send_data), { headers: headers }).subscribe(
         data => {

            if (data.json().key == "1") {
               this.toastr.error(Message_data.rptApprovedSuccess);
            } else {
               if (data.json().result != null)
                  this.toastr.error(data.json().result);
               else
                  this.toastr.error(Message_data.rptApprovedNotSuccess);
            }
         });
   }

   back_Nav() {
      this.reporttype = this.diag_report_type;

      if (this.reporttype == 'pathology') {
         this.messageservice.sendMessage("pathcompleted");
      } else if (this.reporttype == 'radiology') {
         this.messageservice.sendMessage("radiocompleted");
      } else if (this.reporttype == 'microbiology') {
         this.messageservice.sendMessage("microcompleted");
      }
   }

   getBase64Image(data) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/gb64/', {
         imagepath: data
      },
         { headers: headers })
         .subscribe(
            response => {
               var data = JSON.parse(JSON.stringify(response));
               this.imageString = "data:image/jpeg;base64," + data.imagestr;
            });
   }

   printMod(e) {
      if (e == "header") {
         this.printTemplate = "banner"
      } else if (e == "noHeader") {

         this.printTemplate = "printnoheader"
      }
      console.log(this.printTemplate, e)
   }

   print() {
      var footerimg = this.hospitalFooter;
      var footerflag = this.printfFlag;
      var display;

      display = footerflag == true ? "display:none;" : "display:block;";
      var backimg = this.bgImage;
      let printContents, popupWin;
      if (this.printTemplate != undefined && this.printTemplate == "banner") {
         printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else if (this.printTemplate != undefined && this.printTemplate == "logowithname") {
         printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else if (this.printTemplate != undefined && this.printTemplate == "printnoheadsidebar") {
         printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else {
         printContents = this.printnoheader.nativeElement.innerHTML;

         // var printContents1 = this.printnoheader.nativeElement.innerHTML;
      }

      // this.convertToImage();

      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
        <head>
          <title>Report</title>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
          <link rel="stylesheet" media="screen" href="">
          <style>
          @page { size: auto;  margin: 0mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          * {
            font-size: 11px !important; 
          }
          .casesheet_container {
            width: 211px;
            padding: 1px;
            background: #277196;
            display: inline-flex;
            position: relative;
            padding-left: 5px;
            top: 21px;
            color: #FFFFFF;
          }
          .border_style{
            border:solid 1px;
            border-color: #488aff;
          }
          .width_style{
            margin-top:4px;
            width:600px;
          }
          
          
          @media print {

            @page { 
               margin-top: 1cm; 
               margin-bottom: 1cm; 
            } 

            body{
              -webkit-print-color-adjust: exact;
              -moz-print-color-adjust: exact;
              -ms-print-color-adjust: exact;
              print-color-adjust: exact;
            }
            
          }
          </style>
        </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}" >${printContents}</body>
        
      </footer>
      </html>`
      );
      popupWin.document.close();
   }
   download() {
      const iframe = document.getElementById('myIframe') as HTMLIFrameElement;
      const content = iframe.contentWindow.document.body.innerHTML;

      const blob = new Blob([content], { type: 'text/html' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'downloaded_content.html';
      a.click();
      window.URL.revokeObjectURL(url);
   }

   print1() {
      const iframe = document.getElementById('myIframe') as HTMLIFrameElement;
      iframe.contentWindow.print();
   }

   capitalizeFirstLetter(input: string): string {
      if (!input) return ''; // Handle empty string or null input
      return input.charAt(0).toUpperCase() + input.slice(1);
   }
}


