import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Helper_Class } from '../../helper_class';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { MatDialog } from '@angular/material/dialog';
import { BmiandbmrService } from '../service/bmiandbmr.service';
import { ipaddress } from '../../ipaddress';
import { Nurse_Helper } from 'src/app/Nurse_module/Nurse_Helper';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-vitals',
  templateUrl: './vitals.component.html',
  styleUrls: ['./vitals.component.scss']
})
export class VitalsComponent implements OnInit {
  spo2colorstyle: boolean;
  pulsecolorstyle:boolean;
  rrcolorstyle:boolean;
  public cbg_txt;
  public temp_read;
  public rr_txt;
  cbgcolorstyle:boolean;
  public vitalFlag = "min";
  subscription: Subscription;
  siscolorstyle: boolean;
  diocolorstyle: boolean;
  // spo2colorstyle: boolean;
  public colorstyle:boolean;
  public temperature : string;
  tempcolorstyle: boolean;
  public height_measure;
  public weight_measure;
  public header_footer_flag: boolean;
  public pulse_txt;
  public height_txt;
  public weight_txt;
  public BP_01;
  public rs_text;
  public BP_02;
  public Heart_Beat;
  public Respiratory_rate;
  public Temperature;
  public life_style_id;
  public vital_history_data = [];
  public Vitalhist;
  public diab_case_hist_id;
  public vital_graph: boolean;
  public height_hidden: boolean;
  public height_measurehidden: boolean;
  public weight_hidden: boolean;
  public weight_measurehidden: boolean;
  public heart_hidden: boolean;
  public temp_hidden: boolean;
  public blood_hidden: boolean;
  public pulse:boolean;
  public cvs_hidden:boolean;
  public rs_hidden:boolean;
  public cns_hidden:boolean;
  public abd_hidden:boolean;
  public pr_hidden:boolean;

  public txt_bmi;
  public txt_bmr;
  public exer_run: string;
  public cal_txt: any;
  public body_type: string;
  public body_nature: string;
  public cvs_txt:string;
  public rstxt:string;
  public cns_txt:string;
  public abd_txt:string;
  public pr_txt:string;
  public spo2_txt:string;
  public patient_list;
  public userinfo;
  public user_id;
  public client: boolean;

  constructor(public bmiservice:BmiandbmrService,public naviagationService:MenuViewService,public messageservice: CasesheetService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,public dialog: MatDialog,) {
      this.height_measure = "cms";
      this.weight_measure = "kgs";
   }

  ngOnInit(): void {
    this.temp_read="F";
    this.userinfo = Helper_Class.getInfo();
    console.log(" *************** -"+JSON.stringify(Doc_Helper.getClient_Info()))
    console.log("inpatient ---"+JSON.stringify(Helper_Class.getRet()))
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
        this.vitalFlag = message;
      }
    });

    if (Doc_Helper.getAppFlow() == "client") { 
      this.patient_list = Helper_Class.getInfo();
      this.client = true;
     
    } else {
      this.patient_list = Doc_Helper.getClient_Info();
      this.client = false;
    }
    console.log("inpatient  ---"+JSON.stringify(this.patient_list))
    if(this.patient_list != undefined){
      if(this.patient_list.height != undefined && this.patient_list.height != "" && this.patient_list.height != null){
         if(this.patient_list.height != undefined && this.patient_list.height != ""){
          this.height_txt = this.patient_list.height;
          this.height_measure = this.patient_list.height_measure;
        }
        if(this.patient_list.weight != undefined && this.patient_list.weight != ""){
          this.weight_txt = this.patient_list.weight;
          this.weight_measure = this.patient_list.weight_measure;
        }
        if(this.patient_list.temparature != undefined){
          var temp=this.patient_list.temparature.split(" ");
          this.Temperature = temp[0];
          this.temp_read = temp[1];
        }
       // this.Temperature = this.patient_list.temparature;
         this.pulse_txt = this.patient_list.pulse;
        if(this.height_txt != undefined && this.weight_txt != undefined){
          this.calories_data();
        }

        if(this.patient_list.bp != undefined){
          var bp = this.patient_list.bp.split("/");
          if(bp[0] != null && bp[0] != "null")
            this.BP_01 = bp[0];
          else
            this.BP_01 = "";

          this.BP_02 = bp[1];
        }
        
        this.Heart_Beat =  this.patient_list.pulse;
        if(this.patient_list.spo2 != undefined){
          this.spo2_txt=this.patient_list.spo2;
        }else{
          // if(this.persondata.pulse != undefined){
          //   this.spo2_txt=this.patient_list.spo2;
          // }
        }
       
        this.cvs_txt=this.patient_list.cvs;
        this.rstxt=this.patient_list.rs;
        this.rr_txt=this.patient_list.rr;
        this.cns_txt=this.patient_list.cns;
        this.abd_txt=this.patient_list.abd;
        this.pr_txt=this.patient_list.pr;
        this.cbg_txt=this.patient_list.cbg;  

              //Bmi and mnr
        if (this.txt_bmi != null) {
          var datavalue=null;
          datavalue={
            txt_bmr:this.txt_bmr,
            txt_bmi:this.txt_bmi,
            height:this.height_txt,
            height_measure:this.height_measure,
            weight: this.weight_txt,
            weight_measure: this.weight_measure,
            temparature:this.Temperature+" "+this.temp_read,
            bp:this.patient_list.bp,
            pulse:this.Heart_Beat, 
            bodynatute:this.body_nature,
            spo2: this.spo2_txt,
            cvs:this.cvs_txt,
            rstxt:this.rstxt,
            rrtxt:this.rr_txt,
            cns_txt:this.cns_txt,
            abd_txt:this.abd_txt,
            pr_txt:this.pr_txt,
            cbg_txt:this.cbg_txt,
            flag:"vitals"       
          }
          
          this.bmiservice.sendMessage(datavalue);
        }        
      }else{

        var obj = Helper_Class.getRet();
        
        if (obj != null && obj["key"] != 0) {
          if (obj.height != null) {
            this.height_txt = obj.height;
            this.height_measure = obj.height_measure;
          }
          //Weight
          if (obj.weight != null) {
            this.weight_txt = obj.weight;
            this.weight_measure = obj.weight_measure;
          }
          //Bmi and mnr
          if (obj.bmi != null) {
            this.txt_bmi = obj.bmi;
            this.txt_bmr = obj.bmr;
          
            this.body_nature = obj.body_nature;
            if (this.txt_bmi != null) {
              var datavalue=null;
              datavalue={
                txt_bmr:this.txt_bmr,
                txt_bmi:this.txt_bmi,
                height:this.height_txt,
                height_measure:this.height_measure,
                weight: this.weight_txt,
                weight_measure: this.weight_measure,
                temparature:this.Temperature+' '+this.temp_read,
                bodynatute:this.body_nature,  
                spo2: this.spo2_txt,
                cvs:this.cvs_txt,
                rstxt:this.rstxt,
                cns_txt:this.cns_txt,
                abd_txt:this.abd_txt,
                pr_txt:this.pr_txt,  
                flag:"vitals",
                cbg_txt:this.cbg_txt      
              }
              
              this.bmiservice.sendMessage(datavalue);
            }
          }
        }
      }
    }
    var persondata = Helper_Class.getRet();

    if(persondata !=undefined){
      if(persondata.blood_pressure_sit != undefined){
        var bptext = persondata.blood_pressure_sit.split("/");
        this.BP_01=bptext[0];
        this.BP_02=bptext[1];
      } else if(persondata.bp != undefined){
        var bptext = persondata.bp.split("/");
        this.BP_01=bptext[0];
        this.BP_02=bptext[1];
      }
      this.spo2_txt = persondata.spo2;
      this.cvs_txt = persondata.cvs;
      this.Heart_Beat =  persondata.pulse;
      this.rstxt = persondata.rs;
      this.cns_txt = persondata.cns;
      this.abd_txt = persondata.abd,
      this.pr_txt = persondata.pr;  
      if(persondata.temparature != undefined){
        var temp =persondata.temparature.split(" ");  
        this.Temperature = temp[0];
        this.temp_read = temp[1];
      }
      
    }
  } 

  // @HostListener('window:click', ['$event'])
  // onclick(event: any): void {
  //   this.send_data();
  // }

  // @HostListener('window:blur', ['$event'])
  // onBlur(event: any): void {
  //   this.send_data();
  // }

  send_data(){
    var vitals = {
      height: this.height_txt,
      height_measure: this.height_measure,
      weight: this.weight_txt,
      weight_measure: this.weight_measure,
      bmi: this.txt_bmi,
      bmr: this.txt_bmr,
      temparature:this.Temperature+' '+this.temp_read,
      rr_txt:this.rstxt,
      pulse:this.Heart_Beat, 
      blood_pressure:this.patient_list.bp,
      spo2:this.spo2_txt,
      cns_txt:this.cns_txt,
      abd_txt:this.abd_txt,
      pr_txt:this.pr_txt,  
      cvs:this.cvs_txt,
      cbg_txt:this.cbg_txt,
      body_nature:this.body_nature
     }
    Doc_Helper.setVitals(vitals);
  }

  save_click() {
    var chk_read_flag = true;
    var Send_data = null;
    var pedia_case_hist_id = undefined;
    var bpvar: any;
    var lif_stl_id = undefined;

    if (this.height_txt == undefined) {
      chk_read_flag = false;
      this.toastr.error("Please enter  mandatory fields");
    }

    if (this.height_measure == undefined) {
      chk_read_flag = false;
      this.toastr.error("Please enter  mandatory fields");
    }

    if (this.weight_txt == undefined) {
      chk_read_flag = false;
      this.toastr.error("Please enter  mandatory fields");
    }

    if (this.weight_measure == undefined) {
      chk_read_flag = false;
      this.toastr.error("Please enter  mandatory fields");
    }

    if (Helper_Class.getRet() != undefined) {
      if (Helper_Class.getRet().life_style_id != null) {
        lif_stl_id = Helper_Class.getRet().life_style_id;
      }

      if (Helper_Class.getRet().case_hist_id != null) {
        pedia_case_hist_id = Helper_Class.getRet().case_hist_id;
      }
    }

    if (this.BP_01 != undefined && this.BP_02 != undefined) {
      bpvar = this.BP_01 + "/" + this.BP_02;
    } else {
      bpvar = null;
    }

    if (this.patient_list.sub_id != "" && this.patient_list.sub_id.length != 0) {
      Send_data = {
        client_reg_id: this.patient_list.Client_id,
        doc_app_id: this.patient_list.app_id,
        relation_id: this.patient_list.rel_id,
        sub_rel_id: this.patient_list.sub_id,
        life_style_id: lif_stl_id,
        country: ipaddress.country_code,
        height: this.height_txt,
        height_measure: this.height_measure,
        weight: this.weight_txt,
        weight_measure: this.weight_measure,
        bmi:this.txt_bmi,
        bmr:this.txt_bmr,
        temparature: this.Temperature+" "+this.temp_read,
        pulse: this.pulse_txt,
        rr_txt:this.rr_txt,
        bloodPressure: bpvar,
        spo2:this.spo2_txt,
        cns_txt:this.cns_txt,
        abd_txt:this.abd_txt,
        pr_txt:this.pr_txt,     
        cvs:this.cvs_txt,
        rstxt:this.rstxt,              
        rs_text:this.rs_text,
        cbg_txt:this.cbg_txt,      
        case_hist_id: pedia_case_hist_id,
      };
    }
    else {
      Send_data = {
        client_reg_id: this.patient_list.Client_id,
        doc_app_id: this.patient_list.app_id,
        relation_id: this.patient_list.rel_id,
        life_style_id: lif_stl_id,
        country: ipaddress.country_code,
        height: this.height_txt,
        height_measure: this.height_measure,
        weight: this.weight_txt,
        weight_measure: this.weight_measure,
        bmi:this.txt_bmi,
        bmr:this.txt_bmr,
        temparature: this.Temperature+" "+this.temp_read,
        pulse: this.pulse_txt,
        rr_txt:this.rr_txt,
        bloodPressure: bpvar,
        spo2:this.spo2_txt,
        cns_txt:this.cns_txt,
        abd_txt:this.abd_txt,
        pr_txt:this.pr_txt,     
        cvs:this.cvs_txt,
        rstxt:this.rstxt,              
        rs_text:this.rs_text,
        cbg_txt:this.cbg_txt,       
        case_hist_id: pedia_case_hist_id,     
       
      };
    }
    console.log("VITAL DETAILS ================ "+JSON.stringify(Nurse_Helper.getINPatient()))
    if(Helper_Class.getInpatientFlow() == "nurse"){
      // if(Nurse_Helper.getINPatient() != undefined) {
      //   Send_data.created_by = Nurse_Helper.getINPatient().created_by;
      //   Send_data.creator_type = Nurse_Helper.getINPatient().creator_type;
      // } else {
        Send_data.created_by =this.userinfo.first_name+" "+this.userinfo.last_name;
        Send_data.creator_type =this.userinfo.user_type;
      // }
    }

    if (chk_read_flag == true) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'lifestyle/svt', JSON.stringify(Send_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.key != null) {
              if (obj.key == "1") {
                var datavalue=null;
                datavalue={
                  // bodynatute:this.body_nature,
                  txt_bmr:this.txt_bmr,
                  txt_bmi:this.txt_bmi,
                  height:this.height_txt,
                  height_measure:this.height_measure,
                  weight: this.weight_txt,
                  weight_measure: this.weight_measure,
                  temparature: this.Temperature+" "+this.temp_read,
                  bp:bpvar,
                  pulse:this.Heart_Beat,
                  bodynatute:this.body_nature,
                  cvs:this.cvs_txt,
                  rstxt:this.rstxt,
                  cns_txt:this.cns_txt,
                  abd_txt:this.abd_txt,
                  pr_txt:this.pr_txt,  
                  flag:"vitals"       
                }
                this.bmiservice.sendMessage(datavalue);         
                this.toastr.success("Vital details saved successfully");
              } else {
                this.toastr.error("Vital details not saved, please try again later");
              }
            }
          },
          error => {
            this.toastr.error("Something Went Wrong, Please Try Again Later");
          }
        )

    } else {
      this.toastr.error("Please enter mandatory fields");
    }
  }

  //Get bmi and bmr and calories data
  calories_data() {
    console.log("inpatient ---"+JSON.stringify(Doc_Helper.getClient_Info()))

    if (this.height_txt == undefined || this.height_txt == "" || this.weight_txt == undefined || this.weight_txt == "") {
      this.txt_bmr = "";
      this.txt_bmi = "";
    } else {
      if (this.height_txt != undefined) {
        if (this.height_measure == "cms") {
          this.height_txt = this.height_txt.replace(/[^0-9]/g, '');

        } else {
          this.height_txt = this.height_txt.replace('/[^-0-9\.]/g', '');

          if (this.height_txt.indexOf('.') !== -1) {
            if (this.height_txt.split('.').length > 2) {
              this.height_txt = this.height_txt.height.slice(0, -1);
            }
          }
        }

        if (this.height_measure == "cms") {
          if (parseFloat(this.height_txt) > 300) {
            this.height_txt = this.height_txt.slice(0, -1);
          }

        } else {
          if (parseFloat(this.height_txt) > 100) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        }
      }

      if (this.weight_measure == "kgs" && this.height_measure == "inch") {
        var pound = this.weight_txt * 2.20462;
        var height_txt = parseFloat(this.height_txt)
        var dat = pound / (height_txt * height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.patient_list.Gender_data == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.patient_list.Age_data) + 5;
          
          this.txt_bmr = Math.round(get_bmr);

        } else if (this.patient_list.Gender_data == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.patient_list.Age_data) - 161;
          this.txt_bmr = Math.round(get_bmr);
          
        }

      } else if (this.weight_measure == "kgs" && this.height_measure == "cms") {
        var meter = parseFloat(this.height_txt) / 100.00;
        var to = parseFloat(this.weight_txt) / (meter * meter);
        this.txt_bmi = Math.round(to);

        if (this.patient_list.Gender_data == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.patient_list.Age_data) + 5;
          this.txt_bmr = Math.round(get_bmr);
          
          } else if (this.patient_list.Gender_data == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.patient_list.Age_data) - 161;
          this.txt_bmr = Math.round(get_bmr);
          
        }

      } else if (this.weight_measure == "lbs" && this.height_measure == "inch") {
        var height_txt = parseFloat(this.height_txt);
        var dat = this.weight_txt / (height_txt * height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.patient_list.Gender_data == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.patient_list.Age_data) + 5;
          this.txt_bmr = Math.round(get_bmr);
          

        } else if (this.patient_list.Gender_data == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.patient_list.Age_data) - 161;
          this.txt_bmr = Math.round(get_bmr);
          

        }

      } else if (this.weight_measure == "lbs" && this.height_measure == "cms") {
        var height_txt = parseFloat(this.height_txt);
        var inch = height_txt * 0.393701;
        var dat = this.weight_txt / (inch * inch);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);
      

        if (this.patient_list.Gender_data == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.patient_list.Age_data) + 5;
          this.txt_bmr = Math.round(get_bmr);

        } else if (this.patient_list.Gender_data == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.patient_list.Age_data) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      }
      
      var SelectedText1 = this.exer_run;
      if (SelectedText1 == "Light") {
        var get_txt = this.txt_bmr * 1.375;
        this.cal_txt = Math.round(get_txt);

      } else if (SelectedText1 == "Moderate") {
        var get_txt = this.txt_bmr * 1.55;
        this.cal_txt = Math.round(get_txt);

      } else if (SelectedText1 == "Heavy") {
        var get_txt = this.txt_bmr * 1.725;
        this.cal_txt = Math.round(get_txt);

      } else if (SelectedText1 == "Very Heavy") {
        var get_txt = this.txt_bmr * 1.9;
        this.cal_txt = Math.round(get_txt);

      } else {
        var get_txt = parseFloat(this.txt_bmr) * 1.375;
        this.cal_txt = Math.round(get_txt);
      }

      if (this.txt_bmi < 18.5) {
        this.body_nature = "Under weight";

      } else if (this.txt_bmi >= 18.5 && this.txt_bmi <= 24.9) {
        this.body_nature = "Normal weight";

      } else if (this.txt_bmi >= 25 && this.txt_bmi <= 29.9) {
        this.body_nature = "Over weight";

      } else {
        this.body_nature = "Obase";
      }

    }
    //this.dentalservice.sendMessage(THIS.TXT);
  }

  diostol(value) {
    if (value > 90 || value < 70) {
      this.diocolorstyle = true;
    } else {
      this.diocolorstyle = false;
    }
  }

  sistol(value) {
    if (value > 160 || value < 110) {
      this.siscolorstyle = true;
    } else {
      this.siscolorstyle = false;
    }
  }
  spo2(value){
    if(  value < 75 ){
      this.spo2colorstyle=true;
    } else {
      this.spo2colorstyle=false;
    }
  }
  tempcolor(value){
    if (this.temp_read === '°C') {
      // Temperature is in Celsius
      // if (value > 32 || value < 21) { // Convert 90°F to 32°C and 70°F to 21°C
      //   this.colorstyle = true;
      // } else {
      //   this.colorstyle = false;
      // }
    } else if (this.temp_read === 'F') {
      // Temperature is in Fahrenheit
      if (value > 100 || value < 96) {
        this.colorstyle = true;
      } else {
        this.colorstyle = false;
      }
    }
  }
  settempcolor(temprature){
    if(temprature >100){
      this.tempcolorstyle=true;
    }else{
      this.tempcolorstyle=false;
    }
  }
  spo2value(value) {
    if (value < 95) {
      this.spo2colorstyle = true;
    } else {
      this.spo2colorstyle = false;
    }
  }
  pulsevalue(value){ 
    if (value < 60 ||  value >100) {
      this.pulsecolorstyle = true;
    } else {
      this.pulsecolorstyle = false;
    }
  }
  rrvalue(value){ 
    if (value < 14 ||  value > 20) {
      this.rrcolorstyle = true;
    } else {
      this.rrcolorstyle = false;
    }
  }
  cbgvalue(value){ 
    if (value < 90 ||  value > 250) {
      this.cbgcolorstyle = true;
    } else {
      this.cbgcolorstyle = false;
    }
  }
  validate(event: any) {
    const inputValue = event.target.value;
    const charCode = event.key.charCodeAt(0);

    if ((charCode >= 48 && charCode <= 57) || 
      charCode === 46 || charCode === 67 || charCode === 70 ||  
      event.key === 'Backspace' || event.key === 'Delete' 
    ) {
      const dotIndex = inputValue.indexOf('.');

      if (charCode === 46) {
        if (dotIndex !== -1 || inputValue === '') {
          event.preventDefault(); 
        }
      } else if (dotIndex !== -1) {
        if (event.key === 'Backspace' || event.key === 'Delete') {
          const decimalPart = inputValue.substr(dotIndex + 2);
          if (decimalPart.length === 1) {
            event.preventDefault();
            event.target.value = inputValue.substr(0, dotIndex); 
          }
        } else if (inputValue.substr(dotIndex + 2).length >= 1) {
          event.preventDefault(); 
        }
      }
    } else {
      event.preventDefault(); 
    }
  }
  chnagetemp(e){
    this.temp_read=e;
  }
}

