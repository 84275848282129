import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Time_Formate_Session } from '../../../assets/js/common';
import { Time_Formate, Date_Formate } from '../../../assets/js/common';
import { Observable, observable, Subscription } from 'rxjs';
import { Doc_Helper } from '../Doc_Helper';
import { DocumentDetailsZoomComponent } from '../document-details-zoom/document-details-zoom.component';
import { CasesheetService } from '../casesheet/casesheet.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UploadReportComponent } from '../upload-report/upload-report.component';
import { ipaddress } from '../../ipaddress'; import { Message_data } from 'src/assets/js/Message_data';

declare var $: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  @ViewChild('printlogowithname') public printlogowithname: ElementRef = {} as ElementRef;
  @ViewChild('printbanner') public printbanner: ElementRef = {} as ElementRef;
  @ViewChild('printnoheader') public printnoheader: ElementRef = {} as ElementRef;
  @ViewChild('printnoheadsidebar') public printnoheadsidebar: ElementRef = {} as ElementRef;
  // @ViewChild('barcode', { static: false }) barcode!: ElementRef;
  // @ViewChild('barcodeElement') public barcodeElement: ElementRef = {} as ElementRef;
  @ViewChild('barcodeElement') barcodeElement!: ElementRef;
  @ViewChild('table') public table: ElementRef = {} as ElementRef;
  public sampletimecheck;
  diag_report_type: any;
  public documentviewflag: boolean = false
  public print_template;
  public pathology_flag: boolean = true;
  public radiology_flag: boolean = true;
  public microbiology_flag: boolean = true;
  approver_name: string;
  qualityHead_name: string;
  public visistime;
  public temparray;
  public sampleflag: boolean;
  public consultant;
  public visited_time;
  public visited_date;
  public sampledatecheck;
  AgreeBox: boolean;
  lastImage: string = null;
  public reporteddate;
  public reportedtime;
  public sample_id;
  public sample_type;
  public pres_url: string;
  public doc_url: string;
  public send_data;
  public send_doc_data;
  public pres_data;
  public pres_ids = [];
  public doc_data;
  public doc_ids;
  public submenu_flag: boolean = false;
  public submenu_flag1: boolean = false;
  public pres_id: string;
  public image_send_data;

  public image_file_str;
  public image_file_name;

  public Image_table_list = [];

  public second_total_data;
  public second_doctor_list;
  public appointment_client_data;
  public appointment_doctor_data;
  public second_general_data;
  public second_healthmedicine_data;
  public second_family_data;
  public second_document_data;

  public image_doc_obj: any;
  public send_report_data: any;
  public send_micoreport_data: any;

  public appointment_data_list;

  public blood_report_list = [];
  public urine_report_list = [];
  public feaces_report_list = [];
  public xray_report_list = [];
  public scan_report_list = [];
  public microlist = []
  public ultrasound_report_list = [];

  public retr_blood_report_list = [];
  public retr_scan_report_list = [];
  public retr_prescription_list = [];
  public retrival_document_data;
  public retrival_imagelist = [];

  public header_footer_flag: boolean = false;

  public add_document_div: boolean = false;
  public extraadd_document_div: boolean = false;
  public retrival_flag_data: boolean = false;

  public second_update_data: any;
  public userinfo: any;
  public user_id: any;
  public first_name: string;
  public last_name: string;
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public profile_image: string;
  public messages;
  public msgflag: boolean = false;

  public notifications;
  public notifyflag: boolean = false;
  public mainarray2 = [];
  // send diagniss array
  Send_Ultra_Select_List = [];
  Send_Scan_Select_List = [];
  Send_Xray_Select_List = [];
  Send_Feaces_Select_List = [];
  Send_Urine_Select_List = [];
  Send_Blood_Select_List = [];
  ExitsUser: boolean;
  Newupload: boolean;

  btnFlag: boolean = true;
  private saveflag: boolean;
  public panelOpenState1: boolean;
  public panelOpenState2: boolean;
  public panelOpenState3: boolean;
  public panelOpenState4: boolean;
  public panelOpenState5: boolean;
  public panelOpenState6: boolean;

  public report_detail_flag: boolean = true;
  public report_detail_dig_flag: boolean = true;
  public report_detail_pres_flag: boolean = true;

  //details
  public report_pres_id;
  public report_type;
  public report_diag_id;
  public report_test_id;

  public date_txt: string;
  public diag_name: string;
  public diag_add: string;
  public diag_loc: string;
  public diag_cityzip: string;
  public diag_statecon: string;
  public diag_teleph: string;
  public client_age: string;
  public get_age: string;
  public Age_div: boolean;
  public review_sign: string;
  public signflag: boolean = false;
  public review_div: boolean;
  public clent_id: string;
  public review_by: string;
  public client_another_name: string;
  public client_name: string;
  public diag_refertxt: string;
  public client_gender: string;
  public time_collect: string;
  public review_txt: string;
  public second_opinion_mintlist = [];
  public second_opinion_subtlist = [];
  public second_opinion_mintlist1 = [];
  public temp_value = null;
  public dicom_doc: boolean = true;
  public report_img = [];
  public report_img_flag: boolean = false;

  public report_detail: string;
  public blood_urine_faeces_div: boolean;
  public xray_scan_ultra_div: boolean;

  public opn_id;
  public opn_type;
  public opn_pres_test_id;
  public opn_app_id;
  public loader: boolean;
  public clientflag: boolean;
  public docflag: boolean;
  public diagflag: boolean;
  public relation: any;

  //prescription details
  public url_prescription = ipaddress.getIp.toString() + "prescription/medpresdet";
  public prescription_pres_id;
  public data_prescription: any;

  public tablet_tracker = [];

  public total_name: string;
  public dr_total_name: string;
  public middlename: string;
  public date_prescription;
  public disease_name: string;
  public disease_describtion: string;
  public personaldata;
  public subscription: Subscription;

  public reportexp_flag = "min";
  ImagePath: any;

  //upload
  public filetype: string;
  public img_str: any;
  report_arr: any = [];
  public photos = [];
  public pdf;
  public pdfObj;
  public base64data;
  public image_file_str1;
  current_date;
  public imgsend_data;
  public documentView_flag: boolean = false;
  public documentList = [];
  public document;
  public report_datas = [];

  public documentBlood: boolean;
  public documentUrine: boolean;
  public documentFaeces: boolean;
  public documentXray: boolean;
  public documentmicro: boolean;
  public documentScan: boolean;
  public documentUltrasound: boolean;
  // public microbiology_flag: boolean = true;
  template_flag: boolean;
  value_flag: boolean;

  public address1;
  public outsource_id;
  public uploadidproof;
  public imageflag_outsource_id: boolean = true;
  public doctorname;
  public address2;
  public clntlocation;
  public clntstate;
  public clntdistrict;
  public clntcountry;
  public clntzipcode;
  public entry_date;
  public entry_time;
  public sampleidvalue;
  public sampletype;
  public sampledate;
  public sampletime;
  // public doctorname;

  pdfTable!: ElementRef;
  public tableReportTypeFlag: boolean;
  public paraReportTypeFlag: boolean;

  public reportType;
  public diagAppID;
  public presTestID;
  public presDiagID;

  public clientAnotherName;
  public clientGender;
  public clientAge;
  public reviewSign;
  public signFlag: boolean;
  public sigSectionFlag: boolean = false;
  public reviewFlag: boolean;
  public reviewBy;
  public sampleCollectedTime;
  public reportIMG;
  public dicomDotor: boolean = true;
  public sequences;
  public impression;
  public viewTestArray = [];
  public diagTestReadingArray = [];
  public centerName;
  public diagAddress;
  public diagLocation;
  public diagCityZip;
  public diagStateCon;
  public diagTelephone;
  public clientID;
  public diagReferedBy;
  public date;
  public time;
  public testArray = [];
  public sequence;
  public Impression;
  public ReportFlag;
  public approvelFlag: boolean;
  public uploadFlag: boolean;
  public reportDetails;
  public testName: string;
  public referFlag: boolean = false;
  public innerFrame;
  public iFrame;
  public content;
  public suntestNameFlag: boolean = false;
  public testMethod;
  public printFlag: boolean;
  public printTemplate;
  public hospitalFooter;
  public printfFlag;
  public bgImage;
  public hospImage;
  public hospitalLogo;
  public imageString;
  public patientID: any;
  public subtestMethodFlag: boolean;
  public createdBy;
  public printArray = [];
  public culture;
  public cultureFlag: boolean = false;
  public testrangeFlag: boolean = false;
  public userInfo;
  public hospitalDetails;
  public hospitalName;
  public hptlLogo;
  public location;
  public city;
  public state;
  public country;
  public reporttype;
  public template: any;
  public colonyCountArray: any = [];
  public oraganismIso1: any;
  public oraganismIso2: any;
  public oraganismIso3: any;
  culture_report_type_desc: any;
  gram_stain_desc: any;
  culture_report_desc: any;
  ot_sterile: any = [];
  ot_listFlag: boolean;
  technical_note: any;
  comment: any;
  ot_Sterile_flag: boolean;
  micro_temp: boolean;

  public org_iso_1_code: any;
  public org_iso_2_code: any;
  public org_iso_3_code: any;

  public org_iso_1_desc: any;
  public org_iso_2_desc: any;
  public org_iso_3_desc: any;

  public org_iso_1_cc_desc: any;
  public org_iso_2_cc_desc: any;
  public org_iso_3_cc_desc: any;

  public org_iso1_cr_desc: any;
  public org_iso2_cr_desc: any;
  public org_iso3_cr_desc: any;

  public org_iso1_growth_type_desc: any;
  public org_iso2_growth_type_desc: any;
  public org_iso3_growth_type_desc: any;

  public org_iso1_antibiotics: any = [];
  public org_iso2_antibiotics: any = [];
  public org_iso3_antibiotics: any = [];
  public org_iso1_flag: boolean = false;
  public org_iso2_flag: boolean = false;
  public org_iso3_flag: boolean = false;
  pres_tests: any = [];
  test_flag: any;
  public mic_iso1_flag: any;
  public mic_iso2_flag: any;
  public mic_iso3_flag: any;

  public org_iso1_desc_flag: boolean = false;
  public org_iso1_cc_flag: boolean = false;
  public org_iso1_cr_flag: boolean = false;
  public org_iso1_growth_type_flag: boolean = false;

  public org_iso2_desc_flag: boolean = false;
  public org_iso2_cc_flag: boolean = false;
  public org_iso2_cr_flag: boolean = false;
  public org_iso2_growth_type_flag: boolean = false;

  public org_iso3_desc_flag: boolean = false;
  public org_iso3_cc_flag: boolean = false;
  public org_iso3_cr_flag: boolean = false;
  public org_iso3_growth_type_flag: boolean = false;

  public technical_noteFlag: boolean = false;
  public commentsFlag: boolean = false;
  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';

  elementType: 'svg';
  lineColor = '#000000';
  width = 0.8;
  barcodeheight = 20;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'left';
  textPosition = 'bottom';
  textMargin = 0;
  fontSize = 18;
  background = '#ffffff';
  margin = 10;
  marginTop = 2;
  marginBottom = 2;
  marginLeft = 0;
  marginRight = 10;
  // ot_Sterile_flag: boolean;
  // micro_temp: boolean;
  age: any;
  serum: number;
  weight: string;
  serumValue: number;
  gender: string;
  total: number;
  eGFR: number;
  // public consultant: string;
  egfr_flag: boolean;
  org_iso_heading: string;
  public sampleColl_dateTime
  public reportFlag: string;
  public grouped_array: any = [];
  public grouping_flag: boolean = false;
  public barcode_url: any;
  public barcode_flag: boolean;
  public approver_sign: string;
  public lab_tech_sign: string;
  public quality_head_sign: string;
  public showTechnician: boolean = false;
  public showConsultant: boolean = false;
  public showQualhead: boolean = false;
  public mobile: any;
  public scan_grouped_array: any = [];
  public micro_grouped_array: any = [];
  // public visited_date: any;
  // public visited_time: any;
  constructor(public sanitizer: DomSanitizer, public dialog: MatDialog, private http: HttpClient, private routes: ActivatedRoute, private router: Router, public toastr: ToastrService, public messageservice1: CasesheetService) {
    this.ExitsUser = false;
    this.Newupload = false;
    this.saveflag = false;

    this.Send_Ultra_Select_List = [];
    this.Send_Scan_Select_List = [];
    this.Send_Xray_Select_List = [];
    this.Send_Feaces_Select_List = [];
    this.Send_Urine_Select_List = [];
    this.Send_Blood_Select_List = [];
    this.doc_ids = [];
    this.Image_table_list = [];
    this.pres_data = [];
    this.doc_data = [];
  }

  ngOnInit(): void {
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.personaldata = Doc_Helper.getClient_Info()
    console.log("LABORATORY TESTS " + JSON.stringify(Doc_Helper.getClient_Info()))
    if (this.personaldata.AppFlow != "Nurseoutpatient") {
      this.subscription = this.messageservice1.getCasesheetMessage().subscribe(message => {
        if (message) {
          this.reportexp_flag = message;
          this.report_detail_flag = false;
        }
      });
    } else {
      if (this.personaldata.AppFlow == "Nurseoutpatient") {
        this.reportexp_flag = "max"
        this.report_detail_flag = false;

      }
    }
    this.sampletimecheck = "";
    this.sampledatecheck = "";
    if (Helper_Class.getHospital()[0].pres_print_template != undefined) {
      this.printTemplate = Helper_Class.getHospital()[0].pres_print_template;
    } else {
      this.printTemplate = "noheader";
    }

    if (Helper_Class.getHospital()[0].pres_print_logo != undefined && Helper_Class.getHospital()[0].pres_print_logo != "undefined") {
      this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getHospital()[0].pres_print_logo;
      this.hospitalLogo = Helper_Class.getHospital()[0].pres_print_logo_style;
    }
    this.hospitalLogo = this.userinfo.hospitals[0].pres_print_logo_style;
    if (Doc_Helper.getHospFooter() != undefined) {
      this.hospitalFooter = Doc_Helper.getHospFooter();
      // this.printf_flag = false;
    } else {
      //this.printf_flag = true;
    }
    // this.headerstyle = this.sanitizer.bypassSecurityTrustHtml(Helper_Class.getHospital()[0].pres_print_heading);
    // this.footerstyle = this.sanitizer.bypassSecurityTrustHtml(Helper_Class.getHospital()[0].pres_print_footer);
    // // this.subscription = this.messageservice1.getCasesheetMessage().subscribe(message => {
    //   if (message) {
    //     this.reportexp_flag = message;
    //     this.report_detail_flag = false;
    //   }
    // });



    this.image_file_str = "assets/img/default.jpg";
    this.pres_url = ipaddress.getIp + "record/medprescriptions/";
    this.doc_url = ipaddress.getIp + "secopn/cdoc";
    var send_data: any;
    if (this.personaldata.AppFlow == "Nurseoutpatient") {
      this.retrival_flag_data = true;
      this.send_data = {
        client_id: this.personaldata.Client_id,
        relation: this.personaldata.relationship_name,
        home_care: "0"
      };

      this.send_doc_data = {
        client_reg_id: this.personaldata.Client_id,
        relation_id: this.personaldata.relationship_name,
      };

      this.send_report_data = {
        client_id: this.personaldata.Client_id,
        relation: this.personaldata.relationship_name,
        home_care: "0"
      }
      this.send_micoreport_data = {
        client_id: this.personaldata.Client_id,
        relation: this.personaldata.relationship_name,
        home_care: "0"
      }
      send_data = {
        client_reg_id: this.personaldata.Client_id,
        relation_id: this.personaldata.rel_id,
        spl_id: this.personaldata.spl_id,
      }

      this.retrieveUploadDocumentList(send_data);
      this.get_Blood_reports(this.send_report_data);
      this.getmicroreport(this.send_micoreport_data)
      // this.get_Urine_reports(this.send_report_data);
      // this.get_Feaces_reports(this.send_report_data);
      this.get_Scan_reports(this.send_report_data);
      // this.get_Xray_reports(this.send_report_data);
      // this.get_Ultrasound_reports(this.send_report_data);
      // this.set_retrival_document_data();
      if (this.appointment_data_list != undefined) {
        if (this.appointment_data_list.status != null && this.appointment_data_list.status == "0") {
          this.pres_ids = [];
          this.doc_ids = [];



          this.extraadd_document_div = false;
          this.add_document_div = false;
        }
        else {
          this.extraadd_document_div = true;
        }
      }
    } else {
      // alert(this.personaldata.AppFlow)
      if (this.personaldata.sub_id.trim == null || this.personaldata.sub_id == "" || this.personaldata.sub_id.trim == undefined) {
        var relation = this.personaldata.relationship_name != undefined ? this.personaldata.relationship_name: "self";

        this.retrival_flag_data = true;
        this.send_data = {
          client_id: this.personaldata.Client_id,
          relation: this.personaldata.relationship_name,
          home_care: "0"
        };

        this.send_doc_data = {
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.relationship_name,
        };

        this.send_report_data = {
          client_id: this.personaldata.Client_id,
          relation: relation,
          home_care: "0"
        }
        this.send_micoreport_data = {
          client_id: this.personaldata.Client_id,
          relation: relation,
          home_care: "0"
        }
        send_data = {
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,
          spl_id: this.personaldata.spl_id,
        }
        this.retrieveUploadDocumentList(send_data);
        this.get_Blood_reports(this.send_report_data);
        this.getmicroreport(this.send_micoreport_data)
        // this.get_Urine_reports(this.send_report_data);
        // this.get_Feaces_reports(this.send_report_data);
        this.get_Scan_reports(this.send_report_data);
        // this.get_Xray_reports(this.send_report_data);
        // this.get_Ultrasound_reports(this.send_report_data);
        // this.set_retrival_document_data();
        if (this.appointment_data_list != undefined) {
          if (this.appointment_data_list.status != null && this.appointment_data_list.status == "0") {
            this.pres_ids = [];
            this.doc_ids = [];



            this.extraadd_document_div = false;
            this.add_document_div = false;
          }
          else {
            this.extraadd_document_div = true;
          }
        }
      } else {
        this.retrival_flag_data = false;
        this.send_data = {
          client_id: this.personaldata.Client_id,
          relation: this.personaldata.relationship_name,
          sub_relation_id: this.personaldata.sub_id,
          home_care: "0"
        };

        this.send_doc_data = {
          client_id: this.personaldata.Client_id,
          relation: this.personaldata.relationship_name,
          sub_relation_id: this.personaldata.sub_id,
        };

        this.send_report_data = {
          client_id: this.personaldata.Client_id,
          relation: this.personaldata.relationship_name,
          sub_relation_id: this.personaldata.sub_id,
          home_care: "0"
        }

        send_data = {
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,
          sub_relation_id: this.personaldata.sub_id,
          spl_id: this.personaldata.spl_id,
        }

        this.pres_ids = [];
        this.doc_ids = [];
        // this.GetPrescriptions();
        this.retrieveUploadDocumentList(send_data);
        this.get_Blood_reports(this.send_report_data);
        // this.get_Urine_reports(this.send_report_data);
        // this.get_Feaces_reports(this.send_report_data);
        this.getmicroreport(this.send_micoreport_data)
        this.get_Scan_reports(this.send_report_data);
        //this.get_Xray_reports(this.send_report_data);
        //this.get_Ultrasound_reports(this.send_report_data);

        this.add_document_div = true;
      }
    }


  }

  changeImge(e: any) {
    this.ImagePath = e.target.currentSrc;
  }

  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/dist/all.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  public loadCss() {
    $("<link/>", {
      rel: "stylesheet",
      type: "text/css",
      href: "assets/dist/all.css"
    }).appendTo("mdb-card");
  }

  Add_Documents_Data() {

  }

  GetPrescriptions() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.pres_url, (this.send_data), { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj != null) {
          if (obj.prescriptions != null && obj.prescriptions.length != 0) {
            this.pres_data = [];
            for (var pres_int = 0; pres_int < obj.prescriptions.length; pres_int++) {
              var pres = obj.prescriptions[pres_int];
              var doc_name = "";
              if (pres.middle_name != null) {
                doc_name = pres.first_name + " " + pres.middle_name + " " + pres.last_name;
              } else {
                doc_name = pres.first_name + " " + pres.last_name;
              }

              var clnt_name = "";
              if (pres.client_middle_name != null) {
                clnt_name = encrypt_decript.Decript(pres.client_first_name) + " " + encrypt_decript.Decript(pres.client_middle_name) + " " + encrypt_decript.Decript(pres.client_last_name);
              } else {
                clnt_name = encrypt_decript.Decript(pres.client_first_name) + " " + encrypt_decript.Decript(pres.client_last_name);
              }

              var pres_date = "";
              if (pres.date != null) {
                pres_date = " on " + pres.date.split('-')[2] + "-" + pres.date.split('-')[1] + "-" + pres.date.split('-')[0];
              }
              this.pres_data.push({
                data: "Dr." + doc_name + " prescribed for " + clnt_name + pres_date,
                id: pres.pres_drug_id
              });
            }
          }
        }
      }, error => { });
  }

  getdoc(doc_id, docname) {
    var check_value = true;
    for (var h = 0; h < this.doc_ids.length; h++) {
      if (this.doc_ids[h] == doc_id) {
        check_value = false;
      } else {
        check_value = true;
      }
    }
    if (check_value == true) {
      this.doc_ids.push(doc_id + "#" + docname);
    }
    else {
      for (var i = 0; i < this.doc_ids.length; i++) {
        if (this.doc_ids[i] == doc_id + "#" + docname) {
          this.doc_ids.splice(this.doc_ids.indexOf(i), 1);
          break;
        }
      }
    }

  }

  view_med(test_id) {
    this.report_detail_flag = true;
    this.report_detail_dig_flag = true;
    this.report_detail_pres_flag = false;

    this.pres_id = test_id;
    this.prescriptionGetvalue();
  }

  prescriptionGetvalue() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.http.post(this.url_prescription, (
      { pres_id: this.pres_id }),
      { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(data["_body"]);

          this.data_prescription = obj;
          if (obj.middle_name != null && obj.middle_name != "") {
            this.total_name = obj.first_name + ' ' + obj.middle_name + ' ' + obj.last_name;
          }
          else {
            this.total_name = obj.first_name + ' ' + obj.last_name;
          }

          if (obj.cl_middle_name != null && encrypt_decript.Decript(obj.cl_middle_name) != undefined) {
            this.dr_total_name = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name) + ' ' + encrypt_decript.Decript(obj.cl_middle_name);
          }
          else {
            this.dr_total_name = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
          }


          this.date_prescription = Date_Formate(obj.pres_date);
          this.disease_name = obj.disease;
          this.disease_describtion = obj.dis_desc;

          for (var i = 0; i < obj.drug_list.length; i++) {
            var master_tablet_data = obj.drug_list[i];
            var medicene_dosage_data = "";
            if (master_tablet_data.intake_desc == "Every 6 hours") {
              medicene_dosage_data = master_tablet_data.morning + '-' + master_tablet_data.morning + '-' + master_tablet_data.morning + '-' + master_tablet_data.morning;
            } else {
              medicene_dosage_data = master_tablet_data.morning + '-' + master_tablet_data.afternoon + '-' + master_tablet_data.evening;;
            }

            var master_timeDuration = "";
            if (master_tablet_data.drug_intake == "With food") {
              master_timeDuration = master_tablet_data.drug_intake;
            } else {
              if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
              }
              else {
                master_timeDuration = master_tablet_data.drug_intake;
              }
            }
            this.tablet_tracker.push({
              medicene_name: master_tablet_data.drug_type_name + ' - ' + master_tablet_data.drug_name,
              medicene_dosage: medicene_dosage_data,
              medicene_intakedays: master_tablet_data.days + ' days ',
              medicene_food: master_timeDuration
            })
          }
        },
        error => { });
  }

  viewdoc(doc_id, name) {
    // this.report_imagepdf_detail(doc_id + "#" + name);
  }

  get_Blood_reports(get_data) {
    get_data.type = "lab";
    console.log("record/reportlist TESTS " + JSON.stringify(get_data))

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist/", (get_data), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));
          console.log("record/reportlist TESTS " + JSON.stringify(obj))

          if (obj != null) {
            if (obj.reportlist != null && obj.reportlist.length != 0) {
              for (var i = 0; i < obj.reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";

                if (obj.reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                }

                if (obj.reportlist[i].first_name != undefined && obj.reportlist[i].middle_name != undefined && obj.reportlist[i].last_name != undefined) {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].middle_name + " " + obj.reportlist[i].last_name;
                } else if (obj.reportlist[i].first_name != undefined && obj.reportlist[i].last_name != undefined) {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].last_name;
                } else if (obj.reportlist[i].first_name != undefined) {
                  doctor_name = obj.reportlist[i].first_name
                }

                this.blood_report_list.push({
                  Date: Date_Formate(obj.reportlist[i].appointment_date),
                  Diag_Test_Id: obj.reportlist[i].diag_test_id,
                  diag_test_name: obj.reportlist[i].diag_test_name,
                  Centre_Name: obj.reportlist[i].diag_centre_name,
                  diag_appointment_id: obj.reportlist[i].diag_appointment_id,
                  test_type: obj.reportlist[i].test_type,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name,
                  appointment_time: Time_Formate(obj.reportlist[i].appointment_time)
                });

                console.log("LABORATORY TESTS " + JSON.stringify(this.blood_report_list))
              }

              const grp_by_appnt = this.groupBy(this.blood_report_list, 'diag_appointment_id');
              console.log("grp_by_appnt" + JSON.stringify(grp_by_appnt))

              this.grouped_array = [];
              for (var key in grp_by_appnt) {
                var tests_arr = [];
                var diag_appnt_id = key;
                var date = grp_by_appnt[key][0].Date
                var time = grp_by_appnt[key][0].appointment_time
                var doc_name = "";
                if (grp_by_appnt[key][0].Doctor_Name != undefined) {
                  doc_name = grp_by_appnt[key][0].Doctor_Name;
                }

                for (var k = 0; k < grp_by_appnt[key].length; k++) {
                  tests_arr.push({
                    // Date: grp_by_appnt[key][k].Date,
                    // Diag_Test_Id: grp_by_appnt[key][k].Diag_Test_Id,
                    // diag_test_name: grp_by_appnt[key][k].diag_test_name,
                    // Centre_Name: grp_by_appnt[key][k].Centre_Name,
                    // diag_appointment_id: grp_by_appnt[key][k].diag_appointment_id,
                    // test_type: grp_by_appnt[key][k].test_type,
                    // Doctor_Name: grp_by_appnt[key][k].Doctor_Name,
                    // Client_Name: grp_by_appnt[key][k].Client_Name,

                    pres_test_id: grp_by_appnt[key][k].Diag_Test_Id,
                    test_type: grp_by_appnt[key][k].test_type,
                    pres_diag_id: grp_by_appnt[key][k].diag_appointment_id,
                    test_name: grp_by_appnt[key][k].diag_test_name,
                    sample_datetime: grp_by_appnt[key][k].sample_collect_date_time,
                    approve_datetime: grp_by_appnt[key][k].approvedDate
                  })
                }

                this.grouped_array.push({
                  diag_app_id: diag_appnt_id,
                  date: date,
                  appointment_time: time,
                  test_values: tests_arr,
                  doc_name: doc_name,
                })
              }

              console.log("grouped arry" + JSON.stringify(this.grouped_array))
            }
          }
        },
        error => { });
  }
  getmicroreport(get_data) {
    get_data.type = "micro";

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist/", (get_data), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));
          console.log("getmicroreport/reportlist TESTS " + JSON.stringify(get_data))
          console.log("getmicroreport/reportlist TESTS " + JSON.stringify(obj))

          if (obj != null) {
            if (obj.reportlist != null && obj.reportlist.length != 0) {
              for (var i = 0; i < obj.reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";

                if (obj.reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                }

                if (obj.reportlist[i].first_name != undefined && obj.reportlist[i].middle_name != undefined && obj.reportlist[i].last_name != undefined) {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].middle_name + " " + obj.reportlist[i].last_name;
                } else if (obj.reportlist[i].first_name != undefined && obj.reportlist[i].last_name != undefined) {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].last_name;
                } else if (obj.reportlist[i].first_name != undefined) {
                  doctor_name = obj.reportlist[i].first_name
                }

                this.microlist.push({
                  Date: obj.reportlist[i].appointment_date,
                  Diag_Test_Id: obj.reportlist[i].diag_test_id,
                  diag_test_name: obj.reportlist[i].diag_test_name,
                  Centre_Name: obj.reportlist[i].diag_centre_name,
                  diag_appointment_id: obj.reportlist[i].diag_appointment_id,
                  test_type: obj.reportlist[i].test_type,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name
                });
                console.log("microlist " + JSON.stringify(this.microlist))
              }

              const grp_by_appnt = this.groupBy(this.microlist, 'diag_appointment_id');
              console.log("grp_by_appnt -------- Scan" + JSON.stringify(grp_by_appnt))

              this.micro_grouped_array = [];
              for (var key in grp_by_appnt) {
                var tests_arr = [];
                var diag_appnt_id = key;
                var date = grp_by_appnt[key][0].Date
                var time = grp_by_appnt[key][0].appointment_time
                var doc_name = "";
                if (grp_by_appnt[key][0].Doctor_Name != undefined) {
                  doc_name = grp_by_appnt[key][0].Doctor_Name;
                }

                for (var k = 0; k < grp_by_appnt[key].length; k++) {
                  tests_arr.push({
                    pres_test_id: grp_by_appnt[key][k].Diag_Test_Id,
                    test_type: grp_by_appnt[key][k].test_type,
                    pres_diag_id: grp_by_appnt[key][k].diag_appointment_id,
                    test_name: grp_by_appnt[key][k].diag_test_name,
                    // sample_datetime: grp_by_appnt[key][k].sample_collect_date_time,
                    // approve_datetime: grp_by_appnt[key][k].approvedDate
                  })
                }

                this.micro_grouped_array.push({
                  diag_app_id: diag_appnt_id,
                  date: date,
                  appointment_time: time,
                  test_values: tests_arr,
                  doc_name: doc_name,
                })
              }

              console.log("micro_grouped_array" + JSON.stringify(this.micro_grouped_array))
            }
          }
        },
        error => { });
  }

  // get_Urine_reports(get_data) {
  //   get_data.type = "urine";

  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');

  //   this.http.post(
  //     ipaddress.getIp + "record/reportlist/", (get_data), { headers: headers }).subscribe(
  //       data => {
  //         var obj = JSON.parse(JSON.stringify(data));
  //         if (obj != null) {
  //           if (obj.reportlist != null && obj.reportlist.length != 0) {
  //             for (var i = 0; i < obj.reportlist.length; i++) {
  //               var client_name = "";
  //               var doctor_name = "";

  //               if (obj.reportlist[i].client_middle_name != null) {
  //                 client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
  //                   encrypt_decript.Decript(obj.reportlist[i].client_middle_name) + " " +
  //                   encrypt_decript.Decript(obj.reportlist[i].client_last_name);
  //               } else {
  //                 client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
  //                   encrypt_decript.Decript(obj.reportlist[i].client_last_name);
  //               }

  //               if (obj.middle_name != null) {
  //                 doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].middle_name + " " + obj.reportlist[i].last_name;
  //               } else {
  //                 doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].last_name;
  //               }

  //               this.urine_report_list.push({
  //                 Date: obj.reportlist[i].appointment_date,//this.gservice.get_Date_Formate(obj.reportlist[i].appointment_date),
  //                 Diag_Test_Id: obj.reportlist[i].diag_test_id,
  //                 diag_test_name:obj.reportlist[i].diag_test_name,
  //                 Centre_Name: obj.reportlist[i].diag_centre_name,
  //                 diag_appointment_id:obj.reportlist[i].diag_appointment_id,
  //                 Doctor_Name: doctor_name,
  //                 Client_Name: client_name
  //               });
  //             }
  //           }
  //         }
  //       },
  //       error => {});
  // }

  // get_Feaces_reports(get_data) {
  //   get_data.type = "faeces";

  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(
  //     ipaddress.getIp + "record/reportlist/", (get_data), { headers: headers }).subscribe(
  //       data => {
  //         var obj = JSON.parse(JSON.stringify(data));
  //         if (obj != null) {
  //           if (obj.reportlist != null && obj.reportlist.length != 0) {
  //             for (var i = 0; i < obj.reportlist.length; i++) {
  //               var client_name = "";
  //               var doctor_name = "";

  //               if (obj.reportlist[i].client_middle_name != null) {
  //                 client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
  //                   encrypt_decript.Decript(obj.reportlist[i].client_middle_name) + " " +
  //                   encrypt_decript.Decript(obj.reportlist[i].client_last_name);
  //               } else {
  //                 client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
  //                   encrypt_decript.Decript(obj.reportlist[i].client_last_name);
  //               }

  //               if (obj.middle_name != null) {
  //                 doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].middle_name + " " + obj.reportlist[i].last_name;
  //               } else {
  //                 doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].last_name;
  //               }

  //               this.feaces_report_list.push({
  //                 Date: obj.reportlist[i].appointment_date,//this.gservice.get_Date_Formate(obj.reportlist[i].appointment_date),
  //                 Diag_Test_Id: obj.reportlist[i].diag_test_id,
  //                 diag_test_name:obj.reportlist[i].diag_test_name,
  //                 Centre_Name: obj.reportlist[i].diag_centre_name,
  //                 diag_appointment_id:obj.reportlist[i].diag_appointment_id,
  //                 Doctor_Name: doctor_name,
  //                 Client_Name: client_name
  //               });
  //             }
  //           }
  //         }
  //       },
  //       error => {});
  // }

  get_Scan_reports(get_data) {
    get_data.type = "radio";
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "record/reportlist/", (get_data), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));
          console.log("get_Scan_reports/reportlist TESTS " + JSON.stringify(get_data))
          console.log("get_Scan_reports/reportlist TESTS " + JSON.stringify(obj))
          if (obj != null) {
            if (obj.reportlist != null && obj.reportlist.length != 0) {
              for (var i = 0; i < obj.reportlist.length; i++) {
                var client_name = "";
                var doctor_name = "";

                if (obj.reportlist[i].client_middle_name != null) {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_middle_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                } else {
                  client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
                    encrypt_decript.Decript(obj.reportlist[i].client_last_name);
                }

                if (obj.reportlist[i].first_name != undefined && obj.reportlist[i].middle_name != undefined && obj.reportlist[i].last_name != undefined) {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].middle_name + " " + obj.reportlist[i].last_name;
                } else if (obj.reportlist[i].first_name != undefined && obj.reportlist[i].last_name != undefined) {
                  doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].last_name;
                } else if (obj.reportlist[i].first_name != undefined) {
                  doctor_name = obj.reportlist[i].first_name
                }

                this.scan_report_list.push({
                  Date: obj.reportlist[i].appointment_date,//this.gservice.get_Date_Formate(obj.reportlist[i].appointment_date),
                  Diag_Test_Id: obj.reportlist[i].diag_test_id,
                  diag_test_name: obj.reportlist[i].diag_test_name,
                  Centre_Name: obj.reportlist[i].diag_centre_name,
                  diag_appointment_id: obj.reportlist[i].diag_appointment_id,
                  test_type: obj.reportlist[i].test_type,
                  Doctor_Name: doctor_name,
                  Client_Name: client_name
                });
                console.log("RADIOLOGY TESTS " + JSON.stringify(this.scan_report_list))
              }

              const grp_by_appnt = this.groupBy(this.scan_report_list, 'diag_appointment_id');
              console.log("grp_by_appnt -------- Scan" + JSON.stringify(grp_by_appnt))

              this.scan_grouped_array = [];
              for (var key in grp_by_appnt) {
                var tests_arr = [];
                var diag_appnt_id = key;
                var date = grp_by_appnt[key][0].Date
                var time = grp_by_appnt[key][0].appointment_time
                var doc_name = "";
                if (grp_by_appnt[key][0].Doctor_Name != undefined) {
                  doc_name = grp_by_appnt[key][0].Doctor_Name;
                }

                for (var k = 0; k < grp_by_appnt[key].length; k++) {
                  tests_arr.push({
                    pres_test_id: grp_by_appnt[key][k].Diag_Test_Id,
                    test_type: grp_by_appnt[key][k].test_type,
                    pres_diag_id: grp_by_appnt[key][k].diag_appointment_id,
                    test_name: grp_by_appnt[key][k].diag_test_name,
                    // sample_datetime: grp_by_appnt[key][k].sample_collect_date_time,
                    // approve_datetime: grp_by_appnt[key][k].approvedDate
                  })
                }

                this.scan_grouped_array.push({
                  diag_app_id: diag_appnt_id,
                  date: date,
                  appointment_time: time,
                  test_values: tests_arr,
                  doc_name: doc_name,
                })
              }

              console.log("scan grouped arry" + JSON.stringify(this.scan_grouped_array))
            }
          }
        },
        error => { });
  }

  // get_Xray_reports(get_data) {
  //   get_data.type = "x-ray";

  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(
  //     ipaddress.getIp + "record/reportlist/",(get_data), { headers: headers }).subscribe(
  //       data => {
  //         var obj = JSON.parse(JSON.stringify(data));
  //         if (obj != null) {
  //           if (obj.reportlist != null && obj.reportlist.length != 0) {
  //             for (var i = 0; i < obj.reportlist.length; i++) {
  //               var client_name = "";
  //               var doctor_name = "";
  //               if (obj.reportlist[i].client_middle_name != null) {
  //                 client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
  //                   encrypt_decript.Decript(obj.reportlist[i].client_middle_name) + " " +
  //                   encrypt_decript.Decript(obj.reportlist[i].client_last_name);
  //               } else {
  //                 client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
  //                   encrypt_decript.Decript(obj.reportlist[i].client_last_name);
  //               }

  //               if (obj.middle_name != null) {
  //                 doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].middle_name + " " + obj.reportlist[i].last_name;
  //               } else {
  //                 doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].last_name;
  //               }

  //               this.xray_report_list.push({
  //                 Date: obj.reportlist[i].appointment_date,//this.gservice.get_Date_Formate(obj.reportlist[i].appointment_date),
  //                 Diag_Test_Id: obj.reportlist[i].diag_test_id,
  //                 diag_test_name:obj.reportlist[i].diag_test_name,
  //                 Centre_Name: obj.reportlist[i].diag_centre_name,
  //                 diag_appointment_id:obj.reportlist[i].diag_appointment_id,
  //                 Doctor_Name: doctor_name,
  //                 Client_Name: client_name
  //               });
  //             }
  //           }
  //         }
  //       },
  //       error => {});
  // }

  // get_Ultrasound_reports(get_data) {
  //   get_data.type = "Ultra-Sound";

  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(
  //     ipaddress.getIp + "record/reportlist/", (get_data), { headers: headers }).subscribe(
  //       data => {
  //         var obj = JSON.parse(JSON.stringify(data));
  //         if (obj != null) {
  //           if (obj.reportlist != null && obj.reportlist.length != 0) {
  //             for (var i = 0; i < obj.reportlist.length; i++) {
  //               var client_name = "";
  //               var doctor_name = "";

  //               if (obj.reportlist[i].client_middle_name != null) {
  //                 client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
  //                   encrypt_decript.Decript(obj.reportlist[i].client_middle_name) + " " +
  //                   encrypt_decript.Decript(obj.reportlist[i].client_last_name);
  //               } else {
  //                 client_name = encrypt_decript.Decript(obj.reportlist[i].client_first_name) + " " +
  //                   encrypt_decript.Decript(obj.reportlist[i].client_last_name);
  //               }

  //               if (obj.middle_name != null) {
  //                 doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].middle_name + " " + obj.reportlist[i].last_name;
  //               } else {
  //                 doctor_name = obj.reportlist[i].first_name + " " + obj.reportlist[i].last_name;
  //               }

  //               this.ultrasound_report_list.push({
  //                 Date: obj.reportlist[i].appointment_date,//this.gservice.get_Date_Formate(obj.reportlist[i].appointment_date),
  //                 Diag_Test_Id: obj.reportlist[i].diag_test_id,
  //                 diag_test_name:obj.reportlist[i].diag_test_name,
  //                 Centre_Name: obj.reportlist[i].diag_centre_name,
  //                 diag_appointment_id:obj.reportlist[i].diag_appointment_id,
  //                 Doctor_Name: doctor_name,
  //                 Client_Name: client_name,
  //                 TestId: "Test_" + i
  //               });
  //             }
  //           }
  //         }
  //       },
  //       error => {});
  // }

  report_view(test_id, type, diag_appointment_id, testtype, testName) {
    this.documentviewflag = true;
    this.grouping_flag = false;
    this.testName = testName;
    console.log("DDDDDD" + type)
    if (type == "pathology") {
      this.tableReportTypeFlag = false;
      this.paraReportTypeFlag = true;
    } else {
      this.tableReportTypeFlag = true;
      this.paraReportTypeFlag = false;
      this.cultureFlag = this.reportType == "Culture" ? true : false;
    }

    this.report_diag_id = diag_appointment_id;
    this.report_test_id = test_id;
    this.relation = this.personaldata.rel_id;
    this.report_type = type;
    this.Get_Report_Detailview("normal", testtype); // view report view
    this.clientflag = true;
    this.docflag = false;
    this.diagflag = false;
    this.report_detail_flag = true;
    this.report_detail_dig_flag = false;
    this.report_detail_pres_flag = true;
    this.documentView_flag = false;
  }

  back_Nav() {
    this.report_detail_flag = false;
    this.report_detail_dig_flag = true;
    this.report_detail_pres_flag = true;
    this.documentView_flag = false;
  }
  approve_dateTime: any;
  salutation: any;
  public visit_id;

  Get_Report_Detailview(type, testtype) {

    this.diagTestReadingArray = [];
    this.imageflag_outsource_id = true;
    var send_json = null, url = null;
    if (type == "normal") {
      //url = 'record/diagreportview/';
      url = 'record/drview/';
      send_json = ({
        type: this.report_type,
        flag: "doctor",
        diag_app_id: this.report_diag_id,
        country: ipaddress.country_code,
        pres_diag_id: this.report_pres_id,
        pres_test_id: this.report_test_id,
        test_type: testtype,
      })

    } else if (type == "diag") {
      //url = 'record/diagreportview/';
      url = 'record/drview/';
      send_json = ({
        type: this.report_type,
        flag: "diagnosis",
        diag_app_id: this.report_diag_id,
        country: ipaddress.country_code,
        pres_diag_id: this.report_pres_id,
        pres_test_id: this.report_test_id,
        test_type: testtype,
      })

    } else {
      url = 'secopn/greport/';
      send_json = ({
        type: this.opn_type,
        pres_test_id: this.opn_pres_test_id,
        opinion_id: this.opn_id,
        country: ipaddress.country_code,
        app_id: this.opn_app_id,
        test_type: testtype,
      })
    }
    console.log("check send_json " + JSON.stringify(send_json))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('Access-Control-Allow-Headers', 'Content-Type',);
    headers.set('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,DELETE,PUT');
    headers.set('Authorization', 'Bearer key');
    console.log("check data url" + JSON.stringify(url))

    this.http.post(ipaddress.getIp.toString() + url, send_json,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.ImagePath = "https://mdbootstrap.com/img/Others/documentation/img%20(150)-mini.jpg";
          console.log("check data objobj" + JSON.stringify(obj))
          if (obj != null) {
            this.centerName = obj.diag_centre_name;
            this.diagAddress = obj.address2 != undefined && obj.address2 != "" ? obj.address1 + " " + obj.address2 : obj.address1;
            if (obj.sample_id != undefined) {
              var sampletest = obj.sample_id.split(",")
              this.sample_id = sampletest[0];
              var sample_typevalue = obj.sample_type.split(",")
              this.sample_type = sample_typevalue[0];
            }
            // if (this.pres_tests[0].sample_datetime != undefined) {
            //   this.sampleColl_dateTime = this.pres_tests[0].sample_datetime;
            // }
            // if (this.pres_tests[0].approve_datetime != undefined) {
            //   this.approve_dateTime = this.pres_tests[0].approve_datetime;
            // }
            if (obj.salutation_desc != undefined) {
              this.salutation = obj.salutation_desc
            }
            if (obj.diag_appointment_id != undefined) {
              this.visit_id = obj.diag_appointment_id;
            } else {
              this.visit_id = obj.diag_appointment_id;
            }
            if (this.report_type == "microbiology") {
              this.microbiology_flag = false;
            }
            if (obj.main_tests[0].ot_sterile_key != undefined) {
              if (obj.main_tests[0].ot_sterile_key == '1') {
                this.ot_Sterile_flag = false;
              } else {
                this.ot_Sterile_flag = true;
              }
            }


            if (obj.main_tests[0].organism_isolation_2_code != undefined && obj.main_tests[0].organism_isolation_3_code != undefined) {
              this.org_iso_heading = "Organism Isolated 1";
            } else {
              this.org_iso_heading = "Organism Isolated";
            }
            if (obj.date_entered != undefined) {
              this.reporteddate = Date_Formate(obj.date_entered);
              var timecheck = Time_Formate(obj.time_entered);
              var checkvalue = timecheck.split(":")
              var checkvalue1 = checkvalue[1].split(" ")
              console.log("time check" + checkvalue + checkvalue1)
              this.reportedtime = Time_Formate(obj.time_entered)
            }

            // if(this.report_type == "radiology"){
            //   this.sampleflag = false;
            //   if(obj.visited_time != undefined){
            //     this.visited_date=obj.visited_date
            //   }
            //   if(obj.visited_time != undefined){
            //     this.visited_time=obj.visited_time
            //   }
            // }else{
            //   this.sampleflag= true;
            // }
            if (obj.main_tests != undefined) {
              if (obj.main_tests[0].sample_date != undefined) {
                this.sampledate = Date_Formate(obj.main_tests[0].sample_date)
                this.sampledatecheck = Date_Formate(obj.main_tests[0].sample_date)
              }
              if (obj.main_tests[0].technical_note != undefined) {
                this.technical_note = obj.main_tests[0].technical_note;
                this.technical_noteFlag = true;
              }
              if (obj.main_tests[0].comment != undefined) {
                this.comment = obj.main_tests[0].comment;
                this.commentsFlag = true;
              }
              if (obj.app_first_name != undefined && obj.app_last_name != undefined) {
                this.approver_name = obj.app_first_name + ' ' + obj.app_last_name;
              } else if (obj.app_first_name != undefined) {
                this.approver_name = obj.app_first_name;
              }
              if (obj.qual_first_name != undefined && obj.qual_last_name != undefined) {
                this.qualityHead_name = obj.qual_first_name + ' ' + obj.qual_last_name;
              } else if (obj.qual_first_name != undefined) {
                this.qualityHead_name = obj.qual_first_name;
              }
              this.diag_report_type = this.report_type
              if (this.report_type == "pathology") {
                this.pathology_flag = false;
                this.microbiology_flag = true;
                this.radiology_flag = true;
                this.diag_report_type = "pathology"
                this.consultant = "Consultant Pathologist";

              } else if (this.report_type == "radiology") {
                this.diag_report_type = "radiology"
                this.pathology_flag = true;
                this.radiology_flag = false;
                this.microbiology_flag = true;
                this.consultant = "Consultant Radiologist";
                // this.pathology_flag = true;
              } else {
                this.pathology_flag = true;
                this.radiology_flag = true;
                this.microbiology_flag = false;
                this.consultant = "Consultant Microbiologist";
                //  this.diag_report_type ="radiology"
              }
              if (this.report_type == "radiology") {
                this.sampleflag = false;
                if (obj.main_tests[0].visited_time != undefined) {
                  this.visited_date = obj.main_tests[0].visited_date
                }
                if (obj.main_tests[0].visited_time != undefined) {
                  this.visistime = obj.main_tests[0].visited_time
                  this.visited_time = Time_Formate(this.visistime);
                }
              } else {
                this.sampleflag = true;
              }
              if (obj.main_tests[0].sample_time != undefined) {
                if (obj.main_tests[0].sample_time != undefined) {
                  this.sampletime = obj.main_tests[0].sample_time;
                  var check1 = this.sampletime.split(":")
                  var check2 = check1[0] + ":" + check1[1];
                  this.sampletimecheck = Time_Formate(this.sampletime);
                }


              }

              this.oraganismIso1 = obj.main_tests[0].org_iso_1_desc;
              this.oraganismIso2 = obj.main_tests[0].org_iso_2_desc;
              this.oraganismIso3 = obj.main_tests[0].org_iso_3_desc;
              if (obj.main_tests[0].culture_report_type_desc != undefined) {
                this.culture_report_type_desc = obj.main_tests[0].culture_report_type_desc;
              }
              if (obj.main_tests[0].outsource_id != undefined) {
                this.outsource_id = obj.main_tests[0].outsource_id;
                this.imageflag_outsource_id = false;
                this.uploadidproof = this.sanitizer.bypassSecurityTrustResourceUrl(ipaddress.Ip_with_img_address + obj.main_tests[0].image_path);

                //this.uploadidproof = ipaddress.Ip_with_img_address + obj.main_tests[0].image_path;
                console.log("idkjbjklhh" + JSON.stringify(this.uploadidproof))
              }
              if (obj.main_tests[0].gram_stain_desc != undefined) {
                this.gram_stain_desc = obj.main_tests[0].gram_stain_desc;
              }
              if (obj.main_tests[0].culture_report_desc != undefined) {
                this.culture_report_desc = obj.main_tests[0].culture_report_desc;
              }
              if (obj.main_tests[0].technical_note != undefined) {
                this.technical_note = obj.main_tests[0].technical_note;
              }
              if (obj.main_tests[0].comment != undefined) {
                this.comment = obj.main_tests[0].comment;
              }
              if (obj.ot_sterile != undefined) {
                this.ot_sterile = obj.ot_sterile;
                console.log(JSON.stringify(this.ot_sterile))
                this.ot_listFlag = true;
              } else {
                this.ot_listFlag = false;
              }

              if (obj.main_tests[0].ot_sterile_key == '1') {
                this.ot_Sterile_flag = false;
              } else {
                this.ot_Sterile_flag = true;
              }

              if (obj.main_tests[0].ot_sterile_key != undefined) {
                this.micro_temp = false;
              } else {
                this.micro_temp = true;
              }

              if (obj.main_tests[0].template != undefined) {
                this.template_flag = true;
                this.template = obj.main_tests[0].template;
                console.log("this.template_flag", this.template_flag, this.template)
              } else {
                this.template_flag = false;
                this.template = "";
                if (this.report_type == "microbiology") {
                  this.microbiology_flag = false;
                  if (this.microbiology_flag == false) {
                    this.value_flag = false;
                  } else {
                    this.value_flag = true;
                  }
                } else {
                  this.value_flag = true; this.microbiology_flag = true;
                }
              }
            }
            if (this.report_type == "microbiology") {
              if (obj.main_tests != undefined) {
                if (obj.main_tests[0].org_iso1_cr != undefined && obj.main_tests[0].org_iso1_cr != "") {
                  this.org_iso1_cr_desc = obj.main_tests[0].org_iso1_cr;
                  this.org_iso1_cr_flag = true;
                } else {
                  this.org_iso1_cr_flag = false;
                }

                if (obj.main_tests[0].org_iso2_cr != undefined) {
                  this.org_iso2_cr_desc = obj.main_tests[0].org_iso2_cr;
                  this.org_iso2_cr_flag = true;
                }

                if (obj.main_tests[0].org_iso3_cr != undefined) {
                  this.org_iso3_cr_desc = obj.main_tests[0].org_iso3_cr;
                  this.org_iso3_cr_flag = true;
                }

                if (obj.main_tests[0].organism_isolation_1_code != undefined && obj.main_tests[0].organism_isolation_1_desc != undefined) {
                  this.org_iso1_flag = true;
                  this.org_iso_1_code = obj.main_tests[0].organism_isolation_1_code;

                  if (obj.main_tests[0].organism_isolation_1_desc != undefined) {
                    this.org_iso_1_desc = obj.main_tests[0].organism_isolation_1_desc;
                    this.org_iso1_desc_flag = true;
                  }

                  if (obj.main_tests[0].org_iso1_colonycount_desc != undefined) {
                    this.org_iso_1_cc_desc = obj.main_tests[0].org_iso1_colonycount_desc;
                    this.org_iso1_cc_flag = true;
                  }

                  if (obj.main_tests[0].org_iso1_growth_type_desc != undefined) {
                    this.org_iso1_growth_type_desc = obj.main_tests[0].org_iso1_growth_type_desc;
                    this.org_iso1_growth_type_flag = true;
                  }

                  // this.org_iso_1_cc_desc = obj.main_tests[0].org_iso1_colonycount_desc;
                  // this.org_iso1_growth_type_desc = obj.main_tests[0].org_iso1_growth_type_desc;

                  this.org_iso1_antibiotics = obj.org_iso1_antibiotics;
                  this.mic_iso1_flag = this.org_iso1_antibiotics.every(element => element.mic != undefined);


                } else {
                  this.org_iso1_flag = false;
                  this.org_iso1_cc_flag = false;
                  this.org_iso1_desc_flag = false;
                  this.org_iso1_growth_type_flag = false;
                }

                if (obj.main_tests[0].organism_isolation_2_code != undefined && obj.main_tests[0].organism_isolation_2_desc != undefined) {
                  this.org_iso2_flag = true;
                  this.org_iso_2_code = obj.main_tests[0].organism_isolation_2_code;

                  if (obj.main_tests[0].organism_isolation_2_desc != undefined) {
                    this.org_iso_2_desc = obj.main_tests[0].organism_isolation_2_desc;
                    this.org_iso2_desc_flag = true;
                  }

                  if (obj.main_tests[0].org_iso2_colonycount_desc != undefined) {
                    this.org_iso_2_cc_desc = obj.main_tests[0].org_iso2_colonycount_desc;
                    this.org_iso2_cc_flag = true;
                  }

                  if (obj.main_tests[0].org_iso2_growth_type_desc != undefined) {
                    this.org_iso2_growth_type_desc = obj.main_tests[0].org_iso2_growth_type_desc;
                    this.org_iso2_growth_type_flag = true;
                  }
                  // this.org_iso_2_desc = obj.main_tests[0].organism_isolation_2_desc;
                  // this.org_iso_2_cc_desc = obj.main_tests[0].org_iso2_colonycount_desc;
                  // this.org_iso2_cr_desc = obj.main_tests[0].org_iso2_cr;
                  // this.org_iso2_growth_type_desc = obj.main_tests[0].org_iso2_growth_type_desc;


                  this.org_iso2_antibiotics = obj.org_iso2_antibiotics;
                  this.mic_iso2_flag = this.org_iso2_antibiotics.every(element => element.mic != undefined);
                } else {
                  this.org_iso2_flag = false;
                  this.org_iso2_growth_type_flag = false;
                  this.org_iso2_cc_flag = false;
                  this.org_iso2_desc_flag = false;
                }

                if (obj.main_tests[0].organism_isolation_3_code != undefined && obj.main_tests[0].organism_isolation_3_desc != undefined) {
                  this.org_iso3_flag = true;

                  this.org_iso_3_code = obj.main_tests[0].organism_isolation_3_code;

                  if (obj.main_tests[0].organism_isolation_3_desc != undefined) {
                    this.org_iso_3_desc = obj.main_tests[0].organism_isolation_3_desc;
                    this.org_iso3_desc_flag = true;
                  }

                  if (obj.main_tests[0].org_iso3_colonycount_desc != undefined) {
                    this.org_iso_3_cc_desc = obj.main_tests[0].org_iso3_colonycount_desc;
                    this.org_iso3_cc_flag = true;
                  }

                  if (obj.main_tests[0].org_iso3_growth_type_desc != undefined) {
                    this.org_iso3_growth_type_desc = obj.main_tests[0].org_iso3_growth_type_desc;
                    this.org_iso3_growth_type_flag = true;
                  }

                  // this.org_iso_3_desc = obj.main_tests[0].organism_isolation_3_desc;
                  // this.org_iso_3_cc_desc = obj.main_tests[0].org_iso3_colonycount_desc;
                  // this.org_iso3_cr_desc = obj.main_tests[0].org_iso3_cr;
                  // this.org_iso3_growth_type_desc = obj.main_tests[0].org_iso3_growth_type_desc;


                  this.org_iso3_antibiotics = obj.org_iso3_antibiotics;
                  this.mic_iso3_flag = this.org_iso3_antibiotics.every(element => element.mic != undefined);
                }
                else {
                  this.org_iso3_flag = false;
                  this.org_iso3_desc_flag = false;
                  this.org_iso3_growth_type_flag = false;
                  this.org_iso3_cc_flag = false;
                }
              }
            }
            if (obj.date_entered != undefined)
              this.date = Date_Formate(obj.date_entered);
            if (obj.sample_id != undefined)
              this.sampleidvalue = Date_Formate(obj.sample_id);
            if (obj.sample_type != undefined)
              this.sampletype = Date_Formate(obj.sample_type);
            if (obj.doctorname != undefined)
              this.doctorname = Date_Formate(obj.doctorname);
            if (obj.entry_date != undefined)
              this.entry_date = Date_Formate(obj.entry_date);
            if (obj.entry_time != undefined)
              this.entry_time = (obj.entry_time);

            if (obj.time_entered != undefined)
              this.time = Time_Formate(obj.time_entered);

            if (obj.colony_count != undefined) {
              this.colonyCountArray = obj.colony_count
            }

            this.diagLocation = obj.location;
            this.diagCityZip = obj.city + "-" + obj.zipcode;
            this.diagStateCon = obj.state + "-" + obj.country;
            this.diagTelephone = obj.telephone;
            this.clientID = obj.client_reg_id;
            this.patientID = obj.patient_id;

            if (obj.first_name != null) {
              if (obj.middle_name != null && encrypt_decript.Decript(obj.middle_name).toString() != "")
                this.clientAnotherName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
              else
                this.clientAnotherName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            }

            if (obj.referred_by != undefined && obj.referred_by != "" && obj.referred_by != null && obj.referred_by != "null") {
              this.referFlag = true;
              this.diagReferedBy = obj.referred_by;
            }
            if (obj.dr_first_mame != null) {
              if (obj.dr_middle_mame != null && encrypt_decript.Decript(obj.dr_middle_mame).toString() != "")
                this.doctorname = encrypt_decript.Decript(obj.dr_first_mame) + " " + encrypt_decript.Decript(obj.dr_middle_mame) + " " + encrypt_decript.Decript(obj.dr_last_mame);
              else
                this.doctorname = encrypt_decript.Decript(obj.dr_first_mame) + " " + encrypt_decript.Decript(obj.dr_last_mame);
            }

            if (obj.client_address2 != undefined) {
              this.address1 = encrypt_decript.Decript(obj.client_address1) + encrypt_decript.Decript(obj.client_address2);
            }
            if (obj.client_address2 != undefined) {
              this.address1 = "";
            }
            // if (obj.client_address2 != undefined){
            //     this.address2 = encrypt_decript.Decript(obj.client_address2);
            // }
            if (obj.client_location != undefined) {
              this.clntlocation = (obj.client_location);
            }
            if (obj.sign != undefined) {
              this.signFlag = true;
              this.review_sign = (obj.sign);
            }
            if (obj.client_state
              != undefined) {
              this.clntstate = (obj.client_state
              );
            }
            if (obj.client_country != undefined) {
              this.clntcountry = (obj.client_country
              );
            }
            if (obj.client_district != undefined) {
              this.clntdistrict = (obj.client_district);
            }
            if (obj.client_zipcode != null)
              this.clntzipcode = encrypt_decript.Decript(obj.client_zipcode);
            if (obj.gender != null)
              this.clientGender = encrypt_decript.Decript(obj.gender);

            if (obj.age != null)
              this.clientAge = obj.age == 0 ? "<1" : obj.age;
            else
              this.clientAge = "";

            if (obj.sign != undefined && obj.sign != "") {
              this.reviewSign = "data:image/png;base64," + obj.sign;
              this.signFlag = true;
            } else {
              this.signFlag = false;
            }

            if (obj.sign == undefined && obj.sign == "" || obj.app_middle_name == null)
              this.sigSectionFlag = true;

            this.reviewFlag = obj.app_first_name != null ? false : true;
            this.reviewBy = obj.app_middle_name != null ? obj.app_first_name + " " + obj.app_middle_name + " " + obj.app_last_name : obj.app_first_name + " " + obj.app_last_name;

            if (obj.time_collected != undefined)
              this.sampleCollectedTime = Time_Formate(obj.time_collected);

            if (obj.main_tests != null && obj.main_tests.length != 0) {
              this.printArray = [];
              if (obj.main_tests[0].image != undefined) {
                this.reportDetails = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                this.reportIMG = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                this.dicomDotor = false;
              }
              this.viewTestArray = [];

              for (var i = 0; i < obj.main_tests.length; i++) {
                this.presTestID = obj.main_tests[i].pres_test_id;
                this.sequences = obj.main_tests[i].sequences;
                this.impression = obj.main_tests[i].impression;

                if (obj.main_tests[i].created_by != undefined)
                  this.createdBy = obj.main_tests[i].created_by;

                if (obj.main_tests[i].test_method != undefined && obj.main_tests[i].test_method != null
                  && obj.main_tests[i].test_method != "" && obj.main_tests[i].test_method != "null")
                  this.testMethod = obj.main_tests[i].test_method;

                var test_name;
                test_name = obj.main_tests[i].test_name;
                if (obj.main_tests[i].subtests != null) {
                  this.viewTestArray = [];
                  for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                    var temp_value = "";
                    var test_method = "";
                    this.subtestMethodFlag = false;
                    this.suntestNameFlag = obj.main_tests[i].subtests[j].sub_test_name == test_name ? false : true;

                    if (this.testrangeFlag == true) {
                      if (this.clientGender == "Male" && this.clientAge < 1) {
                        temp_value = obj.main_tests[i].subtests[j].newborn;
                      } else if (this.clientGender == "Male" && (this.clientAge > 1 && this.clientAge < 13)) {
                        temp_value = obj.main_tests[i].subtests[j].child;
                      } else if (this.clientGender == "Male" && this.clientAge > 13) {
                        temp_value = obj.main_tests[i].subtests[j].male;
                      } else if (this.clientGender == "Female" && this.clientAge > 13) {
                        temp_value = obj.main_tests[i].subtests[j].female;
                      }

                    } else {
                      if (obj.main_tests[i].subtests[j].range != undefined)
                        temp_value = obj.main_tests[i].subtests[j].range;
                    }

                    if (obj.main_tests[i].subtests[j].test_method != undefined) {
                      test_method = obj.main_tests[i].subtests[j].test_method;
                      this.subtestMethodFlag = true;
                    } else {
                      this.subtestMethodFlag = false;
                    }

                    var isReadingNormal = false;
                    var isReadingAbnormal = false;
                    var isReadingCritical = false;
                    if (obj.main_tests[i].subtests[j].reading != undefined && obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0) {
                      if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                        isReadingNormal = true;
                      } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                        isReadingAbnormal = true;
                      } else {
                        isReadingCritical = true;
                      }
                    }

                    console.log(isReadingNormal, isReadingAbnormal, isReadingCritical)

                    // var test_range;
                    // if (obj.main_tests[i].subtests[j].reference[0].min_nor_value != undefined && obj.main_tests[i].subtests[j].reference[0].max_nor_value != undefined){
                    //     test_range = obj.main_tests[i].subtests[j].reference[0].range
                    // }

                    var printReading;
                    if (obj.main_tests[i].subtests[j].reading != undefined) {
                      if (obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].reference[0]).length != 0) {
                        if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                          printReading = obj.main_tests[i].subtests[j].reading;
                        } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                          printReading = obj.main_tests[i].subtests[j].reading;
                        } else {
                          printReading = obj.main_tests[i].subtests[j].reading;
                        }
                      } else {
                        printReading = obj.main_tests[i].subtests[j].reading;
                      }
                    } else {
                      printReading = "-"
                    }

                    var test_range;
                    if (obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0) {
                      if (obj.main_tests[i].subtests[j].reference[0].range != undefined) {
                        test_range = obj.main_tests[i].subtests[j].reference[0].range
                      } else {
                        test_range = '-'
                      }
                    } else {
                      test_range = '-'
                    }

                    var testReading;
                    if (obj.main_tests[i].subtests[j].reading != undefined) {
                      testReading = obj.main_tests[i].subtests[j].reading;
                    } else {
                      testReading = '-'
                    }

                    this.viewTestArray.push({
                      sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,
                      reading: obj.main_tests[i].subtests[j].reading,
                      unit: obj.main_tests[i].subtests[j].sub_uom,
                      test_range: test_range,
                      test_method: obj.main_tests[i].subtests[j].test_method,
                      is_Reading_normal: isReadingNormal,
                      is_Reading_Abnormal: isReadingAbnormal,
                      is_Reading_Critical: isReadingCritical,
                      tests: obj.main_tests[i].subtests[j].tests,
                      print_reading: printReading,
                      // newtestvalue: obj.main_tests[i].subtests[j].sub_test_name.tests[k].sub_test_name,
                      // newtestreading: obj.main_tests[i].subtests[j].sub_test_name.tests[k].reading,

                    });

                    console.log("tep array 000" + JSON.stringify(this.viewTestArray))
                  }
                }
                // Assuming your sample array is stored in a variable named 'sampleArray'

                // Iterate through main_tests


                this.diagTestReadingArray.push({
                  test_name: obj.main_tests[i].test_name,
                  category: obj.main_tests[i].test_category,

                  sub_test_list: this.viewTestArray,
                  template: obj.main_tests.template,
                  // testvalue:this.mainarray2,
                });
                console.log("this.viewTestArray" + JSON.stringify(this.diagTestReadingArray))

                //printoption
                this.printArray.push({
                  test_name: obj.main_tests[i].test_name,
                  category: obj.main_tests[i].test_category,
                  sub_test_list: this.viewTestArray,
                  //testvalue:this. obj.main_tests[i].subtests[j].sub_test_name,
                });
                console.log("this.this.printArraythis.printArraythis.printArray" + JSON.stringify(this.printArray))


                if (obj.main_tests[i].image != undefined) {
                  this.dicomDotor = false;
                  this.reportDetails = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                  this.reportIMG = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                }

                if (obj.main_tests[i].observation != undefined) {
                  this.sequence = obj.main_tests[i].observation;
                }

                if (obj.main_tests[i].impression != undefined) {
                  this.Impression = obj.main_tests[i].impression;
                }

                if (obj.main_tests[i].culture != undefined) {
                  this.culture = obj.main_tests[i].culture;
                }
              }
            }
            console.log("PRINT TEM" + this.template_flag)
            this.printMod("header");
          } else {
            this.toastr.error(Message_data.network);
          }
        },
        error => {
          this.toastr.error(Message_data.network);
        }
      )

  }

  getRetrieval2(diagAppID, pres_tests) {//Get report details
    this.diagTestReadingArray = [];

    this.pathology_flag = false;
    this.microbiology_flag = true;
    this.radiology_flag = true;
    this.diag_report_type = "pathology"
    this.consultant = "Consultant Pathologist";

    this.documentviewflag = true;
    var type = "pathology"

    if (type == "pathology") {
      this.tableReportTypeFlag = false;
      this.paraReportTypeFlag = true;
    } else {
      this.tableReportTypeFlag = true;
      this.paraReportTypeFlag = false;
      this.cultureFlag = this.reportType == "Culture" ? true : false;
    }

    // this.report_diag_id = diag_appointment_id;
    // this.report_test_id = test_id;
    this.relation = this.personaldata.rel_id;
    this.report_type = type;
    // this.Get_Report_Detailview("normal", testtype); // view report view
    this.clientflag = true;
    this.docflag = false;
    this.diagflag = false;
    this.report_detail_flag = true;
    this.report_detail_dig_flag = false;
    this.report_detail_pres_flag = true;
    this.documentView_flag = false;

    // alert("//Get2 report details")
    this.grouping_flag = true;
    this.loader = false;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'record/drvg',
      {
        flag: "diagnosis",
        country: ipaddress.country_code,
        diag_app_id: diagAppID,
        type: type,
        pres_tests: pres_tests,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          console.log("send data = " + JSON.stringify({
            flag: "diagnosis",
            country: ipaddress.country_code,
            diag_app_id: diagAppID,
            type: "test",
            pres_tests: pres_tests,
          }))
          console.log("this.reportType", this.reportType)
          console.log("getRetrieval----------obj" + JSON.stringify(obj))
          console.log("this.pres_tests" + JSON.stringify(this.pres_tests))
          if (obj != null) {
            this.centerName = obj.diag_centre_name;
            this.diagAddress = obj.address2 != undefined && obj.address2 != "" ? obj.address1 + " " + obj.address2 : obj.address1;
            // if (this.pres_tests != undefined) {
            //   if (this.pres_tests[0].sample_datetime != undefined) {
            //     this.sampleColl_dateTime = this.pres_tests[0].sample_datetime;
            //   }
            //   if (this.pres_tests[0].approve_datetime != undefined) {
            //     this.approve_dateTime = this.pres_tests[0].approve_datetime;
            //   }
            // }

            if (obj.barcode != undefined) {
              this.barcode_url = ipaddress.Ip_with_img_address + obj.barcode;
              this.barcode_flag = true;
            }

            if (obj.main_tests != undefined) {
              if (obj.main_tests[0].sample_date != undefined) {
                this.sampledate = Date_Formate(obj.main_tests[0].sample_date)
              }
              this.sampletime = obj.main_tests[0].sample_time
              this.oraganismIso1 = obj.main_tests[0].org_iso_1_desc;
              this.oraganismIso2 = obj.main_tests[0].org_iso_2_desc;
              this.oraganismIso3 = obj.main_tests[0].org_iso_3_desc;
              if (obj.main_tests[0].culture_report_type_desc != undefined) {
                this.culture_report_type_desc = obj.main_tests[0].culture_report_type_desc;
              }
              if (obj.main_tests[0].outsource_id != undefined) {
                this.outsource_id = obj.main_tests[0].outsource_id;
                this.imageflag_outsource_id = false;
                // this.uploadidproof = this.santizer.bypassSecurityTrustResourceUrl(ipaddress.Ip_with_img_address + obj.main_tests[0].image_path);

                //this.uploadidproof = ipaddress.Ip_with_img_address + obj.main_tests[0].image_path;
                // console.log("idkjbjklhh" + JSON.stringify(this.uploadidproof))
              }
              if (obj.main_tests[0].gram_stain_desc != undefined) {
                this.gram_stain_desc = obj.main_tests[0].gram_stain_desc;
              }
              if (obj.main_tests[0].culture_report_desc != undefined) {
                this.culture_report_desc = obj.main_tests[0].culture_report_desc;
              }
              if (obj.main_tests[0].technical_note != undefined) {
                this.technical_note = obj.main_tests[0].technical_note;
              }
              if (obj.main_tests[0].comment != undefined) {
                this.comment = obj.main_tests[0].comment;
              }
              if (obj.ot_sterile != undefined) {
                this.ot_sterile = obj.ot_sterile;
                console.log(JSON.stringify(this.ot_sterile))
                this.ot_listFlag = true;
              } else {
                this.ot_listFlag = false;
              }

              if (obj.main_tests[0].ot_sterile_key == '1') {
                this.ot_Sterile_flag = false;
              } else {
                this.ot_Sterile_flag = true;
              }

              if (obj.main_tests[0].ot_sterile_key != undefined) {
                this.micro_temp = false;
              } else {
                this.micro_temp = true;
              }

              if (obj.main_tests[0].template != undefined) {
                this.template_flag = true;
                this.template = obj.main_tests[0].template;
                console.log("this.template_flag", this.template_flag, this.template)
              } else {
                this.template_flag = false;
                this.template = "";
                if (this.microbiology_flag == true) {
                  this.value_flag = true;
                } else {
                  this.value_flag = false;
                }
              }
            }

            // New template variables 
            if (obj.main_tests != undefined) {

              if (obj.main_tests[0].organism_isolation_1_code != undefined && obj.main_tests[0].organism_isolation_1_desc != undefined) {
                this.org_iso1_flag = true;
                this.org_iso_1_code = obj.main_tests[0].organism_isolation_1_code;
                this.org_iso_1_desc = obj.main_tests[0].organism_isolation_1_desc;
                this.org_iso_1_cc_desc = obj.main_tests[0].org_iso1_colonycount_desc;
                this.org_iso1_cr_desc = obj.main_tests[0].org_iso1_cr;
                this.org_iso1_growth_type_desc = obj.main_tests[0].org_iso1_growth_type_desc;
                this.org_iso1_antibiotics = obj.org_iso1_antibiotics;
              }

              if (obj.main_tests[0].organism_isolation_2_code != undefined && obj.main_tests[0].organism_isolation_2_desc != undefined) {
                this.org_iso2_flag = true;
                this.org_iso_2_code = obj.main_tests[0].organism_isolation_2_code;
                this.org_iso_2_desc = obj.main_tests[0].organism_isolation_2_desc;
                this.org_iso_2_cc_desc = obj.main_tests[0].org_iso2_colonycount_desc;
                this.org_iso2_cr_desc = obj.main_tests[0].org_iso2_cr;
                this.org_iso2_growth_type_desc = obj.main_tests[0].org_iso2_growth_type_desc;
                this.org_iso2_antibiotics = obj.org_iso2_antibiotics;
              }

              if (obj.main_tests[0].organism_isolation_3_code != undefined && obj.main_tests[0].organism_isolation_3_desc != undefined) {
                this.org_iso3_flag = true;

                this.org_iso_3_code = obj.main_tests[0].organism_isolation_3_code;
                this.org_iso_3_desc = obj.main_tests[0].organism_isolation_3_desc;
                this.org_iso_3_cc_desc = obj.main_tests[0].org_iso3_colonycount_desc;
                this.org_iso3_cr_desc = obj.main_tests[0].org_iso3_cr;
                this.org_iso3_growth_type_desc = obj.main_tests[0].org_iso3_growth_type_desc;
                this.org_iso3_antibiotics = obj.org_iso3_antibiotics;
              }
            }


            if (obj.date_entered != undefined)
              this.date = Date_Formate(obj.date_entered);
            if (obj.sample_id != undefined)
              this.sampleidvalue = obj.sample_id;
            if (obj.sample_type != undefined)
              this.sampletype = obj.sample_type;
            if (obj.doctorname != undefined)
              this.doctorname = obj.doctorname;
            if (obj.entry_date != undefined)
              this.entry_date = Date_Formate(obj.entry_date);
            if (obj.entry_time != undefined)
              this.entry_time = (obj.entry_time);

            if (obj.time_entered != undefined)
              this.time = Time_Formate(obj.time_entered);

            if (obj.colony_count != undefined) {
              this.colonyCountArray = obj.colony_count
            }

            this.diagLocation = obj.location;
            this.diagCityZip = obj.city + "-" + obj.zipcode;
            this.diagStateCon = obj.state + "-" + obj.country;
            this.diagTelephone = obj.telephone;
            this.clientID = obj.client_reg_id;
            this.patientID = obj.patient_id;

            // if (obj.pat_type != undefined) {
            //    this.patient_type = this.capitalizeFirstLetter(obj.pat_type);
            // }

            if (obj.sample_id != undefined) {
              this.sample_id = obj.sample_id;
            }

            if (obj.sample_type != undefined) {
              this.sample_type = obj.sample_type;
            }

            if (obj.entry_date != undefined) {
              this.entry_date = Date_Formate(obj.entry_date);
            }
            if (obj.entry_time != undefined) {
              this.entry_time = Time_Formate(obj.entry_time);
            }

            if (obj.app_first_name != undefined && obj.app_first_name != "-" && obj.app_last_name != undefined && obj.app_last_name != "-") {
              this.approver_name = obj.app_first_name + ' ' + obj.app_last_name;
              this.showConsultant = true;
            } else if (obj.app_first_name != undefined && obj.app_first_name != "-") {
              this.approver_name = obj.app_first_name;
              this.showConsultant = true;
            }

            if (obj.qual_first_name != undefined && obj.qual_last_name != undefined) {
              this.qualityHead_name = obj.qual_first_name + ' ' + obj.qual_last_name;
              this.showQualhead = true;
            } else if (obj.qual_first_name != undefined) {
              this.qualityHead_name = obj.qual_first_name;
              this.showQualhead = true;
            }

            if (obj.diag_appointment_id != undefined) {
              this.visit_id = obj.diag_appointment_id;
            } else {
              this.visit_id = obj.diag_appointment_id;
            }

            if (obj.salutation_desc != undefined) {
              this.salutation = obj.salutation_desc
            }

            // if(obj.dr_first_mame != undefined && obj.dr_middle_mame != undefined && obj.dr_last_mame)

            if (obj.first_name != null) {
              if (obj.middle_name != null && encrypt_decript.Decript(obj.middle_name).toString() != "")
                this.clientAnotherName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
              else
                this.clientAnotherName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            }

            if (obj.referred_by != undefined && obj.referred_by != "" && obj.referred_by != null && obj.referred_by != "null") {
              this.referFlag = true;
              this.diagReferedBy = obj.referred_by;
            }
            if (obj.dr_first_name != null) {
              if (obj.dr_middle_name != null && obj.dr_middle_name != "")
                this.doctorname = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
              else
                this.doctorname = "Dr." + obj.dr_first_name + " " + obj.dr_last_name;
            }


            if (obj.client_address2 != undefined && obj.client_address1 != undefined) {
              // alert(encrypt_decript.Decript(obj.client_address1))
              this.address1 = encrypt_decript.Decript(obj.client_address1) + ' ' + encrypt_decript.Decript(obj.client_address2);

            } else if (obj.client_address1 != undefined) {
              this.address1 = encrypt_decript.Decript(obj.client_address1)
            }

            if (obj.mobile != undefined) {
              this.mobile = encrypt_decript.Decript(obj.mobile);
            }

            if (obj.client_location != undefined) {
              this.clntlocation = (obj.client_location);
            }
            if (obj.client_state
              != undefined) {
              this.clntstate = (obj.client_state
              );
            }
            if (obj.client_country != undefined) {
              this.clntcountry = (obj.client_country
              );
            }
            if (obj.client_district != undefined) {
              this.clntdistrict = (obj.client_district);
            }
            if (obj.client_zipcode != null)
              this.clntzipcode = encrypt_decript.Decript(obj.client_zipcode);
            if (obj.gender != null)
              this.clientGender = encrypt_decript.Decript(obj.gender);

            if (obj.age != null)
              this.clientAge = obj.age == 0 ? "<1" : obj.age;
            else
              this.clientAge = "";

            if (obj.sign != undefined && obj.sign != "") {
              this.reviewSign = "data:image/png;base64," + obj.sign;
              this.signFlag = false;
            } else {
              this.signFlag = true;
            }

            if (obj.approv_sign != undefined) {
              this.approver_sign = "data:image/png;base64," + obj.approv_sign;
            }

            if (obj.tech_sign != undefined) {
              this.lab_tech_sign = "data:image/png;base64," + obj.tech_sign;
            }

            if (obj.quality_sign != undefined) {
              this.quality_head_sign = "data:image/png;base64," + obj.quality_sign;
            }

            if (obj.sign == undefined && obj.sign == "" || obj.app_middle_name == null)
              this.sigSectionFlag = true;

            this.reviewFlag = obj.app_first_name != null ? false : true;
            this.reviewBy = obj.app_middle_name != null ? obj.app_first_name + " " + obj.app_middle_name + " " + obj.app_last_name : obj.app_first_name + " " + obj.app_last_name;

            if (obj.time_collected != undefined)
              this.sampleCollectedTime = Time_Formate(obj.time_collected);

            if (obj.main_tests != null && obj.main_tests.length != 0) {
              this.printArray = [];
              if (obj.main_tests[0].image != null) {
                this.reportDetails = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                this.reportIMG = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                this.dicomDotor = false;
              }
              this.viewTestArray = [];

              for (var i = 0; i < obj.main_tests.length; i++) {
                this.presTestID = obj.main_tests[i].pres_test_id;
                this.sequences = obj.main_tests[i].sequences;
                this.impression = obj.main_tests[i].impression;

                if (obj.main_tests[i].created_by != undefined) {
                  this.createdBy = obj.main_tests[i].created_by;
                  this.showTechnician = true;
                }

                if (obj.main_tests[i].test_method != undefined && obj.main_tests[i].test_method != null
                  && obj.main_tests[i].test_method != "" && obj.main_tests[i].test_method != "null")
                  this.testMethod = obj.main_tests[i].test_method;

                var test_name;
                test_name = obj.main_tests[i].test_name;
                if (obj.main_tests[i].subtests != null) {
                  this.viewTestArray = [];
                  for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                    var temp_value = "";
                    var test_method = "";
                    this.subtestMethodFlag = false;
                    this.suntestNameFlag = obj.main_tests[i].subtests[j].sub_test_name == test_name ? false : true;

                    if (this.testrangeFlag == true) {
                      if (this.clientGender == "Male" && this.clientAge < 1) {
                        temp_value = obj.main_tests[i].subtests[j].newborn;
                      } else if (this.clientGender == "Male" && (this.clientAge > 1 && this.clientAge < 13)) {
                        temp_value = obj.main_tests[i].subtests[j].child;
                      } else if (this.clientGender == "Male" && this.clientAge > 13) {
                        temp_value = obj.main_tests[i].subtests[j].male;
                      } else if (this.clientGender == "Female" && this.clientAge > 13) {
                        temp_value = obj.main_tests[i].subtests[j].female;
                      }

                    } else {
                      if (obj.main_tests[i].subtests[j].range != undefined)
                        temp_value = obj.main_tests[i].subtests[j].range;
                    }

                    if (obj.main_tests[i].subtests[j].test_method != undefined) {
                      test_method = obj.main_tests[i].subtests[j].test_method;
                      this.subtestMethodFlag = true;
                    } else {
                      this.subtestMethodFlag = false;
                    }

                    var isReadingNormal = false;
                    var isReadingAbnormal = false;
                    var isReadingCritical = false;
                    if (obj.main_tests[i].subtests[j].reading != undefined && obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].reference[0]).length != 0) {
                      var min_nor_value = obj.main_tests[i].subtests[j].reference[0].min_nor_value;
                      var max_nor_value = obj.main_tests[i].subtests[j].reference[0].max_nor_value;
                      var min_abnor_value = obj.main_tests[i].subtests[j].reference[0].min_abnor_value;
                      var max_abnor_value = obj.main_tests[i].subtests[j].reference[0].max_abnor_value;
                      var min_crit_value = obj.main_tests[i].subtests[j].reference[0].min_crit_value;
                      var max_crit_value = obj.main_tests[i].subtests[j].reference[0].max_crit_value;

                      if (((min_nor_value != undefined || min_nor_value != "") && (max_nor_value != undefined || max_nor_value != "")) && ((min_abnor_value == undefined || min_abnor_value == "") && (max_abnor_value == undefined || max_abnor_value == "")) && ((min_crit_value == undefined || min_crit_value == "") && (max_crit_value == undefined || max_crit_value == ""))) {
                        // alert(obj.main_tests[i].subtests[j].reference.length + "  " +  obj.main_tests[i].subtests[j].sub_test_name)
                        if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                          isReadingNormal = true;
                        } else {
                          isReadingAbnormal = true;
                        }
                      } else {

                        if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                          isReadingNormal = true;
                        } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                          isReadingAbnormal = true;
                        } else {
                          isReadingCritical = true;
                        }
                      }
                    }

                    console.log(isReadingNormal, isReadingAbnormal, isReadingCritical)

                    var printReading = "-", font_Color = '#000000';
                    if (obj.main_tests[i].subtests[j].reading != undefined) {
                      if (obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].reference[0]).length != 0) {
                        if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                          printReading = obj.main_tests[i].subtests[j].reading;
                          font_Color = '#00FF00';
                        } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                          printReading = obj.main_tests[i].subtests[j].reading;
                          font_Color = '#FFA500';
                        } else {
                          printReading = obj.main_tests[i].subtests[j].reading;
                          font_Color = '#FF0000';
                        }
                      } else {
                        printReading = obj.main_tests[i].subtests[j].reading;
                      }
                    } else {
                      printReading = "-"
                    }

                    var test_range;
                    if (obj.main_tests[i].subtests[j].reference != undefined) {
                      if (obj.main_tests[i].subtests[j].reference.length != 0) {

                        if (obj.main_tests[i].subtests[j].reference[0].range != undefined && Object.keys(obj.main_tests[i].subtests[j].reference[0]).length != 0) {
                          test_range = obj.main_tests[i].subtests[j].reference[0].range
                        } else {
                          test_range = ''
                        }
                      }
                    } else {
                      test_range = ''
                    }

                    var testReading, egfr_value;
                    if (obj.main_tests[i].subtests[j].reading != undefined) {
                      testReading = obj.main_tests[i].subtests[j].reading;

                      if (obj.main_tests[i].subtests[j].sub_test_name.toLowerCase() == "creatinine") {
                        this.egfr_flag = true;
                        egfr_value = this.serumCreatChange(testReading);
                        // alert(egfr_value + "   " + Number.isNaN(egfr_value))
                        if (Number.isNaN(egfr_value) == true) {
                          egfr_value = "-"
                        }
                      } else {
                        this.egfr_flag = false;
                      }
                    } else {
                      testReading = '-'
                      this.egfr_flag = false;
                    }

                    var subtests = undefined;
                    if (obj.main_tests[i].subtests[j].tests != undefined) {
                      subtests = [];
                      for (var l = 0; l < obj.main_tests[i].subtests[j].tests.length; l++) {
                        var sub_reading = '-'
                        if (obj.main_tests[i].subtests[j].tests[l].reading != undefined) {
                          sub_reading = obj.main_tests[i].subtests[j].tests[l].reading
                        }
                        var isReadingNormal_sub = false;
                        var isReadingAbnormal_sub = false;
                        var isReadingCritical_sub = false;
                        var reference = [];
                        if (obj.main_tests[i].subtests[j].tests[l].reading != undefined && obj.main_tests[i].subtests[j].tests[l].reference != undefined && obj.main_tests[i].subtests[j].tests[l].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].tests[l].reference[0]).length != 0) {
                          var min_nor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value;
                          var max_nor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value;
                          var min_abnor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_abnor_value;
                          var max_abnor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_abnor_value;
                          var min_crit_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_crit_value;
                          var max_crit_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_crit_value;
                          reference = obj.main_tests[i].subtests[j].tests[l].reference;


                          if (((min_nor_value_sub != undefined || min_nor_value_sub != "") && (max_nor_value_sub != undefined || max_nor_value_sub != "")) && ((min_abnor_value_sub == undefined || min_abnor_value_sub == "") && (max_abnor_value_sub == undefined || max_abnor_value_sub == "")) && ((min_crit_value_sub == undefined || min_crit_value_sub == "") && (max_crit_value_sub == undefined || max_crit_value_sub == ""))) {
                            // alert(obj.main_tests[i].subtests[j].reference.length + "  " +  obj.main_tests[i].subtests[j].sub_test_name)
                            if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value)) {
                              isReadingNormal_sub = true;
                            } else {
                              isReadingAbnormal_sub = true;
                            }
                          } else {
                            if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value)) {
                              isReadingNormal_sub = true;
                            } else if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_abnor_value)) {
                              isReadingAbnormal_sub = true;
                            } else {
                              isReadingCritical_sub = true;
                            }
                          }
                        }

                        var ref_range = '';
                        if (obj.main_tests[i].subtests[j].tests[l].range != undefined) {
                          ref_range = obj.main_tests[i].subtests[j].tests[l].range;
                        }

                        var test_order = '';
                        if (obj.main_tests[i].subtests[j].tests[l].test_order != undefined) {
                          test_order = obj.main_tests[i].subtests[j].tests[l].test_order;
                        }

                        var test_method = '';
                        if (obj.main_tests[i].subtests[j].tests[l].test_method != undefined) {
                          test_method = obj.main_tests[i].subtests[j].tests[l].test_method;
                        }

                        subtests.push({
                          sub_test_list_id: obj.main_tests[i].subtests[j].tests[l].sub_test_list_id,
                          sub_test_name: obj.main_tests[i].subtests[j].tests[l].sub_test_name,
                          reading: sub_reading,
                          unit: obj.main_tests[i].subtests[j].tests[l].unit,
                          sub_uom: obj.main_tests[i].subtests[j].tests[l].sub_uom,
                          test_method: test_method,
                          diag_tests_list_id: obj.main_tests[i].subtests[j].tests[l].diag_tests_list_id,
                          test_order: test_order,
                          range: ref_range,
                          reference: reference,
                          is_Reading_normal: isReadingNormal_sub,
                          is_Reading_Abnormal: isReadingAbnormal_sub,
                          is_Reading_Critical: isReadingCritical_sub,
                        })
                      }
                    }

                    this.viewTestArray.push({
                      test_name: obj.main_tests[i].test_name,
                      sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,

                      reading: obj.main_tests[i].subtests[j].reading,
                      unit: obj.main_tests[i].subtests[j].sub_uom,
                      test_order: obj.main_tests[i].subtests[j].test_order,
                      test_range: test_range,
                      test_method: obj.main_tests[i].subtests[j].test_method,
                      is_Reading_normal: isReadingNormal,
                      is_Reading_Abnormal: isReadingAbnormal,
                      is_Reading_Critical: isReadingCritical,
                      tests: subtests,
                      egfr_value: egfr_value,

                      egfr_flag: this.egfr_flag,
                      print_reading: printReading,
                      fontColor: font_Color,
                      fontWeight: '500',
                    });
                    console.log("this.viewTestArray", this.viewTestArray)
                  }
                }

                this.diagTestReadingArray.push({
                  test_name: obj.main_tests[i].test_name,
                  category: obj.main_tests[i].test_category,
                  test_order: obj.main_tests[i].test_order,
                  sub_test_list: this.viewTestArray,
                  template: obj.main_tests.template
                });

                //printoption
                this.printArray.push({
                  test_name: obj.main_tests[i].test_name,
                  category: obj.main_tests[i].test_category,
                  sub_test_list: this.viewTestArray
                });



                if (obj.main_tests[i].image != null) {
                  this.dicomDotor = false;
                  this.reportDetails = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                  this.reportIMG = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                }

                if (obj.main_tests[i].observation != undefined) {
                  this.sequence = obj.main_tests[i].observation;
                }

                if (obj.main_tests[i].impression != undefined) {
                  this.Impression = obj.main_tests[i].impression;
                }

                if (obj.main_tests[i].culture != undefined) {
                  this.culture = obj.main_tests[i].culture;
                }
              }

              console.log("this.printArray", JSON.stringify(this.printArray));

              const groupedByCategory = this.groupBy(this.diagTestReadingArray, 'category');
              console.log(groupedByCategory);
              var viewtestarray2 = [];
              var diagTestReadingArray2 = [];

              var new_viewtestarray = [];

              for (var key in groupedByCategory) {
                // console.log(key + ': ' + JSON.stringify(groupedByCategory[key]));
                new_viewtestarray = [];
                for (var k = 0; k < groupedByCategory[key].length; k++) {
                  var subTest_list = groupedByCategory[key][k].sub_test_list;
                  var test_order_list = groupedByCategory[key][k].test_order;
                  var maintest_Name = "";
                  if (subTest_list.length > 1) {
                    maintest_Name = groupedByCategory[key][k].test_name;
                  }
                  console.log("subTest_list", subTest_list)
                  viewtestarray2 = [];
                  for (var l = 0; l < subTest_list.length; l++) {
                    viewtestarray2.push({
                      test_name: subTest_list[l].test_name,
                      sub_test_name: subTest_list[l].sub_test_name,
                      reading: subTest_list[l].reading,
                      unit: subTest_list[l].unit,
                      test_range: subTest_list[l].test_range,
                      test_method: subTest_list[l].test_method,
                      is_Reading_normal: subTest_list[l].is_Reading_normal,
                      is_Reading_Abnormal: subTest_list[l].is_Reading_Abnormal,
                      is_Reading_Critical: subTest_list[l].is_Reading_Critical,
                      tests: subTest_list[l].tests,
                      egfr_value: subTest_list[l].egfr_value,
                      egfr_flag: subTest_list[l].egfr_flag,
                      print_reading: subTest_list[l].print_reading,
                      fontColor: subTest_list[l].fontColor,
                      fontWeight: subTest_list[l].fontWeight,
                    })
                  }
                  new_viewtestarray.push({
                    test_name: maintest_Name,
                    test_order: test_order_list,
                    sub_test_list: viewtestarray2,
                  })
                }
                diagTestReadingArray2.push({
                  category: key,
                  sub_test_list: viewtestarray2,
                  test_name: maintest_Name,
                  tests: new_viewtestarray.sort((a, b) => a.test_order - b.test_order),
                })
              }

              this.diagTestReadingArray = [];
              this.printArray = [];
              this.diagTestReadingArray = diagTestReadingArray2;
              this.printArray = diagTestReadingArray2;

              // console.log("viewtestarray2 ", viewtestarray2);
              console.log("diagTestReadingArray2", diagTestReadingArray2);
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  Document_click() {
    const dialogRef = this.dialog.open(DocumentDetailsZoomComponent, {
      width: '900px',
      data: { report_pdf: this.report_detail }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  savesend_data() {
    if (this.report_detail_flag == true) {
      if ((document.getElementById("theimage")) != null) {
        var img_str = (document.getElementById("theimage") as HTMLImageElement).src;
      }

      var img_path = "";
      if (img_str != null) {
        img_path = (img_str.toString()).split(',')[1];
      }
      else {
        img_path = "";
      }

      //upload document
      if (img_path != "") {
        var send_data;
        if (this.personaldata.sub_id != "" && this.personaldata.sub_id.length != 0) {
          send_data = {
            image: (img_path),
            client_reg_id: this.personaldata.Client_id,
            relation_id: this.personaldata.rel_id,
            sub_rel_id: this.personaldata.sub_id,
            country: ipaddress.country_code,
            image_type: "PNG",
            scan_test: this.report_type,
            pres_test_id: this.report_test_id,
            document: this.base64data,
          }
        }
        else {
          send_data = {
            image: (img_path),
            client_reg_id: this.personaldata.Client_id,
            relation_id: this.personaldata.rel_id,
            country: ipaddress.country_code,
            image_type: "PNG",
            scan_test: this.report_type,
            pres_test_id: this.report_test_id,
            document: this.base64data,
          }
        }



        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        this.http.post(ipaddress.getIp + 'prescription/udr', (send_data), { headers: headers }).subscribe(
          response => {

            if (response["key"] != 0) {
              this.toastr.success(Message_data.imgSavedSuccess);
            } else {
              this.toastr.error(Message_data.errOccur);
            }
          })
      } else {
        var send_data;
        if (this.personaldata.sub_id != "" && this.personaldata.sub_id.length != 0) {
          send_data = {
            image: (img_path),
            client_reg_id: this.personaldata.Client_id,
            relation_id: this.personaldata.rel_id,
            sub_rel_id: this.personaldata.sub_id,
            country: ipaddress.country_code,
            image_type: "PNG",
            scan_test: this.report_type,
            pres_test_id: this.report_test_id,
            document: this.base64data,
          }
        }
        else {
          send_data = {
            image: (img_path),
            client_reg_id: this.personaldata.Client_id,
            relation_id: this.personaldata.rel_id,
            country: ipaddress.country_code,
            image_type: "PNG",
            scan_test: this.report_type,
            pres_test_id: this.report_test_id,
          }
        }



        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        this.http.post(ipaddress.getIp + 'prescription/udr', (send_data), { headers: headers }).subscribe(
          response => {

            if (response["key"] != 0) {
              this.toastr.success(Message_data.imgSavedSuccess);
            } else {
              this.toastr.error(Message_data.errOccur);
            }
          })
      }
    }

  }

  uploadDocument() {
    const dialogRef = this.dialog.open(UploadReportComponent, {
      width: '700px',
      height: '220px',
      data: {
        client_reg_id: this.personaldata.Client_id,
        relation_id: this.personaldata.rel_id,
        sub_rel_id: this.personaldata.sub_id,
        spl_id: this.personaldata.spl_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("result" + JSON.stringify(result))

      var send_data;
      if (this.personaldata.sub_id.trim == null || this.personaldata.sub_id == "" || this.personaldata.sub_id.trim == undefined) {
        send_data = {
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,
          spl_id: this.personaldata.spl_id,
        }
      } else {
        send_data = {
          client_reg_id: this.personaldata.Client_id,
          relation_id: this.personaldata.rel_id,
          sub_relation_id: this.personaldata.sub_id,
          spl_id: this.personaldata.spl_id,
        }
      }
      this.retrieveUploadDocumentList(send_data);
    });
  }

  retrieveUploadDocumentList(getData) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(
      ipaddress.getIp + "gen/cgdoc", (getData), { headers: headers }).subscribe(
        data => {
          if (data != undefined) {
            console.log("check ****** " + JSON.stringify(data))
            var response = JSON.parse(JSON.stringify(data));
            if (response.doc_details.length != 0) {
              var obj = response.doc_details;
              this.documentBlood = false;
              this.documentUrine = false;
              this.documentFaeces = false;
              this.documentXray = false;
              this.documentmicro = false;
              this.documentScan = false;
              this.documentUltrasound = false;
              for (var i = 0; i < obj.length; i++) {
                var doc_type = "";
                if (obj[i].doc_type_name) {
                  doc_type = obj[i].doc_type_name;
                  if (doc_type == "Blood report") {
                    this.documentBlood = true;
                  }
                  if (doc_type == "Urine report") {
                    this.documentUrine = true;
                  }
                  if (doc_type == "Stool report") {
                    this.documentFaeces = true;
                  }
                  if (doc_type == "X-ray report") {
                    // this.documentXray = true;
                    this.documentScan = true;
                  }
                  if (doc_type == "Scan report") {
                    this.documentScan = true;
                  }
                  if (doc_type == "Micro report") {
                    this.documentmicro = true;
                  }
                  if (doc_type == "Ulta-sound report") {
                    this.documentUltrasound = true;
                  }
                }

                var document_name = "";
                if (obj[i].document_name != undefined) {
                  document_name = obj[i].document_name;
                }

                var document_date = "";
                if (obj[i].document_date != undefined) {
                  document_date = Date_Formate(obj[i].document_date);
                }

                var document: any = "";
                if (obj[i].document != undefined) {
                  document = this.sanitizer.bypassSecurityTrustResourceUrl(ipaddress.Ip_with_img_address + obj[i].document);
                }

                var middle_name = "";
                if (obj[i].middle_name != undefined) {
                  middle_name = encrypt_decript.Decript(obj[i].middle_name);
                }

                this.documentList.push({
                  client_doc_id: obj[i].client_doc_id,
                  relation_id: obj[i].relation_id,
                  relationship_name: obj[i].relationship_name,
                  document_type: obj[i].document_type,
                  type: obj[i].doc_type_name,
                  document_name: document_name,
                  document_date: document_date,
                  document: document,
                  spl_id: obj[i].spl_id,
                  spl_name: obj[i].spl_name,
                  first_name: encrypt_decript.Decript(obj[i].first_name),
                  last_name: encrypt_decript.Decript(obj[i].last_name),
                  middle_name: middle_name,
                });
              }
            }
            console.log("check *****documentList* " + JSON.stringify(this.documentList))
          }
        });
  }

  document_view(document) {
    this.documentviewflag = false;
    // this.paraReportTypeFlag = false;
    // this.tableReportTypeFlag=false;
    // this.sample
    this.relation = this.personaldata.rel_id;
    this.clientflag = true;
    this.docflag = false;
    this.diagflag = false;
    this.report_detail_flag = true;
    this.report_detail_dig_flag = true;
    this.report_detail_pres_flag = true;
    this.documentView_flag = true;
    this.documentDetailedView(document);
  }

  documentDetailedView(document) {
    this.document = "";
    if (document.length != 0) {
      this.document = document.document;
      if (document.middle_name != "") {
        this.client_another_name = document.first_name + " " + document.middle_name + " " + document.last_name;
      } else {
        this.client_another_name = document.first_name + " " + document.last_name;
      }
      this.clent_id = this.personaldata.Client_id;
      this.client_age = this.personaldata.Age_data;
      this.client_gender = this.personaldata.Gender_data;
      this.date_txt = document.document_date;
    }
  }
  clickDocument() {
  }
  print() {
    var footerimg = this.hospitalFooter;
    var footerflag = this.printfFlag;
    var display;

    display = footerflag == true ? "display:none;" : "display:block;";
    var backimg = this.bgImage;
    let printContents, popupWin;
    if (this.printTemplate != undefined && this.printTemplate == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else if (this.printTemplate != undefined && this.printTemplate == "logowithname") {
      printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else if (this.printTemplate != undefined && this.printTemplate == "printnoheadsidebar") {
      printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      // var printContents1 = this.printnoheader.nativeElement.innerHTML;
    }

    // this.convertToImage();

    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
      <head>
        <title>Report</title>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
        <link rel="stylesheet" media="screen" href="">
        <style>
        @page { size: auto;  margin: 0mm; }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        * {
          font-size: 11px !important; 
        }
        .casesheet_container {
          width: 211px;
          padding: 1px;
          background: #277196;
          display: inline-flex;
          position: relative;
          padding-left: 5px;
          top: 21px;
          color: #FFFFFF;
        }
        .border_style{
          border:solid 1px;
          border-color: #488aff;
        }
        .width_style{
          margin-top:4px;
          width:600px;
        }
        @media all {
          .page-break { display: none; }
          }
          
          @media print {
          .page-break { display: block; page-break-before: always; }
          }
        @media print {
          body{
            -webkit-print-color-adjust: exact;
            -moz-print-color-adjust: exact;
            -ms-print-color-adjust: exact;
            print-color-adjust: exact;
          }
          footer {
            position: fixed;
            bottom: 0;
          }
        }
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}" >${printContents}</body>
      
    </footer>
    </html>`
    );
    popupWin.document.close();
  }

  printMod(e) {
    if (e == "header") {
      this.printTemplate = "banner"
    } else if (e == "noHeader") {
      this.printTemplate = "printnoheader"
    }
    console.log(this.printTemplate, e)
  }




  groupdata(data) {
    this.reportFlag = "Modify";
    var pres_tests = [];

    if (data.test_values != undefined) {
      if (data.test_values.every(element => element.isSelected == false)) {
        for (var j = 0; j < data.test_values.length; j++) {
          pres_tests.push({
            pres_test_id: data.test_values[j].pres_test_id,
            test_type: data.test_values[j].test_type,
            pres_diag_id: data.test_values[j].pres_diag_id,
            test_name: data.test_values[j].test_name,
            sample_datetime: data.test_values[j].sample_collect_date_time,
            approve_datetime: data.test_values[j].approvedDate
          })
        }
      } else {
        for (var j = 0; j < data.test_values.length; j++) {
          if (data.test_values[j].isSelected == true) {
            pres_tests.push({
              pres_test_id: data.test_values[j].pres_test_id,
              test_type: data.test_values[j].test_type,
              pres_diag_id: data.test_values[j].pres_diag_id,
              test_name: data.test_values[j].test_name,
              sample_datetime: data.test_values[j].sample_collect_date_time,
              approve_datetime: data.test_values[j].approvedDate
            })
          }
        }
      }
    }

    var report = {
      AppFlow: "reportview",
      diag_app_id: data.diag_app_id,
      report_diag_id: data.diag_app_id,
      Diag_report_flag: this.reportFlag,
      pres_tests: pres_tests,
      test_flag: "group"
    };

    console.log("data--------", data)
  }

  groupBy(array, key) {
    return array.reduce((result, obj) => {
      const keyValue = obj[key];
      // If the key doesn't exist in the result, create an empty array for it
      if (!result[keyValue]) {
        result[keyValue] = [];
      }
      // Push the object to the corresponding key's array
      result[keyValue].push(obj);
      return result;
    }, {});
  }

  serumCreatChange(SerumCreat) {//Serium criteria calculation for egrf
    if (SerumCreat != "") {
      if (this.clientAge != undefined && this.clientAge != null) {
        if (this.clientGender == "Male") {
          this.eGFR = 175 * (parseFloat(SerumCreat) ** -1.154) * (parseInt(this.clientAge) ** -0.203);
        } else if (this.clientGender == "Female") {
          this.eGFR = 175 * (parseFloat(SerumCreat) ** -1.154) * (parseInt(this.clientAge) ** -0.203) * 0.742;
        }
      }
      return this.eGFR.toFixed(2);
    }
    else {
      this.eGFR = undefined;
    }
  }
}
