<div class="row">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Assessment</h5>
          </div>
          <div class="headerButtons">
            <div>
              <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backbutton()" width="85px"
                style="margin-right: 9px;" />
            </div>

            <div>
              <img src="../../../assets/ui_icons/buttons/write_RX_Button.svg" (click)="writerx()" width="85px"
                style="margin-right: 9px;" />
            </div>
            <div>
              <img src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save()" width="85px"
                style="margin-right: 9px;" />
              <!-- <img src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo"
                (click)="update()" *ngIf="updateFlag"> -->
            </div>
            <!-- <div>
              <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="save()" width="85px"
                style="margin-right: 9px;" />
                </div> -->
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>

        <div class="row whole_table">
          <div class="Details" style="margin-top: -10px;">
            <div class="row">
              <div class="cover_div" style="padding-bottom: 13px;width: 98%;
    margin-left: auto;
    margin-right: auto;">
                <div class="header_lable">Personal Details</div>
                <div class="content_cover">

                  <div class="row">
                    <div class="col-2" style="text-align: center;">
                      <img src="{{this.profileImg}}" onerror="this.src='../../../assets/img/default.jpg';"
                        class="img-fluid z-depth-1 rounded-circle mr-1 profileimage">
                    </div>
                    <div class="col-10">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"> <span style="font-weight: 550;">MR no:</span> {{mrnno}}</p>

                        </div>
                        <div class="col-3">
                          <p class="textformat"> <span style="font-weight: 550;">Name:</span> {{patient_name}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> <span style="font-weight: 550;">Age/Gender:</span>
                            {{patient_age}}/{{patient_gender}}</p>

                        </div>

                        <div class="col-3">
                          <p class="textformat"> <span style="font-weight: 550;">Doctor:</span> {{doctor_name}}</p>

                        </div>
                        <div class="col-3">
                          <p class="textformat"> <span style="font-weight: 550;">Speciality:</span>
                            {{speclization_arry}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"> <span style="font-weight: 550;">Address:</span> {{clntAddress1}}</p>
                        </div>
                        <!-- <div class="col-3">
                          <p class="textformat">    Address2: {{this.clntAddress2}}</p>
                      </div> -->
                        <!-- <div class="col-3">
                          <p class="textformat"> Zipcode: {{this.clntZipcode}} </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Location: {{this.clntLocation}} </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> District / City: {{this.clntCity}} </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> State: {{this.clntState}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Country: {{this.clntCountry}}</p>
                        </div> -->
                        <!-- <div class="col-3">
                          <p class="textformat">Mobile: {{this.clntContNumber}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Emergency contact1: {{this.clntEmergNumber}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Emergency contact2: {{this.clntEmergNumber1}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Aadhar Number: {{this.aadharNumber}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Whatsapp Number: {{this.whatsappNumber}}</p>
                        </div> -->
                        <!-- <div class="col-3">
                                      <p class="textformat">   Symptoms: {{this.clntSymptom}}</p>
                                    </div> -->


                        <!-- <div class="col-3">
                          <p class="textformat"> Address: {{this.clntAddress1}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Address2: {{this.clntAddress2}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Zipcode: {{this.clntZipcode}} </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Location: {{this.clntLocation}} </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> District / City: {{this.clntCity}} </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> State: {{this.clntState}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Country: {{this.clntCountry}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Channel: {{this.ref_source}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"> Referred by: {{this.referredDoctor}} </p>
                        </div> -->
                        <!-- <div class="col-3">
                                      <p class="textformat">   Visit purpose: {{this.visit_purpose}}</p>
                                    </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin: 15px 0 15px 0;">
                <div class="row">
                  <div class="col-4">
                    <mat-label class="matlabel" style="font-weight: 600;">In-patient / Out-patient : </mat-label>
                  </div>
                  <div class="col-8">
                    <div class="switch-field">
                      <input type="radio" id="radio-one" (click)="changetype('app',2,'yes')" />
                      <label for="radio-one"
                        [ngClass]="apptTypeFlag ? 'radio_active':'radio_inactive'">Out-patient</label>
                      <input type="radio" id="radio-two" (click)="changetype('app',2,'no')" />
                      <label for="radio-two"
                        [ngClass]="!apptTypeFlag ? 'radio_active':'radio_inactive'">In-patient</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin: 15px 0 15px 0;"
                [hidden]="inpatientflag">
                <div class="row">
                  <div class="col-4">
                    <mat-label class="matlabel" style="font-weight: 600;">Normal/ICU : </mat-label>
                  </div>
                  <div class="col-8">
                    <div class="switch-field">
                      <input type="radio" id="radio-three" (click)="changetype('admission',3,'yes')" />
                      <label for="radio-three" [ngClass]="ynoptapp1 ? 'radio_active':'radio_inactive'">Normal</label>
                      <input type="radio" id="radio-four" (click)="changetype('admission',4,'no')" />
                      <label for="radio-four" [ngClass]="!ynoptapp1 ? 'radio_active':'radio_inactive'">ICU</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">

                <mat-label class="matlabel"> <b class="label_bold">Complaints<span class="required-marker">*</span></b>
                  <quill-editor #editor_chief id="editor_chief" [(ngModel)]="chief_comp" [style]="editorStyle"
                    [modules]="modules" placeholder="Enter Complaints"
                    (onContentChanged)="onContentChanged_chief_comp($event)" [styles]="{height: 'calc(35vh - 100px)'}">
                  </quill-editor>
                  <div class="auto_complete_text1" *ngIf="chiefComplaintsList.length != 0">
                    <ul *ngFor="let chiefComplaints of chiefComplaintsList">
                      <li>
                        <a (click)="select_chiefComplaints(chiefComplaints)">{{chiefComplaints}}</a>
                      </li>
                    </ul>
                  </div>
                </mat-label>
              </div>

              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">Present illness</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="pres_ill" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter Present illness"
                      (onContentChanged)="changelinemgmt($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="linemgmt" [content]="pres_ill"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">Past medical history</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="pastill" [(ngModel)]="past_illness" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter Past illness" (onContentChanged)="changelinemgmt1($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="pastill" [content]="past_illness"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">Surgical History</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="family" [(ngModel)]="surgicalvalue" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter Surgical History"
                      (onContentChanged)="changesurgical($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="family" [content]="surgicalvalue"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">Heart disease</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="family" [(ngModel)]="heartdisease" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter Heart disease"
                      (onContentChanged)="changeheartdisease($event)">
                    </quill-editor>
                    <div>
                      <quill-view-html hidden id="family" [content]="family_history"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">Family history</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="family" [(ngModel)]="family_history" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter Family history"
                      (onContentChanged)="changelinemgmt2($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="family" [content]="family_history"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12" style="margin-top: 10px;">
                <mat-label class="matlabel ">Pain Scale</mat-label>
                <div class="wrapper" style="text-align: center;">
                  <ul class="pain-scale">
                    <li *ngFor="let level of painLevels" class="pain-scale__level" style="text-align: center;"
                      (click)="handlePainScaleClick(level.number)">
                      <div>
                        <img class="pain-scale__emoji emojiimage"
                          [ngStyle]="{ 'background-color': level.selected ? '#d3d3d3' : '#fff', 'box-shadow': level.selected ? 'rgb(38, 57, 77) 0px 20px 30px -10px' : 'none' }"
                          [src]="level.emoji">
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- diabe -->
            <div class="row">
              <div class="col-12">
                <div class="row" style="margin: 15px;">
                  <div class="col-4 paddingcheck">
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel" style="font-weight: 600;">Diabetes : </mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="ynopt2 != undefined">
                          <input type="radio" id="radio-five" (click)="changeAllery('Diabetes',1,'yes')" />
                          <label for="radio-five" [ngClass]="ynopt2 ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-six" (click)="changeAllery('Diabetes',1,'no')" />
                          <label for="radio-six" [ngClass]="!ynopt2 ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                      <div class="col-4"></div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                        *ngIf="diabflag">
                        <mat-label class="matlabel">Details
                          <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="diabdetials"
                            matInput></textarea>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 paddingcheck">
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel" style="font-weight: 600;">Hypertension : </mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="ynopt3 != undefined">
                          <input type="radio" id="radio-seven" (click)="changeAllery('hyper',1,'yes')" />
                          <label for="radio-seven" [ngClass]="ynopt3 ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-eight" (click)="changeAllery('hyper',1,'no')" />
                          <label for="radio-eight" [ngClass]="!ynopt3 ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                      <div class="col-4">

                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                        *ngIf="hyperflag">
                        <mat-label class="matlabel">Details
                          <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="hype_details"
                            matInput></textarea>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 paddingcheck">
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel" style="font-weight: 600;">CKD : </mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="ynopt12 != undefined">
                          <input type="radio" id="radio-nine" (click)="changeAllery('CKD',1,'yes')" />
                          <label for="radio-nine" [ngClass]="ynopt12 ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-ten" (click)="changeAllery('CKD',1,'no')" />
                          <label for="radio-ten" [ngClass]="!ynopt12 ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>

                      <div class="col-4">

                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                        *ngIf="ckdflag">
                        <mat-label class="matlabel">Details
                          <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="ckddetails"
                            matInput></textarea>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 paddingcheck">
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel" style="font-weight: 600;">CAD : </mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="ynopt4 != undefined">
                          <input type="radio" id="radio-eleven" (click)="changeAllery('cad',1,'yes')" />
                          <label for="radio-eleven" [ngClass]="ynopt4 ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-tweleve" (click)="changeAllery('cad',1,'no')" />
                          <label for="radio-tweleve" [ngClass]="!ynopt4 ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                      <div class="col-4">

                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                        *ngIf="cadflag">
                        <mat-label class="matlabel">Details
                          <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="caddetails"
                            matInput></textarea>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 paddingcheck">
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel" style="font-weight: 600;">CVD : </mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="ynopt5 != undefined">
                          <input type="radio" id="radio-thirteen" (click)="changeAllery('cvd',1,'yes')" />
                          <label for="radio-thirteen" [ngClass]="ynopt5 ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-fourteen" (click)="changeAllery('cvd',1,'no')" />
                          <label for="radio-fourteen" [ngClass]="!ynopt5 ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                      <div class="col-4"></div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                        *ngIf="cvdflag">
                        <mat-label class="matlabel">Details
                          <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="cvd_details"
                            matInput></textarea>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 paddingcheck">
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel" style="font-weight: 600;">Tuberculosis : </mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="ynopt6 != undefined">
                          <input type="radio" id="radio-fifteen" (click)="changeAllery('tuber',1,'yes')" />
                          <label for="radio-fifteen" [ngClass]="ynopt6 ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-sixteen" (click)="changeAllery('tuber',1,'no')" />
                          <label for="radio-sixteen" [ngClass]="!ynopt6 ? 'radio_active':'radio_inactive'">No</label>
                        </div>
                      </div>
                      <div class="col-4"></div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                        *ngIf="tuberflag">
                        <mat-label class="matlabel">Details
                          <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="tuber_details"
                            matInput></textarea>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 paddingcheck">
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel" style="font-weight: 600;">Others : </mat-label>
                      </div>
                      <div class="col-6">
                        <div class="switch-field" *ngIf="ynopt7 != undefined">
                          <input type="radio" id="radio-seventeen" (click)="changeAllery('Others',1,'yes')" />
                          <label for="radio-seventeen" [ngClass]="ynopt7 ? 'radio_active':'radio_inactive'">Yes</label>
                          <input type="radio" id="radio-eighteen" (click)="changeAllery('Others',1,'no')" />
                          <label for="radio-eighteen" [ngClass]="!ynopt7 ? 'radio_active':'radio_inactive'">No</label>
                        </div>

                      </div>
                      <div class="col-4">

                      </div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin: 15px 0 15px 0;"
                        *ngIf="otherflag">
                        <mat-label class="matlabel">Details
                          <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="Others_details"
                            matInput></textarea>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- habitual -->
            <div class="row">
              <div class="cover_div"
                style="margin-top: 10px;width: 98%;margin-left: auto;margin-right: auto;">
                <div class="header_lable">
                  Habitual
                </div>
                <div class="content_cover">
                  <div class="row" style="margin-top: 20px;">
                    <div class="col-3">
                      <div class="hebitual_cover">
                        <div class="header_lable">
                          Alcohol
                        </div>
                        <div class="content_cover">
                          <mat-accordion class="mataccordion">
                            <div class="vl"></div>
                            <mat-expansion-panel [expanded]="panelOpenState1" hideToggle [(ngModel)]="toggAlchol"
                              (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false"
                              class="exp_panel_cover">
                              <mat-expansion-panel-header class="exppanel">
                                <div class="hab_exp_image_cover">
                                  <img
                                    [src]="!panelOpenState1 ? '../../../assets/ui_icons/alcohol_red.svg' : '../../../assets/ui_icons/alcohol.svg'">
                                </div>
                              </mat-expansion-panel-header> <!-- heading  -->
                              <div class="hab_content_cover">
                                <div class="row">
                                  <mat-label class="col-12" class="hab_conter_text">Quantity
                                    <select class="sdropdown" ngDefaultControl [(ngModel)]="Alc_Quant">
                                      <option disabled value="Select">Select</option>
                                      <option value={{quan.description}} *ngFor="let quan of Quantity_array">
                                        {{quan.description}}</option>
                                    </select>
                                  </mat-label>

                                  <mat-label class="col-12" class="hab_conter_text">Mixing Type
                                    <select class="sdropdown" [(ngModel)]="Alc_Mix">
                                      <option disabled value="Select">Select</option>
                                      <option value={{Mix.description}} *ngFor="let Mix of Mixing_array">
                                        {{Mix.description}}
                                      </option>
                                    </select>
                                  </mat-label>

                                  <mat-label class="col-12" class="hab_conter_text">Duration
                                    <select class="sdropdown" [(ngModel)]="alchol_duration">
                                      <option value="&lt;1 Year">&lt;1 Year</option>
                                      <option value="1 - 2 Years"> 1 - 2 Years</option>
                                      <option value="3 - 5 Years"> 3 - 5 Years</option>
                                      <!-- <option value="&lt;5 Years">&lt;5 Years</option> -->
                                      <option value=">5 Years"> >5 Years</option>
                                    </select>
                                  </mat-label>

                                  <mat-label class="col-12" class="hab_conter_text">Freqency/day
                                    <select class="sdropdown" [(ngModel)]="alcohol_frequency">
                                      <option value=1>1</option>
                                      <option value="2"> 2</option>
                                      <option value="3"> 3</option>
                                      <option value="4"> 4</option>
                                      <option value=">5 to 10">>5 to 10</option>
                                      <option value="10+">10+</option>
                                    </select>
                                  </mat-label>


                                </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="hebitual_cover">
                        <div class="header_lable">
                          Smoke
                        </div>
                        <div class="content_cover">
                          <mat-accordion class="mataccordion">
                            <div class="vl"></div>
                            <mat-expansion-panel [expanded]="panelOpenState2" hideToggle
                              (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false"
                              class="exp_panel_cover">
                              <mat-expansion-panel-header class="exppanel">
                                <div class="hab_exp_image_cover">
                                  <img
                                    [src]="!panelOpenState2 ? '../../../assets/ui_icons/smoke_red.svg' : '../../../assets/ui_icons/smoke.svg'">
                                </div>
                              </mat-expansion-panel-header> <!-- heading  -->
                              <div class="hab_content_cover">
                                <div class="row">
                                  <mat-label class="col-12" class="hab_conter_text">Duration
                                    <select class="sdropdown" [(ngModel)]="smoke_duration">
                                      <option value="&lt;1 Year"> &lt;1 Year</option>
                                      <option value="1 - 2 Years"> 1 - 2 Years</option>
                                      <option value="3 - 5 Years"> 3 - 5 Years</option>
                                      <!-- <option value="&lt;5 Years "> &lt;5 Years </option> -->
                                      <option value="&gt;5 Years">&gt;5 Years </option>
                                    </select>
                                  </mat-label>
                                  <mat-label class="col-12" class="hab_conter_text">Freqency/day(times)
                                    <select class="sdropdown" [(ngModel)]="smoke_frequency">
                                      <option value=1>1</option>
                                      <option value="2"> 2</option>
                                      <option value="3"> 3</option>
                                      <option value="4"> 4</option>
                                      <option value=">5 to 10">>5 to 10</option>
                                      <option value="10+">10+</option>
                                    </select>
                                  </mat-label>
                                </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="hebitual_cover">
                        <div class="header_lable">
                          Gutka
                        </div>
                        <div class="content_cover">
                          <mat-accordion class="mataccordion">
                            <div class="vl"></div>
                            <mat-expansion-panel hideToggle [expanded]="panelOpenState3"
                              (opened)="panelOpenState3 = true" (closed)="panelOpenState3 = false"
                              class="exp_panel_cover">
                              <mat-expansion-panel-header class="exppanel">
                                <div class="hab_exp_image_cover">
                                  <img
                                    [src]="!panelOpenState3 ? '../../../assets/ui_icons/gutkha_red.svg' : '../../../assets/ui_icons/gutkha.svg'">
                                </div>
                              </mat-expansion-panel-header> <!-- heading  -->
                              <div class="hab_content_cover">
                                <div class="row">
                                  <mat-label class="col-12" class="hab_conter_text">Duration
                                    <select class="sdropdown" [(ngModel)]="gutka_duration">
                                      <option value="&lt;1 Year">&lt;1 Year</option>
                                      <option value="1 - 2 Years"> 1 - 2 Years</option>
                                      <option value="3 - 5 Years"> 3 - 5 Years</option>
                                      <!-- <option value="&lt;5 Years ">&lt;5 Years </option> -->
                                      <option value="&gt;5 Years">&gt;5 Years</option>

                                    </select>
                                  </mat-label>
                                  <mat-label class="col-12" class="hab_conter_text">Freqency/day (Times)
                                    <select class="sdropdown" [(ngModel)]="gutka_frequency">
                                      <option value=1>1</option>
                                      <option value="2"> 2</option>
                                      <option value="3"> 3</option>
                                      <option value="4"> 4</option>
                                      <option value=">5 to 10">>5 to 10</option>
                                      <option value="10+">10+</option>
                                    </select>
                                  </mat-label>
                                </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="hebitual_cover">
                        <div class="header_lable">
                          Exercise
                        </div>
                        <div class="content_cover">
                          <mat-accordion class="mataccordion">
                            <mat-expansion-panel hideToggle [expanded]="panelOpenState4"
                              (opened)="panelOpenState4 = true" (closed)="panelOpenState4 = false"
                              class="exp_panel_cover">
                              <mat-expansion-panel-header class="exppanel">
                                <div class="hab_exp_image_cover">
                                  <img
                                    [src]="!panelOpenState4 ? '../../../assets/ui_icons/exercise_red.svg' : '../../../assets/ui_icons/exercise.svg'">
                                </div>
                              </mat-expansion-panel-header> <!-- heading  -->
                              <div class="hab_content_cover">
                                <div class="row">
                                  <mat-label class="col-12" class="hab_conter_text">Exercise
                                    <select class="sdropdown" [(ngModel)]="life_exer"
                                      (ngModelChange)="Exercise_change($event)" disableOptionCentering>
                                      <option disabled value="Select">Select</option>
                                      <option *ngFor="let exercise of Physcial_exercise" value={{exercise.description}}>
                                        {{exercise.description}}</option>
                                    </select>
                                  </mat-label>
                                  <mat-label class="col-12" *ngIf="!yoga_div">
                                    <div class="scroll_checkbox test_box" id="Div8" style="margin-top: 12px;">
                                      <table style="margin-top: -5px;">
                                        <tr *ngFor="let yoga of Get_theyoga">
                                          <td class="test">
                                            <mat-checkbox multiple
                                              [checked]="yoga_check.indexOf(yoga.yoga_asana_id) > -1"
                                              (change)="yoga_click(yoga.yoga_asana_id,$event.checked);">
                                            </mat-checkbox>
                                          </td>
                                          <td style="text-align: left !important;text-align: left !important;                                    
                                             color: black;
                                             width: -webkit-fit-content;
                                             width: -moz-fit-content;
                                             width: fit-content;
                                             font-size: 12px !important;
                                             font-family: Arial;
                                             font-weight: 400;
                                             letter-spacing: 0.5px;">
                                            <span>{{yoga.description}}</span>
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </mat-label>
                                  <!-- <mat-label class="col-12" class="hab_conter_text" *ngIf="!yoga_div"><br>
                                     <select class="sdropdown" disableOptionCentering multiple >
                                        <option disabled value="Select">Select</option>
                                        <option #matoption color="primary" (click)="yoga_click(yoga.yoga_asana_id,matoption)"
                                           *ngFor="let yoga of Get_theyoga" value={{yoga.description}}>
                                           {{yoga.description}}
                                        </option>
                                     </select>
                                  </mat-label> -->

                                  <mat-label class="col-12" class="hab_conter_text">Duration<br>
                                    <select class="sdropdown" [(ngModel)]="life_exerdure" disableOptionCentering>
                                      <option disabled value="Select">Select</option>
                                      <option value="10 mins">10 mins</option>
                                      <option value="15 mins">15 mins</option>
                                      <option value="30 mins">30 mins</option>
                                      <option value="45 mins">45 mins</option>
                                    </select>
                                  </mat-label>
                                  <!-- <mat-label class="col-12" class="hab_conter_text">Frequency<br>
                                     <select class="sdropdown" [(ngModel)]="life_exe_freq" 
                                        disableOptionCentering >
                                        <option disabled value="Select">Select</option>
                                        <option value="Daily">Daily</option>
                                        <option value="Thrice a week">Thrice a week</option>
                                        <option value="4 days a week">4 days a week</option>
                                        <option value="5 days a week">5 days a week</option>
                                     </select>
                                  </mat-label> -->
                                  <mat-label class="col-12" class="hab_conter_text">Exercise routine<br>
                                    <select class="sdropdown" [(ngModel)]="exer_run" disableOptionCentering>
                                      <option disabled value="Select">Select</option>
                                      <option *ngFor="let routine of exercise_routine_arry"
                                        value="{{routine.description}}">
                                        {{routine.description}}</option>
                                    </select>
                                  </mat-label>
                                </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- diet text editor -->
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">Diet</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="family" [(ngModel)]="diet_data" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter Diet" (onContentChanged)="changediet($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="family" [content]="diet_data"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">General examination</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="family" [(ngModel)]="generalexamination" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter General examination"
                      (onContentChanged)="changegen($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="family" [content]="generalexamination"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- allergies -->
            <div class="col-12 col-sm-12" style="margin-top: 5px !important;" style="margin-top: 10px;">
              <div class="row">
                <div class="col-2">
                  <mat-label class="matlabel" style="font-weight: 600;">Allergies :
                  </mat-label>
                </div>
                <div class="col-6">
                  <div class="switch-field" *ngIf="ynopt8 != undefined">
                    <input type="radio" id="radio-thirty" (click)="changeAllery1('main',1,'yes')" />
                    <label for="radio-thirty" [ngClass]="ynopt8 ? 'radio_active':'radio_inactive'">Yes</label>
                    <input type="radio" id="radio-thirtyone" (click)="changeAllery1('main',1,'no')" />
                    <label for="radio-thirtyone" [ngClass]="!ynopt8 ? 'radio_active':'radio_inactive'">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" style="margin-top: 5px !important;">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 " style="margin: 27px 0 0px 0;"
                  *ngIf="allergyFlag">
                  <div class="row">
                    <div class="col-6">
                      <mat-label class="matlabel" style="font-weight: 600;">Drug
                        allergy : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt9 != undefined">
                        <input type="radio" id="radio-thirtytwo" (click)="changeAllery1('drug',1,'yes')" />
                        <label for="radio-thirtytwo" [ngClass]="ynopt9 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-thirtythree" (click)="changeAllery1('drug',1,'no')" />
                        <label for="radio-thirtythree" [ngClass]="!ynopt9 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" style="margin: 15px 0 15px 0;"
                  *ngIf="drugDetailsFlag">
                  <mat-label class="matlabel">Details
                    <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="drug_details" matInput></textarea>
                  </mat-label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 " style="margin: 27px 0 0px 0;"
                  *ngIf="allergyFlag">
                  <div class="row">
                    <div class="col-6">
                      <mat-label class="matlabel" style="font-weight: 600;">
                        Environmental allergy : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt10 != undefined">
                        <input type="radio" id="radio-thirtyfour" (click)="changeAllery1('evn',1,'yes')" />
                        <label for="radio-thirtyfour" [ngClass]="ynopt10 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-thirtyfive" (click)="changeAllery1('evn',1,'no')" />
                        <label for="radio-thirtyfive" [ngClass]="!ynopt10 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" style="margin: 15px 0 15px 0;"
                  *ngIf="engDetailsFlag && allergyFlag">
                  <mat-label class="matlabel">Details
                    <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="env_details" matInput></textarea>
                  </mat-label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 " style="margin: 27px 0 0px 0;"
                  *ngIf="allergyFlag">
                  <div class="row">
                    <div class="col-6">
                      <mat-label class="matlabel" style="font-weight: 600;">Food
                        allergy : </mat-label>
                    </div>
                    <div class="col-6">
                      <div class="switch-field" *ngIf="ynopt11 != undefined">
                        <input type="radio" id="radio-thirtysix" (click)="changeAllery1('food',1,'yes')" />
                        <label for="radio-thirtysix" [ngClass]="ynopt11 ? 'radio_active':'radio_inactive'">Yes</label>
                        <input type="radio" id="radio-thirtyseven" (click)="changeAllery1('food',1,'no')" />
                        <label for="radio-thirtyseven" [ngClass]="!ynopt11 ? 'radio_active':'radio_inactive'">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" style="margin: 15px 0 15px 0;"
                  *ngIf="foodDetailsFlag && allergyFlag">
                  <mat-label class="matlabel">Details
                    <textarea class="ipcss inpat_width" maxlength="250" [(ngModel)]="food_details" matInput></textarea>
                  </mat-label>
                </div>
              </div>
            </div>
            <!-- Family history -->

            <div class="row">
              <div class="cover_div" style="margin-top: 15px;width: 98%;margin-left: auto;margin-right: auto;">
                <div class="header_lable">
                  Vitals & Systemic Examination
                </div>
                <div class="content_cover">
                  <div class="container  p-0"
                    style="margin-top: 2px;margin-bottom: 2px;margin-left: 0px;margin-right: 0px;">
                    <div class="row">
                      <p style=""><span style="font-weight: 550;">Vitals :</span></p>
                    </div>

                    <div class="row">
                      <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                        <div class="row">
                          <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Height
                              <input class="ipcss " (change)="calculateCalories()" [(ngModel)]="height"
                                onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"/>
                                 <!-- onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput -->
                            </mat-label>
                          </div>


                          <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Measure<br>
                              <select class="ipcss widthch1" [(ngModel)]="hmeasure" (change)="calculateCalories()">
                                <option value="cms">cms</option>
                                <option value="inch">inch</option>
                              </select>
                            </mat-label>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                        <div class="row">
                          <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Weight<br>
                              <input class="ipcss widthch1" (change)="calculateCalories()"
                                 [(ngModel)]="weight"
                                 onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                                matInput />
                                <!-- onkeypress="return event.charCode >= 48 && event.charCode <= 57" -->
                            </mat-label>
                          </div>
                          <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Measure<br>
                              <select class="ipcss widthch1" [(ngModel)]="wmeasure" (change)="calculateCalories()">
                                <option value="kgs">kgs</option>
                                <option value="lbs">lbs</option>
                              </select>
                            </mat-label>
                          </div>
                        </div>
                      </div>

                      <!-- onkeypress="return event.charCode >= 48 && event.charCode <= 57" -->

                      <div class="col-6 col-sm-4 col-md-1 col-lg-1 col-xl-1">
                        <mat-label class="matlabel">BMI
                          <input class="ipcss " [(ngModel)]="bmi" disabled matInput />
                        </mat-label>
                      </div>


                      <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-1">
                        <mat-label class="matlabel">BMR<br>
                          <input class="ipcss " [(ngModel)]="bmr" disabled matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2" [hidden]="bloodPressureFlag">
                        <mat-label class="matlabel">Blood pressure</mat-label>
                        <div class="row" [hidden]="bloodPressureFlag">
                          <div class=" col-6" [hidden]="bloodPressureFlag">

                            <input type="text" [hidden]="bloodPressureFlag" class="ipcss widthch1" placeholder="mm Hg"
                              maxlength="3" [(ngModel)]="BP01" (keyup)="updateUserprofile()"
                              [ngStyle]="{'color': siscolorstyle ? 'red' : 'black'}"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" (input)="sistol(BP01)">
                          </div>
                          <div class="col-6" [hidden]="bloodPressureFlag">
                            <input type="text" [hidden]="bloodPressureFlag" class="ipcss widthch1" placeholder="mm Hg"
                              maxlength="3" [(ngModel)]="BP02" (keyup)="updateUserprofile()"
                              [ngStyle]="{'color': diocolorstyle ? 'red' : 'black'}"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" (input)="diostol(BP02)">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <div class="row">
                          <div class="col-8">
                            <mat-label class="matlabel">PR<br>
                              <input matInput class="ipcss " maxlength="25" placeholder="PR" [(ngModel)]="pr_txt"
                                (input)="pulsevalue(pr_txt)" [ngStyle]="{'color': pulsecolorstyle ? 'red' : 'black'}">
                            </mat-label>
                          </div>
                          <div class="col-4" style="margin-top:26px;padding: 0px;">
                            <span style="display:inline-block">/</span><span style="display:inline-block">min</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-sm-4 col-md-1 col-lg-1 col-xl-2">
                        <mat-label class="matlabel">Temperature</mat-label>
                        <div class="row">
                          <div class="col-8">

                            <input type="number" matInput class="ipcss noappt_width"
                              [ngStyle]="{'color': colorstyle ? 'red' : 'black'}" [(ngModel)]="temperature"
                              (keyup)="updateUserprofile()" (input)="tempcolor(temperature)"> 

                          </div>
                          <div class="col-4">
                            <mat-label class="matlabel" style="margin-left: -10px !important;position: relative;top: 7px;">°F</mat-label>
                            <!-- <select [(ngModel)]="temp_read" class="ipcss widthappt"
                              (ngModelChange)="chnagetemp($event)">
                              <option value="°C">°C</option>
                              <option value="F">F</option>
                            </select> -->

                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <div class="row">
                          <div class="col-8">
                            <mat-label class="matlabel">RR<br>
                              <input matInput class="ipcss " maxlength="25" placeholder="RR" [(ngModel)]="rr_txt"
                                (input)="rrvalue(rr_txt)" [ngStyle]="{'color': rrcolorstyle ? 'red' : 'black'}">
                            </mat-label>
                          </div>
                          <div class="col-4" style="padding: 0px;">
                            <span style="display:inline-block">/</span><span style="display:inline-block">min</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <div class="row">
                          <div class="col-8">
                            <mat-label class="matlabel">Spo2<br>
                              <input matInput class="ipcss " maxlength="25" placeholder="Spo2" [(ngModel)]="spo2"
                                (input)="spo2value(spo2)" [ngStyle]="{'color': spo2colorstyle ? 'red' : 'black'}">
                            </mat-label>
                          </div>
                          <div class="col-4" style="margin-top:26px;padding: 0px;">
                            <span>%</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                        <div class="row">
                          <div class="col-8">
                            <mat-label class="matlabel">CBG<br>
                              <input matInput class="ipcss " maxlength="25" placeholder="CBG" [(ngModel)]="cbg_txt"
                                (input)="cbgvalue(cbg_txt)" [ngStyle]="{'color': cbgcolorstyle ? 'red' : 'black'}">
                            </mat-label>
                          </div>
                          <div class="col-4" style="margin-top:26px;padding: 0px;">
                            <span> mg/dl</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container  p-0"
                    style="margin-top: 11px;margin-bottom: 2px;margin-left: 0px;margin-right: 0px;">
                    <div class="row">
                      <p style=""><span style="font-weight: 550;">Systemic Examination :</span></p>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <mat-label class="matlabel">CVS<br>
                          <input matInput class="ipcss " placeholder="CVS" [(ngModel)]="cvs_txt">
                        </mat-label>
                      </div>
                      <!-- newly added rs key -->

                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <mat-label class="matlabel">RS<br>
                          <input matInput class="ipcss " maxlength="25" placeholder="RS" [(ngModel)]="rs_text">
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <mat-label class="matlabel">Abdomen<br>
                          <input matInput class="ipcss " placeholder="ABD" maxlength="25" [(ngModel)]="abd_txt">
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <mat-label class="matlabel">CNS<br>
                          <input matInput class="ipcss " maxlength="25" placeholder="CNS" [(ngModel)]="cns_txt">
                        </mat-label>
                      </div>
                      <!-- <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <div class="row">
                          <div class="col-8">
                            <mat-label class="matlabel">Pulse<br>
                              <input matInput class="ipcss " placeholder="pulse" [(ngModel)]="pulse"
                                (input)="pulsevalue(pulse)" [ngStyle]="{'color': pulsecolorstyle ? 'red' : 'black'}">
                            </mat-label>
                          </div>
                          <div class="col-4" style="margin-top:26px;padding: 0px;">
                            <span> bpm</span>
                          </div>
                        </div>
                      </div> -->
                      <div class="col-3">
                        <mat-label class="matlabel">&nbsp;<br>
                          <img src="../../../assets/ui_icons/buttons/add_button.svg" class="add_button"
                            (click)="addVitals('data')" />
                        </mat-label>
                      </div>

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4"
                      *ngIf="vitals_List.length != 0">
                      <table>
                        <thead>
                          <tr>
                            <th style="text-align: left;">S.No</th>
                            <th style="text-align: left;">Height</th>
                            <th style="text-align: left;">Weight</th>
                            <th style="text-align: left;">BMI</th>
                            <th style="text-align: left;">Bmr</th>
                            <th style="text-align: left;">BP</th>
                            <th style="text-align: left;">PR</th>
                            <th style="text-align: left;">Temp</th>
                            <!-- <th style="text-align: left;">Pulse</th> -->
                            <th style="text-align: left;">RR</th>
                            <th style="text-align: left;">SPO2</th>
                            <th style="text-align: left;">CBG</th>
                            <th style="text-align: left;">CVS</th>
                            <th style="text-align: left;">RS</th>
                            <th style="text-align: left;">ABD</th>
                            <th style="text-align: left;">CNS</th>


                            <th style="text-align: left;">Date</th>
                            <!-- <th style="text-align: left;">Time</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let vital of vitals_List">
                            <td style="text-align: left;">{{vital.sNo}}</td>
                            <td style="text-align: left;">{{vital.height}}</td>
                            <td style="text-align: left;">{{vital.weight}}</td>
                            <td style="text-align: left;">{{vital.bmi}}</td>
                            <td style="text-align: left;">{{vital.bmr}}</td>
                            <td style="text-align: left;">
                              <span [style.color]="vital.is_bpAbnormal ? 'red' : 'black'">
                                {{ vital.bloodPressurekey }}
                              </span>
                            </td>
                            <td style="text-align: left;"><span
                                [style.color]="vital.is_pulseRateAbnormal ? 'red' : 'black'">{{vital.PR}}</span></td>
                            <td style="text-align: left;">
                              <span [style.color]="vital.is_tempAbnormal ? 'red' : 'black'">{{vital.temperature}}</span>
                            </td>
                            <!-- <td style="text-align: left;">
                              {{vital.pulse}}</td> -->
                            <td style="text-align: left;">
                              <span
                                [style.color]="vital.is_respiratoryRateAbnormal ? 'red' : 'black'">{{vital.RR}}</span>
                            </td>

                            <td style="text-align: left;">
                              <span [style.color]="vital.is_spo2Abnormal ? 'red' : 'black'">{{vital.SPO2}}</span>
                            </td>
                            <td style="text-align: left;"><span
                                [style.color]="vital.is_cbgAbnormal ? 'red' : 'black'">{{vital.cbg_txt}}</span></td>
                            <td style="text-align: left;">{{vital.Cvs}}</td>
                            <td style="text-align: left;">{{vital.Rs}}</td>
                            <td style="text-align: left;">{{vital.ABD}}</td>
                            <td style="text-align: left;">{{vital.Cns}}</td>

                            <!-- <td style="text-align: left;">{{vital.cbd_txt}}</td> -->
                            <td style="text-align: left;">{{vital.date}} {{vital.time}}</td>
                            <!-- <td style="text-align: left;">{{vital.time}}</td> -->
                          </tr>
                        </tbody>
                      </table>



                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--  systemic ex -->

            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">Local examination</b></mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="local_examination" [maxLength]="10"
                      [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                      placeholder="Enter Local examination" (onContentChanged)="changeexamination($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="local_examination" [content]="local_examination" [maxLength]="10">
                      </quill-view-html>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel"><b class="label_bold">Provisional diagnosis</b></mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="editor1" [(ngModel)]="prov_diagnosis" [maxLength]="10"
                      [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                      placeholder="Enter Provisional diagnosis" (onContentChanged)="changeprov($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="local_examination" [content]="prov_diagnosis" [maxLength]="10">
                      </quill-view-html>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-lg-6 col-sm-12 col-xl-6 ">
                <div class="row">
                  <div class="col-12 margin_10">
                    <mat-label class="matlabel">
                      <b class="label_bold">Line of managment</b>
                    </mat-label>
                  </div>
                  <div class="col-12">
                    <quill-editor id="line_mgmt" [(ngModel)]="line_mgmt" [styles]="{height: 'calc(35vh - 100px)'}"
                      [modules]="modules" placeholder="Enter Line of managment"
                      (onContentChanged)="changelinemgmt3($event)">
                    </quill-editor>
                    <div>
                      <!-- Preview -->
                      <quill-view-html hidden id="line_mgmt" [content]="line_mgmt"></quill-view-html>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-6">
                <div class="cover_div">
                  <div class="header_lable">
                    Assign
                  </div>
                  <div class="content_cover">
                    <div class="row">

                      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <mat-label class="matlabel">Specialization<br>
                          <select id="mandatory_specfication" class="ipcss widthappt" [(ngModel)]="splName"
                            (change)="getDoctorList(splName)">
                            <option *ngFor="let spl of splList" value="{{spl.spl_name}}">{{spl.spl_name}}</option>
                          </select>
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <mat-label class="matlabel">Doctor<br>
                          <select class="ipcss widthappt" [(ngModel)]="doctorname" 
                            (change)="getDoctorDetails(doctorname)">
                            <option *ngFor="let doctor of doctorList" value="{{doctor.docname}}">{{doctor.docname}}
                            </option>
                          </select>
                        </mat-label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>