<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Supplier master</h5>
          </div>
          <div class="headerButtons">
            <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
              (click)="clickSupplerMaster()" />
            <img *ngIf="!showSupplierFlag" src="../../../assets/ui_icons/buttons/Back_button.svg"
              class="saveimgbtn_inpatinfo" (click)="back()" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Supplier details
          </div>
          <div class="content_cover" style="position: relative;">
            <img style="position: absolute;  top: -10px;  right: -13px;  width: 25px;  cursor: pointer;height: 25px;"
              (click)="reset()" src="../../../assets/img/captcha_refresh.png" />
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Supplier name
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="supplierName" [required]="requiredkey == '0'|| requiredkey == '1'" maxlength="75"
                    matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Supplier code
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="supplierCode" [required]="requiredkey == '0' ||requiredkey == '1'" maxlength="10"
                    matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Drug licence
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="drug_lic" [required]="requiredkey == '1'" maxlength="75"
                    matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Account no
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="acct_no" [required]="requiredkey == '1'" maxlength="75"
                    matInput />
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Bank name
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="bnkname" [required]="requiredkey == '1'" maxlength="75"
                    matInput />
                </mat-label>
              </div>


              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel"> IFSC code
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="ifscode" [required]="requiredkey == '1'" maxlength="75"
                    matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel"> Mail id 1
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="mail_id1" [required]="requiredkey == '1'" maxlength="75"
                    matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel"> Mail id 2
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="mail_id2" maxlength="75"
                    matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel"> Mail id 3
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="mail_id3"  maxlength="75"
                    matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Telephone
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="supplierTelephone" maxlength="10" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Mobile
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="supplierMobile" [required]="requiredkey == '0'" pattern="^\d{4}-\d{3}-\d{4}$"
                    maxlength="10" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Whatsapp no
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="whatsapp_no" pattern="^\d{4}-\d{3}-\d{4}$"
                    maxlength="10" maxlength="10" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">GST number
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="suppliergst" maxlength="15" required matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Credit days
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="creditdays" [required]="requiredkey == '1'" 
                  maxlength="3" onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Address 1
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="supplierAddress1" [required]="requiredkey == '1'||requiredkey == '0'" maxlength="75"
                    matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Address 2
                  <input type="text" class="ipcss widthappt"  [(ngModel)]="supplierAddress2" maxlength="75" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Country<br>
                  <select disableOptionCentering class="ipcss widthappt" [required]="requiredkey == '1'" [(ngModel)]="supplierCountry"
                    (ngModelChange)="getState(supplierCountry,'0','0')">
                    <option disabled>Select</option>
                    <option *ngFor="let country of countryArray" [value]="country.country_id">
                      {{country.description}}
                    </option>
                  </select>
                </mat-label>
              </div>
              
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">State<br>
                  <select disableOptionCentering class="ipcss widthappt" [required]="requiredkey == '1'" [(ngModel)]="supplierState"
                    (change)="getCity(supplierState,'0')">
                    <option disabled>Select</option>
                    <option *ngFor="let states of stateArray" [value]="states.state_id">{{states.description}}
                    </option>
                  </select>
                </mat-label>
              </div>
              
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">City<br>
                  <select disableOptionCentering class="ipcss widthappt" [required]="requiredkey == '1'" [(ngModel)]="supplierCity">
                    <option disabled>Select</option>
                    <option *ngFor="let city of cityArray" [value]="city.district_id">{{city.description}}</option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                <span>Lead Time</span>
                <p style="display:flex">
                  <input type="number" class="ipcss widthappt" style="width:35%" placeholder="25" [(ngModel)]="refil_dur" maxlength="75" matInput/> 
                  <span style="display:inline-block;position:relative;top:5px;left:5px"> Days </span>
                </p>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel" style="margin-top: 7px;">Column Order<br>
                  <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="pharColumn"
                    (change)="columnOrder(pharColumn)">
                    <option *ngFor="let column of inventoryColumnArray" [ngValue]="column">
                      {{column}}</option>
                  </select>
                </mat-label>
              </div>
              
              <div class="col-12 col-sm-6 col-md-6 col-lg-8 col-xl-8">
                <mat-label class="matlabel">Invoice receivables<br>
                  <input type="text" class="ipcss widthappt" [(ngModel)]="invoice_colm"/>
                </mat-label>
              </div>
              
              <div class="col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1" [hidden]="data_flag">
                <img style="width:20px;position: relative;
                top: 10px;" src="../../../assets/img/captcha_refresh.png" (click)="clear_data()" class="refresh_btn"
                  alt="clear data">
              </div>
            </div>
          </div>
        </div>

        <div class="row suppliertable" *ngIf="showSupplierFlag">
          <div class="col-12" style="margin-top: 20px;">
            <table *ngIf="supplerMasterArray.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th>Supplier name</th>
                  <th>Supplier code</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>State</th>
                  <th>bank </th>
                  <!-- <th style="width:9%">Country</th>
                  <th>Telephone</th> -->
                  <th>Mobile</th>
                  <th>Whatsapp</th>
                  <th>GST number</th>
                  <th style="width:9%">Lead time</th>
                  <th style="width:6%" *ngIf="editbutton">Edit</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let supplier of supplerMasterArray; let i = index">
                  <td style="font-size: 12px;text-align: left !important;">{{supplier.name}}</td>
                  <td style="font-size: 12px;text-align: left !important;">{{supplier.supp_code}}</td>
                  <td style="font-size: 12px;text-align: left !important;">{{supplier.address}}</td>
                  <td style="font-size: 12px;text-align: left !important;">{{supplier.city_desc}}</td>
                  <td style="font-size: 12px;text-align: left !important;">{{supplier.state_desc}}</td>
                  <td>{{supplier.bank_name}}</td>
                  <!-- <td style="font-size: 12px;text-align: left !important;">{{supplier.country_desc}}</td> -->
                  <!-- <td style="font-size: 12px;">{{supplier.telephone}}</td> -->
                  <td style="font-size: 12px;">{{supplier.mobile}}</td>
                  <td>{{supplier.whatsapp_no}}</td>
                  <td style="font-size: 12px;">{{supplier.gst_no}}</td>
                  <td style="font-size: 12px;text-align: left !important;">{{supplier.refil_dur}}</td>
                  <td style="font-size: 12px;" *ngIf="editbutton"> <a (click)="editSuppler(supplier.Index)" class="curser_pointer"><i
                        class="fas fa-edit"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>