<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white" style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Bill Create</h5>
          </div>
          <div class="headerButtons">
            <a (click)="backClicked()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
              class="saveimgbtn_inpatinfo" style="cursor: pointer;" /></a>&nbsp;
            <a *ngIf="printbutton" (click)="print_area('print')" ><img src="../../../assets/img/printer.svg" 
              width="25px" height="25px" style="cursor: pointer;" /></a>&nbsp;
            <a *ngIf="show_save_btn && newbutton" (click)="Bill_save()"><img src="../../../assets/ui_icons/buttons/save_button.svg"
                class="saveimgbtn_inpatinfo" style="margin-left: 7px;" style="cursor: pointer;" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
            <mat-label class="matlabel">Filter by<br>
              <select required class="ipcss" [(ngModel)]="filter" required (change)="changeFilter()"
                      disableOptionCentering>                                        
                <option value="Date">Date</option>
                <option value="Name">Name</option>
                <option value="Patient_ID">MR.No</option>
                <option value="Mobile_No">Mobile No</option>
              </select>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" >
            <mat-label class="matlabel" >Ratecard<br>
              <select required class="ipcss" [(ngModel)]="rate_card" disableOptionCentering (change)="getConcession()" [disabled]="rate_card_disable">
                <option *ngFor="let RateCard of RateCardArray" value={{RateCard.rate_card_id}}> {{RateCard.rate_card_desc}} </option>
              </select>
            </mat-label>
          </div>
          
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">      
            <mat-label class="matlabel" [hidden]="toFlag">Date<br>
              <input type="date" class="ipcss" style="height: 25px;" (change)="OnDateChangedto(date)"
                [hidden]="fromFlag" [(ngModel)]="date" #matInput max="{{currentDate}}">
            </mat-label>
          
            <mat-label class="matlabel" [hidden]="firstNameFlag">First name<br>
              <input #focusFName id="focusFName" matInput class="ipcss" style="height: 25px;" type="text" [hidden]="firstNameFlag" required
                [(ngModel)]="firstName" (blur)="fnameToUpper()" />
            </mat-label>
            
            <mat-label class="matlabel" [hidden]="uhidFlag">MR.No<br>
              <input #focusMRNo id="focusMRNo" class="ipcss" style="height: 25px;" type="text" [hidden]="uhidFlag" required
                [(ngModel)]="patientId" (change)="getPatientListByMRNo(patientId)" />
            </mat-label>
    
            <mat-label class="matlabel" [hidden]="mobileFlag">Mobile No<br>
              <input #focusMobileNo  id="focusMobileNo" matInput class="ipcss" style="height: 25px;" type="text" [hidden]="mobileFlag" required
                [(ngModel)]="mobileNo" (change)="getPatientListByMobile_no(mobileNo)"/>
            </mat-label>                            
          </div>   
    
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="firstNameFlag"> 
            <mat-label class="matlabel" [hidden]="firstNameFlag">Last name<br>
              <input matInput class="ipcss" type="text" [hidden]="firstNameFlag" required
                [(ngModel)]="lastName" (blur)="lnameToUpper()" />
            </mat-label>
          </div>
          <div class="col-1  p-0" [hidden]="!mr_no_flag"> 
            <mat-label class="matlabel">
              <a (click)="searchPatient()" ><img src="../../../assets/ui_icons/search_icon.svg"
                  class="searchButton" /></a> 
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="paytypreq">
            <mat-label class="matlabel">Payment type<br>
              <select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="pay_type"
                (change)="changePayType()" disableOptionCentering >
                <option *ngFor="let pay of paytypelist" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="insurFlag">
            <mat-label class="matlabel">Insurer<br>
              <select required class="ipcss  widthbillcreate" [(ngModel)]="insurer_id" disableOptionCentering [required]="insreqflag">
                <option *ngFor="let ins of insurerlist" value={{ins.insurer_id}}>{{ins.insurer_desc}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="cardFlag">
            <mat-label class="matlabel">Card No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" [required]="cardreqflag">
            </mat-label>
           </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="cardFlag">
            <mat-label class="matlabel">Holder name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
            </mat-label>
           </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="transactionFlag">
            <mat-label class="matlabel">Transaction ID<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionId" [required]="transreqflag"/>
            </mat-label>
           </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="chequeFlag">
            <mat-label class="matlabel">Cheque No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" [required]="chequereqflag"/>
            </mat-label>
           </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="chequeFlag">
            <mat-label class="matlabel">Bank Name <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" [required]="chequereqflag"/>
            </mat-label>
          </div> 
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2">
            <mat-label class="matlabel">Patient Name<br>
              <select required class="ipcss" [(ngModel)]="appoint_txt" disableOptionCentering    
                (change)="Appointmnet_change(appoint_txt)">
                <option *ngFor="let location of appointment_list" value="{{location.docid_name}}">
                  {{location.docid_name}}
                </option>
              </select>
            </mat-label>
          </div> 
        </div>
   
        <div class="modal fade" id="exampleModal_1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> 
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-body">
                <div class="container-fluid">
                  <div class="snapshot" >
                    <div class="row">
                      <div class="col-12 mt-3" >
                        <table *ngIf="chargeNameArray.length !=0" style="font-size: 12px;width: 99%" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable billlisttable">
                          <thead>
                            <tr>
                              <th style="width:  1% !important;" [hidden]="true">Charge Id</th>
                              <th style="width: 34% !important;">Charge type</th>
                              <th style="width: 14% !important;">Quantity</th>
                              <th style="width: 15% !important;">Rate</th>
                              <th style="width: 22% !important;align-items: start;">Concession%</th>      
                              <th style="width: 10% !important;align-items: end;">Amount</th> 
                              <th style="width:  5% !important;"></th>              
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let charge of chargeNameArray" >
                              <td style="width: 1% !important;" [hidden]="true"> {{charge.charge_type}} </td>
                              <td class="align_left" style="width: 34% !important" > {{charge.biill_type}} </td>                       
                              <td class="align_center" style="width: 6% !important">
                                <input [disabled]="charge.selected" [(ngModel)]="charge.quantity"  type="text" class="formbold-form-input align_right" (keyup)="changeamount(charge)" />  
                              </td>
                              <td class="align_right" style="width: 10% !important">
                                <input [disabled]="charge.selected" [(ngModel)]="charge.amount_txt"  type="text" class="formbold-form-input align_right" (keyup)="changeamount(charge)" /> 
                              </td>                           
                              <td  [disabled]="true" class="align_center" style="width: 12% !important"> {{charge.concession}} </td>
                              <td class="align_right" style="width: 8% !important"> {{charge.fee}} </td>      
                              <td style="width: 5% !important"> 
                                <input type="checkbox"  [(ngModel)]="charge.selected" (change)="updateBillList(charge)">                                                    
                              </td>                                       
                            </tr>
                          </tbody>
                        </table> 
                        <div class="headerButtons" style="border: none;">
                          <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo add_align1" data-bs-dismiss="modal" /> 
                        </div>                             
                      </div>
                    </div>
                  </div>
                </div>                                 
              </div> 
            </div>            
          </div>
        </div>   
        
        <div class="col-12" *ngIf="createpage_flag">
          <div class="cover_div" *ngFor="let billprint of billing_print_array">
            <div class="header_lable">Patient details</div>
              <div class="content_cover">             
                <div class="row">
                  <div class="col-2" style="text-align: center;">
                    <img src="{{this.profileImg}}" onerror="this.src='../../../assets/img/default.jpg';"
                      class="profileimage">
                  </div>
                  <div class="col-10">
                    <div class="row">                                                       
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                <b>MR No</b></td>
                              <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{mrno}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                <b>Patient name</b></td>
                              <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{clnt_saldesc}}{{patient_name}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                <b>Age/Gender</b></td>
                              <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                {{clnt_age}} <span *ngIf="gender != '' && gender != undefined">/</span> {{gender}}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                <b>Mobile</b></td>
                              <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{mobile_no}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>      
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                <b>Doctor</b></td>
                              <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{doctor_name}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div> 
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                <b>DOA</b></td>
                              <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                {{admitted_date | date : "dd-MM-yyyy"}} {{admitted_time}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>        
                      <div class="col-3" *ngIf="e_contact != '' && e_contact != undefined">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                <b>Emerg contact</b></td>
                              <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{e_contact}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>  
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                <b>Dept</b></td>
                              <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{dept_desc}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>  
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                <b>Ward/Bed</b></td>
                              <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{wardName}} <span *ngIf="bed_no != '' && bed_no != undefined">/</span> {{bed_no}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div> 
                      <div class="col-3" *ngIf="surgery != undefined && surgery != ''">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                <b>Surgery name</b></td>
                              <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif"> {{surgery}} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>                                                                 
                      <div class="col-6">
                        <table style="width: 100%;" class="head-table">
                           <tbody>
                              <tr class="head-tr">
                                 <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Address</b></td>
                                 <td class="head-td" style="width: 10px; font-weight: 500;">:&nbsp;</td>
                                 <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{billprint.address1}} {{billprint.address2}} {{billprint.location}} {{billprint.city}} {{billprint.state}}
                                </td>
                              </tr>                 
                           </tbody>
                        </table>
                      </div>
                    </div>      
                  </div>
                </div>               
              </div>
          </div>
        </div>
        
        <div class="headerButtons" style="border: none;margin-top: 10px !important;" *ngIf="editbutton">
          <img src="../../../assets/ui_icons/Add_icon.svg"  class="saveimgbtn_inpatinfo_add" (click)="addNewBillDetails()"/> 
        </div>

        <div class="row">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">           
            <div *ngIf="grouping_flag">
              <div>
                <div>
                  <div class='row'>
                    <div class='col-12'>
                      <div class="table-responsive dig_table_overflow" [hidden]="categoryChargeList.length == 0" style="margin-top: 10px;">
                        <table class="table table-hover table-dynamic table-nowrap table-sm bill_edit_table" style="margin-bottom: 0px">
                          <thead>
                            <tr>
                              <th style="width: 10%;">Sno</th>
                              <th style="width: 20%">Service</th>
                              <th [hidden]="paytypreq" style="width: 5%">Payment type</th>
                              <th style="width: 6%">Rate</th>
                              <th [hidden]="discount_flag" style="width: 5%">Discount(%)</th>
                              <th style="width: 6%">Quantity</th> 
                              <th style="width: 6%">Amount</th>                                                
                              <th style="width: 10%" class="delete">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let charge of categoryChargeList">
                              <td colspan="8" *ngIf="charge.category_flag"> 
                                <div>
                                  <div >
                                    <p class="tervys_heading col-12"><b
                                      style="text-transform: capitalize;">{{charge.category}}</b></p>
                                    <table class="table" style="margin-bottom: 0;">
                                      <tbody>
                                        <tr *ngFor='let person of charge.charges'>
                                          <td style="text-align: center;width: 10%">{{person.sno}}</td>
                                          <td style="text-align: left;width: 20%">
                                            <mat-label>
                                              <input type="text" class="ipcss order_width getChargeDesc" style="font-size: 12px !important;" maxlength="50" (keyup)="person.biill_type && person.biill_type.length >=2 && handleKeyUp($event,person.biill_type,person)" 
                                                  [(ngModel)]="person.biill_type" required matInput [matAutocomplete]="auto1" [disabled]="person.edit_flag" /> 
                                              <mat-autocomplete #auto1="matAutocomplete" [panelWidth]="'auto'">
                                                  <mat-option *ngFor="let charge of serviceType" (click)="changeChargeType1(charge,person)" 
                                                    value="{{charge.description}}">  
                                                    {{charge.description}}           
                                                  </mat-option>
                                              </mat-autocomplete>
                                            </mat-label>
                                          </td>
                                          <td [hidden]="paytypreq" style="text-align: center;width: 5%">{{person.pay_type}}</td>
                                          <td style="text-align: right;width: 6%" [hidden]="!discount_flag">
                                            {{person.amount_txt}}
                                          </td>
                                          <td [hidden]="discount_flag" style="text-align: center;width: 6%">
                                            <input type="text" class="bill_edit_td" style="width: 75%;" 
                                            [(ngModel)]="person.amount_txt"
                                            #matInput (keyup)="changeamount1(person,0)" />
                                          </td>
                                          <td [hidden]="discount_flag" style="text-align: center;width: 5%">
                                            <input type="text" class="bill_edit_td" style="width: 75%;" 
                                            [(ngModel)]="person.concession"
                                            #matInput (keyup)="changeamount1(person,0)" />
                                          </td>           
                                          <td style="text-align: center !important;width: 6%">
                                            <input type="text" class="bill_edit_td" style="width: 75%;" [(ngModel)]="person.quantity" (keyup)="changeamount1(person,0)"
                                            #matInput />
                                          </td>
                                          <td [hidden]="multiply_flag" style="text-align: right;width: 6%">{{person.total}}</td>
                                          <td [hidden]="discount_flag" style="text-align: right !important;width: 6%">
                                            <input type="text" class="bill_edit_td" style="width: 75%;" [(ngModel)]="person.fee" (keyup)="changeamount1(person,2)"
                                            #matInput />
                                          </td>
                                          <td style="text-align: center;width: 10%">
                                            <a (click)="Delete_bill(person)">
                                              <img src="../../../assets/ui_icons/buttons/trash_icon.svg" class="billing_edit" style="cursor: pointer;">
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div [hidden]="billden_plan.length==0">
          <p class="casesheet_para">Treatement plan</p>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <table *ngIf="billden_plan.length" mdbTable datatable [dtOptions]="dtOptions"
            class="table table-nowrap table-sm dataTable billcreae_table">
            <thead>
              <tr>
                <th>Plan</th>
                <th class="tbl_txt_center">Estimate</th>
                <th class="tbl_txt_center">Select</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let person of billden_plan; let i = index">
                <td>{{person.treatment_desc}}</td>
                <td class="tbl_txt_center">{{person.estimate}}</td>
                <td class="tbl_txt_center">
                  <mat-checkbox color="primary" (change)="bill_click(person.den_treatment_plan_id,bill_palnche,i)"
                    [checked]="person.checked">
                  </mat-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row" style="margin-top: 10px !important;">
          <div class="col-0 col-sm-3  col-md-7 col-lg-7 col-xl-7">
            <div class="cover_div" style="margin-top: 5px !important;">
              <div class="row">
                <div class="col-2">
                  <mat-label class="matlabel">Credit bill
                      <mat-checkbox (change)="showCreditOption()" color="primary" [(ngModel)]="credit_bill">
                      </mat-checkbox>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-5" [hidden]="credit_flag">
                  <div class="row">
                    <div class="col-6">
                      <mat-label class="matlabel">Credit type </mat-label>
                    </div>
                    <div class="col-6">
                      <select class="ipcss " [(ngModel)]="credit_type" disableOptionCentering>
                        <option *ngFor="let credit of hosp_credits" value={{credit.credit_id}}> {{credit.credit_desc}} </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-4" [hidden]="credit_flag">
                  <input class="ipcss" [(ngModel)]="credit_person" placeholder="name of employee" matInput maxlength="50" />
                </div>
              </div>
              <div class="row " [hidden]="!credit_flag">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="!paytypreq">
                  <mat-label class="matlabel">Payment mode<br>
                    <mat-select class="ipcss " [(ngModel)]="payType1" disableOptionCentering>
                      <mat-option *ngFor="let pay of paytypelist" value={{pay.pay_id}}> {{pay.pay_desc}} </mat-option>
                    </mat-select>
                  </mat-label>
                </div>
        
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4" [hidden]="!paytypreq">
                  <mat-label class="matlabel">Amount<br>
                    <input class="ipcss billing_text_align" [(ngModel)]="amountval"  matInput required maxlength="12" />
                  </mat-label>
                </div>

                <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2" [hidden]="!paytypreq">
                  <mat-label class="matlabel ">
                    <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo btn-outline-success rounded"
                    (click)="addPaymentDetails()" style="float: right;margin-top: 20px;cursor: pointer;"/>
                  </mat-label>
                </div>
              </div>

              <!--CashDenomination Popup Start-->                                
              <div *ngIf="showModal">
                <div class="modal fade show"  id="cashModalLabel" tabindex="-1" role="dialog" aria-labelledby="cashModalLabel" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">               
                      <div class="modal-body">                                             
                        <div *ngFor="let denomination of cashDenomination" class="denomination-container">
                          <label class="denomination-label"> {{ denomination.value }} Rupees: </label>
                          <input type="number" class="denomination-input" [(ngModel)]="denomination.count" (ngModelChange)="calculateTotalAmount()" />
                        </div>                        
                        <div><strong>Total Amount &nbsp;:</strong><span style="font-size: 14px;font-weight: 500;">&nbsp;{{ totalAmount }}</span></div>                                                 
                      </div>
                      <div class="modal-footer">                       
                        <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" style="cursor: pointer;" (click)="saveCashDenomination()"/>                       
                        <img src="../../../assets/ui_icons/buttons/cancel_button.svg" class="saveimgbtn_inpatinfo" style="cursor: pointer;" (click)="showModal = false" />       
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-backdrop fade show"></div>
              </div>
              <!--CashDenomination Popup End-->

              <div class="row rowchanges" [hidden]="!paytypreq">
                <table>
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 150px !important;" class="payment_array_style"> {{pay.pay_desc}} </td>
                    <td style="width: 150px !important;text-align: right;" class="payment_array_style"> {{pay.amount}} </td>&nbsp;
                    <td>
                      <img  *ngIf="editbutton" src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)"> &nbsp;&nbsp;
                      <img  *ngIf="deletebutton" src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-0 col-sm-2  col-md-2 col-lg-2 col-xl-2"></div>
          <div class="col-0 col-sm-6  col-md-3 col-lg-3  col-xl-3">
            <div>
              <div class="row">
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" >
                  <mat-label class="matlabel heightmsr">Include GST &nbsp;&nbsp; </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <mat-checkbox color="primary" class="matlabel" [(ngModel)]="inGST"
                    (change)="include_gst_click(inGST)">
                  </mat-checkbox>
                </div>
                <div *ngFor="let gsttax of gst_data_list">
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class="matlabel  gstfield">{{gsttax.Gst_Descrip}}</mat-label>
                    </div>
                    <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                      <mat-label class=" ipcss billcreatech heightmsr bill_txt">{{gsttax.Gst_amount}}</mat-label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row ">
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="previous_balshow">
                  <mat-label class="matlabel  heightmsr">Previous balance </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="previous_balshow">
                  <input class="ipcss  heightmsr bill_txt" matInput text-right [(ngModel)]="balance_data" maxlength="15"
                    disabled />
                </div>

                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr"> Total </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true" [(ngModel)]="totalBillAmount" />
                </div>

                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6"> 
                  <mat-label class="matlabel heightmsr"> Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6"> 
                  <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right [(ngModel)]="discount_amount" (keyup)="changeDiscount()"/>
                </div>
                
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Bill amount </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right 
                    [(ngModel)]="final_amount" [disabled]="true"/>
                </div>
                
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label  *ngIf="Advance_bill != 0" class="matlabel heightmsr">Advance</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input *ngIf="Advance_bill != 0" class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="advance_change()"
                    [(ngModel)]="Advance_bill" maxlength="15" readonly />
                </div>
                
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Paid</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="paid_change()"
                    [(ngModel)]="paid_bill" [disabled]="paid_amount_flag" maxlength="15" />
                </div>

                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr" [hidden]="balance_show">Balance</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss billcreatech heightmsr bill_txt" [hidden]="balance_show" [disabled]="true" matInput text-right
                    [(ngModel)]="remaing_billbal" maxlength="15" />
                </div>
              </div>
            </div>   
          </div>  
        </div>  
       
        <div [hidden]="true" #printbanner id="printbanner" *ngFor="let billprint of billing_print_array">
          <table style="border:'0';width:100%">                      
            <thead>
              <tr>
                <th style="width:100%;">                                 
                  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                    <div style="width: 100%; height: 205px !important;"> 
                      <div style="width: 100%;">
                        <img alt="image" src={{hospitalLogo}}  [style]="printlogostyle">
                      </div>                                                             
                      <table style="width: 100%;font-size: 12px;border: 1px solid black;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;height: 95px;"> 
                        <tbody>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                            <td>: {{clnt_saldesc}}{{patient_name}} </td>
                            <td style="padding-left: 5px;"><strong>Address</strong></td>
                            <td>: {{billprint.address1}} {{billprint.address2}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                            <td>:  {{clnt_age}} / {{gender}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td><span class="address_visibility">:</span> {{billprint.location}} {{billprint.city}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                            <td>: {{billprint.dr_name}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td><span class="address_visibility">:</span> {{billprint.state}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                            <td>: {{billId}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                            <td>: {{bill_date | date : "dd-MM-yyyy"}}&nbsp;{{time}}</td>
                          </tr>
                          <tr *ngIf="surgery != undefined && surgery != ''">
                            <td style="padding-left: 5px;"><strong>Surgery name</strong></td>                             
                            <td>: {{surgery}}</td>                                                   
                          </tr>                         
                          <tr *ngIf="credit_type != undefined && credit_type != ''">
                            <td style="padding-left: 5px;"><strong>Credit bill</strong></td>                             
                            <td>: {{credit_person}}</td>
                          </tr>                        
                          <tr>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td>
                            </td> 
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td *ngIf="barcode_flag">
                              <img [style]="barcode_style"
                                src={{barcode_url}} /></td>
                          </tr>                         
                        </tbody>
                      </table>
                    </div>
                  </div>               
                </th>
              </tr>
            </thead>             
            <tbody>  
             <tr>  
               <td width="100%">  
                <p [style]="p_tag_style"><b>Bill/Receipt</b></p>
                <div class="bill_border">
                  <div>                    
                    <div *ngIf="billaddlist.length != 0" style="margin-left: 20px;margin-right: 10px;">                    
                      <div class="row">
                        <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                          <thead>
                            <tr>
                              <th style="width: 70px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                              <th style="width: 380px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Rate</b></th>
                              <th [hidden]="discount_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Discount(%)</b></th>
                              <th [hidden]="!bill_qty_flag || !inpatient_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Quantity</b></th>
                              <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let charge of categoryChargeList">
                              <td colspan="8" *ngIf="charge.category_flag"> 
                                <div>
                                  <div>
                                    <p style="font-weight: 500;font-family: Arial;color: black;font-size: 13px !important;margin: 5px 0 0 0 !important;
                                              text-align: center !important;"><b
                                      style="text-transform: capitalize;">{{charge.category}}</b></p> 
                                    <table style="border: 1px solid black;border-collapse: collapse;border-style: solid hidden solid hidden;"> 
                                      <tbody>
                                        <tr *ngFor='let billprinttable of charge.charges'>
                                          <td style="width: 70px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                            data-th="sno">{{billprinttable.sno}}</td>
                                          <td style="width: 380px;text-align: left;border: 1px solid black;border-collapse: collapse;"
                                            data-th="Type">{{billprinttable.biill_type}}</td>
                                          <td style="width: 150px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                            data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                          <td [style]="amount_td_style" 
                                            data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td>
                                          <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                            data-th="Fee" [hidden]="bill_qty_flag"> {{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                                          <td style="width: 150px;text-align: right;border: 1px solid black;border-collapse: collapse;"
                                            data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                          <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                            data-th="Fee" [hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                          <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                            data-th="Fee" [hidden]="multiply_flag">{{billprinttable.total}}</td> 
                                          <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                            data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>                        
                        </table>
                      </div>
                    </div>                                      
                    <div style="width: 100%;">
                      <table style="margin-bottom: -20px;">
                        <tr>
                          <td style="vertical-align: top; width: 26%;">
                            <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray !=0">
                              <tr *ngFor="let pay of paymentsarray">
                                <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                              </tr>
                            </table>       
                          </td>
                          <td style="vertical-align: top;width: 31%;">
                            <table  style="float: right;margin-left: 110px;margin-right: 25px;" *ngIf="paymentsarray.length != 0">                       
                              <tr [hidden]="gst_data_list.length==0" *ngFor="let gsttax of gst_data_list">
                                <th style="text-align: left;"><strong>{{gsttax.Gst_Descrip}}</strong></th>
                                <td style="text-align: right; width: 100px;" data-th="Central GST"
                                  class="fee_txt">{{gsttax.Gst_amount}}
                                  </td>
                              </tr> 
                              <tr [hidden]="previous_balshow">
                                <th style="text-align: left;">Previous balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                  class="fee_txt">{{balance_data}}</td>
                              </tr>
                              <tr [hidden]="totalBillAmount == final_amount">
                                <th style="text-align: left;">Total (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                  {{totalBillAmount}}</td>
                              </tr>
                              <tr *ngIf="discount_amount != undefined && discount_amount != 0">
                                <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                  {{discount_amount}}</td>
                              </tr>
                              <tr [hidden]="final_amount == undefined || final_amount == 0">
                                <th style="text-align: left;">Bill amount (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                  class="fee_txt">
                                  {{final_amount}}</td>
                              </tr>              
                              <tr [hidden]="Advance_bill == undefined || Advance_bill == 0 || final_amount == paid_bill">
                                <th style="text-align: left;">Advance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance"
                                  class="fee_txt">
                                  {{Advance_bill}}</td>
                              </tr>
                              <tr [hidden]="paid_bill >= final_amount">
                                <th style="text-align: left;">Paid (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{paid_bill}}</td>
                              </tr>
                              <tr [hidden]="paid_bill >= final_amount || remaing_billbal <= 0">
                                <th style="text-align: left;">Balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                  {{remaing_billbal}}</td>
                              </tr>
                            </table>
                            <table  style="float: right;margin-left: 120px;margin-right: 25px;" *ngIf="paymentsarray.length == 0">                       
                              <tr [hidden]="gst_data_list.length==0" *ngFor="let gsttax of gst_data_list">
                                <th style="text-align: left;"><strong>{{gsttax.Gst_Descrip}}</strong></th>
                                <td style="text-align: right; width: 100px;" data-th="Central GST"
                                  class="fee_txt">{{gsttax.Gst_amount}}
                                  </td>
                              </tr> 
                              <tr [hidden]="previous_balshow">
                                <th style="text-align: left;">Previous balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                  class="fee_txt">{{balance_data}}</td>
                              </tr>
                              <tr [hidden]="totalBillAmount == final_amount">
                                <th style="text-align: left;">Total (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                  {{totalBillAmount}}</td>
                              </tr>
                              <tr *ngIf="discount_amount != undefined && discount_amount != 0">
                                <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                  {{discount_amount}}</td>
                              </tr>
                              <tr [hidden]="final_amount == undefined || final_amount == 0">
                                <th style="text-align: left;">Bill amount (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                  class="fee_txt">
                                  {{final_amount}}</td>
                              </tr>              
                              <tr [hidden]="Advance_bill == undefined || Advance_bill == 0 || final_amount == paid_bill">
                                <th style="text-align: left;">Advance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance"
                                  class="fee_txt">
                                  {{Advance_bill}}</td>
                              </tr>
                              <tr [hidden]="paid_bill >= final_amount">
                                <th style="text-align: left;">Paid (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{paid_bill}}</td>
                              </tr>
                              <tr [hidden]="paid_bill >= final_amount || remaing_billbal <= 0">
                                <th style="text-align: left;">Balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                  {{remaing_billbal}}</td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                      <div class="row">
                        <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" *ngIf="numToWords != undefined && paid_bill != 0">
                          <tr>
                            <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>               
               </td>
             </tr>
           </tbody>
           <tfoot style="margin-bottom: 10px;"> 
            <tr>  
              <td width="100%">  
                <table style="width: 100%;">  
                  <tr>  
                    <td style="vertical-align: top;width:50%;">
                      <table style="float: left !important;margin-left: 15px;">
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                      </table> 
                    </td>  
                    <td style="vertical-align: top;width:50%;">
                      <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                        <tr>
                          <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                            <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                            <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                          </div>                       
                        </tr>
                      </table>                      
                    </td>
                  </tr>  
                </table> 
              </td>
            </tr> 
           </tfoot>
          </table>
        </div>

        <div [hidden]="true" #printnoheader id="printnoheader" *ngFor="let billprint of billing_print_array">
          <table style="border:'0';width:100%">   
            <thead>
              <tr>
                <th style="width:100%;">
                  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                    <div [style]="nobanner_style">
                      <div style="height: 5px !important;"></div>
                      <table style="width: 100%;font-size: 12px;border: 1px solid black;border-radius: 10px;height: 85px;"> 
                        <tbody>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                            <td>: {{clnt_saldesc}}{{patient_name}}</td>
                            <td style="padding-left: 5px;"><strong>Address</strong></td>
                            <td>: {{billprint.address1}} {{billprint.address2}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                            <td>: {{clnt_age}} / {{gender}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td><span class="address_visibility">:</span> {{billprint.location}} {{billprint.city}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                            <td>: {{billprint.dr_name}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td><span class="address_visibility">:</span> {{billprint.state}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                            <td>: {{billId}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                            <td>: {{bill_date | date : "dd-MM-yyyy"}}&nbsp;{{time}}</td>                     
                          </tr>
                          <tr *ngIf="surgery != undefined && surgery != ''">
                            <td style="padding-left: 5px;"><strong>Surgery name</strong></td>                             
                            <td>: {{surgery}}</td>                          
                          </tr>
                          <tr *ngIf="credit_type != undefined && credit_type != ''">
                            <td style="padding-left: 5px;"><strong>Credit bill</strong></td>                             
                            <td>: {{credit_person}}</td>                            
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td>
                            </td> 
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td *ngIf="barcode_flag">
                              <img [style]="barcode_style"
                                src={{barcode_url}} /></td>
                          </tr>          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>             
           <tbody>  
             <tr>  
               <td width="100%">
                <p style="text-align: center;font-size: 16px;margin: 0px;margin-bottom: 3px;margin-right: 10px;"><b>Bill/Receipt</b></p>
                <div class="bill_border">
                  <div>                                      
                    <div *ngIf="billaddlist.length != 0" style="margin-left: 20px;margin-right: 10px;">                    
                      <div class="row">
                        <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                          <thead>
                            <tr>
                              <th style="width: 70px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                              <th style="width: 380px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b></th>
                              <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Rate</b></th>
                              <th [hidden]="discount_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Discount(%)</th>
                              <th [hidden]="!bill_qty_flag || !inpatient_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Quantity</th>
                              <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let charge of categoryChargeList">
                              <td colspan="8" *ngIf="charge.category_flag"> 
                                <div>
                                  <div>
                                    <p style="font-weight: 500;font-family: Arial;color: black;font-size: 13px !important;margin: 5px 0 0 0 !important;
                                              text-align: center !important;"><b
                                      style="text-transform: capitalize;">{{charge.category}}</b></p> 
                                    <table style="border: 1px solid black;border-collapse: collapse;border-style: solid hidden solid hidden;"> 
                                      <tbody>
                                        <tr *ngFor='let billprinttable of charge.charges'>
                                          <td style="width: 70px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                            data-th="sno">{{billprinttable.sno}}</td>
                                          <td style="width: 380px;text-align: left;border: 1px solid black;border-collapse: collapse;"
                                            data-th="Type">{{billprinttable.biill_type}}</td>
                                          <td style="width: 150px;text-align: center;border: 1px solid black;border-collapse: collapse;"
                                            data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                          <td [style]="amount_td_style" 
                                            data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td>
                                          <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                            data-th="Fee" [hidden]="bill_qty_flag"> {{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                                          <td style="width: 150px;text-align: right;border: 1px solid black;border-collapse: collapse;"
                                            data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                          <td style="width: 150px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                            data-th="Fee" [hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                          <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                            data-th="Fee" [hidden]="multiply_flag">{{billprinttable.total}}</td> 
                                          <td style="width: 170px;text-align: right; border: 1px solid black;border-collapse: collapse;"
                                            data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>                      
                        </table>
                      </div>
                    </div>
                                       
                    <div style="width: 100%;">
                      <table style="margin-bottom: -20px;">
                        <tr>
                          <td style="vertical-align: top; width: 26%;">
                            <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray !=0">
                              <tr *ngFor="let pay of paymentsarray">
                                <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                              </tr>
                            </table>       
                          </td>
                          <td style="vertical-align: top;width: 31%;">
                            <table  style="float: right;margin-left: 110px;margin-right: 25px;" *ngIf="paymentsarray.length != 0">                       
                              <tr [hidden]="gst_data_list.length==0" *ngFor="let gsttax of gst_data_list">
                                <th style="text-align: left;"><strong>{{gsttax.Gst_Descrip}}</strong></th>
                                <td style="text-align: right; width: 100px;" data-th="Central GST"
                                  class="fee_txt">{{gsttax.Gst_amount}}
                                  </td>
                              </tr> 
                              <tr [hidden]="previous_balshow">
                                <th style="text-align: left;">Previous balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                  class="fee_txt">{{balance_data}}</td>
                              </tr>
                              <tr [hidden]="totalBillAmount == final_amount">
                                <th style="text-align: left;">Total (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                  {{totalBillAmount}}</td>
                              </tr>
                              <tr *ngIf="discount_amount != undefined && discount_amount != 0">
                                <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                  {{discount_amount}}</td>
                              </tr>
                              <tr [hidden]="final_amount == undefined || final_amount == 0">
                                <th style="text-align: left;">Bill amount (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                  class="fee_txt">
                                  {{final_amount}}</td>
                              </tr>
                              <tr [hidden]="Advance_bill == undefined || Advance_bill == 0 || final_amount == paid_bill">
                                <th style="text-align: left;">Advance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance"
                                  class="fee_txt">
                                  {{Advance_bill}}</td>
                              </tr>
                              <tr [hidden]="paid_bill >= final_amount">
                                <th style="text-align: left;">Paid (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{paid_bill}}</td>
                              </tr>
                              <tr [hidden]="paid_bill >= final_amount || remaing_billbal <= 0">
                                <th style="text-align: left;">Balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                  {{remaing_billbal}}</td>
                              </tr>
                            </table>
                            <table  style="float: right;margin-left: 120px;margin-right: 25px;" *ngIf="paymentsarray.length == 0">                       
                              <tr [hidden]="gst_data_list.length==0" *ngFor="let gsttax of gst_data_list">
                                <th style="text-align: left;"><strong>{{gsttax.Gst_Descrip}}</strong></th>
                                <td style="text-align: right; width: 100px;" data-th="Central GST"
                                  class="fee_txt">{{gsttax.Gst_amount}}
                                  </td>
                              </tr> 
                              <tr [hidden]="previous_balshow">
                                <th style="text-align: left;">Previous balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                  class="fee_txt">{{balance_data}}</td>
                              </tr>
                              <tr [hidden]="totalBillAmount == final_amount">
                                <th style="text-align: left;">Total (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                  {{totalBillAmount}}</td>
                              </tr>
                              <tr *ngIf="discount_amount != undefined && discount_amount != 0">
                                <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                  {{discount_amount}}</td>
                              </tr>
                              <tr [hidden]="final_amount == undefined || final_amount == 0">
                                <th style="text-align: left;">Bill amount (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                  class="fee_txt">
                                  {{final_amount}}</td>
                              </tr>
                              <tr [hidden]="Advance_bill == undefined || Advance_bill == 0 || final_amount == paid_bill">
                                <th style="text-align: left;">Advance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance"
                                  class="fee_txt">
                                  {{Advance_bill}}</td>
                              </tr>
                              <tr [hidden]="paid_bill >= final_amount">
                                <th style="text-align: left;">Paid (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                  class="fee_txt">
                                  {{paid_bill}}</td>
                              </tr>
                              <tr [hidden]="paid_bill >= final_amount || remaing_billbal <= 0">
                                <th style="text-align: left;">Balance (र)</th>
                                <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                  {{remaing_billbal}}</td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                      <div class="row">
                        <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" *ngIf="numToWords != undefined && paid_bill != 0">
                          <tr>
                            <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>               
               </td>
             </tr>
           </tbody>
           <tfoot style="margin-bottom: 10px;"> 
            <tr>  
              <td width="100%">  
                <table style="width: 100%;">  
                  <tr>  
                    <td style="vertical-align: top;width:50%;">
                      <table style="float: left !important;margin-left: 15px;">
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                      </table> 
                    </td>  
                    <td style="vertical-align: top;width:50%;">
                      <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                        <tr>
                          <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                            <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                            <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                          </div>                       
                        </tr>
                      </table>                      
                    </td>
                  </tr>  
                </table> 
              </td>
            </tr> 
          </tfoot>
          </table>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>