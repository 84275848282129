import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress' ;

@Component({
  selector: 'app-ward-master',
  templateUrl: './ward-master.component.html',
  styleUrls: ['./ward-master.component.scss']
})
export class WardMasterComponent implements OnInit {

  public wardName;
  public floor;
  public userInfo;
  public hospitalDetails;
  public wardList = [];
  public bedNo;
  public bedType;
  public charges;
  public bedList;
  public bedTableCount:any = [];
  public rowCount;
  public wardMasterList;
  public showCreateDetailFlag:boolean = false;
  dtOptions: DataTables.Settings = {};
  public wardId;
  public updateFlag:boolean;
  public wardViewdata = [];
  public nursingCharges;
  public bedCharges;
  public bed_count: any;

  constructor(public http:HttpClient,public toastr:ToastrService,private cdRef: ChangeDetectorRef) { 
  }

  ngOnInit() {
    this.userInfo= Helper_Class.getInfo();
    this.hospitalDetails = Helper_Class.getHospitalId();
    this.bed_count = 1;
    this.getBedType();
    this.getWardData();

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
       ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    console.log(this.bedTableCount);
  }

  getWardData(){
    this.wardList=[];
    var send_data = {
      hptl_clinic_id:this.hospitalDetails,
    };
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gward',send_data,
      { headers: headers })
      .subscribe(
        response => {
          this.wardList=[];
          var obj = JSON.parse(JSON.stringify(response));
          if(obj != undefined){
            this.wardList = obj.wards;
            console.log("this.wardList "+JSON.stringify(this.wardList ) );
            console.log("this.wardList "+JSON.stringify(this.wardList.length ) );

          }
        });
  }

  getWardList(ward){
    this.wardViewdata = [];
    var send_data = {
      hptl_clinic_id:Helper_Class.getHospitalId(),
      ward_id:ward.ward_id,
    };
    console.log("this.wardList "+JSON.stringify(ward ) +JSON.stringify(send_data ));

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gwdet',send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("this.wardList obj"+JSON.stringify(obj ) );
         
          if(obj != undefined && obj != null && obj != ""){
            if(obj.bed_details != undefined){
              this.viewWardDetail(obj);
            }
          }
        });
  }

  viewWardDetail(ward){
    this.showCreateDetailFlag = true;
    this.bedTableCount = [];
    this.bed_count = '';
    this.wardName = ward.ward_name;
    this.floor = ward.floor;
    this.nursingCharges = ward.nursing_charges;
    this.wardId = ward.ward_id;
    this.updateFlag = this.wardId != undefined && this.wardId != null ? true:false;
    var bedDetails = ward.bed_details;
    if(bedDetails.length != 0){
      for(var i= 0; i < bedDetails.length; i++){
        this.bedTableCount.push({
          ward_bed_id:bedDetails[i].ward_bed_id,
          bed_no:bedDetails[i].bed_no,
          bed_type:bedDetails[i].bed_type,
          rate: bedDetails[i].rate,
          // nursing_charge: bedDetails[i].nursing_charge
        });
      }
      setTimeout(()=>{
        this.bed_count = this.bedTableCount.length;
      },500)
    }else{
       var bedDetails = ward.bed_details;
    }
   
    
  }

  getBedType(){
    this.bedList = [];
    var send_data = {
      hptl_clinic_id:Helper_Class.getHospitalId(),
    };
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gbt',send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if(obj!=undefined){
            this.bedList = obj.bedtypes;
            this.bedType = this.bedList[0].bedtypes_id;
          }
        });
  }

  changeWard(){
  }

  createMaster(){
    this.showCreateDetailFlag = true;
    this.updateFlag = false;
    this.wardName = "";
    this.floor = "";
    this.nursingCharges = "";
    this.bedTableCount = [];
    var no = 1;
    for(var i = 0; i < no; i++){
      // this.addRow();
      this.bedTableCount.push({
        bed_no:"1",
        bed_type:this.bedType,
        rate: this.bedCharges,
        // nursing_charge: this.nursingCharges
      });
    }
    this.bed_count = 1;
  }

  createTable(){
    this.bed_count = '';
    this.bedTableCount.push({
      bed_no: "",
      bed_type:this.bedType,
      rate: this.bedCharges,
      // nursing_charge: this.nursingCharges
    });

    if (this.bedTableCount.length != 0) {
      for (var i = 0; i < this.bedTableCount.length; i++) {
        this.bedTableCount[i].bed_no = i + 1;
      }
    }
    setTimeout(()=>{
      this.bed_count = this.bedTableCount.length;
    },500)
  }

  addMaster(action){
    var send_data;
    var bedDetails = [];
    var required_flag:boolean = true;

    bedDetails = this.bedTableCount;
    if(bedDetails.length != 0){
      var check:boolean = true;
      for(var i = 0; i < bedDetails.length;i++){
        if(bedDetails[i].bed_no == ""){
          check = false;
        }
        if(bedDetails[i].rate == "0" || bedDetails[i].rate == ""){
          check = false;
        }
        // if(bedDetails[i].nursing_charge == "0" || bedDetails[i].nursing_charge == ""){
        //   check = false;
        // }
        if(check == false){
          bedDetails.splice(i);
        }
      }
    }else{
      required_flag = false;
    }

    if(this.nursingCharges == "" || this.nursingCharges == undefined || this.nursingCharges == null || bedDetails.length == 0) {
      required_flag = false;
    }
    if(bedDetails.length == 0){
      this.toastr.error("Enter Bed details");
    }
    if(required_flag == true){
      if(action == "add"){
        send_data = {
          hptl_clinic_id:this.hospitalDetails,
          ward_name:this.wardName,
          floor:this.floor,
          nursing_charges:this.nursingCharges,
          bed_details:bedDetails,
        }
      }else{
        send_data = {
          hptl_clinic_id:this.hospitalDetails,
          ward_id:this.wardId,
          ward_name:this.wardName,
          floor:this.floor,
          nursing_charges:this.nursingCharges,
          bed_details:bedDetails,
        }
      }
      console.log("send data --"+JSON.stringify(send_data));
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inpat/swd',send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log(obj);
            if(obj.key == "1"){
              this.toastr.success(obj.result);
              this.showCreateDetailFlag = false;
              this.updateFlag = false;
             setTimeout(() => {
              this.getWardData();
             }, 1000);
            } else {
              this.toastr.error(obj.result);
              if(bedDetails.length < 3){
                var no = 3 - bedDetails.length;
                for(var i = 0; i < no; i++){
                  // this.addRow();
                  this.bedTableCount.push({
                    bed_no: "1",
                    bed_type: this.bedType,
                    rate: "0",
                    // nursing_charge: "0"
                  });
                }
              }
            }
          });
    }
  }

  createBed() {
    this.bedTableCount = [];
    if (this.bed_count != undefined && this.bed_count != '' && this.bed_count != 0 && this.bed_count != '0') {
      // var bed_count = this.bed_count;
      // bed_count = bed_count - 1;  
      console.log(this.bed_count);
      for (var i = 0; i < this.bed_count; i++) {
        this.createTable();
      }
    }
  }

  createBedCharges() {
    if (this.bedCharges != undefined && this.bedCharges != '' && this.bedCharges != 0 && this.bedCharges != '0') {
      if (this.bedTableCount.length != 0) {
        for (var i = 0; i < this.bedTableCount.length; i++) {
          this.bedTableCount[i].rate = this.bedCharges;
        }
      }
    }
  }

  // createNursingCharges() {
  //   if (this.nursingCharges != undefined && this.nursingCharges != '' && this.nursingCharges != 0 && this.nursingCharges != '0') {
  //     if (this.bedTableCount.length != 0) {
  //       for (var i = 0; i < this.bedTableCount.length; i++) {
  //         this.bedTableCount[i].nursing_charge = this.nursingCharges;
  //       }
  //     }
  //   }
  // }

  bedRate() {
  }

  goBack(){
    this.showCreateDetailFlag = false;
    this.updateFlag = false;
    this.cdRef.detectChanges();
    this.getWardData();
  }

  empty(data){
    var check:boolean = true;
    if(data.length != 0){
      for(var i = 0; i < data.length;i++){
        if(data[i].bed_no == ""){
          check = false;
        }
        if(data[i].rate == "0"){
          check = false;
        }
        if(check == false){
          data.splice(i,1);
        }
      }
    }
    return this.bedTableCount;
  }
}
