// import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule, Routes } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPrintModule } from 'ngx-print';
import { AngularSplitModule } from 'angular-split';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DataTablesModule } from 'angular-datatables';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { InpatPatientInfoComponent } from './inpat-patient-info/inpat-patient-info.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BrowserModule } from '@angular/platform-browser';
import { QuillModule } from 'ngx-quill';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DoctorViewComponent } from './doctor-view/doctor-view.component';
import { GeneralPersonalComponent } from './general-personal/general-personal.component';
import { DoctorAppListComponent } from './doctor-app-list/doctor-app-list.component';
import { GynoObestricPopupModule } from './gyna-obestric-pop-selection/gyna-obestric-pop-selection.module';
import { CasesheetPersonalComponent } from './casesheet-personal/casesheet-personal.component';
import { DoctorhomeComponent } from './doctorhome/doctorhome.component';
import { NoappointmentComponent } from './noappointment/noappointment.component';
import { DoctorbilllistpageComponent } from './doctorbilllistpage/doctorbilllistpage.component';
import { BillCreateComponent } from './bill-create/bill-create.component';
import { BillDetailViewComponent } from './bill-detail-view/bill-detail-view.component';
import { BillEditComponent } from './bill-edit/bill-edit.component';
import { InpatDischargeSummaryComponent } from './inpat-discharge-summary/inpat-discharge-summary.component';
import { InpatDischargeViewComponent } from './inpat-discharge-view/inpat-discharge-view.component';
import { InpatDashboardComponent } from './inpat-dashboard/inpat-dashboard.component';
import { InpatPatientListComponent } from './inpat-patient-list/inpat-patient-list.component';
import { HelpComponent } from './help/help.component';
import { DoctorNotificationComponent } from './doctor-notification/doctor-notification.component';
import { CasesheetComponent } from './casesheet/casesheet.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DoctorScheduleListPageComponent } from './doctor-schedule-list-page/doctor-schedule-list-page.component';
import { PopupDoctorScheduleListComponent } from './popup-doctor-schedule-list/popup-doctor-schedule-list.component';
import { PopupDoctorScheduleListModule } from './popup-doctor-schedule-list/popup_doctor_schedule_list.module';
import { DoctorAvailableNonavailableScedulePageComponent } from './doctor-available-nonavailable-scedule-page/doctor-available-nonavailable-scedule-page.component';
import { DoctorManageScheduleMainComponent } from './doctor-manage-schedule-main/doctor-manage-schedule-main.component';
import { SecondOpinionComponent } from './second-opinion/second-opinion.component';
import { SecondOpinionCasesheetComponent } from './second-opinion-casesheet/second-opinion-casesheet.component';
import { SecondOpinionCasesheetApptdetailsComponent } from './second-opinion-casesheet-apptdetails/second-opinion-casesheet-apptdetails.component';
import { SecondOpinionCasesheetFamilyhistoryComponent } from './second-opinion-casesheet-familyhistory/second-opinion-casesheet-familyhistory.component';
import { SecondOpinionCasesheetHmComponent } from './second-opinion-casesheet-hm/second-opinion-casesheet-hm.component';
import { SecondOpinionCasesheetObservationsComponent } from './second-opinion-casesheet-observations/second-opinion-casesheet-observations.component';
import { SecondOpinionCasesheetPersonalComponent } from './second-opinion-casesheet-personal/second-opinion-casesheet-personal.component';
import { SecondOpinionCasesheetReportsComponent } from './second-opinion-casesheet-reports/second-opinion-casesheet-reports.component';
import { SecondOpinionMedicationPopupModule } from './second-opinion-medication-popup/second_opinion_medication_popup.module';
import { SecondOpinionPresviewPopupPopupModule } from './second-opinion-presview-popup/second_opinion_presview_popup.module';
import { SecondOpinionReportdetailviewPopupModule } from './second-opinion-reportdetailview-popup/second_opinion_reportdetailview_popup.module';
import { HomecareComponent } from './homecare/homecare.component';
import { IcdCodePopupModule } from './icd-code-popup/icd-code-popup.module';
import { VideocallModule } from './videocall/videocall.module';
import { PreviousPrescriptionPopupComponent } from './previous-prescription-popup/previous-prescription-popup.component';
import { PreviousprescriptionPopupModule } from './previous-prescription-popup/previous_prescription_popup.module';
import { AppoinmentviewComponent } from './appoinmentview/appoinmentview.component';
import { DiagPrescriptionComponent } from './diag-prescription/diag-prescription.component';
import { DischargeSummaryComponent } from './discharge-summary/discharge-summary.component';
import { FamilyHistoryComponent } from './family-history/family-history.component';
import { GynClinicalExamComponent } from './gyn-clinical-exam/gyn-clinical-exam.component';
import { GynPersonalComponent } from './gyn-personal/gyn-personal.component';
import { GynSurgicalHistoryComponent } from './gyn-surgical-history/gyn-surgical-history.component';
import { HabitualinfoComponent } from './habitualinfo/habitualinfo.component';
import { ObstetricHistoryComponent } from './obstetric-history/obstetric-history.component';
import { OthersClinicalExamComponent } from './others-clinical-exam/others-clinical-exam.component';
import { CardDietplanComponent } from './card-dietplan/card-dietplan.component';
import { DietpopupwindowModule } from './dietpopupwindow/dietpopupwindow.module';
import { NephrologyBmiVitalsComponent } from './nephrology-bmi-vitals/nephrology-bmi-vitals.component';
import { NephrologyDiagGraphComponent } from './nephrology-diag-graph/nephrology-diag-graph.component';
import { NephrologyDiagnosisComponent } from './nephrology-diagnosis/nephrology-diagnosis.component';
import { NephrologyPhysicalComponent } from './nephrology-physical/nephrology-physical.component';
import { PediatricsDevelopmentComponent } from './pediatrics-development/pediatrics-development.component';
import { PediatricsHeadCardiovascularComponent } from './pediatrics-head-cardiovascular/pediatrics-head-cardiovascular.component';
import { PediatricsPersonalComponent } from './pediatrics-personal/pediatrics-personal.component';
import { PediatricsPregnancyDeliveryComponent } from './pediatrics-pregnancy-delivery/pediatrics-pregnancy-delivery.component';
import { PediatricsVitalsComponent } from './pediatrics-vitals/pediatrics-vitals.component';
import { ClinicalExamComponent } from './clinical-exam/clinical-exam.component';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { MedDiagPrescriptionComponent } from './med-diag-prescription/med-diag-prescription.component';
import { GeneralSurgeryComponent } from './general-surgery/general-surgery.component';
import { UploadtabComponent } from './uploadtab/uploadtab.component';
import { ReadingtabComponent } from './readingtab/readingtab.component';
import { ComplicationComponent } from './complication/complication.component';
import { FootassessmentComponent } from './footassessment/footassessment.component';
import { GeneralReportComponent } from './general-report/general-report.component';
import { DietplanEditModule } from './dietplan-edit/dietplan-edit.module';
import { DietplanhistorypopupModule } from './dietplanhistorypopup/dietplanhistorypopup.module';
import { DentalProbingdepthpopupModule } from './dental-probingdepthpopup/dental-probingdepthpopup.module';
import { DentalexaminationComponent } from './dentalexamination/dentalexamination.component';
import { DentalGumsTeethComponent } from './dental-gums-teeth/dental-gums-teeth.component';
import { DentalplantabComponent } from './dentalplantab/dentalplantab.component';
import { DentalReportComponent } from './dental-report/dental-report.component';
import { DentalSpecalityComponent } from './dental-specality/dental-specality.component';
import { DentalSplBirthHistPaneComponent } from './dental-spl-birth-hist-pane/dental-spl-birth-hist-pane.component';
import { DentalSplDentalHistComponent } from './dental-spl-dental-hist/dental-spl-dental-hist.component';
import { DentalSplGeneralHistPaneComponent } from './dental-spl-general-hist-pane/dental-spl-general-hist-pane.component';
import { DentalSplNataHistPaneComponent } from './dental-spl-nata-hist-pane/dental-spl-nata-hist-pane.component';
import { MaterialorderComponent } from './materialorder/materialorder.component';
import { PediatricsImmunizationComponent } from './pediatrics-immunization/pediatrics-immunization.component';
import { DoctorProfileUpdateComponent } from './doctor-profile-update/doctor-profile-update.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PermissionComponent } from './permission/permission.component';
import { ChangePasswordModule } from '../common-module/change-password/change-password.module';
import { TranslateModule } from '@ngx-translate/core';
import { MedprespopuppageModule } from './med-pres-popup-page/med-pres-popup-page.module';
import { CancelAppointmentModule } from './cancel-appointment/cancel-appointment.module';
import { DiabeticpopuphistoryModule } from './diabeticpopuphistory/diabeticpopuphistory.module';
import { ApgarscorePopupModule } from './apgarscore/apgarscore.module';
import { PediatricsImmunizationRemarksModule } from './pediatrics-immunization-remarks/pediatrics-immunization-remarks.module';
import { PediatricsAddVaccineModule } from './pediatrics-add-vaccine/pediatrics-add-vaccine.module';
import { OthersPersonalComponent } from './others-personal/others-personal.component';
import { NephroGraphPopupModule } from './nephro-graph-popup/nephro-graph-popup.module';
import { GynacDisSummaryComponent } from './gynac-dis-summary/gynac-dis-summary.component';
import { InpatMedPresViewModule } from './inpat-medpresc-view/inpat-medpresc-view.module';
import { CardiopopuphistoryModule } from './cardio-popup-history/cardio-popup-history.module';
import { ReportsComponent } from './reports/reports.component';
import { EntClinicalExamComponent } from './ent-clinical-exam/ent-clinical-exam.component';
import { EntpersonalComponent } from './entpersonal/entpersonal.component';
import { EntdiagnosisComponent } from './entdiagnosis/entdiagnosis.component';
import { BillHistViewModule } from './bill-hist-view/bill-hist-view.module';
import { UploadReportModule } from './upload-report/upload-report-module';
import { DentalteethpopupModule } from './dentalteethpopup/dentalteethpopup.module';
import { DropDownModelPageModule } from './dropdown-modelpage/dropdown-modelpage.module';
import { PediatricGraphPopupModule } from './pediatric-graph-popup/pediatric-graph-popup.module';
import { CasesheetListComponent } from './casesheet-list/casesheet-list.component';
import { CardioCasesheetViewComponent } from './cardio-casesheet-view/cardio-casesheet-view.component';
import { NephrohistoryPopupModule } from './nephro-history/nephro-history.module';
import { DocumentDetailsZoomModule } from './document-details-zoom/document-details-zoom.module';
import { VitalsComponent } from './vitals/vitals.component';
import { TermsConditionModule } from './terms-condition/terms-condition.module';
import { ReferenceVideoComponent } from './reference-video/reference-video.component';
import { DocSalesReportComponent } from './doc-sales-report/doc-sales-report.component';
import { DocReceivablesReportComponent } from './doc-receivables-report/doc-receivables-report.component';
import { DocStockReportComponent } from './doc-stock-report/doc-stock-report.component';
import { VideolinkModule } from './video-link/video-link.module';
import { IpatientBillCreateComponent } from './ipatient-bill-create/ipatient-bill-create.component';
import { InpatMedicineTrackerModule } from '../Nurse_module/inpat-medicine-tracker/inpat-medicine-tracker.module';
import { InpatienCardComponent } from './inpatien-card/inpatien-card.component';
import { ConsolidatedBillsComponent } from './consolidated-bills/consolidated-bills.component';
import { NewDietplanComponent } from './new-dietplan/new-dietplan.component';
import { CasesheetDietTrackingComponent } from './casesheet-diet-tracking/casesheet-diet-tracking.component';
import { DocAppCreateComponent } from './doc-app-create/doc-app-create.component';
import { GastroClinicalExamComponent } from './gastro-clinical-exam/gastro-clinical-exam.component';
import { PharmaSalesReportComponent} from './pharma-sales-report/pharma-sales-report.component';
import { MedDiagPresNocaseComponent } from './med-diag-pres-nocase/med-diag-pres-nocase.component';
import { ViewMedpresComponent } from './view-medpres/view-medpres.component';
import { MedPresViewComponent } from './med-pres-view/med-pres-view.component';
import { PresDialogComponent } from './pres-dialog/pres-dialog.component';
import { EditPrescriptionComponent } from './edit-prescription/edit-prescription.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { AssessmentComponent } from './assessment/assessment.component';
import { DischargeTimelineComponent } from './discharge-timeline/discharge-timeline.component';
import { NextVisitReportComponent } from './next-visit-report/next-visit-report.component';
@NgModule({
  declarations: [
    DoctorViewComponent,
    GeneralPersonalComponent,
    InpatPatientListComponent,
    DoctorAppListComponent,
    InpatPatientInfoComponent,
    CasesheetPersonalComponent,
    DoctorhomeComponent,
    NoappointmentComponent,
    DoctorbilllistpageComponent,
    BillCreateComponent,
    BillDetailViewComponent,
    BillEditComponent,
    InpatDischargeSummaryComponent,
    InpatDashboardComponent,
    InpatDischargeViewComponent,
    HelpComponent,
    DoctorNotificationComponent,
    CasesheetComponent,
    DoctorScheduleListPageComponent,
    DoctorAvailableNonavailableScedulePageComponent,
    DoctorManageScheduleMainComponent,
    SecondOpinionComponent,
    SecondOpinionCasesheetComponent,
    SecondOpinionCasesheetApptdetailsComponent,
    SecondOpinionCasesheetFamilyhistoryComponent,
    SecondOpinionCasesheetHmComponent,
    SecondOpinionCasesheetObservationsComponent,
    SecondOpinionCasesheetPersonalComponent,
    SecondOpinionCasesheetReportsComponent,
    HomecareComponent,
    AppoinmentviewComponent,
    DiagPrescriptionComponent,
    DischargeSummaryComponent,
    FamilyHistoryComponent,
    GynClinicalExamComponent,
    ClinicalExamComponent,
    MedDiagPrescriptionComponent,
    DiagnosisComponent,
    GynPersonalComponent,
    GynSurgicalHistoryComponent,
    HabitualinfoComponent,
    ObstetricHistoryComponent,
    CardDietplanComponent,
    OthersClinicalExamComponent,
    OthersPersonalComponent,
    PreviousPrescriptionPopupComponent,
    NephrologyBmiVitalsComponent,
    NephrologyDiagGraphComponent,
    NephrologyDiagnosisComponent,
    NephrologyPhysicalComponent,
    PediatricsDevelopmentComponent,
    PediatricsHeadCardiovascularComponent,
    PediatricsImmunizationComponent,
    PediatricsPersonalComponent,
    PediatricsPregnancyDeliveryComponent,
    PediatricsVitalsComponent,
    GeneralSurgeryComponent,
    GeneralReportComponent,
    FootassessmentComponent,
    UploadtabComponent,
    ReadingtabComponent,
    ComplicationComponent,
    DentalexaminationComponent,
    DentalGumsTeethComponent,
    DentalplantabComponent,
    DentalReportComponent,
    DentalSpecalityComponent,
    DentalSplBirthHistPaneComponent,
    DentalSplDentalHistComponent,
    DentalSplGeneralHistPaneComponent,
    DentalSplNataHistPaneComponent,
    MaterialorderComponent,
    DoctorProfileUpdateComponent,
    PermissionComponent,
    GynacDisSummaryComponent,
    ReportsComponent,
    EntClinicalExamComponent,
    EntpersonalComponent,
    EntdiagnosisComponent,
    CasesheetListComponent,
    CardioCasesheetViewComponent,
    VitalsComponent,
    ReferenceVideoComponent,
    DocSalesReportComponent,
    DocReceivablesReportComponent,
    DocStockReportComponent,
    IpatientBillCreateComponent,
    InpatienCardComponent,
    NewDietplanComponent,
    ConsolidatedBillsComponent,
    CasesheetDietTrackingComponent,
    DocAppCreateComponent,
    GastroClinicalExamComponent,
    PharmaSalesReportComponent,
    MedDiagPresNocaseComponent,
    ViewMedpresComponent,
    MedPresViewComponent,
    PresDialogComponent,
    EditPrescriptionComponent,
    AssessmentComponent,
    DischargeTimelineComponent,
    NextVisitReportComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatSliderModule,
    RouterModule,
    MatSelectModule,
    MatOptionModule,
    ScrollingModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatDividerModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatListModule,
    MatExpansionModule,
    MatRadioModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    NgxPrintModule,
    AngularSplitModule,
    MatCardModule,
    MatDialogModule,
    DataTablesModule,
    GynoObestricPopupModule,
    CarouselModule,
    PopupDoctorScheduleListModule,
    SecondOpinionMedicationPopupModule,
    SecondOpinionPresviewPopupPopupModule,
    SecondOpinionReportdetailviewPopupModule,
    IcdCodePopupModule,
    PreviousprescriptionPopupModule,
    DietpopupwindowModule,
    DietplanhistorypopupModule,
    DietplanEditModule,
    DentalProbingdepthpopupModule,
    NgbModule,
    MatButtonToggleModule,
    ChangePasswordModule,
    TranslateModule,
    MedprespopuppageModule,
    DiabeticpopuphistoryModule,
    ApgarscorePopupModule,
    PediatricsImmunizationRemarksModule,
    PediatricGraphPopupModule,
    PediatricsAddVaccineModule,
    ApgarscorePopupModule,
    PediatricsAddVaccineModule,
    PediatricsImmunizationRemarksModule,
    InpatMedPresViewModule,
    TabsModule,
    CancelAppointmentModule,
    NephroGraphPopupModule,
    CardiopopuphistoryModule,
    BillHistViewModule,
    UploadReportModule,
    DentalteethpopupModule,
    DropDownModelPageModule,
    NephrohistoryPopupModule,
    DocumentDetailsZoomModule,
    InpatMedicineTrackerModule,
    VideocallModule,
    VideolinkModule,
    TermsConditionModule,
    QuillModule.forRoot(),
    DataTablesModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center'
    }),
    ToastNoAnimationModule.forRoot(),
    NgxBarcodeModule
  ],
  exports: [
    BrowserModule,
    CommonModule,
    MatDialogModule,
    MatNativeDateModule,
    TranslateModule,
    CasesheetComponent,
    GynoObestricPopupModule,
    DropDownModelPageModule,
    DietplanhistorypopupModule,
    InpatPatientInfoComponent,
    CasesheetComponent,
    CasesheetListComponent,
    CardioCasesheetViewComponent,
    IpatientBillCreateComponent,
    NewDietplanComponent,
    DoctorhomeComponent,
    DoctorNotificationComponent,
    DoctorbilllistpageComponent,
    BillCreateComponent,
    BillDetailViewComponent,
    BillEditComponent,
    ReferenceVideoComponent,
    InpatPatientListComponent,
    DoctorAppListComponent,
    NoappointmentComponent,
    InpatDischargeSummaryComponent,
    InpatDashboardComponent,
    InpatDischargeViewComponent,
    GynacDisSummaryComponent,
    HelpComponent,
    DoctorScheduleListPageComponent,
    DoctorAvailableNonavailableScedulePageComponent,
    DoctorManageScheduleMainComponent,
    HomecareComponent,
    SecondOpinionComponent,
    SecondOpinionCasesheetComponent,
    DoctorProfileUpdateComponent,
    PermissionComponent,
    MedDiagPrescriptionComponent,
    AppoinmentviewComponent,
    DocSalesReportComponent,
    DocReceivablesReportComponent,
    DocStockReportComponent,
    InpatienCardComponent,
    ConsolidatedBillsComponent,
    DocAppCreateComponent,
    PharmaSalesReportComponent,
    MedDiagPresNocaseComponent,
    EditPrescriptionComponent,
    MedPresViewComponent,
    AssessmentComponent,
    ReportsComponent,
    DiagPrescriptionComponent,
    DischargeTimelineComponent,
    NextVisitReportComponent
  ],
  providers: [DatePipe],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class DoctorModule { }
