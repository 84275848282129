<div class="row" style="margin:0 auto; width:100%">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white ">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Diagnostic order</h5>
               </div>
               <div class="headerButtons">
                  <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo"
                     (click)="backOption()" />
                  <img src="../../../assets/ui_icons/buttons/Book_now.svg" class="saveimgbtn_inpatinfo"
                     (click)="saveApp()" [hidden]="saveFlag" />
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div class="row">
               <div class="col-6" *ngIf="show_patType_selection">
                  <div class="row">
                     <div class="col-12 col-sm-12" style="margin: 15px 0 15px 0;">
                        <div class="row">
                           <div class="col-4">
                              <mat-label class="matlabel" style="font-weight: 600;">Appointment Type :
                              </mat-label>
                           </div>
                           <div class="col-5">
                              <div class="switch-field">
                                 <input type="radio" id="radio-one" (click)="changeClientType('patient',1,'yes')" />
                                 <label for="radio-one"
                                    [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">Out-patient</label>
                                 <input type="radio" id="radio-two" (click)="changeClientType('patient',1,'no')" />
                                 <label for="radio-two"
                                    [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">In-patient</label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-6" *ngIf="showclient">
                  <div class="row">
                     <div class="col-12 col-sm-12" style="margin: 15px 0 15px 0;">
                        <div class="row">
                           <div class="col-3">
                              <mat-label class="matlabel" style="font-weight: 600;">Client Type : </mat-label>
                           </div>
                           <div class="col-6">
                              <div class="switch-field">
                                 <input type="radio" id="radio-three" (click)="changeClientType('client',1,'yes')"
                                    [disabled]="inpatient" />
                                 <label for="radio-three"
                                    [ngClass]="ynopt2 ? 'radio_active':'radio_inactive'">New</label>
                                 <input type="radio" id="radio-four" (click)="changeClientType('client',1,'no')"
                                    [disabled]="inpatient" />
                                 <label for="radio-four"
                                    [ngClass]="!ynopt2 ? 'radio_active':'radio_inactive'">Existing</label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="container-fluid" style="margin-left: -20px;">
                  <table style="width: 100%;">
                     <tr>
                        <td colspan="4" style="vertical-align: top !important;width: 24%;">
                           <div class="cover_div" style="padding-bottom: 13px;">
                              <div class="header_lable">Patient</div>
                              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                 <div class="row">
                                    <div class="col-12">
                                       <div class="switch-field">
                                          <input type="radio" id="radio-seven" (click)="searchType('patientid');" />
                                          <label for="radio-seven"
                                             [ngClass]="typeflag == 'patientid' ? 'radio_active':'radio_inactive'">Mr
                                             no</label>
                                          <input type="radio" id="radio-five" (click)="searchType('name')" />
                                          <label for="radio-five"
                                             [ngClass]="typeflag == 'name' ? 'radio_active':'radio_inactive'">Name</label>
                                          <input type="radio" id="radio-six" (click)="searchType('mobile')" />
                                          <label for="radio-six"
                                             [ngClass]="typeflag == 'mobile' ? 'radio_active':'radio_inactive'">Mobile</label>

                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div class="content_cover">
                                 <div>
                                    <input #focusMRNo id="focusMRNo filterInput" class="inputStyle2" type="text"
                                       [(ngModel)]="patientName" placeholder={{searchtext}}
                                       (keyup)="getPatientListByName(patientName)">
                                    <!-- <input class="inputStyle2" type="text" id="filterInput" [(ngModel)]="patientName"
                                       placeholder={{searchtext}} (keyup)="getPatientListByName(patientName)"
                                       style="margin-top: 5px !important;"> -->
                                    <div class="scrollbar" #scrollContainer (scroll)="onScroll($event)">
                                       <div class="scrollbar-inner">
                                          <table *ngIf="patientList.length">
                                             <tbody>
                                                <tr *ngFor="let person of filteredArr; let i = index"
                                                   (click)='retrieveClient(person,person.pres_diag_id)'>
                                                   <td style="font-size: 12px;text-align: left;">{{
                                                      person.full_name }} <br /> {{
                                                      person.mobile }}<br />
                                                      {{person.patient_id}} </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </td>
                        <td colspan="8" style="vertical-align: top !important;">
                           <div class="cover_div" style="padding-bottom: 13px;">
                              <div class="header_lable">Personal Details</div>
                              <div class="content_cover">
                                 <div class="row">
                                    <div class="col-2" style="text-align: center;">
                                       <img src="{{this.profileImg}}"
                                          onerror="this.src='../../../assets/img/default.jpg';"
                                          class="img-fluid z-depth-1 rounded-circle mr-1 profileimage">
                                    </div>
                                    <div class="col-10">
                                       <div class="row">
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">MR no:</span>
                                                {{this.mrno}}</p>
                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">Name:</span>
                                                {{this.clientname}}</p>
                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">DOB:</span>
                                                {{this.clntDOB}}</p>
                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">Age:</span>
                                                {{clntAge}} </p>
                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">Gender:</span>
                                                {{clntGender}} </p>
                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">Mobile:</span>
                                                {{clntContNumber}} </p>

                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">Emergency
                                                   contact 1:</span> {{clntEmergNumber}} </p>

                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">Emergency
                                                   contact 2:</span> {{clntEmergNumber1}} </p>

                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">Aadhar Number:</span>
                                                {{aadharNumber}} </p>

                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">Whatsapp
                                                   Number:</span> {{whatsappNumber}} </p>

                                          </div>

                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">Address:</span>
                                                {{this.clntAddress1}}</p>
                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">Address2:</span>
                                                {{this.clntAddress2}}</p>
                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal"> Zipcode:</span>
                                                {{this.clntZipcode}} </p>
                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">Location:</span>
                                                {{this.clntLocation}} </p>
                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal">District /
                                                   City:</span> {{this.clntCity}}
                                             </p>
                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal"> State:</span>
                                                {{this.clntState}}</p>
                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"> <span class="fontweight_normal">Country:</span>
                                                {{this.clntCountry}}</p>
                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"><span class="fontweight_normal"> Channel:</span>
                                                {{this.ref_source}}</p>
                                          </div>
                                          <div class="col-3">
                                             <p class="textformat"> <span class="fontweight_normal">Referred by:</span>
                                                {{this.referredDoctor}}
                                             </p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="cover_div" style="padding-bottom: 13px;">
                              <div class="header_lable">
                                 Appointment Details
                              </div>
                              <div class="content_cover">
                                 <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " [hidden]="docflag">
                                       <mat-label class="matlabel">Doctor
                                          <select [(ngModel)]="doctorID" class="ipcss">
                                             <option [ngValue]="Select">Select</option>
                                             <option *ngFor="let doctor of doctorList" value={{doctor.prov_id}}>
                                                {{doctor.name}}
                                             </option>
                                          </select>
                                       </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 ">
                                       <mat-label class="matlabel">Booking date
                                          <input type="date" class="ipcss" max="9999-12-31" [(ngModel)]="appDate"
                                             (click)="clickAppDate(appDate)" min="{{currentDate}}">
                                       </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 ">
                                       <mat-label class="matlabel">Booking Session
                                          <select class="ipcss" [(ngModel)]="visitSession" (change)="selectSession()">
                                             <option *ngFor="let session of getVisitingSessionArray">
                                                {{session.description}}
                                             </option>
                                          </select>
                                       </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" [hidden]="sessionFlag">
                                       <mat-label class="matlabel">Time</mat-label>
                                       <div class="row">
                                          <div class="col-6" style="padding-right: 0px;">
                                             <select class="ipcss  " [(ngModel)]="startTime">
                                                <option *ngFor="let name of sessionTimeStart" value="{{name}}">{{name}}
                                                </option>
                                             </select>
                                          </div>
                                          <div class="col-6" [hidden]="sessionFlag" style="padding-right: 0px;">
                                             <select class="ipcss " [(ngModel)]="endTime">
                                                <option *ngFor="let name of sessionTimeEnd" value="{{name}}">{{name}}
                                                </option>
                                             </select>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 " [hidden]="paytypreq">
                                       <mat-label class="matlabel">Payment type<br>
                                          <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType"
                                             (selectionChange)="changePay()" disableOptionCentering>
                                             <mat-option *ngFor="let pay of paymentArray"
                                                value={{pay.pay_id}}>{{pay.pay_desc}}
                                             </mat-option>
                                          </mat-select>
                                       </mat-label>
                                    </div>

                                    <!-- Button trigger modal -->
                                    <div *ngIf="addtest_Flag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 ">
                                       <img data-bs-toggle="modal" data-bs-target="#addTestModal"
                                          class="saveimgbtn_inpatinfo" style="margin-top: 20px;" (click)="search_test()"
                                          src="../../../assets/ui_icons/buttons/add_test_button.svg" />
                                    </div>

                                    <!-- Modal -->
                                    <div class="modal fade" id="addTestModal" tabindex="-1"
                                       aria-labelledby="addTestModalLabel" aria-hidden="true">
                                       <div class="modal-dialog modal-xl">
                                          <div class="modal-content">
                                             <div class="modal-body">
                                                <div class="mx-3 mt-3">
                                                   <div class="row mt-3">
                                                      <div class="col-6">
                                                         <input type="text" class="ipcss" aria-label="Number"
                                                            [(ngModel)]="LabtestName" (keyup)="mainFilter(LabtestName)"
                                                            placeholder="Search tests" matInput />
                                                      </div>
                                                   </div>
                                                   <div class="row mt-3" *ngIf="testFlag">
                                                      <div class="col-12 col-sm-12 col-xl-7 col-lg-7 col-md-7">
                                                         <div class="row">
                                                            <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6">
                                                               <div class="diagnosis_testgrid testtype ">
                                                                  <p class="titletest">

                                                                     <strong>Laboratory tests</strong>
                                                                  </p>
                                                                  <div class="diag_test_content_cover p-3">
                                                                     <div *ngFor="let subtest of filteredLabArray"
                                                                        id="testlbel">
                                                                        <span *ngIf="subtest.checked == true"
                                                                           class="check_img">
                                                                           <img
                                                                              (click)="ChangeTestType(false,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'labtest')"
                                                                              src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                              style="cursor: pointer;"
                                                                              width="12px" /></span>

                                                                        <span *ngIf="subtest.checked == false"
                                                                           class="check_img">
                                                                           <img
                                                                              (click)="ChangeTestType(true,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'labtest')"
                                                                              src="../../../assets/ui_icons/yellow_box.svg"
                                                                              style="cursor: pointer;"
                                                                              width="12px" /></span>
                                                                        {{subtest.test_name}}
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                            <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6">
                                                               <div class="diagnosis_testgrid testtype ">
                                                                  <p class="titletest">

                                                                     <strong>Radiology tests</strong>
                                                                  </p>
                                                                  <div class="diag_test_content_cover p-3">
                                                                     <div *ngFor="let subtest of filteredRadioArray"
                                                                        id="testlbel">
                                                                        <span *ngIf="subtest.checked == true"
                                                                           class="check_img">
                                                                           <img
                                                                              (click)="ChangeTestType(false,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'radiotest')"
                                                                              src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                              style="cursor: pointer;"
                                                                              width="12px" /></span>

                                                                        <span *ngIf="subtest.checked == false"
                                                                           class="check_img">
                                                                           <img
                                                                              (click)="ChangeTestType(true,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'radiotest')"
                                                                              src="../../../assets/ui_icons/yellow_box.svg"
                                                                              style="cursor: pointer;"
                                                                              width="12px" /></span>
                                                                        {{subtest.test_name}}
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>

                                                            <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6">
                                                               <div class="diagnosis_testgrid testtype ">
                                                                  <p class="titletest">

                                                                     <strong>Microbiology tests</strong>
                                                                  </p>
                                                                  <div class="diag_test_content_cover p-3">
                                                                     <div *ngFor="let subtest of filteredMicroArray"
                                                                        id="testlbel">
                                                                        <span *ngIf="subtest.checked == true"
                                                                           class="check_img">
                                                                           <img
                                                                              (click)="ChangeTestType(false,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'microtest')"
                                                                              src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                              style="cursor: pointer;"
                                                                              width="12px" /></span>

                                                                        <span *ngIf="subtest.checked == false"
                                                                           class="check_img">
                                                                           <img
                                                                              (click)="ChangeTestType(true,subtest.diag_tests_id, subtest.test_name, subtest.test_cat, subtest, subtest.test_type, 'microtest')"
                                                                              src="../../../assets/ui_icons/yellow_box.svg"
                                                                              style="cursor: pointer;"
                                                                              width="12px" /></span>
                                                                        {{subtest.test_name}}
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>

                                                            <div class="col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6">
                                                               <div class="diagnosis_testgrid testtype ">
                                                                  <p class="titletest">
                                                                     <strong>Profiles & packages</strong>
                                                                  </p>
                                                                  <div class="diag_test_content_cover p-3">
                                                                     <!-- <input type="text" class="ipcss" aria-label="Number" [(ngModel)]="profilesName" placeholder="Search tests" matInput /> -->
                                                                     <div *ngFor="let package of filteredProfilesArray"
                                                                        id="testlbel">
                                                                        <span *ngIf="package.checked == true"
                                                                           class="check_img">
                                                                           <img
                                                                              (click)="clickTest(false, package.diag_profile_id)"
                                                                              src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                              style="cursor: pointer;"
                                                                              width="12px" /></span>

                                                                        <span *ngIf="package.checked == false"
                                                                           class="check_img">
                                                                           <img
                                                                              (click)="clickTest(true, package.diag_profile_id)"
                                                                              src="../../../assets/ui_icons/yellow_box.svg"
                                                                              style="cursor: pointer;"
                                                                              width="12px" /></span>
                                                                        {{package.profile_name}}
                                                                     </div>
                                                                     <div *ngFor="let packages of filteredPackagesArray"
                                                                        id="testlbel">
                                                                        <span *ngIf="packages.checked == true"
                                                                           class="check_img">
                                                                           <img
                                                                              (click)="clickPackage(false, packages.health_pkg_id)"
                                                                              src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                              style="cursor: pointer;"
                                                                              width="12px" /></span>

                                                                        <span *ngIf="packages.checked == false"
                                                                           class="check_img">
                                                                           <img
                                                                              (click)="clickPackage(true, packages.health_pkg_id)"
                                                                              src="../../../assets/ui_icons/yellow_box.svg"
                                                                              style="cursor: pointer;"
                                                                              width="12px" /></span>
                                                                        {{packages.package_name}}
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                            <!-- <div class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-4">
                                                               <div class="diagnosis_testgrid testtype ">
                                                                  <p class="titletest">
                                                                     <strong>Packages</strong>
                                                                  </p>
                                                                  <div class="diag_test_content_cover p-3">
                                                                     <div *ngFor="let package of packages_array"
                                                                        id="testlbel">
                                                                        <span *ngIf="package.checked == true"
                                                                           class="check_img">
                                                                           <img
                                                                              (click)="clickPackage(false, package.health_pkg_id)"
                                                                              src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                                              style="cursor: pointer;"
                                                                              width="12px" /></span>

                                                                        <span *ngIf="package.checked == false"
                                                                           class="check_img">
                                                                           <img
                                                                              (click)="clickPackage(true, package.health_pkg_id)"
                                                                              src="../../../assets/ui_icons/yellow_box.svg"
                                                                              style="cursor: pointer;"
                                                                              width="12px" /></span>
                                                                        {{package.package_name}}
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                           
                                                            </div> -->
                                                         </div>
                                                      </div>

                                                      <div class="col-12 col-sm-12 col-xl-4 col-lg-4 col-md-4">
                                                         <div class="container-fluid my-3"
                                                            *ngIf="labarray.length != 0 ">
                                                            <h2 *ngIf="labarray.length != 0" class=" tervys_heading1">
                                                               Laboratory tests</h2>
                                                            <div class="row">
                                                               <div *ngFor="let test of labarray"
                                                                  class="col-12 card-container-2 d-flex flex-row align-items-center justify-content-between">
                                                                  <p style="margin: 0px !important;font-weight: 500;
                                                                               font-size: 11px;">{{test.test_name}}
                                                                     {{test.TAT}}</p>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div class="container-fluid my-3"
                                                            *ngIf="radioarray.length != 0 ">
                                                            <h2 *ngIf="radioarray.length != 0" class=" tervys_heading1">
                                                               Radiology tests</h2>
                                                            <div class="row">
                                                               <div *ngFor="let test of radioarray"
                                                                  class="col-12 card-container-2 d-flex flex-row align-items-center justify-content-between">
                                                                  <p style="margin: 0px !important;font-weight: 500;
                                                                               font-size: 11px;">{{test.test_name}}
                                                                     {{test.TAT}}</p>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div class="container-fluid my-3"
                                                            *ngIf="selected_profiles.length != 0 ">
                                                            <h2 *ngIf="selected_profiles.length != 0"
                                                               class=" tervys_heading1">Profiles
                                                            </h2>
                                                            <div class="row">
                                                               <!-- <div *ngFor="let profile of selected_profiles"
                                                                  class="col-12 card-container-2 d-flex flex-row align-items-center justify-content-between">
                                                                  <p style="margin: 0px !important;font-weight: 500;
                                                                               font-size: 11px;">
                                                                     {{profile.profile_name}}</p>
                                                               </div> -->

                                                               <div *ngFor="let profile of selected_profiles"
                                                                  class="col-12 card-container-2 d-flex flex-column align-items-left justify-content-between"
                                                                  data-bs-toggle="collapse"
                                                                  [attr.data-bs-target]="'#'+ 'target' +profile.diag_profile_id"
                                                                  [attr.aria-controls]="profile.diag_profile_id"
                                                                  aria-expanded="false">
                                                                  <p
                                                                     style="margin: 0px !important;font-weight: 500;font-size: 11px;">
                                                                     {{profile.profile_name}}</p>
                                                                  <div style="width: 85%;" class="collapse"
                                                                     [attr.id]="'target' +profile.diag_profile_id">
                                                                     <ul style="padding-left: 20px !important;">
                                                                        <li *ngFor="let test of profile.tests">
                                                                           {{test.test_name}}</li>
                                                                     </ul>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>

                                                         <div class="container-fluid my-3"
                                                            *ngIf="selected_packages.length != 0 ">
                                                            <h2 *ngIf="selected_packages.length != 0"
                                                               class=" tervys_heading1">Packages</h2>
                                                            <div class="row">
                                                               <div *ngFor="let package of selected_packages"
                                                                  class="col-12 card-container-2 d-flex flex-column align-items-left justify-content-between"
                                                                  data-bs-toggle="collapse"
                                                                  [attr.data-bs-target]="'#'+ 'target' +package.health_pkg_id"
                                                                  [attr.aria-controls]="package.health_pkg_id"
                                                                  aria-expanded="false">
                                                                  <p
                                                                     style="margin: 0px !important;font-weight: 500;font-size: 11px;">
                                                                     {{package.package_name}}</p>
                                                                  <div style="width: 85%;" class="collapse"
                                                                     [attr.id]="'target' +package.health_pkg_id">
                                                                     <ul style="padding-left: 20px !important;">
                                                                        <li *ngFor="let test of package.tests">
                                                                           {{test.test_name}}</li>
                                                                     </ul>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div class="container" *ngIf="packageFlag">
                                                      <div class="row">
                                                         <div class="col-4" *ngFor="let package of testGroupArray">
                                                            <div class="plan">
                                                               <div class="inner">
                                                                  <span class="pricing">
                                                                     <span>
                                                                        {{package.price}}<small>/Rs</small>
                                                                     </span>
                                                                  </span>
                                                                  <p class="title">{{package.package_name}}</p>
                                                                  <p class="info">{{package.package_desc}}</p>
                                                                  <ul class="features">
                                                                     <li *ngFor="let test of package.tests">
                                                                        <div>
                                                                           <span class="icon mx-2">
                                                                              <svg height="24" width="24"
                                                                                 viewBox="0 0 24 24"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                 <path d="M0 0h24v24H0z" fill="none">
                                                                                 </path>
                                                                                 <path fill="currentColor"
                                                                                    d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z">
                                                                                 </path>
                                                                              </svg>
                                                                           </span>
                                                                           <span>{{test.test_name}}</span>
                                                                        </div>
                                                                     </li>
                                                                  </ul>
                                                                  <div class="action">
                                                                     <input
                                                                        (change)="clickTest(package.checked,package.diag_packages_id)"
                                                                        type="checkbox" class="ui-checkbox"
                                                                        [(ngModel)]="package.checked"><span
                                                                        class="mx-2">Choose plan</span>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="modal-footer">
                                                <img *ngIf="!packageFlag" data-bs-dismiss="modal"
                                                   class="saveimgbtn_inpatinfo" (click)="addTest2()"
                                                   src="../../../assets/ui_icons/buttons/save_button.svg" />
                                                <img *ngIf="packageFlag" data-bs-dismiss="modal"
                                                   class="saveimgbtn_inpatinfo" (click)="addTest2()"
                                                   src="../../../assets/ui_icons/buttons/save_button.svg" />
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 " [hidden]="insuranceFlag">
                                       <mat-label class="matlabel">Insurer<br>
                                          <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insuranceId"
                                             disableOptionCentering>
                                             <mat-option *ngFor="let ins of insurancyArray" value={{ins.insurer_id}}>
                                                {{ins.insurer_desc}}</mat-option>
                                          </mat-select>
                                       </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom"
                                       [hidden]="cardFlag">
                                       <mat-label class="matlabel">Card No <br>
                                          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                                       </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom"
                                       [hidden]="cardFlag">
                                       <mat-label class="matlabel">Holder name<br>
                                          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
                                       </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom"
                                       [hidden]="tranFlag">
                                       <mat-label class="matlabel">Transaction ID<br>
                                          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionID" />
                                       </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom"
                                       [hidden]="chequeFlag">
                                       <mat-label class="matlabel">Cheque No <br>
                                          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                                       </mat-label>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom"
                                       [hidden]="chequeFlag">
                                       <mat-label class="matlabel">Bank Name <br>
                                          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
                                       </mat-label>
                                    </div>

                                 </div>
                              </div>
                           </div>
                           <div class="row">
                              <!-- bill create -->
                              <div *ngIf="priceTable.length != 0 && diag_app_show_test_det"
                                 class="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12"
                                 style="margin-top: 24px;margin-bottom: 10px !important;">
                                 <div class="dig_table_overflow">
                                    <div class="table-responsive" *ngIf="priceTable.length != 0">
                                       <table class="table table-hover table-dynamic" id="tbl">
                                          <thead>
                                             <tr>
                                                <th>Sno</th>
                                                <th>Description</th>
                                                <th [hidden]="paytypreq">Pay type</th>
                                                <th style="width:12%;">Amount</th>
                                                <!-- <th style="width:12%;">Quantity</th> -->
                                                <th style="width:12%;">Concession (%)</th>
                                                <th style="width:12%;">After Concession</th>
                                                <th style="width:13%;">Priority</th>
                                                <th>Action</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             <tr *ngFor="let price of priceTable">
                                                <td style="text-align: left;">{{price.sno}}</td>
                                                <td style="text-align: left;">{{price.test_name}}</td>
                                                <td style="text-align: left;" [hidden]="paytypreq">
                                                   {{price.pay_type}}</td>
                                                <td>
                                                   <input style="text-align: right;" class="ipcss"
                                                      [(ngModel)]="price.amount_txt"
                                                      (keyup)="changeAmount(price.quantity,price.amount_txt,price.concession,price.test_id,price.type_of_test)"
                                                      matInput />
                                                </td>
                                                <!-- <td style="text-align: center;">
                                                   <input style="text-align: right;" class="ipcss" type="number"
                                                      [(ngModel)]="price.quantity" value="1"
                                                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                      (keyup)="changeAmount(price.quantity,price.amount_txt,price.concession,price.test_id, price.type_of_test)"
                                                      matInput />
                                                </td> -->
                                                <td>
                                                   <input style="text-align: right;" class="ipcss"
                                                      [(ngModel)]="price.concession"
                                                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                      (keyup)="changeAmount(price.quantity,price.amount_txt,price.concession,price.test_id, price.type_of_test)"
                                                      matInput />
                                                </td>
                                                <td>
                                                   <input style="text-align: right;" class="ipcss" disabled="true"
                                                      [(ngModel)]="price.fee" matInput />
                                                </td>
                                                <td>
                                                   <select class="ipcss" [(ngModel)]="price.priority">
                                                      <option value="0"> Normal </option>
                                                      <option value="1"> High </option>
                                                   </select>
                                                </td>
                                                <td>
                                                   <img (click)="removeTest(price.test_id, price.type_of_test, price)"
                                                      src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px"
                                                      height="15px" />
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                              <div class="container-fluid selectedTest my-3"
                                 *ngIf="priceTable.length != 0 && !diag_app_show_test_det">
                                 <h2 *ngIf="priceTable.length != 0" class="tervys_heading2">Selected Tests
                                 </h2>
                                 <div class="row">
                                    <div *ngFor="let price of priceTable"
                                       class="col-3 card-container d-flex flex-row align-items-center justify-content-between">
                                       <p style="margin: 0px !important;">{{price.test_name}}</p>
                                       <img (click)="removeTest(price.test_id, price.type_of_test, price)"
                                          src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px"
                                          height="15px" />
                                    </div>
                                 </div>
                              </div>
                              <!-- doctor  -->
                              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                 <div class="row">
                                    <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                       <div class="row"
                                          *ngIf="paytypreq == true && userinfo.diag_bill_generate == '1' && priceTable.length != 0">
                                          <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 select_bottom">
                                             <mat-label class="matlabel">Payment mode<br>
                                                <mat-select class="ipcss " [(ngModel)]="payType1"
                                                   disableOptionCentering>
                                                   <mat-option *ngFor="let pay of paymentArray" value={{pay.pay_id}}>
                                                      {{pay.pay_desc}} </mat-option>
                                                </mat-select>
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-3">
                                             <mat-label class="matlabel">Amount<br>
                                                <input class="ipcss billing_text_align align_right"
                                                   [(ngModel)]="amountval" matInput required maxlength="12" />
                                             </mat-label>
                                          </div>
                                          <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                                             <mat-label class="matlabel ">
                                                <img src="../../../assets/ui_icons/buttons/add_button.svg"
                                                   class="saveimgbtn_inpatinfo add_align btn-outline-success rounded"
                                                   (click)="addPaymentDetails()"
                                                   style="float: right;margin-top: 20px;cursor: pointer;" />
                                             </mat-label>
                                          </div>
                                       </div>
                                       <div class="row">
                                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                             <table>
                                                <tr *ngFor="let pay of paymentsarray">
                                                   <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                                                   <td style="width: 150px !important;">{{pay.amount}}</td>
                                                   <td>
                                                      <img src="../../../assets/img/edit.png" class="billing_edit"
                                                         (click)="editPayment(pay)">
                                                      &nbsp;&nbsp;
                                                      <img src="../../../assets/img/Delete.png" class="billing_edit"
                                                         (click)="deletePayment(pay)">
                                                   </td>
                                                </tr>
                                             </table>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                       <div class="col-0 col-sm-6 col-md-3 col-lg-3 col-xl-12">
                                          <div class="row" *ngIf="priceTable.length != 0">
                                             <div class="col-12" *ngIf="userinfo.diag_bill_generate == '1'">
                                                <div class="row" style="float: right;">
                                                   <div class="col-2" style="width: 193px;">
                                                      <mat-label class="matlabel"
                                                         style="float: right;padding: 5px 0 0 0;">Total amount
                                                      </mat-label>
                                                   </div>
                                                   <div class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-5"
                                                      style="padding-right: 34px;">
                                                      <input type="number" class="ipcss" [(ngModel)]="balance"
                                                         style="text-align: right;" disabled matInput>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="col-12" *ngIf="userinfo.diag_bill_generate == '1'">
                                                <div class="row" style="float: right;">
                                                   <div class="col-2" style="width: 193px;">
                                                      <mat-label class="matlabel"
                                                         style="float: right;padding: 5px 0 0 0;">Discount
                                                      </mat-label>
                                                   </div>
                                                   <div class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-5"
                                                      style="padding-right: 34px;">
                                                      <input type="number" class="ipcss" [(ngModel)]="discount_amt"
                                                         style="text-align: right;" disabled matInput>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="col-12">
                                                <div class="row" style="float: right;">
                                                   <div class="col-2" style="width: 193px;">
                                                      <mat-label class="matlabel"
                                                         style="float: right;padding: 5px 0 0 0;">Bill amount
                                                      </mat-label>
                                                   </div>
                                                   <div class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-5"
                                                      style="padding-right: 34px;">
                                                      <input type="number" class="ipcss" [(ngModel)]="bill_amount"
                                                         style="text-align: right;" disabled matInput>
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="col-12" *ngIf="userinfo.diag_bill_generate == '1'">
                                                <div class="row" style="float: right;">
                                                   <div class="col-2" style="width: 193px;">
                                                      <mat-label class="matlabel"
                                                         style="float: right;padding: 5px 0 0 0;">Paid
                                                      </mat-label>
                                                   </div>
                                                   <div class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-5"
                                                      style="padding-right: 34px;">
                                                      <input class="ipcss" matInput text-right (keyup)="changeAdvance()"
                                                         [(ngModel)]="advance" maxlength="10"
                                                         style="border-radius: 5px;text-align: right;" />
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="col-12" *ngIf="userinfo.diag_bill_generate == '1'">
                                                <div class="row" style="float: right;">
                                                   <div class="col-2" style="width: 193px;">
                                                      <mat-label class="matlabel"
                                                         style="float: right;padding: 5px 0 0 0;">Balance
                                                      </mat-label>
                                                   </div>
                                                   <div class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-5"
                                                      style="padding-right: 34px;">
                                                      <input class="ipcss" matInput text-right
                                                         [(ngModel)]="remaingBalance" maxlength="10"
                                                         style="border-radius: 5px;text-align: right;" disabled />
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </td>
                     </tr>
                  </table>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>