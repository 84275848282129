import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Subject, Observable } from 'rxjs';
import { ScanDetected } from 'ngx-scanner-detection';
import { NgxBarcodeModule } from 'ngx-barcode';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';


@Component({
  selector: 'app-diag-sample-list-adv',
  templateUrl: './diag-sample-list-adv.component.html',
  styleUrls: ['./diag-sample-list-adv.component.scss']
})
export class DiagSampleListADVComponent implements OnInit {
  public save_btn: boolean = true;
  public sampledate: string;
  public filtduration: string;
  public currentDate: string;
  public sampleList: any = [];
  public selectedList: any = [];
  public sampletype_data: any = [];
  public sampleDetails_data: any = [];
  public testtube_data: any = [];
  dtOptions: DataTables.Settings = {};
  selected_tube_colors: any[];
  public sample_id: any = [];
  public isSelected: boolean;
  public  isHovered: boolean = false;


  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';

  elementType: 'svg';
  lineColor = '#000000';
  width = 0.9;
  barcodeheight = 23;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 11;
  background = '#ffffff';
  margin = 2;
  marginTop = 2;
  marginBottom = 2;
  marginLeft = 2;
  marginRight = 2;

  specimenPageType: any;
  service: any;
  hptl_clinic_id: any;
  public lab_samples: any = [];
  public micro_samples: any = [];
  public all_samples:any=[];

  public newbutton: boolean = false
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public moduleList: any = [];
  public searchInput: any;
 
  patient_name: any;
  mrval: any;
  filteredSamplelistArray: any = [];
  
  constructor(public diagnosisservice: MenuViewService, public toastr: ToastrService,
    public gservice: CommonDataService, public http: HttpClient, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.filtduration = "select";
    this.specimenPageType = Diagnosis_Helper.getReportType();
    this.getdate();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[0, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by test name or count"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.hptl_clinic_id = Helper_Class.getInfo().diag_centre_id;
    } else {
      this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    }
    this.getSampleTypes();
    this.getDiagTesttubes();

    this.moduleList = Helper_Class.getmodulelist();

    if (this.moduleList != undefined) {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.moduleList[i].module_id == "101") {  
          console.log(JSON.stringify(this.moduleList[i]));
          if (this.moduleList[i].edit == "1") {
            this.editbutton = true;//update button
          }
          if (this.moduleList[i].create == "1") {
            this.newbutton = true;
          }
          if (this.moduleList[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.moduleList[i].print == "1") {
            this.printbutton = true;
          }
          if (this.moduleList[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }
  }

  getdate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", ({ country: ipaddress.country_code }), { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        this.currentDate = response.current_date;
        this.sampledate = this.currentDate;
        this.getSampleList(this.sampledate)
      }, error => { });
  }

  getSampleList(date) {
    var durval;
    if (this.filtduration != 'select') {
      durval = this.filtduration
    }

    var send_data = {
      country: ipaddress.country_code,
      diag_center_id: this.hptl_clinic_id,
      duration: durval,
      date: date,
      type: "specimen",
      department : this.specimenPageType
    }
    console.log("getSampleList--send_data", send_data)

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/ghscl", send_data, { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        console.log("response", JSON.stringify(response.sample_header));
        this.sampleList = [];
        if (response.sample_header != undefined) {
          this.save_btn = false;
          for (var i = 0; i < response.sample_header.length; i++) {
            var cname;
            if (response.sample_header[i].middle_name != undefined) {
              cname = encrypt_decript.Decript(response.sample_header[i].first_name) + " " + encrypt_decript.Decript(response.sample_header[i].middle_name) + " " + encrypt_decript.Decript(response.sample_header[i].last_name);
            } else {
              cname = encrypt_decript.Decript(response.sample_header[i].first_name) + " " + encrypt_decript.Decript(response.sample_header[i].last_name);
            }

            var Drname;
            if (response.sample_header[i].dr_first_name != undefined || response.sample_header[i].dr_middle_name != undefined || response.sample_header[i].dr_last_name != undefined) {
              if (response.sample_header[i].dr_middle_name != undefined) {
                Drname = "Dr " + response.sample_header[i].dr_first_name + " " + response.sample_header[i].dr_middle_name + " " + response.sample_header[i].dr_last_name;
              } else {
                
               Drname = "Dr " + response.sample_header[i].dr_first_name + " " + response.sample_header[i].dr_last_name;
              }
            } else {
              Drname = "";
            }
            var dateset;
            if(response.sample_header[i].bill_date != undefined) {
              var date_val = response.sample_header[i].bill_date.split("-")
              dateset =date_val[2] + "-" + date_val[1] + "-" + date_val[0]
            }else {
              dateset="";
            }
           
            var timeset;
            if(response.sample_header[i].bill_time != undefined){
              timeset=Time_Formate(response.sample_header[i].bill_time);
            } else {
              timeset="";
            }
            this.sampleList.push({
              mrno: response.sample_header[i].mrno,
              patient_fname: encrypt_decript.Decript(response.sample_header[i].first_name),
              patient_name: cname,
              dr_name: Drname,
              bill_date:dateset ,
              bill_time: timeset,
              ward_name: response.sample_header[i].ward_name,
              bed_no: response.sample_header[i].bed_no,
              client_reg_id: response.sample_header[i].client_reg_id,
              relation_id: response.sample_header[i].relation_id,
              sub_rel_id: response.sample_header[i].sub_rel_id,

            })
          }

          this.filteredSamplelistArray = this.sampleList;         
          console.log("this.sampleList", this.sampleList);
        }

      }, error => { });
  }

  saveSamples() {
    this.selectedList = [];
    console.log("SAMPLE LIST "+JSON.stringify(this.sampleList))
    for (var i = 0; i < this.sampleList.length; i++) {
      if (this.sampleList[i].isSelected == true) {
        this.selectedList.push({
          pres_diag_id: this.sampleList[i].diagtestid,
          test_type: this.sampleList[i].test_type_name,
          pres_test_id: this.sampleList[i].prestestid,
          sample_type: this.sampleList[i].sample_type,
          mrno:this.sampleList[i].mrno,
        })
      }
    }

    console.log("this.selectedList", this.selectedList)
    var send_data = {
      user_id: Helper_Class.getInfo().user_id,
      country: ipaddress.country_code,
      tests: this.selectedList,
      diag_centre_id: this.hptl_clinic_id,
      department : this.specimenPageType,
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/sscl", send_data, { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        console.log(response)
        if (response.key == "1") {
          if(this.specimenPageType =="radiology") {
            this.toastr.success("Successfully saved visit details");
            
          } else {
            this.toastr.success("Successfully saved sample collections");
          }
          //this.toastr.success("Successfully saved sample collections");
          this.getSampleList(this.sampledate);
        }

      }, error => { })
  }

  saveSamples2() {
    this.selectedList = [];
    this.sample_id = [];

    for (var i = 0; i < this.sampleDetails_data.length; i++) {
      if (this.sampleDetails_data[i].isSelected == true) {
        var specimen_ids = [];
        var selectedtest = this.sampleDetails_data[i];
        console.log("selectedtest-sample save", selectedtest)
        specimen_ids = this.sampleDetails_data[i].selected_specimen;

        var specimen_names = [];
        for(var k = 0; k < specimen_ids.length; k++){
          for(var l = 0; l < selectedtest.specimen_types.length; l++){
            if(specimen_ids[k] == selectedtest.specimen_types[l].specimen_type_id){
              specimen_names.push({
                specimen_type_id: selectedtest.specimen_types[l].specimen_type_id,
                specimen_desc: selectedtest.specimen_types[l].specimen_desc
              })
            }
          }
        }

        console.log("specimen_names", specimen_names)        
        this.selectedList.push({
          pres_diag_id: selectedtest.test_no,
          test_type: selectedtest.test_type,
          pres_test_id: selectedtest.prestestid,
          sample_type: selectedtest.sample_type,
          outsource: selectedtest.selected_outsource,
          specimen: specimen_ids,
          specimen_names: specimen_names,
          priority: selectedtest.priority,
          tube_type: selectedtest.tube_color,
          sample_id: selectedtest.sample_id,
          mrno: selectedtest.mrno,
        })
      }
    }

    console.log("this.selectedList", this.selectedList)
    var send_data = {
      user_id: Helper_Class.getInfo().user_id,
      country: ipaddress.country_code,
      tests: this.selectedList,
      diag_centre_id: Helper_Class.getHospitalInfo().hptl_clinic_id,
      department : this.specimenPageType,
    }

    // this.sample_id = this.selectedList[0].sample_id; 
    console.log("saveSamples2", this.sample_id)

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "diagnosiscontroller/sscl", send_data, { headers: headers }).subscribe(
      data => {
        var response = JSON.parse(JSON.stringify(data));
        // this.sample_id = response[0].sample_id;
        console.log("saveSamples2---response", response)

        if (response.key == "1") {
          
          this.getSampleList(this.sampledate);
          this.sample_id = response.test_no;

          if (this.sampleList.length == 0){
            this.save_btn = true;
          }
          
          if(this.specimenPageType !="radiology") {
            this.toastr.success("Successfully saved sample collections");
            setTimeout(() => {
              this.printIdCard();
              // console.log(this.sample_id)
            }, 1000);
          } else {
            this.toastr.success("Successfully saved visit details");
          }
        }
      }, error => { })
    } 
  

  unselectAll(data){
    if (data == false){
      this.isSelected = false;
    }

    if(this.sampleDetails_data.every(value => value.isSelected == true)){
      this.isSelected = true;
    }
  }

  getSampleTypes() {
    this.sampletype_data = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gstbd', { diag_centre_id: this.hptl_clinic_id },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("obj----getSampleTypes", obj)
          this.lab_samples = obj.lab_samples;
          this.micro_samples = obj.micro_samples;
          this.all_samples = obj.all_samples;
          var sampleTypesData = obj.specimen_types;

          if (sampleTypesData!= undefined && sampleTypesData.length != 0) {
            for (var i = 0; i < sampleTypesData.length; i++) {
              this.sampletype_data.push({
                  sample_type_id: sampleTypesData[i].specimen_type_id,
                  sample_desc: sampleTypesData[i].specimen_desc,
                  tube_type_id: sampleTypesData[i].tube_type_id,
                  tube_color: sampleTypesData[i].tube_color,
                }
              )
            }
          }
        }, error => { });
  }

  getSampleDetails(data) {
    this.isSelected = false;

    this.patient_name = data.patient_fname;
    this.mrval = data.mrno;
    console.log("sample data "+JSON.stringify(data))
    this.sampleDetails_data = [];

    var durval;
    if (this.filtduration != 'select') {
      durval = this.filtduration
    }

    var senddata = {
      diag_centre_id: this.hptl_clinic_id,
      client_reg_id: data.client_reg_id,
      relation_id: data.relation_id,
      sub_rel_id: data.sub_rel_id,
      date: this.sampledate,
      duration: durval,
      country: ipaddress.country_code,
      type: "specimen",
      department : this.specimenPageType,
    }

    console.log("senddata--getSampleDetails", senddata)

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/ghscd', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var sampleDetails = obj.sample_list;
          console.log("getSampleDetails-obj " + JSON.stringify(obj));

          if (sampleDetails.length != 0) {
            for (var i = 0; i < sampleDetails.length; i++) {
              var outsourceFlag, selected_outsource;
              if (sampleDetails[i].outsources == undefined) {
                outsourceFlag = false;
              } else {
                outsourceFlag = true;
                selected_outsource = sampleDetails[i].outsources[0].out_id;
              }

              var Selected_specimen = [];
              this.selected_tube_colors = [];
              var specimen_type_array;
             
              specimen_type_array = this.all_samples;
              if(sampleDetails[i].sub_department == 'Microbiology'){
                //specimen_type_array = this.micro_samples;
                this.selected_tube_colors = [];
                
                if(sampleDetails[i].sample_type != undefined) {
                  Selected_specimen = sampleDetails[i].sample_type.split(',');
                }
               
              } else {
                if(sampleDetails[i].sample_type != undefined) {
                  this.selected_tube_colors = [];
                  var tube_array = [];
                  var sampleIds = '';
                  sampleIds = sampleDetails[i].sample_type.split(',');
    
                  if (sampleDetails[i].tube_image != undefined) {
                    var data = sampleDetails[i].tube_image
                  }
    
                  var d_sampleId = sampleDetails[i].dsample;
                  var isAssertion;
    
                  if (sampleDetails[i].assertion == '1') {
                    isAssertion = true;
                    for (var l = 0; l < specimen_type_array.length; l++) {
                      if (d_sampleId == specimen_type_array[l].specimen_type_id) {
                        this.selected_tube_colors = specimen_type_array[l].tube_color
                      }
                    }
                    Selected_specimen = [d_sampleId];
                  } else {
                    isAssertion = false;
                    for (var l = 0; l < specimen_type_array.length; l++) {
                      for (var j = 0; j < sampleIds.length; j++) {
                        if (sampleIds[j] == specimen_type_array[l].specimen_type_id) {
                          tube_array = specimen_type_array[l].tube_color;
                          if(tube_array != undefined) {
                            for (var k = 0; k < tube_array.length; k++) {
                              this.selected_tube_colors.push(tube_array[k])
                            }
                          }
                        }
                      }
                    }
                    Selected_specimen = sampleDetails[i].sample_type.split(',');
                  }
                }
              }

              this.sampleDetails_data.push({
                mrno:this.mrval,
                test_no: sampleDetails[i].diagtestid,
                test_type_name: sampleDetails[i].test_type_name,
                subtest_name: sampleDetails[i].test_name,
                test_amount: sampleDetails[i].price,
                tube_type: sampleDetails[i].tube_type,
                outsource: sampleDetails[i].outsources,
                sample_id: sampleDetails[i].sample_id,
                outsource_flag: outsourceFlag,
                prestestid: sampleDetails[i].prestestid,
                sample_type: sampleDetails[i].sample_type,
                selected_specimen: Selected_specimen,
                tube_color: this.selected_tube_colors,
                priority: "0",
                image:ipaddress.Ip_with_img_address+data,
                assertion: sampleDetails[i].assertion,
                assertion_reason: sampleDetails[i].assertion_reason,
                isAssertion: isAssertion,
                specimen_types: specimen_type_array,
                test_type:sampleDetails[i].test_type,
                selected_outsource: selected_outsource,
              })
              selected_outsource=undefined;
              outsourceFlag=false;
            }
            console.log("this.sampleDetails_data" + JSON.stringify(this.sampleDetails_data))
          }
        }, error => { });
  }

  selectAll() {
    console.log(this.isSelected)
    if (this.isSelected == true) {
      for (var i = 0; i < this.sampleDetails_data.length; i++) {
        this.sampleDetails_data[i].isSelected = true;
      }

    } else {
      for (var i = 0; i < this.sampleDetails_data.length; i++) {
        this.sampleDetails_data[i].isSelected = false;
      }
    }
  }

  getDiagTesttubes() {
    this.testtube_data = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdtt', { diag_centre_id:this.hptl_clinic_id },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          // var dept_data = obj.diag_dept_list;
          var diagtube_data = obj.tube_type_list;

          if (diagtube_data.length != 0) {
            for (var i = 0; i < diagtube_data.length; i++) {
              this.testtube_data.push(
                {
                  tube_type_id: diagtube_data[i].tube_type_id,
                  tube_desc: diagtube_data[i].tube_desc,
                  status: diagtube_data[i].status,
                }
              )
            }
          }
          console.log("getDiagTesttubes", obj)
        }, error => { });
  }

  getTubetype(sampleData, data) {
    this.selected_tube_colors = [];
    var tube_array = [];

    for (var i = 0; i < sampleData.specimen_types.length; i++) {      
      for (var j = 0; j < data.length; j++) {
        if (data[j] == sampleData.specimen_types[i].specimen_type_id) {
          // console.log(this.sampletype_data[i].tube_color)
          tube_array = sampleData.specimen_types[i].tube_color;
          for (var k = 0; k < tube_array.length; k++) {
            this.selected_tube_colors.push(tube_array[k])
          }
        }
      }
    }

    for (var k = 0; k < this.sampleDetails_data.length; k++) {
      if (this.sampleDetails_data[k].prestestid == sampleData.prestestid) {
        this.sampleDetails_data[k].tube_color = this.selected_tube_colors
      }
    }

    console.log("getTubetype", this.selected_tube_colors)
  }

  printIdCard() {
    let printContents, popupWin;
    printContents = document.getElementById('idprint').innerHTML;

    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
          <head>
            <title>Bill</title>
            <link rel="stylesheet" media="screen" href="">
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
            <style>
            @page { size: auto;  margin: 0mm; }
            .table_class {
              background-color: rgb(108, 180, 248);
            }

            *{
              font-family: monospace;
            }
            
            </style>
          </head>
              <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
            </html>`
    );
    popupWin.document.close();
  }

  openDialog(data) {
    this.service.openDialog(ipaddress.Ip_with_img_address + data);
  }

  filterSamplelistArray(searchInput) {
    this.filteredSamplelistArray = this.sampleList.filter(item =>
      item.mrno.toLowerCase().includes(searchInput.toLowerCase()) || item.patient_name.toLowerCase().includes(searchInput.toLowerCase()) || item.dr_name.toLowerCase().includes(searchInput.toLowerCase())
    );
  }
}
