<div class="row" style="width:100%;margin:0 auto;">
   <div class="col-12">
      <div class="row">
         <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" *ngIf="locationArray.length != 0" style="margin-left: 9px;margin-top: 13px;">
        <mat-label class="matlabel">Diagnosis Centre
          <mat-select class="ipcss" [(ngModel)]="info">
            <mat-option value="{{loc.diag_centre_name+'-'+loc.diag_loc}}"
              *ngFor="let loc of locationArray; let i = index" (click)="changeLocation(loc)">
              {{loc.diag_centre_name+"-"+loc.diag_loc}}
            </mat-option>
          </mat-select>
        </mat-label>
      </div> -->
      </div>
   </div>
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white ">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Completed</h5>
               </div>
               <div class="headerButtons">
                  <mat-label class="matlabel"><b>Total Count:</b>&nbsp; </mat-label>
                  <mat-label class="matlabel">{{updateDataArray.length}}</mat-label>
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>

            <div style="margin-top: -20px;">
               <div class="row">
                  <div class="col-2" style="margin-top: 20px; margin-bottom: 20px;margin-left: 22px !important;">
                     <select [(ngModel)]="filter_type" class="ipcss" (change)="changeFilter(filter_type)">
                        <option value="date">Date</option>
                        <option value="mr_no">MR No</option>
                        <option value="name">Name</option>
                     </select>
                  </div>
                  <div class="col-3" style="margin-top: 17px;" *ngIf="mr_no_nameFlag">
                     <input type="text" class="ipcss" aria-label="Number" [(ngModel)]="searchInput"
                        (keyup)="filterCompletedlistArray(searchInput)" placeholder={{filter_placeholder}} matInput />
                  </div>
                  <div class="col-2" style="margin-bottom: 12px;" *ngIf="date_Flag">
                     <mat-label class="matlabel">From
                        <input type="date" class="ipcss_date" id="appt_date1" [(ngModel)]="from_date" #matInput>
                     </mat-label>
                  </div>
                  <div class="col-2" style="margin-bottom: 12px;" *ngIf="date_Flag">
                     <mat-label class="matlabel">To
                        <input type="date" class="ipcss_date" id="appt_date2" [(ngModel)]="to_date" #matInput>
                     </mat-label>
                  </div>
                  <div class="col-2" style="margin-top: 17px;" *ngIf="date_Flag">
                     <img src="../../../assets/ui_icons/buttons/search_button.svg" (click)="changeLocation('')"
                        class="saveimgbtn_inpatinfo" />
                  </div>
               </div>
               <p class="nodata" [hidden]='updateDataArray.length!=0'>No test(s) found</p>
               <div class="container-fluid" *ngIf="updateDataArray.length">
                  <div class="row">
                     <div class="col-12">
                        <div>
                           <mat-accordion displayMode="flat" multi="false" class="mat-table">
                              <section matSort class="mat-elevation-z2 mat-header-row"
                                 style="background-color: #c1e6fb;">
                                 <!-- <span class="mat-header-cell">S.No</span> -->
                                 <span class="mat-header-cell">MR No</span>
                                 <span class="mat-header-cell">Patient Name</span>
                                 <span class="mat-header-cell">Age & gender</span>
                                 <span *ngIf="reporttypeval != 'radiology'" class="mat-header-cell">Sample Collected
                                    Date & Time</span>
                                 <span *ngIf="reporttypeval == 'radiology'" class="mat-header-cell">Visited Date &
                                    Time</span>
                                 <span class="mat-header-cell">Ward Details</span>
                                 <!-- <span class="mat-header-cell">Sample date & time</span> -->
                                 <!-- <span class="mat-header-cell">Collected date</span> -->
                                 <!-- <span *ngIf="reporttype == 'pathology'" class="mat-header-cell"></span> -->
                              </section>

                              <mat-expansion-panel *ngFor="let app of filteredCompletedlistArray">
                                 <mat-expansion-panel-header class="mat-row align-items-center">
                                    <!-- <span class="mat-cell">{{app.s_no}}</span> -->
                                    <span class="mat-cell">{{app.patient_diag_id}}</span>
                                    <span class="mat-cell">{{app.patient_name}}</span>
                                    <span class="mat-cell">{{app.age_gender}}</span>
                                    <span *ngIf="reporttypeval != 'radiology'"
                                       class="mat-cell">{{app.sample_collect_date_time}}</span>
                                    <span *ngIf="reporttypeval == 'radiology'"
                                       class="mat-cell">{{app.visited_date_time}}</span>
                                    <span class="mat-cell">{{app.ward_details}}</span>
                                    <!-- <span class="mat-cell">{{report.collected_date}}</span> -->
                                    <!-- <span *ngIf="reporttype == 'pathology'">
                                  <img width="30px" height="auto" style="margin-right: 12px;"
                                     src="../../../assets/ui_icons/awaiting_approval.svg"
                                     (click)="changeSubTestNew(report)">
                               </span> -->
                                 </mat-expansion-panel-header>
                                 <div>
                                    <p class="nodata" [hidden]='updateDataArray.length!=0'>No test(s) found</p>
                                    <table *ngIf="updateDataArray.length">
                                       <thead>
                                          <tr>
                                             <th>Test id</th>
                                             <th>Test name</th>
                                             <th>Bill date & time</th>
                                             <th *ngIf="reporttypeval != 'radiology'">Sample date & time</th>
                                             <th *ngIf="reporttypeval != 'radiology'">Assert date & time</th>
                                             <th *ngIf="reporttypeval == 'radiology'">Visited date & time</th>
                                             <th>Approved date & time</th>
                                             <!-- <th *ngIf="reporttype == 'radiology'">Visited date & time</th> -->
                                             <th style="width: 13%;" *ngIf="reporttypeval == 'pathology'">
                                                <div style="position: relative; text-align: center;">
                                                   <img width="35px" height="auto"
                                                      src="../../../assets/ui_icons/Completed_icon.svg"
                                                      (click)="viewReportDetails2(app)" />
                                                </div>
                                             </th>
                                             <th style="width: 5%;">
                                                <!-- <input type="checkbox" (change)="selectAll(app)" [(ngModel)]="isSelected"> -->
                                                <img *ngIf="reporttypeval == 'pathology' && app.re_conductFlag"
                                                   width="25px" height="auto" data-bs-toggle="tooltip"
                                                   data-bs-placement="top" title="Re-Conduct Test"  data-bs-toggle="modal" data-bs-target="#recheckModal"
                                                   src="../../../assets/ui_icons/redo.png"
                                                   (click)="reconductTest(app)" />
                                             </th>
                                          </tr>
                                       </thead>
                                       <!-- *ngFor="let row of rows; let i = index" -->
                                       <tbody>
                                          <tr *ngFor="let test of app.test_values">
                                             <td>{{test.pres_test_id}}</td>
                                             <td>{{test.test_name}}</td>
                                             <td>{{test.bill_date_time}}</td>
                                             <td *ngIf="reporttypeval != 'radiology'">{{test.sample_collect_date_time}}
                                             </td>
                                             <td *ngIf="reporttypeval != 'radiology'">{{test.assert_date_time}}</td>
                                             <td *ngIf="reporttypeval == 'radiology'">{{test.visited_date_time}}</td>
                                             <td>{{test.approvedDate}}</td>
                                             <td style="font-size: 12px; ">
                                                <div style="position: relative; top: 9px; text-align: center;">
                                                   <img *ngIf="test.status == 'Completed'" width="30px" height="auto"
                                                      src="../../../assets/ui_icons/Completed_icon.svg"
                                                      (click)="viewReportDetails(test.test_type, app.diag_app_id,test.pres_test_id, test.pres_diag_id, test.test_name, test.sample_collect_date_time, test.approvedDate, test.visited_date_time)" />
                                                   <img *ngIf="test.status == 'Awaiting For Approval'" width="30px"
                                                      height="auto" src="../../../assets/ui_icons/awaiting_approval.svg"
                                                      (click)="viewReportDetails(test.test_type, app.diag_app_id,test.pres_test_id, test.pres_diag_id, test.test_name, test.sample_collect_date_time, test.approvedDate, test.visited_date_time)" />
                                                   <p>{{test.status}}</p>
                                                </div>
                                             </td>
                                             <td *ngIf="reporttypeval == 'pathology'"
                                                style="font-size: 12px;width: 5%; padding-left: 30px;">
                                                <mdb-checkbox [(ngModel)]="test.isSelected">
                                                </mdb-checkbox>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </mat-expansion-panel>
                           </mat-accordion>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal fade" id="recheckModal" tabindex="-1" aria-labelledby="recheckModalLabel"
               aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="recheckModalLabel">Reason For Reconducting Test</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                     </div>
                     <div class="modal-body">
                        <textarea #matInput class="ipcss txtareaheight" maxlength="50"
                           [(ngModel)]="reconduct_reason"></textarea>
                     </div>
                     <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                              (click)="rejectReport('recheck')">Save</button> -->
                        <img src="../../../assets/ui_icons/buttons/save_button.svg" data-bs-dismiss="modal"
                           (click)="saveReconduct()" class="saveimgbtn_inpatinfo" />
                        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                     </div>
                  </div>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>