<div class="row">
    <!-- <div *ngIf="diagLocation" class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-4"
        style="margin-left: 9px;margin-top: 13px;">
        <mat-label class="matlabel">Diagnosis Centre
            <mat-select disableOptionCentering class="ipcss " [(ngModel)]="location">
                <mat-option [ngValue]="undefined" selected>Select</mat-option>
                <mat-option (click)="changeLocation(loc.view_data)" *ngFor="let loc of locationArray"
                    value="{{loc.view_data}}">{{loc.view_data}}</mat-option>
            </mat-select>
        </mat-label>
    </div> -->
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Report Approval</h5>
                    </div>
                    <div class="headerButtons" style="width: 50px;">
                        <mat-label class="matlabel"><b>Total Count:</b>&nbsp; </mat-label>
                        <mat-label class="matlabel">{{approvelListArrray.length}}</mat-label>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row" [hidden]="true">
                    <div class="col-12">
                        <div [hidden]='approvelListArrray.length!=0' class="nodata">No data found</div>
                        <div [hidden]='approvelListArrray.length==0'>
                            <table *ngIf="approvelListArrray.length" mdbTable datatable [dtOptions]="dtOptions"
                                class="table table-nowrap table-sm dataTable">
                                <thead>
                                    <tr>
                                        <th>M.R No</th>
                                        <th>Test No</th>
                                        <th>Test Name</th>
                                        <th>Patient Name</th>
                                        <th>Created Date & Time</th>
                                        <th>Bill Date & Time</th>
                                        <th *ngIf="reporttype == 'radiology'">Visited Date & Time</th>
                                        <th *ngIf="reporttype != 'radiology'">Collect Date & Time</th>
                                        <th *ngIf="reporttype != 'radiology'">Assert Date & Time</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let app of approvelListArrray"
                                        (click)="viewReportDetails(app.test_id,app.app_id,app.test_type,app.diag_id,app.test_name, app, app.createdDate )">
                                        <td style="text-align: left;">{{app.patient_diag_id}}</td>
                                        <td style="text-align: left;">{{app.test_no}}</td>
                                        <td style="text-align: left;">{{app.test_name}}</td>
                                        <td style="text-align: left;">{{ app.f_name }} {{app.l_name}}</td>
                                        <td style="text-align: center;">{{ app.createdDate }}</td>
                                        <td style="text-align: center;">{{ app.bill_date_time }}</td>
                                        <td *ngIf="reporttype == 'radiology'" style="text-align: center;">{{
                                            app.visited_date_time }}</td>
                                        <td *ngIf="reporttype != 'radiology'" style="text-align: center;">{{
                                            app.sample_collect_date_time }}</td>
                                        <td *ngIf="reporttype != 'radiology'" style="text-align: center;">{{
                                            app.assert_date_time }}</td>
                                        <td>
                                            <div style="position: relative;top: 9px;">
                                                <img width="30px" height="auto"
                                                    src="../../../assets/ui_icons/awaiting_approval.svg" />
                                                <p>Awaiting For Approval</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div>
                    <p class="nodata" [hidden]='approvelListArrray.length!=0'>No test(s) found</p>
                    <div class="container-fluid" *ngIf="approvelListArrray.length">
                        <div class="row">
                            <!-- <div class="col-2 m-3">
                                <mat-label class="matlabel">From
                                    <input type="date" class="ipcss_date" id="appt_date" [(ngModel)]="from_date" #matInput>
                                </mat-label>
                            </div>
                            <div class="col-2 m-3">
                                <mat-label class="matlabel">To
                                    <input type="date" class="ipcss_date" id="appt_date" [(ngModel)]="to_date" #matInput>
                                </mat-label>
                            </div> -->
                            <div class="col-3 m-3">
                                <input type="text" class="ipcss" aria-label="Number" [(ngModel)]="searchInput"
                                    (keyup)="filterApprovallistArray(searchInput)" placeholder="Search by MR.No or Name"
                                    matInput />
                            </div>
                            <div class="col-12">
                                <div>
                                    <mat-accordion displayMode="flat" multi="false" class="mat-table">
                                        <section matSort class="mat-elevation-z2 mat-header-row"
                                            style="background-color: #c1e6fb;">
                                            <span class="mat-header-cell">MR No</span>
                                            <span class="mat-header-cell">Patient Name</span>
                                            <span class="mat-header-cell">Age & gender</span>
                                            <span *ngIf="reporttype != 'radiology'" class="mat-header-cell">Sample
                                                Collected Date & Time</span>
                                            <span class="mat-header-cell">Doctor Name</span>
                                            <span class="mat-header-cell">Ward Details</span>
                                        </section>

                                        <mat-expansion-panel *ngFor="let app of filteredApprovallistArray">
                                            <mat-expansion-panel-header class="mat-row align-items-center">
                                                <span class="mat-cell">{{app.patient_diag_id}}</span>
                                                <span class="mat-cell">{{ app.patient_name}}</span>
                                                <span class="mat-cell">{{ app.age_gender}}</span>
                                                <span *ngIf="reporttype != 'radiology'"
                                                    class="mat-cell">{{app.sample_collect_date_time}}</span>
                                                <span class="mat-cell">{{app.Dr_name}}</span>
                                                <span class="mat-cell">{{app.ward}}</span>
                                            </mat-expansion-panel-header>
                                            <div>
                                                <p class="nodata" [hidden]='approvelListArrray.length!=0'>No test(s)
                                                    found
                                                </p>
                                                <table *ngIf="approvelListArrray.length">
                                                    <thead>
                                                        <tr>
                                                            <th>Test id</th>
                                                            <th>Test name</th>
                                                            <th>Bill date & time</th>
                                                            <th *ngIf="reporttype != 'radiology'">Sample date & time
                                                            </th>
                                                            <th *ngIf="reporttype != 'radiology'">Assert date & time
                                                            </th>
                                                            <th *ngIf="reporttype == 'radiology'">Visited date & time
                                                            </th>
                                                            <!-- <th *ngIf="reporttype == 'radiology'">Visited date & time</th> -->
                                                            <th style="width: 13%;" *ngIf="reporttype == 'pathology'">
                                                                <div style="position: relative; text-align: center;">
                                                                    <img width="35px" height="auto"
                                                                        src="../../../assets/ui_icons/Completed_icon.svg"
                                                                        (click)="viewReportDetails2(app)" />
                                                                </div>
                                                            </th>
                                                            <th *ngIf="reporttype == 'pathology'" style="width: 5%;">
                                                                <!-- <input type="checkbox" (change)="selectAll(app)" [(ngModel)]="isSelected"> -->
                                                            </th>
                                                            <th *ngIf="reporttype != 'pathology'">
                                                                <!-- <input type="checkbox" (change)="selectAll(app)" [(ngModel)]="isSelected"> -->
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <!-- *ngFor="let row of rows; let i = index" -->
                                                    <tbody>
                                                        <tr *ngFor="let test of app.test_values">
                                                            <td>{{test.test_id}}</td>
                                                            <td>{{test.test_name}}</td>
                                                            <td>{{test.bill_date_time}}</td>
                                                            <td *ngIf="reporttype != 'radiology'">
                                                                {{test.sample_collect_date_time}}
                                                            </td>
                                                            <td *ngIf="reporttype != 'radiology'">
                                                                {{test.assert_date_time}}</td>
                                                            <td *ngIf="reporttype == 'radiology'">
                                                                {{test.visited_date_time}}</td>
                                                            <td style="font-size: 12px; ">
                                                                <div
                                                                    style="position: relative; top: 9px; text-align: center;">
                                                                    <img width="30px" height="auto"
                                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                                        title="Awaiting For Approval"
                                                                        src="../../../assets/ui_icons/awaiting_approval.svg"
                                                                        (click)="viewReportDetails(test.test_id, app.app_id, test.test_type, app.diag_id, test.test_name, app, test.visited_date_time)" />
                                                                    <!-- <p>Awaiting For Approval</p> -->
                                                                </div>
                                                            </td>
                                                            <td *ngIf="reporttype == 'pathology'"
                                                                style="font-size: 12px;width: 5%; padding-left: 30px;">
                                                                <mdb-checkbox [(ngModel)]="test.isSelected">
                                                                </mdb-checkbox>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>